/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import AsyncSelect from 'react-select/async';
import _map from 'lodash/map';
import _set from 'lodash/set';
import qs from 'qs';
import axios from 'axios';
import { apiUrl } from '../../../app/api';
import { useAppSelector } from '../../../app/hooks';
import { selectAuthState } from '../../../app/slices/authSlice';
import selectComponents, { selectComponentsWithoutDropdownIndicator } from './CustomAsyncSelect.components';
import selectStyles from './CustomAsyncSelect.styles';

type AnyDataObject = {
  [key: string]: any;
};

interface CustomAsyncSelectProps {
  asyncPath: string;
  asyncLimit?: number;
  asyncSort?: string[];
  setAsyncParams?: (inputValue: string, params: AnyDataObject) => void;
  formatOption?: (entry: AnyDataObject) => AnyDataObject;
  value?: AnyDataObject | AnyDataObject[];
  placeholder?: string;
  defaultOptions?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDropdownIndicator?: boolean;
  onChange?: (newValue: any, actionMeta: any) => void;
}

const CustomAsyncSelect: React.FC<CustomAsyncSelectProps> = ({
  asyncPath,
  asyncLimit,
  asyncSort,
  setAsyncParams,
  formatOption,
  value,
  placeholder,
  defaultOptions,
  isMulti,
  isClearable,
  isSearchable,
  isDropdownIndicator,
  onChange,
}) => {
  const { jwt } = useAppSelector(selectAuthState);

  const createOptions = (entries: AnyDataObject[]): AnyDataObject[] => {
    if (typeof formatOption === 'function') {
      return entries.map((entry) => formatOption({ id: entry.id, ...entry.attributes }));
    }
    return [];
  };

  const loadOptions = async (inputValue: string, callback: (options: any) => void) => {
    const params = {};

    if (asyncLimit) {
      _set(params, ['pagination', 'limit'], asyncLimit);
    }

    if (asyncSort) {
      asyncSort.forEach((sortString) => {
        _set(params, 'sort', [sortString]);
      });
    }

    if (typeof setAsyncParams === 'function') {
      setAsyncParams(inputValue, params);
    }

    const paramStrings = _map(params, (args, key) => qs.stringify({ [key]: args }));

    const { data } = await axios.get(`${apiUrl}${asyncPath}?${paramStrings.join('&')}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (data?.data) {
      callback(createOptions(data.data));
    }
  };

  return (
    <AsyncSelect
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      value={value}
      placeholder={placeholder || ''}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      onChange={onChange}
      noOptionsMessage={() => 'Keine Ergebnisse'}
      openMenuOnFocus={false}
      openMenuOnClick={false}
      menuPlacement="auto"
      cacheOptions
      components={isDropdownIndicator === false ? selectComponentsWithoutDropdownIndicator : selectComponents}
      styles={selectStyles}
    />
  );
};

export default CustomAsyncSelect;
