/* eslint-disable @typescript-eslint/no-explicit-any */
import _map from 'lodash/map';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import qs from 'qs';
import { requestEntities, RequestEntitiesAction, RequestEntitiesThunkArg } from '../requestEntities';
import { assetSchema } from '../schemas';

export const createAsset = (data: { name?: string }): RequestEntitiesAction<AssetData> =>
  requestEntities({
    method: 'POST',
    path: '/assets',
    data,
    schema: assetSchema,
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchAssets = (params?: any): RequestEntitiesAction<AssetData[]> => {
  const requestData: RequestEntitiesThunkArg = {
    method: 'GET',
    path: '/assets',
    schema: [assetSchema],
    withCount: true,
  };

  if (params) {
    const paramStrings = _map(params, (args, key) => qs.stringify({ [key]: args }));
    requestData.path = `${requestData.path}?${paramStrings.join('&')}`;
  }

  return requestEntities(requestData);
};

export const fetchAsset = (assetId: number): RequestEntitiesAction<AssetData> =>
  requestEntities({
    method: 'GET',
    path: `/assets/${assetId}`,
    schema: assetSchema,
  });

export const updateAsset = (assetId: number, data: AssetUpdateData): RequestEntitiesAction<AssetData> =>
  requestEntities({
    method: 'PUT',
    path: `/assets/${assetId}`,
    data,
    schema: assetSchema,
  });

export const deleteAsset = (assetId: number): RequestEntitiesAction<AssetData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/assets/${assetId}`,
    schema: assetSchema,
    type: 'deleteAsset',
  });
};

const assetsAdapter = createEntityAdapter<AssetEntity>();

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: assetsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.assets) {
        assetsAdapter.upsertMany(state, action.payload.entities.assets);
      }
      if (action.meta.arg.type === 'deleteAsset') {
        assetsAdapter.removeOne(state, action.payload.data.id);
      }
    });
  },
});

export default assetsSlice.reducer;
