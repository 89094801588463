import { Block, Group, GroupItem, InlineSVG } from '@webfox-sc/core';
import { IconChevronDown, IconChevronUp, IconDelete, IconPencil } from '@webfox-sc/icons';
import { micromark } from 'micromark';
import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import IconMapMarker from '../../assets/icons/IconMapMarker';
import { COLORS, SPACINGS } from '../../styles/theme';
import { stripMarkup } from '../../utils/article';
import useAPIError from '../apiError/useAPIError';
import { ConfirmDialogContext } from '../dialog/ConfirmDialogProvider';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Headline from '../shared/atoms/Headline';
import Inline from '../shared/atoms/Inline';
import Panel from '../shared/atoms/Panel';
import Text from '../shared/atoms/Text';
import TypeLabel from '../shared/atoms/TypeLabel';
import { useSnippet } from '../snippet/useSnippet';
import StatusPanel from '../status/StatusPanel';
import TagGroup from '../tags/TagGroup';
import { useArticleSections } from './useArticleSections';

interface ArticleSectionSnippetProps {
  sectionId: number;
  articleId?: number;
  snippetId?: number;
  isFirst?: boolean;
  isLast?: boolean;
  onClickUp?: (snippetId: number | undefined) => void;
  onClickDown?: (snippetId: number | undefined) => void;
}

const ArticleSectionSnippet: React.FC<ArticleSectionSnippetProps> = ({
  sectionId,
  articleId,
  snippetId,
  isFirst,
  isLast,
  onClickUp,
  onClickDown,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { confirmAction } = useContext(ConfirmDialogContext);

  const { snippet, contentFormatLabel } = useSnippet(snippetId);
  const { getSectionsMapReducedBySnippet } = useArticleSections(articleId);

  const previewText = useMemo(() => {
    if (!snippet?.content) {
      return undefined;
    }
    const html = micromark(snippet.content);
    const text = stripMarkup(html);
    return text.length > 300 ? `${text.slice(0, 300)} ...` : text;
  }, [snippet]);

  if (!snippet || !articleId) {
    return null;
  }

  const viewPath = `/article/${articleId}/snippet/${snippetId}/view`;
  const editPath = `/article/${articleId}/snippet/${snippetId}/edit`;

  const handleOnClickView = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(viewPath);
  };

  const handleOnClickEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(editPath);
  };

  const handleOnClickRemove = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (await confirmAction('Textelement entfernen?')) {
      const response = await dispatch(
        updateArticle(articleId, {
          Section: getSectionsMapReducedBySnippet(snippet.id),
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  return (
    <>
      <Group nowrap spacing={SPACINGS.XXS}>
        <GroupItem grow>
          <Panel verticalStretch padding={SPACINGS.XL}>
            <TypeLabel primaryLabel="Textelement" secondaryLabel={contentFormatLabel} />
            <Block paddingTop={SPACINGS.M}>
              <Headline>{snippet.name}</Headline>
            </Block>
            {previewText && (
              <Block paddingTop={SPACINGS.M}>
                <Text nl2br>{previewText}</Text>
              </Block>
            )}
            {snippet.comment && (
              <Block paddingTop={SPACINGS.M}>
                <Inline color={COLORS.GREY}>Versionskommentar:</Inline> {snippet.comment}
              </Block>
            )}
            {snippet.locationName && (
              <Block paddingTop={SPACINGS.M}>
                <Text>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${snippet.location?.lat},${snippet.location?.lng}`}
                    target="_blank"
                  >
                    <InlineSVG width="24px" height="24px">
                      {IconMapMarker}
                    </InlineSVG>{' '}
                    {snippet.locationName}
                  </a>
                </Text>
              </Block>
            )}
            {(snippet.tagIds || []).length > 0 && (
              <Block paddingTop={SPACINGS.M}>
                <TagGroup tagIds={snippet.tagIds} />
              </Block>
            )}
            <Block paddingTop={SPACINGS.L}>
              <ButtonPrimary label="Ansehen" href={viewPath} onClick={handleOnClickView} />
            </Block>
          </Panel>
        </GroupItem>
        <GroupItem>
          <StatusPanel
            showStatusLabel
            showSnippetItems
            status={snippet.status}
            createdTime={snippet.createdTime}
            createdByUserId={snippet.createdByUserId}
            updatedTime={snippet.updatedTime}
            updatedByUserId={snippet.updatedByUserId}
            publicationTime={snippet.publicationTime}
            snippetCharacters={snippet.characters}
            snippetLines={snippet.lines}
            verticalStretch
          />
        </GroupItem>
        <GroupItem>
          <Group vertical spacing={SPACINGS.XXS}>
            <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
            <ButtonPrimary
              ariaLabel="Nach oben"
              icon={IconChevronUp}
              disabled={isFirst}
              onClick={() => {
                if (!isFirst && typeof onClickUp === 'function') {
                  onClickUp(sectionId);
                }
              }}
            />
            <ButtonPrimary
              ariaLabel="Nach unten"
              icon={IconChevronDown}
              disabled={isLast}
              onClick={() => {
                if (!isLast && typeof onClickDown === 'function') {
                  onClickDown(sectionId);
                }
              }}
            />
            <ButtonPrimary ariaLabel="Entfernen" icon={IconDelete} onClick={handleOnClickRemove} />
          </Group>
        </GroupItem>
      </Group>
    </>
  );
};

export default ArticleSectionSnippet;
