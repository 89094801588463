import React, { useEffect } from 'react';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import Headline from '../shared/atoms/Headline';
import { fetchNewspapers, selectNewspapersState } from '../../app/slices/newspapersSlice';
import { SPACINGS } from '../../styles/theme';
import { useNewspapers } from './useNewspapers';
import Panel from '../shared/atoms/Panel';
import Text from '../shared/atoms/Text';
import NewspaperTeaser from './NewspaperTeaser';
import useAPIError from '../apiError/useAPIError';

const Newspapers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { fetchNewspapersStatus } = useAppSelector(selectNewspapersState);
  const { newspapersSorted } = useNewspapers();

  useEffect(() => {
    (async () => {
      const response = await dispatch(fetchNewspapers());
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    })();
  }, [addError, dispatch]);

  return (
    <LoadingWrapper
      requestStatus={fetchNewspapersStatus}
      errorCode="404"
      errorText={'Die Zeitungen konnten nicht geladen werden.'}
    >
      <Block justify="center" position="relative">
        <Headline variant="H1">Zeitungen</Headline>
      </Block>

      <Block paddingTop={SPACINGS.XL} paddingBottom={SPACINGS.XXXL}>
        {newspapersSorted.length > 0 ? (
          <Grid columns={2} spacing={SPACINGS.S}>
            {newspapersSorted.map((newspaper) => {
              return (
                <GridItem key={newspaper.id}>
                  <NewspaperTeaser newspaperId={newspaper.id} />
                </GridItem>
              );
            })}
          </Grid>
        ) : (
          <Block>
            <Panel padding={SPACINGS.XL}>
              <Text>Es sind derzeit keine Zeitungen vorhanden.</Text>
            </Panel>
          </Block>
        )}
      </Block>
    </LoadingWrapper>
  );
};

export default Newspapers;
