import React from 'react';
import styled from 'styled-components';
import { COLORS, SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import { Block, Group, GroupItem, Image } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { useAsset } from './useAsset';
import { useMediaAsset } from './useMediaAsset';
import FormButton from '../shared/atoms/FormButton';
import { IconPlus } from '@webfox-sc/icons';
import RatioBox from '../shared/atoms/RatioBox';
import Panel from '../shared/atoms/Panel';
import TagGroup from '../tags/TagGroup';
import { useStatus } from '../status/useStatus';
import { useAssetMedia } from './useAssetMedia';

const StyledTable = styled.table({
  borderSpacing: 0,
});

const StyledTableCell = styled.td({
  padding: 0,
  textAlign: 'left',
  '&:first-of-type': {
    paddingRight: SPACINGS.XXS,
  },
});

interface FormSectionConnectAssetGroupItemProps {
  assetId: number;
  onSelect: (assetId: number) => void;
  disabled?: boolean;
}

const FormSectionConnectAssetItem: React.FC<FormSectionConnectAssetGroupItemProps> = ({
  assetId,
  onSelect,
  disabled,
}) => {
  const { asset } = useAsset(assetId);
  const { mediaAssets } = useAssetMedia(assetId);
  const { firstFile, firstFileTeaserImageSrc, firstFileIsWebImage } = useMediaAsset((asset?.mediaAssetIds || [])[0]);
  const fileMimeSecondary = firstFile?.mime ? firstFile.mime.split('/')[1] : '';

  const { createdTimeFormatted, updatedTimeFormatted } = useStatus({
    createdTime: asset?.createdTime,
    updatedTime: asset?.updatedTime,
  });

  return (
    <Group nowrap spacing={SPACINGS.XXS}>
      <GroupItem grow>
        <Panel background={COLORS.WHITE} padding={SPACINGS.XS}>
          <Group nowrap spacing={SPACINGS.XS}>
            <GroupItem>
              <Block width="120px">
                {firstFileIsWebImage ? (
                  <Image
                    src={firstFileTeaserImageSrc}
                    alt={firstFile?.alternativeText || '–'}
                    ratio="1:1"
                    objectFit="contain"
                    background={COLORS.DARK}
                  />
                ) : (
                  <RatioBox ratio="1:1" background={COLORS.DARK}>
                    <Block width="100%" height="100%" justify="center" alignItems="center">
                      <Headline color={COLORS.GREY}>{fileMimeSecondary && fileMimeSecondary.toUpperCase()}</Headline>
                    </Block>
                  </RatioBox>
                )}
              </Block>
            </GroupItem>
            <GroupItem grow>
              <Text>{asset?.name}</Text>
              <Text variant="small" color={COLORS.GREY}>
                {mediaAssets.length || 'Keine'} {mediaAssets.length === 1 ? 'Datei' : 'Dateien'}
              </Text>
              <Block paddingTop={SPACINGS.XXS}>
                <TagGroup tagIds={asset?.tagIds} variant="boxesWithoutLink" />
              </Block>
            </GroupItem>
            <GroupItem>
              <StyledTable>
                <tbody>
                  <tr>
                    <StyledTableCell>
                      <Text variant="label-small">Erstellt:</Text>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Text variant="small" nowrap>
                        {createdTimeFormatted}
                      </Text>
                    </StyledTableCell>
                  </tr>
                  <tr>
                    <StyledTableCell>
                      <Text variant="label-small">Aktualisiert:</Text>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Text variant="small" nowrap>
                        {updatedTimeFormatted}
                      </Text>
                    </StyledTableCell>
                  </tr>
                </tbody>
              </StyledTable>
            </GroupItem>
          </Group>
        </Panel>
      </GroupItem>
      <GroupItem>
        <FormButton
          variant="smallWithBorder"
          ariaLabel="auswählen"
          icon={IconPlus}
          onClick={() => !disabled && onSelect(assetId)}
          disabled={disabled}
        />
      </GroupItem>
    </Group>
  );
};

export default FormSectionConnectAssetItem;
