import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { ressortSchema } from '../schemas';

export const fetchRessorts = (): RequestEntitiesAction<RessortData[]> =>
  requestEntities({
    method: 'GET',
    path: '/ressorts',
    schema: [ressortSchema],
  });

const ressortsAdapter = createEntityAdapter<RessortEntity>();

export const ressortsSlice = createSlice({
  name: 'ressorts',
  initialState: ressortsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.ressorts) {
        ressortsAdapter.upsertMany(state, action.payload.entities.ressorts);
      }
    });
  },
});

export default ressortsSlice.reducer;
