import React from 'react';
import Text from '../shared/atoms/Text';

interface StatusPanelLegacyItemProps {
  label?: string;
  text?: string;
}

const StatusPanelItem: React.FC<StatusPanelLegacyItemProps> = ({ label, children }) => {
  return (
    <>
      <Text variant="label-small" textAlign="center">
        {label}
      </Text>
      <Text variant="small" textAlign="center">
        {children}
      </Text>
    </>
  );
};

export default StatusPanelItem;
