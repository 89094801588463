import _orderBy from 'lodash/orderBy';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';

type SortingKeyOption = {
  value: string;
  label: string;
};

type SortingOrderOption = {
  value: 'asc' | 'desc';
  label: string;
};

const sortingKeyOptions: SortingKeyOption[] = [
  {
    value: 'createdAt',
    label: 'Erstellt',
  },
  {
    value: 'updatedAt',
    label: 'Aktualisiert',
  },
  {
    value: 'name',
    label: 'Name',
  },
];

const sortingOrderOptions: SortingOrderOption[] = [
  {
    value: 'asc',
    label: 'Aufsteigend',
  },
  {
    value: 'desc',
    label: 'Absteigend',
  },
];

export const useStoryArticles = (
  storyId?: number
): {
  sortingKeyOptions: SortingKeyOption[];
  selectedSortingKeyOption: SortingKeyOption;
  setSelectedSortingKeyOption: React.Dispatch<React.SetStateAction<SortingKeyOption>>;
  sortingOrderOptions: SortingOrderOption[];
  selectedSortingOrderOption: SortingOrderOption;
  setSelectedSortingOrderOption: React.Dispatch<React.SetStateAction<SortingOrderOption>>;
  articlesSorted?: ArticleEntity[];
} => {
  const [selectedSortingKeyOption, setSelectedSortingKeyOption] = useState(sortingKeyOptions[1]);
  const [selectedSortingOrderOption, setSelectedSortingOrderOption] = useState(sortingOrderOptions[1]);

  const story = useAppSelector(({ entities }) => entities.stories.entities[storyId || 0]);

  const articlesSorted = useAppSelector(({ entities }) => {
    const articles: ArticleEntity[] = [];
    if (story?.articleIds) {
      story?.articleIds?.forEach((articleId) => {
        const article = entities.articles.entities[articleId];
        if (article) {
          articles.push(article);
        }
      });
    }
    return _orderBy(
      articles,
      [(article) => article[selectedSortingKeyOption.value]],
      [selectedSortingOrderOption.value]
    );
  }, shallowEqual);

  return {
    sortingKeyOptions,
    selectedSortingKeyOption,
    setSelectedSortingKeyOption,
    sortingOrderOptions,
    selectedSortingOrderOption,
    setSelectedSortingOrderOption,
    articlesSorted,
  };
};
