import { Block } from '@webfox-sc/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS, SPACINGS } from '../../../styles/theme';

type MetaTableVariant = 'default' | 'compact';

const StyledDecoLine = styled.div({
  width: '112px',
  height: '4px',
  background: COLORS.MID_GREY,
});

interface StyledTableProps {
  variant?: MetaTableVariant;
}

const StyledTable = styled.table<StyledTableProps>(({ variant }) => {
  return {
    borderSpacing: 0,
    textIndent: 0,
    border: 'none',
    width: '100%',
    tr: {
      td: {
        paddingTop: variant === 'compact' ? 0 : SPACINGS.XXS,
        verticalAlign: 'top',
      },
      '&:first-of-type': {
        td: {
          paddingTop: 0,
        },
      },
    },
  };
});

interface MetaTableProps {
  variant?: MetaTableVariant;
  showDecoLine?: boolean;
}

const MetaTable: React.FC<MetaTableProps> = ({ variant, showDecoLine, children }) => {
  return (
    <>
      {showDecoLine && (
        <Block paddingBottom={SPACINGS.L}>
          <StyledDecoLine />
        </Block>
      )}
      <StyledTable variant={variant}>
        <tbody>{children}</tbody>
      </StyledTable>
    </>
  );
};

export default MetaTable;
