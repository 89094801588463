import { micromark } from 'micromark';
import { LINES_FACTOR, stripMarkup } from '../../utils/article';

type SnippetSize = {
  charCount: number;
  linesCount: number;
};

export const useSnippetSize = (content: string | undefined): SnippetSize => {
  const countHTML = micromark(stripMarkup(content || ''));
  const pureText = stripMarkup(countHTML);
  const charCount = pureText.length;
  const linesCount = Math.ceil(charCount / LINES_FACTOR);

  return {
    charCount,
    linesCount,
  };
};
