import _set from 'lodash/set';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const filterDataToParams = (filterData: FilterData, useSearchQuery?: boolean): any => {
  const params: any = {};
  const filters: any = [];

  if (filterData?.start) {
    _set(params, ['pagination', 'start'], filterData.start);
  }
  if (filterData?.limit) {
    _set(params, ['pagination', 'limit'], filterData.limit);
  }
  if (filterData?.searchText) {
    if (useSearchQuery) {
      _set(params, ['_q'], filterData.searchText);
    } else {
      filters.push({
        name: {
          $containsi: filterData.searchText,
        },
      });
    }
  }
  if (filterData?.dateRange && filterData?.dateRange !== 'entire_period') {
    const now = new Date();
    let daysToSubtract: number;
    switch (filterData.dateRange) {
      case 'last_7_days':
        daysToSubtract = 7;
        break;
      case 'last_30_days':
        daysToSubtract = 30;
        break;
      default:
        daysToSubtract = 0;
        break;
    }
    const filterDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysToSubtract);

    filters.push({
      updatedAt: {
        $gte: filterDate.toISOString(),
      },
    });
  }
  if (filterData?.sortingKey && filterData?.sortingOrder) {
    _set(params, 'sort', [`${filterData.sortingKey}:${filterData.sortingOrder}`]);
  }
  if (filterData?.excludeIds?.length) {
    filters.push({
      id: {
        $notIn: filterData.excludeIds,
      },
    });
  }
  if (filterData?.tagIds?.length) {
    filters.push(
      ...filterData.tagIds.map((tagId) => ({
        tags: {
          id: {
            $in: [tagId],
          },
        },
      }))
    );
  }
  if (filters.length > 0) {
    if (filters.length > 1) {
      _set(params, ['filters', '$and'], filters);
    } else {
      _set(params, ['filters'], filters[0]);
    }
  }

  return params;
};
