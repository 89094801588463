import React, { useEffect, useState } from 'react';
import { Block, Collapse, Group } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import Input from '../shared/atoms/Input';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import { useOverlay } from '../overlay/useOverlay';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectStoriesState, updateStory } from '../../app/slices/storiesSlice';
import { useCurrentStory } from './useCurrentStory';
import FormSection from '../shared/forms/FormSection';
import FormSectionTags from '../tags/FormSectionTags';
import TextArea from '../shared/atoms/TextArea';
import { useStatus } from '../status/useStatus';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import useAPIError from '../apiError/useAPIError';

const StoryEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { fetchStoryStatus } = useAppSelector(selectStoriesState);

  const { storyId, story } = useCurrentStory();
  const { updatedTextLine } = useStatus({
    updatedTime: story?.updatedTime,
    updatedByUserId: story?.updatedByUserId,
  });

  const [name, setName] = useState(story?.name || '');
  const [isNameError, setIsNameError] = useState(false);
  const [description, setDescription] = useState(story?.description || '');
  const [tagIds, setTagIds] = useState(story?.tagIds);

  useEffect(() => {
    setName(story?.name || '');
    setDescription(story?.description || '');
    setTagIds(story?.tagIds);
  }, [story]);

  useEffect(() => {
    setIsNameError(false);
  }, [name]);

  const handleOnClickUpdate = async () => {
    if (!name) {
      setIsNameError(true);
    } else if (storyId) {
      const result = await dispatch(
        updateStory(storyId, {
          name,
          description,
          tags: tagIds,
        })
      );
      if (result.payload?.error) {
        addError(result.payload.error.message);
      }
      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Story" title="Metadaten bearbeiten">
      <LoadingWrapper
        requestStatus={fetchStoryStatus}
        errorCode="404"
        errorText={'Die Story konnte nicht geladen werden.'}
      >
        <Block minHeight="40px" alignItems="center">
          <Text variant="small">{updatedTextLine}</Text>
        </Block>

        <FormSection title="Titel *" paddingTop={SPACINGS.XL}>
          <Input
            name="name"
            autoComplete="ignore"
            value={name}
            error={isNameError}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameError(false)}
          />
          <Collapse show={isNameError}>
            <Block paddingTop={SPACINGS.XS}>
              <Text variant="small" color={COLORS.RED}>
                Dies ist ein Pflichtfeld.
              </Text>
            </Block>
          </Collapse>
        </FormSection>

        <FormSection title="Beschreibung" paddingTop={SPACINGS.S}>
          <TextArea name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormSection>

        <FormSectionTags paddingTop={SPACINGS.S} defaultTagIds={story?.tagIds} onChange={setTagIds} />

        <FormSection paddingTop={SPACINGS.XL}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
            <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
          </Group>
        </FormSection>
      </LoadingWrapper>
    </OverlayLayout>
  );
};

export default StoryEdit;
