import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import qs from 'qs';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { pageSchema } from '../schemas';

export const createPage = (data: PageCreateData): RequestEntitiesAction<PageData> =>
  requestEntities({
    method: 'POST',
    path: '/pages',
    data,
    schema: pageSchema,
  });

export const refetchPage = (pageId: number): RequestEntitiesAction<PageData> =>
  requestEntities({
    method: 'GET',
    path: `/pages/${pageId}`,
    schema: pageSchema,
  });

export const updatePage = (pageId: number, data: PageUpdateData): RequestEntitiesAction<PageData[]> =>
  requestEntities({
    method: 'PUT',
    path: `/pages/${pageId}`,
    data,
    schema: [pageSchema],
  });

export const deletePage = (pageId: number): RequestEntitiesAction<PageData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/pages/${pageId}`,
    schema: pageSchema,
  });
};

export const fetchPagesWithoutIssue = (): RequestEntitiesAction<PageData[]> =>
  requestEntities({
    method: 'GET',
    path: `/pages?${qs.stringify({
      filters: {
        issue: {
          id: {
            $null: true,
          },
        },
      },
    })}`,
    schema: [pageSchema],
    type: 'fetchPagesWithoutIssue',
  });

const pagesAdapter = createEntityAdapter<PageEntity>();

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: pagesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.pages) {
        pagesAdapter.upsertMany(state, action.payload.entities.pages);
      }
    });
  },
});

export default pagesSlice.reducer;
