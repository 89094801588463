import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useIssuePages = (
  issueId: number | undefined
): {
  issue?: IssueEntity;
  pagesSorted: PageEntity[];
  pagesSortedIds: number[];
  pagesSortedByCreatedDate: PageEntity[];
  pagesSortedByCreatedDateIds: number[];
} => {
  const issue = useAppSelector(({ entities }) => entities.issues.entities[issueId || 0]);
  const pages =
    issueId !== undefined
      ? entitySelectors.selectByIds<PageEntity>('pages', issue?.pageIds)
      : entitySelectors.selectByFilter<PageEntity>('pages', (page: PageEntity) => !page.issueId);

  const pagesSorted = useMemo(() => {
    return _orderBy(pages, [(page) => page.number], ['asc']);
  }, [pages]);

  const pagesSortedIds = useMemo(() => {
    return pagesSorted.map((page) => page.id);
  }, [pagesSorted]);

  const pagesSortedByCreatedDate = useMemo(() => {
    return _orderBy(pages, [(page) => page.createdTime], ['asc']);
  }, [pages]);

  const pagesSortedByCreatedDateIds = useMemo(() => {
    return pagesSortedByCreatedDate.map((page) => page.id);
  }, [pagesSortedByCreatedDate]);

  return {
    issue,
    pagesSorted,
    pagesSortedIds,
    pagesSortedByCreatedDate,
    pagesSortedByCreatedDateIds,
  };
};
