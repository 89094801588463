import { useAppSelector } from '../../app/hooks';

export const useAuthor = (
  authorId: number | undefined
): {
  author?: AuthorEntity;
} => {
  const author = useAppSelector(({ entities }) => entities.authors.entities[authorId || 0]);

  return {
    author,
  };
};
