import React, { useEffect } from 'react';
import { Block } from '@webfox-sc/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchStory, selectStoriesState } from '../../app/slices/storiesSlice';
import { SPACINGS } from '../../styles/theme';
import TypeLabel from '../shared/atoms/TypeLabel';
import StoryMeta from './StoryMeta';
import StoryArticles from './StoryArticles';
import { useCurrentStory } from './useCurrentStory';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import useAPIError from '../apiError/useAPIError';

const Story: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { fetchStoryStatus } = useAppSelector(selectStoriesState);
  const { storyId } = useCurrentStory();

  useEffect(() => {
    (async () => {
      if (storyId) {
        const response = await dispatch(fetchStory(storyId));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [addError, dispatch, storyId]);

  return (
    <LoadingWrapper
      requestStatus={fetchStoryStatus}
      errorCode="404"
      errorText={'Die Story konnte nicht geladen werden.'}
    >
      <TypeLabel primaryLabel="Story" />

      <Block paddingTop={SPACINGS.L}>
        <StoryMeta storyId={storyId} />
      </Block>

      <Block paddingTop={SPACINGS.XL} paddingBottom={SPACINGS.XXXL}>
        <StoryArticles storyId={storyId} />
      </Block>
    </LoadingWrapper>
  );
};

export default Story;
