import { Grid, GridItem } from '@webfox-sc/core';
import React, { useState } from 'react';
import { SPACINGS } from '../../styles/theme';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';

type IssueElementType = 'articles' | 'pages';

interface IssueElementBoxSwitchProps {
  onChange?: (issueElement: IssueElementType) => void;
}

const IssueElementBoxSwitch: React.FC<IssueElementBoxSwitchProps> = ({ onChange }) => {
  const [selectedElement, setSelectedElement] = useState<IssueElementType>('articles');

  const handleOnClick = (issueElement: IssueElementType) => {
    return () => {
      setSelectedElement(issueElement);
      if (onChange) {
        onChange(issueElement);
      }
    };
  };

  return (
    <Grid columns={2} spacing={SPACINGS.XXS}>
      <GridItem>
        <ButtonSecondary
          variant="small"
          width="100%"
          minWidth="none"
          label="Beiträge"
          active={selectedElement === 'articles'}
          onClick={handleOnClick('articles')}
        />
      </GridItem>
      <GridItem>
        <ButtonSecondary
          variant="small"
          width="100%"
          minWidth="none"
          label="Seiten"
          active={selectedElement === 'pages'}
          onClick={handleOnClick('pages')}
        />
      </GridItem>
    </Grid>
  );
};

export default IssueElementBoxSwitch;
