import React from 'react';
import useAPIError from './useAPIError';
import styled from 'styled-components';
import APIErrorNotification from './APIErrorNotification';

const ToastContainer = styled.div(() => ({
  position: 'fixed',
  bottom: '5px',
  display: 'flex',
  left: '50%',
  transform: 'translateX(-50%)',
  flexFlow: 'column',
}));

const APIErrorNotifications: React.FC = () => {
  const { errors } = useAPIError();

  return (
    <>
      {errors.length ? (
        <ToastContainer>
          {errors.map((error) => (
            <APIErrorNotification key={error.id} error={error} />
          ))}
        </ToastContainer>
      ) : null}
    </>
  );
};

export default APIErrorNotifications;
