import React from 'react';
import { SPACINGS } from '../../styles/theme';
import { Group } from '@webfox-sc/core';
import TextLink from '../shared/atoms/TextLink';
import { useSnippets } from './useSnippets';
import { useCurrentArticle } from '../article/useCurrentArticle';

interface SnippetVersionLinksProps {
  snippetIds?: number[];
}

const SnippetVersionLinks: React.FC<SnippetVersionLinksProps> = ({ snippetIds }) => {
  const { articleId } = useCurrentArticle();
  const { snippets } = useSnippets(snippetIds);

  return (
    <Group vSpacing={SPACINGS.XXXS} vertical>
      {snippets.map((snippet) => {
        return (
          <TextLink key={snippet.id} to={`/article/${articleId}/snippet/${snippet.id}/view`} replace>
            {snippet.name}
            {snippet.comment ? ` – ${snippet.comment}` : ''}
          </TextLink>
        );
      })}
    </Group>
  );
};

export default SnippetVersionLinks;
