import React, { useState } from 'react';
import OverlayLayout from '../overlay/OverlayLayout';
import Headline from '../shared/atoms/Headline';
import { COLORS, SPACINGS } from '../../styles/theme';
import FormSectionConnectSnippet from './FormSectionConnectSnippet';
import { useCurrentArticle } from '../article/useCurrentArticle';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useArticleSections } from '../article/useArticleSections';
import { createSnippet } from '../../app/slices/snippetsSlice';
import { updateArticle } from '../../app/slices/articlesSlice';
import { Block, Collapse, Group } from '@webfox-sc/core';
import FormSection from '../shared/forms/FormSection';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { IconChevronRight } from '@webfox-sc/icons';
import { useOverlay } from '../overlay/useOverlay';
import useAPIError from '../apiError/useAPIError';

const SnippetAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();

  const { article } = useCurrentArticle();
  const { getSectionsMapAddedBySnippet } = useArticleSections(article?.id);

  const [name, setName] = useState(article?.name || '');
  const [isNameError, setIsNameError] = useState(false);

  const [snippetConnectDisabled, setSnippetConnectDisabled] = useState(false);

  const updateArticleSections = async (snippetId: number | undefined) => {
    if (article && snippetId) {
      const response = await dispatch(
        updateArticle(article.id, {
          Section: getSectionsMapAddedBySnippet(snippetId),
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  const handleOnClickCreate = async () => {
    if (!name.trim()) {
      setIsNameError(true);
    } else if (article) {
      /**
       * create snippet
       */
      const response = await dispatch(
        createSnippet({
          name: name.trim(),
        })
      );

      if (response.payload?.error) {
        addError(response.payload.error.message);
      }

      /**
       * update article sections
       */
      const snippetId = response?.payload?.data?.id;
      await updateArticleSections(snippetId);

      navigate(`/article/${article.id}/snippet/${snippetId}/create`, { replace: true });
    }
  };

  const handleOnSelectSnippet = async (snippetId: number) => {
    if (article) {
      setSnippetConnectDisabled(true);

      /**
       * update article sections
       */
      await updateArticleSections(snippetId);

      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Textelement" title="Hinzufügen">
      <Block>
        <Headline>Textelement erstellen</Headline>
      </Block>
      <Block paddingTop={SPACINGS.M}>
        <FormSection title="Titel *">
          <Input
            name="name"
            autoComplete="ignore"
            value={name}
            error={isNameError}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameError(false)}
            onBlur={(e) => {
              setName(e.target.value.trim());
            }}
          />
          <Collapse show={isNameError}>
            <Block paddingTop={SPACINGS.XS}>
              <Text variant="small" color={COLORS.RED}>
                Dies ist ein Pflichtfeld.
              </Text>
            </Block>
          </Collapse>
        </FormSection>

        <FormSection paddingTop={SPACINGS.S}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary iconRight={IconChevronRight} label="Weiter" onClick={handleOnClickCreate} />
          </Group>
        </FormSection>
      </Block>

      <FormSectionConnectSnippet
        articleId={article?.id}
        paddingTop={SPACINGS.XXL}
        onSelect={handleOnSelectSnippet}
        disabled={snippetConnectDisabled}
      />
    </OverlayLayout>
  );
};

export default SnippetAdd;
