import React, { useEffect, useRef, useState } from 'react';
import { COLORS, SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { useAppDispatch } from '../../app/hooks';
import { fetchAssets } from '../../app/slices/assetsSlice';
import FormSectionConnectAssetItem from './FormSectionConnectAssetItem';

import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useArticle } from '../article/useArticle';

import { filterDataToParams } from '../../utils/filterData';
import FormSectionFilters from '../article/FormSectionFilters';
import useAPIError from '../apiError/useAPIError';

const ITEMS_PER_PAGE = 10;

interface FormSectionConnectAssetProps {
  articleId?: number | undefined;
  paddingTop?: string;
  onSelect: (assetId: number) => void;
  disabled?: boolean;
}

const defaultFilterData: FilterData = {
  start: 0,
  limit: ITEMS_PER_PAGE,
  dateRange: 'last_7_days',
  sortingKey: 'updatedAt',
  sortingOrder: 'desc',
};

const FormSectionConnectAsset: React.FC<FormSectionConnectAssetProps> = ({
  articleId,
  paddingTop,
  onSelect,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { sections } = useArticle(articleId);

  const [filterData, setFilterData] = useState<FilterData | undefined>(undefined);

  const [assetIds, setAssetIds] = useState<number[]>([]);
  const assetIdsRef = useRef<number[]>([]);

  const [assetCount, setAssetCount] = useState(0);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  useEffect(() => {
    const excludeAssetIds = sections.map((section: SectionEntity) => Number(section.assetId)).filter(Boolean);
    setFilterData({
      ...defaultFilterData,
      excludeIds: excludeAssetIds,
    });
  }, [sections]);

  useEffect(() => {
    (async () => {
      if (filterData) {
        const res = await dispatch(fetchAssets(filterDataToParams(filterData)));
        if (res.payload?.error) {
          addError(res.payload.error.message);
        }

        const data = (res?.payload?.data || []) as AssetData[];
        const newAssetIds = data.map((assetData) => assetData.id);
        if ((filterData?.start || 0) > 0) {
          assetIdsRef.current.push(...newAssetIds);
        } else {
          assetIdsRef.current = newAssetIds;
        }
        setAssetIds([...assetIdsRef.current]);
        const count = res?.payload?.count || 0;
        setAssetCount(count);
        setShowLoadMoreButton(assetIdsRef.current.length < count);
      }
    })();
  }, [addError, dispatch, filterData]);

  const handleFilterChange = (newFilterData: FilterData): void => {
    setFilterData({
      ...newFilterData,
      start: 0,
      limit: ITEMS_PER_PAGE,
    });
  };

  const handleClickLoadMore = () => {
    setFilterData({
      ...filterData,
      start: (filterData?.start || 0) + ITEMS_PER_PAGE,
    });
  };

  return (
    <Block paddingTop={paddingTop}>
      <Headline>Anhang verknüpfen</Headline>

      <Block paddingTop={SPACINGS.M}>
        <FormSectionFilters filterData={filterData} onChange={handleFilterChange} />
      </Block>

      <Group paddingTop={SPACINGS.M} spacing={SPACINGS.XXS} vertical>
        {assetIds.map((assetId) => (
          <GroupItem key={`asset-${assetId}`}>
            <FormSectionConnectAssetItem assetId={assetId} onSelect={onSelect} disabled={disabled} />
          </GroupItem>
        ))}
      </Group>

      <Block display="inline-block" paddingTop={SPACINGS.XXS}>
        <Text variant="small" textAlign="center">
          {assetIds.length} von {assetCount}
        </Text>
        <Block background={COLORS.MID_GREY}>
          <Block
            width={`${(100 / assetCount) * assetIds.length}%`}
            height="4px"
            background={assetCount === 0 ? 'transparent' : COLORS.BLUE}
          />
        </Block>
        <Block paddingTop={SPACINGS.XXS}>
          <ButtonPrimary label="Weitere anzeigen" disabled={!showLoadMoreButton} onClick={handleClickLoadMore} />
        </Block>
      </Block>
    </Block>
  );
};

export default FormSectionConnectAsset;
