import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import { IconLock } from '@webfox-sc/icons';
import PageContent from './PageContent';
import { COLORS, SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';

interface PageHeaderProps {
  loggedIn?: boolean;
  username?: string;
  onClickLogout?: React.MouseEventHandler;
}

const PageHeader: React.FC<PageHeaderProps> = ({ loggedIn, username, onClickLogout }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOnClickLink = (e: React.MouseEvent, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <Block background={COLORS.SILVER}>
      <PageContent paddingTop={SPACINGS.XL} paddingBottom={SPACINGS.XL}>
        <Group justify="space-between" spacing={SPACINGS.M} alignItems="center">
          <Block padding="4px 0">
            <Headline variant="H2">
              <a href={'/'} onClick={(e) => handleOnClickLink(e, '/')}>
                Mundschenk
              </a>{' '}
              CMS BZ
            </Headline>
          </Block>
          {loggedIn && (
            <Group spacing={SPACINGS.S} alignItems="center">
              <Headline variant="H2">{username}</Headline>
              <ButtonPrimary ariaLabel="Logout" icon={IconLock} onClick={onClickLogout} />
            </Group>
          )}
        </Group>
        {loggedIn && (
          <Block paddingTop={SPACINGS.L} justify="flex-end">
            <Group spacing={SPACINGS.XXS}>
              <ButtonSecondary
                label="Zeitungen"
                href="/newspapers"
                active={pathname.startsWith('/newspapers') || pathname === '/'}
                onClick={(e) => handleOnClickLink(e, '/newspapers')}
              />
              <ButtonSecondary
                label="Hashtags"
                href="/tags"
                active={pathname.startsWith('/tags')}
                onClick={(e) => handleOnClickLink(e, '/tags')}
              />
            </Group>
          </Block>
        )}
      </PageContent>
    </Block>
  );
};

export default PageHeader;
