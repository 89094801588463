/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, AsyncThunkAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, Method } from 'axios';
import _forEach from 'lodash/forEach';
import { normalize, Schema } from 'normalizr';
import { RootState } from './store';
import { apiUrl } from './api';

export type NormalizedEntities = {
  newspapers?: { [id: number]: NewspaperEntity };
  issues?: { [id: number]: IssueEntity };
  pages?: { [id: number]: PageEntity };
  stories?: { [id: number]: StoryEntity };
  articles?: { [id: number]: ArticleEntity };
  contentTypes?: { [id: number]: ContentTypeEntity };
  headlines?: { [id: number]: HeadlineEntity };
  sections?: { [id: number]: SectionEntity };
  snippets?: { [id: number]: SnippetEntity };
  assets?: { [id: number]: AssetEntity };
  mediaAssets?: { [id: number]: MediaAssetEntity };
  files?: { [id: number]: FileEntity };
  ressorts?: { [id: number]: RessortEntity };
  channels?: { [id: number]: ChannelEntity };
  tags?: { [id: number]: TagEntity };
  users?: { [id: number]: UserEntity };
  authors?: { [id: number]: AuthorEntity };
  prompts?: { [id: number]: PromptEntity };
  articleAttachments?: { [id: number]: ArticleAttachmentEntity };
};

type RequestEntitiesError = {
  statusCode?: number;
  message?: string;
  code?: string;
};

export type RequestEntitiesReturned<D = any> = {
  data?: D;
  entities?: NormalizedEntities;
  count?: number;
  error?: RequestEntitiesError;
};

export type RequestEntitiesThunkArg = {
  method: Method;
  path: string;
  data?: any;
  params?: any;
  schema: Schema;
  type?: string;
  withCount?: boolean;
  useFormData?: boolean;
};

export type RequestEntitiesThunkApiConfig<D = any> = {
  dispatch: ThunkDispatch<RootState, null | undefined, AnyAction>;
  state: RootState;
  rejectValue: RequestEntitiesReturned<D>;
};

export type RequestEntitiesAction<D = any> = AsyncThunkAction<
  RequestEntitiesReturned<D>,
  RequestEntitiesThunkArg,
  RequestEntitiesThunkApiConfig<D>
>;

const getErrorPayloadFromAxiosError = (error) => {
  const requestEntitiesError: RequestEntitiesError = {};
  if (error.response.data.message) {
    requestEntitiesError.message = error.response.data.message;
  } else {
    requestEntitiesError.message = error.message;
  }

  if (error.response.data.code) {
    requestEntitiesError.code = error.response.data.code;
  }

  requestEntitiesError.statusCode = error.response.status;

  return { error: requestEntitiesError };
};

export const requestEntities = createAsyncThunk<
  RequestEntitiesReturned,
  RequestEntitiesThunkArg,
  RequestEntitiesThunkApiConfig
>('requestEntities', async (arg, thunkAPI) => {
  const { auth } = thunkAPI.getState();

  const axiosConfig: AxiosRequestConfig = {
    method: arg.method,
    url: `${apiUrl}${arg.path}${arg.withCount ? '&count=true' : ''}`,
    data: arg?.useFormData ? arg.data : { data: arg.data },
    params: arg.params,
  };

  if (auth?.jwt) {
    axiosConfig.headers = {
      Authorization: `Bearer ${auth?.jwt}`,
    };
  }

  let data: any;
  // let countResult: number | undefined = undefined;
  let entities: NormalizedEntities = {};

  try {
    ({ data } = await axios(axiosConfig));
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorPayloadFromAxiosError(error));
  }

  let entitiesData;
  if (data) {
    try {
      entitiesData = data.data;
      const normalized = normalize<any, NormalizedEntities>(entitiesData, arg.schema);
      entities = normalized.entities;
      // normalizr creates entities with key 'undefined', if child is null, remove them here
      _forEach(entities, (entityCollection) => {
        _forEach(entityCollection, (_entry, id, collection) => {
          if (id === 'undefined') {
            delete collection[id];
          }
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  const returnData: RequestEntitiesReturned = {
    data: entitiesData,
    entities,
  };

  if (arg.withCount) {
    returnData.count = data?.meta?.pagination?.total;
  }

  return returnData;
});
