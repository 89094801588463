import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { issueSchema } from '../schemas';
import { RootState } from '../store';

export const createIssue = (data: IssueCreateData): RequestEntitiesAction<IssueData> =>
  requestEntities({
    method: 'POST',
    path: '/issues',
    data,
    schema: issueSchema,
  });

export const fetchIssue = (issueId: number): RequestEntitiesAction<IssueData> =>
  requestEntities({
    method: 'GET',
    path: `/issues/${issueId}`,
    schema: issueSchema,
    type: 'fetchIssue',
  });

export const refetchIssue = (issueId: number): RequestEntitiesAction<IssueData> =>
  requestEntities({
    method: 'GET',
    path: `/issues/${issueId}`,
    schema: issueSchema,
  });

export const updateIssue = (issueId: number, data: IssueUpdateData): RequestEntitiesAction<IssueData> =>
  requestEntities({
    method: 'PUT',
    path: `/issues/${issueId}`,
    data,
    schema: issueSchema,
  });

export const deleteIssue = (issueId: number): RequestEntitiesAction<IssueData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/issues/${issueId}`,
    schema: issueSchema,
  });
};

export interface IssuesState extends EntityState<IssueEntity> {
  fetchIssueStatus: RequestStatus;
}

const issuesAdapter = createEntityAdapter<IssueEntity>();

const initialState: IssuesState = issuesAdapter.getInitialState({
  fetchIssueStatus: 'idle',
});

export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchIssue') {
          state.fetchIssueStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchIssue') {
          state.fetchIssueStatus = 'idle';
        }
        if (action.payload.entities?.issues) {
          issuesAdapter.upsertMany(state, action.payload.entities.issues);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchIssue') {
          state.fetchIssueStatus = 'failed';
        }
      });
  },
});

export const selectIssuesState = (state: RootState): IssuesState => state.entities.issues;
export default issuesSlice.reducer;
