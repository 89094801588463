import { schema } from 'normalizr';
import { getEntityId } from './schemaUtils';

/**
 * schemas
 */
const newspaperSchema = new schema.Entity<NewspaperEntity>(
  'newspapers',
  {},
  {
    processStrategy: (data: NewspaperData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: NewspaperEntity = {
        id,
        ...attributes,
        createdTime: attributes.createdAt,
        updatedTime: attributes.updatedAt,
      };

      if (attributes.issues?.data) {
        entity.issueIds = (attributes.issues.data || []).map((issue) => issue.id);
      }

      return entity;
    },
  }
);

const issueSchema = new schema.Entity<IssueEntity>(
  'issues',
  {},
  {
    processStrategy: (data: IssueData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: IssueEntity = {
        id,
        ...attributes,
        newspaperId: getEntityId(attributes.newspaper),
        pageCount: attributes.page_count,
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
      };

      if (attributes.pages?.data) {
        entity.pageIds = (attributes.pages.data || []).map((page) => page.id);
      }

      return entity;
    },
  }
);
const issuesSchema = new schema.Entity<IssueEntity[]>('issues_arr', { data: [issueSchema] });

const pageSchema = new schema.Entity<PageEntity>(
  'pages',
  {},
  {
    processStrategy: (data: PageData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: PageEntity = {
        id,
        ...attributes,
        linesMax: attributes.lines_max || undefined,
        issueId: getEntityId(attributes.issue),
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
      };

      if (attributes.articles?.data) {
        entity.articleIds = (attributes.articles.data || []).map((article) => article.id);
      }

      if (attributes.ressorts?.data) {
        entity.ressortIds = (attributes.ressorts.data || []).map((ressort) => ressort.id);
      }

      return entity;
    },
  }
);
const pagesSchema = new schema.Entity<PageEntity[]>('pages_arr', { data: [pageSchema] });

const storySchema = new schema.Entity<StoryEntity>(
  'stories',
  {},
  {
    processStrategy: (data: StoryData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id, tagIds: [] };
      }
      const entity: StoryEntity = {
        id,
        ...attributes,
        tagIds: (attributes.tags?.data || []).map((tag) => tag.id),
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
      };

      if (attributes.articles?.data) {
        entity.articleIds = (attributes.articles.data || []).map((article) => article.id);
      }

      return entity;
    },
  }
);

const articleSchema = new schema.Entity<ArticleEntity>(
  'articles',
  {},
  {
    processStrategy: (data: ArticleData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id, tagIds: [] };
      }
      const entity: ArticleEntity = {
        id,
        ...attributes,
        rank: attributes.rank === undefined || attributes.rank === null ? 10 : attributes.rank, // use 10 as default
        storyId: getEntityId(attributes.story),
        pageId: getEntityId(attributes.page),
        tagIds: (attributes.tags?.data || []).map((tag) => tag.id),
        channelId: getEntityId(attributes.channel),
        ressortId: getEntityId(attributes.ressort),
        contentTypeId: getEntityId(attributes.content_type),
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
        publicationTime: attributes.publication_time,
        expirationTime: attributes.expiration_time,
        attachmentIds: (attributes.attachments || []).map((att) => att.id),
      };

      if (attributes.headlines) {
        entity.headlineIds = (attributes.headlines || []).map((headline) => headline.id);
      }
      if (attributes.Section) {
        entity.sectionIds = (attributes.Section || []).map((section) => section.id);
      }
      if (attributes.owners?.data) {
        entity.ownerIds = (attributes.owners.data || []).map((owner) => owner.id);
      }
      if (attributes.attachments) {
        entity.attachmentIds = (attributes.attachments || []).map((att) => att.id);
      }

      return entity;
    },
  }
);
const articlesSchema = new schema.Entity<ArticleEntity[]>('articles_arr', { data: [articleSchema] });

const articleAttachmentSchema = new schema.Entity<ArticleAttachmentEntity>(
  'articleAttachments',
  {},
  {
    processStrategy: (data: ArticleAttachmentData) => {
      const { id, name, content, created } = data;

      const entity: ArticleAttachmentEntity = {
        id,
        content,
        name,
        createdTime: created,
        createdByUserName: data?.created_by_user?.data?.attributes?.username,
        fileUrl: data?.file?.data?.attributes?.url,
      };

      return entity;
    },
  }
);

const sectionSchema = new schema.Entity<SectionEntity>(
  'sections',
  {},
  {
    processStrategy: (data: SectionData, parent?: ArticleEntity) => {
      const entity: SectionEntity = {
        ...data,
        assetId: getEntityId(data.asset),
        snippetId: getEntityId(data.snippet),
        articleId: parent?.id,
      };
      return entity;
    },
  }
);

const snippetSchema = new schema.Entity<SnippetEntity>(
  'snippets',
  {},
  {
    processStrategy: (data: SnippetData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: SnippetEntity = {
        id,
        ...attributes,
        contentFormat: attributes.content_format || 'text',
        characters: attributes.characters || 0,
        lines: attributes.lines || 0,
        authorId: getEntityId(attributes.author),
        location: attributes.geo_json
          ? { lng: attributes.geo_json.coordinates[0], lat: attributes.geo_json.coordinates[1] }
          : undefined,
        locationName: attributes.location_name,
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
        publicationTime: attributes.publication_time,
        expirationTime: attributes.expiration_time,
      };

      if (attributes.tags?.data) {
        entity.tagIds = (attributes.tags.data || []).map((tag) => tag.id);
      }
      if (attributes.parent?.data) {
        entity.parentId = attributes.parent.data.id;
      }
      if (attributes.children?.data) {
        entity.childIds = (attributes.children.data || []).map((snippet) => snippet.id);
      }
      if (attributes.owners?.data) {
        entity.ownerIds = (attributes.owners.data || []).map((owner) => owner.id);
      }

      return entity;
    },
  }
);
const snippetsSchema = new schema.Entity<SnippetEntity[]>('snippets_arr', { data: [snippetSchema] });
const snippetObjectSchema = new schema.Entity<SnippetEntity>('snippets_obj', { data: snippetSchema });

const assetSchema = new schema.Entity<AssetEntity>(
  'assets',
  {},
  {
    processStrategy: (data: AssetData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: AssetEntity = {
        id,
        ...attributes,
        location: attributes.geo_json
          ? { lng: attributes.geo_json.coordinates[0], lat: attributes.geo_json.coordinates[1] }
          : undefined,
        locationName: attributes.location_name,
        createdTime: attributes.createdAt,
        createdByUserId: getEntityId(attributes.created_by_user),
        updatedTime: attributes.updatedAt,
        updatedByUserId: getEntityId(attributes.updated_by_user),
      };

      if (attributes.media_assets) {
        entity.mediaAssetIds = (attributes.media_assets || []).map((mediaAsset) => mediaAsset.id);
      }

      if (attributes.tags?.data) {
        entity.tagIds = (attributes.tags.data || []).map((tag) => tag.id);
      }
      if (attributes.owners?.data) {
        entity.ownerIds = (attributes.owners.data || []).map((owner) => owner.id);
      }

      return entity;
    },
  }
);
const assetObjectSchema = new schema.Entity<AssetEntity>('asset_obj', { data: assetSchema });

const mediaAssetSchema = new schema.Entity<MediaAssetEntity>(
  'mediaAssets',
  {},
  {
    processStrategy: (data: MediaAssetData) => {
      const entity: MediaAssetEntity = {
        ...data,
        createdTime: data.createdAt,
        createdByUserId: getEntityId(data.created_by_user),
        updatedTime: data.updatedAt,
        updatedByUserId: getEntityId(data.updated_by_user),
      };

      if (data.channels?.data) {
        entity.channelIds = (data.channels.data || []).map((channel) => channel.id);
      }

      if (data.files?.data) {
        entity.fileIds = (data.files.data || []).map((file) => file.id);
      }

      return entity;
    },
  }
);

const fileSchema = new schema.Entity<FileEntity>(
  'files',
  {},
  {
    processStrategy: (data: FileData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id, displayName: '' };
      }
      const entity: FileEntity = {
        id,
        ...attributes,
      };
      return entity;
    },
  }
);
const filesSchema = new schema.Entity<FileEntity[]>('files_arr', { data: [fileSchema] });

const authorSchema = new schema.Entity<AuthorEntity>(
  'authors',
  {},
  {
    processStrategy: (data: AuthorData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id, displayName: '' };
      }
      const entity: AuthorEntity = {
        id,
        ...attributes,
        firstname: attributes.first_name,
        lastname: attributes.last_name,
        displayName: `${attributes.first_name || ''} ${attributes.last_name || ''}`.trim(),
      };
      return entity;
    },
  }
);
const authorObjectSchema = new schema.Entity<AuthorEntity>('authors_obj', { data: authorSchema });

const ressortSchema = new schema.Entity<RessortEntity>(
  'ressorts',
  {},
  {
    processStrategy: (data: RessortData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: RessortEntity = {
        id,
        ...attributes,
        foreignId: attributes.foreign_id,
        isDisabled: attributes.is_disabled,
        createdTime: attributes.createdAt,
        updatedTime: attributes.updatedAt,
      };
      return entity;
    },
  }
);
const ressortObjectSchema = new schema.Entity<RessortEntity>('ressorts_obj', { data: ressortSchema });
const ressortsSchema = new schema.Entity<RessortEntity[]>('ressorts_arr', { data: [ressortSchema] });

const channelSchema = new schema.Entity<ChannelEntity>(
  'channels',
  {},
  {
    processStrategy: (data: ChannelData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: ChannelEntity = {
        id,
        ...attributes,
      };
      return entity;
    },
  }
);
const channelObjectSchema = new schema.Entity<ChannelEntity>('channels_obj', { data: channelSchema });
const channelsSchema = new schema.Entity<ChannelEntity[]>('channels_arr', { data: [channelSchema] });

const contentTypeSchema = new schema.Entity<ContentTypeEntity>(
  'contentTypes',
  {},
  {
    processStrategy: (data: ContentTypeData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: ContentTypeEntity = {
        id,
        ...attributes,
      };
      return entity;
    },
  }
);
const contentTypeObjectSchema = new schema.Entity<ContentTypeEntity>('contentTypes_obj', { data: contentTypeSchema });
// const contentTypesSchema = new schema.Entity<ChannelEntity[]>('contentTypes_arr', { data: [contentTypeSchema] });

const headlineSchema = new schema.Entity<HeadlineEntity>('headlines', {});

const tagSchema = new schema.Entity<TagEntity>(
  'tags',
  {},
  {
    processStrategy: (data: TagData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: TagEntity = {
        id,
        ...attributes,
      };
      return entity;
    },
  }
);
const tagsSchema = new schema.Entity<TagEntity[]>('tags_arr', { data: [tagSchema] });

const userSchema = new schema.Entity<UserEntity>(
  'users',
  {},
  {
    processStrategy: (data: UserData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: UserEntity = {
        id,
        ...attributes,
      };
      return entity;
    },
  }
);
const usersSchema = new schema.Entity<UserEntity[]>('users_arr', { data: [userSchema] });
const userObjSchema = new schema.Entity<UserEntity>('users_obj', { data: userSchema });

const promptSchema = new schema.Entity<PromptEntity>(
  'prompts',
  {},
  {
    processStrategy: (data: PromptData) => {
      const { id, attributes } = data;
      if (!attributes) {
        return { id };
      }
      const entity: PromptEntity = {
        id,
        ...attributes,
        responseSelector: attributes.response_selector,
      };
      return entity;
    },
  }
);

/**
 * schema definitions
 */
newspaperSchema.define({
  issues: issuesSchema,
});

issueSchema.define({
  newspaper: newspaperSchema,
  pages: pagesSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

pageSchema.define({
  articles: articlesSchema,
  ressorts: ressortsSchema,
  issue: issueSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

storySchema.define({
  articles: articlesSchema,
  tags: tagsSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

articleSchema.define({
  headlines: [headlineSchema],
  story: storySchema,
  Section: [sectionSchema],
  page: pageSchema,
  channel: channelObjectSchema,
  ressort: ressortObjectSchema,
  content_type: contentTypeObjectSchema,
  tags: tagsSchema,
  owners: usersSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
  attachments: [articleAttachmentSchema],
});

sectionSchema.define({
  snippet: snippetObjectSchema,
  asset: assetObjectSchema,
});

snippetSchema.define({
  tags: tagsSchema,
  author: authorObjectSchema,
  parent: assetObjectSchema,
  children: snippetsSchema,
  owners: usersSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

assetSchema.define({
  media_assets: [mediaAssetSchema],
  tags: tagsSchema,
  owners: usersSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

mediaAssetSchema.define({
  files: filesSchema,
  channels: channelsSchema,
  created_by_user: userObjSchema,
  updated_by_user: userObjSchema,
});

articleAttachmentSchema.define({
  file: fileSchema,
  created_by_user: userObjSchema,
});

export {
  newspaperSchema,
  issueSchema,
  pageSchema,
  storySchema,
  articleSchema,
  headlineSchema,
  sectionSchema,
  snippetSchema,
  assetSchema,
  mediaAssetSchema,
  fileSchema,
  ressortSchema,
  channelSchema,
  contentTypeSchema,
  tagSchema,
  userSchema,
  authorSchema,
  promptSchema,
  articleAttachmentSchema,
};
