import styled, { CSSObject } from 'styled-components';
import { COLORS, SPACINGS } from '../../styles/theme';

interface StyledComponentProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}
export const MarkdownEditorWrapper = styled.div<StyledComponentProps>(({ width, error, isFocus }): CSSObject => {
  return {
    position: 'relative',
    boxSizing: 'border-box',
    width: width || '100%',
    background: COLORS.WHITE,
    border: `2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: '3px',
    transition: 'border-color 0.2s ease',
    '.ProseMirror': {
      boxSizing: 'border-box',
      height: 'calc(100vh - 450px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: `${SPACINGS.XXS} ${SPACINGS.S}`,
      '& div[data-contents]': {
        paddingBottom: SPACINGS.XXS,
      },
      blockquote: {
        borderLeft: '5px solid #f1f1f1',
        paddingLeft: '5px',
      },
    },
  };
});
