const monthNamesDe = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const getDateObject = (date: string | number | Date = new Date()) => {
  const oDate = new Date(date);
  const day = oDate.getDate();
  const month = oDate.getMonth() + 1;
  const year = oDate.getFullYear();
  const hours = oDate.getHours();
  const minutes = oDate.getMinutes();

  return {
    day,
    month,
    year,
    hours,
    minutes,
    D: String(day),
    DD: day < 10 ? `0${String(day)}` : String(day),
    M: String(month),
    MM: month < 10 ? `0${String(month)}` : String(month),
    MMMM: monthNamesDe[month - 1],
    YYYY: String(year),
    HH: hours < 10 ? `0${String(hours)}` : String(hours),
    mm: minutes < 10 ? `0${String(minutes)}` : String(minutes),
  };
};

/**
 * getDateFormatted
 * @return string 'DD.MM.YYYY'
 */
export const getDateFormatted = (date: string | number | Date = new Date()): string => {
  const dateObject = getDateObject(date);
  return `${dateObject.DD}.${dateObject.MM}.${dateObject.YYYY}`;
};

/**
 * getDateFormattedLong
 * @return string 'D. MMMM YYYY'
 */
export const getDateFormattedLong = (date: string | number | Date = new Date()): string => {
  const dateObject = getDateObject(date);
  return `${dateObject.D}. ${dateObject.MMMM} ${dateObject.YYYY}`;
};

/**
 * getTimeFormatted
 * @return string 'HH:mm'
 */
export const getTimeFormatted = (date: string | number | Date = new Date()): string => {
  const dateObject = getDateObject(date);
  return `${dateObject.HH}:${dateObject.mm}`;
};

/**
 * @return string 'DD.MM.YYYY HH:mm'
 */
export const getDateTimeFormatted = (date: string | number | Date = new Date()): string => {
  return `${getDateFormatted(date)} ${getTimeFormatted(date)}`;
};

/**
 * getDateFormattedEn
 * @return string 'YYYY-MM-DD'
 */
export const getDateFormattedEn = (date: string | number | Date = new Date()): string => {
  const dateObject = getDateObject(date);
  return `${dateObject.YYYY}-${dateObject.MM}-${dateObject.DD}`;
};
