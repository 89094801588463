import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IssueElementBox from './IssueElementBox';

const articleBoxTop = 24;
const articleBoxBottom = 24;

const StyledBoxWrapper = styled.div({
  position: 'sticky',
  top: '24px',
});

const StyledBoxContainer = styled.div({
  height: undefined,
});

const IssueElementBoxWrapper: React.FC = () => {
  const refWrapper = useRef<HTMLDivElement | null>(null);
  const [wrapperTop, setWrapperTop] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (refWrapper.current) {
        setWrapperTop(refWrapper.current.getBoundingClientRect().top);
      }
    };

    /**
     * push action once on mount
     */
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * re-position on mount
   */
  useEffect(() => {
    if (!isMounted) {
      setTimeout(() => {
        if (refWrapper.current) {
          setWrapperTop(refWrapper.current.getBoundingClientRect().top);
        }
        setIsMounted(true);
      }, 500);
    }
  }, [isMounted]);

  const containerStyle = {
    height: `calc(100vh - ${wrapperTop < articleBoxTop ? articleBoxTop : wrapperTop}px - ${articleBoxBottom}px)`,
  };

  return (
    <StyledBoxWrapper ref={refWrapper}>
      <StyledBoxContainer style={containerStyle}>
        <IssueElementBox />
      </StyledBoxContainer>
    </StyledBoxWrapper>
  );
};

export default IssueElementBoxWrapper;
