export const swapArrayElements = <T>(anyArray: T[], index: number, targetIndex: number): T[] => {
  const newArray = [...anyArray];
  [newArray[index], newArray[targetIndex]] = [anyArray[targetIndex], anyArray[index]];
  return newArray;
};

export const moveArrayElement = <T>(anyArray: T[], index: number, targetIndex: number): T[] => {
  const newArray = [...anyArray];
  const removedElements = newArray.splice(index, 1);
  if (removedElements.length) {
    newArray.splice(targetIndex, 0, removedElements[0]);
  }
  return newArray;
};

export const removeArrayElement = <T>(anyArray: T[], index: number): T[] => {
  const newArray = [...anyArray];
  newArray.splice(index, 1);
  return newArray;
};
