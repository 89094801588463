import React from 'react';
import { Radio } from '@webfox-sc/core';
import FormSection from '../shared/forms/FormSection';
import { SPACINGS } from '../../styles/theme';
import { mediaAssetTypes } from './mediaAssetTypes';
import CustomRadioGroup from '../shared/atoms/CustomRadioGroup';

interface FormSectionMediaAssetTypeProps {
  paddingTop?: string;
  mediaAssetType?: MediaAssetType | undefined;
  onChange?: (typeLabelPrimary: MediaAssetType) => void;
}

const FormSectionMediaAssetType: React.FC<FormSectionMediaAssetTypeProps> = ({
  paddingTop,
  mediaAssetType,
  onChange,
}) => {
  return (
    <FormSection title="Typ" paddingTop={paddingTop}>
      <CustomRadioGroup
        spacing={SPACINGS.S}
        value={mediaAssetType || 'text'}
        onChange={(type) => {
          if (onChange) {
            onChange(type as MediaAssetType);
          }
        }}
      >
        {Object.keys(mediaAssetTypes).map((type) => {
          return <Radio key={type} label={mediaAssetTypes[type]} value={type} name="media-asset-type" />;
        })}
      </CustomRadioGroup>
    </FormSection>
  );
};

export default FormSectionMediaAssetType;
