import { useAppSelector } from '../../app/hooks';

export const useArticleSection = (
  sectionId?: number
): {
  section?: SectionEntity;
} => {
  const section = useAppSelector(({ entities }) => entities.sections.entities[sectionId || 0]);

  return {
    section,
  };
};
