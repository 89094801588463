import { Block, Collapse, Group } from '@webfox-sc/core';
import _isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectArticlesState } from '../../app/slices/articlesSlice';
import { updateAsset } from '../../app/slices/assetsSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import useAPIError from '../apiError/useAPIError';
import FormSectionLocation from '../location/FormSectionLocation';
import OverlayLayout from '../overlay/OverlayLayout';
import { useOverlay } from '../overlay/useOverlay';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import TextArea from '../shared/atoms/TextArea';
import FormSection from '../shared/forms/FormSection';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import { useStatus } from '../status/useStatus';
import FormSectionTags from '../tags/FormSectionTags';
import FormSectionUsers from '../users/FormSectionUsers';
import AssetEditMediaCreate from './AssetEditMediaCreate';
import AssetEditMediaEdit from './AssetEditMediaEdit';
import FormSectionMediaAssets from './FormSectionMediaAssets';
import { useCurrentAsset } from './useCurrentAsset';

interface AssetEditProps {
  viewMode: 'create' | 'edit';
}

const AssetEdit: React.FC<AssetEditProps> = ({ viewMode }) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { fetchArticleStatus } = useAppSelector(selectArticlesState);

  const { asset } = useCurrentAsset();
  const { createdTextLine, updatedTextLine } = useStatus({
    createdTime: asset?.createdTime,
    createdByUserId: asset?.createdByUserId,
    updatedTime: asset?.updatedTime,
    updatedByUserId: asset?.updatedByUserId,
  });

  const [mediaAssetIdEditing, setMediaAssetIdEditing] = useState<number | undefined>(undefined);

  const [name, setName] = useState(asset?.name || '');
  const [isNameError, setIsNameError] = useState(false);
  const [description, setDescription] = useState(asset?.description || '');
  const [showAddMedia, setShowAddMedia] = useState(false);
  const [tagIds, setTagIds] = useState(asset?.tagIds);
  const [geoLocation, setGeoLocation] = useState(asset?.location);
  const [locationName, setLocationName] = useState(asset?.locationName);
  const hasChanges =
    name !== (asset?.name || '') ||
    description !== (asset?.description || '') ||
    !_isEqual(tagIds, asset?.tagIds) ||
    !_isEqual(geoLocation, asset?.location) ||
    locationName !== asset?.locationName;

  useEffect(() => {
    setName(asset?.name || '');
    setDescription(asset?.description || '');
    setTagIds(asset?.tagIds);
    setGeoLocation(asset?.location);
    setLocationName(asset?.locationName);
  }, [asset]);

  useEffect(() => {
    setIsNameError(false);
  }, [name]);

  const handleOnClickUpdate = async (close) => {
    if (!name) {
      setIsNameError(true);
    } else if (asset?.id) {
      const assetData: AssetUpdateData = {
        name: name.trim(),
        description,
        tags: tagIds,
        location_name: locationName,
      };
      if (geoLocation) {
        assetData.geo_json = { type: 'Point', coordinates: [geoLocation.lng, geoLocation.lat] };
      }
      const response = await dispatch(updateAsset(asset?.id, assetData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
      if (close) {
        closeOverlay();
      }
    }
  };

  const handleSave = async () => {
    if (hasChanges) {
      await handleOnClickUpdate(false);
    }
  };

  return (
    <OverlayLayout
      typeLabelPrimary="Anhang"
      title={viewMode === 'create' ? 'Erstellen' : 'Bearbeiten'}
      innerOverlayComponent={
        asset?.id &&
        ((showAddMedia && (
          <AssetEditMediaCreate
            assetId={asset.id}
            onBeforeEdit={handleSave}
            onMediaAssetCreated={() => setShowAddMedia(false)}
            onClickCancel={() => setShowAddMedia(false)}
          />
        )) ||
          (mediaAssetIdEditing && (
            <AssetEditMediaEdit
              assetId={asset.id}
              mediaAssetId={mediaAssetIdEditing}
              onBeforeEdit={handleSave}
              onMediaAssetUpdated={() => setMediaAssetIdEditing(undefined)}
              onClickCancel={() => setMediaAssetIdEditing(undefined)}
            />
          )))
      }
    >
      <LoadingWrapper
        requestStatus={fetchArticleStatus}
        errorCode="404"
        errorText={'Der Beitrag konnte nicht geladen werden.'}
      >
        <Block minHeight="40px" alignItems="center">
          <Text variant="small">{viewMode === 'create' ? createdTextLine : updatedTextLine}</Text>
        </Block>

        <FormSection title="Titel *" paddingTop={SPACINGS.XL}>
          <Input
            name="name"
            autoComplete="ignore"
            value={name}
            error={isNameError}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameError(false)}
          />
          <Collapse show={isNameError}>
            <Block paddingTop={SPACINGS.XS}>
              <Text variant="small" color={COLORS.RED}>
                Dies ist ein Pflichtfeld.
              </Text>
            </Block>
          </Collapse>
        </FormSection>

        <FormSection title="Beschreibung" paddingTop={SPACINGS.S}>
          <TextArea name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormSection>

        <FormSectionMediaAssets
          assetId={asset?.id}
          paddingTop={SPACINGS.S}
          onBeforeEdit={handleSave}
          onClickAddMediaAsset={() => setShowAddMedia(true)}
          onClickEditMediaAsset={setMediaAssetIdEditing}
          isDisabled={isNameError}
        />

        <FormSectionTags paddingTop={SPACINGS.S} defaultTagIds={asset?.tagIds} onChange={setTagIds} />

        <FormSectionLocation
          paddingTop={SPACINGS.S}
          location={geoLocation}
          locationName={locationName}
          onLocationChange={setGeoLocation}
          onLocationNameChange={setLocationName}
        />

        <Block paddingTop={SPACINGS.S}>
          <FormSectionUsers userIds={asset?.ownerIds} />
        </Block>

        <FormSection paddingTop={SPACINGS.XL}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={() => handleOnClickUpdate(true)} />
            <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
          </Group>
        </FormSection>
      </LoadingWrapper>
    </OverlayLayout>
  );
};

export default AssetEdit;
