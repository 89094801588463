import { useAppSelector } from '../../app/hooks';

export const useStory = (
  storyId: number | undefined
): {
  story?: StoryEntity;
} => {
  const story = useAppSelector(({ entities }) => entities.stories.entities[storyId || 0]);

  return {
    story,
  };
};
