/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { articleSchema } from '../schemas';

export const createArticleAttachment = (articleId: number, data: FormData): RequestEntitiesAction<ArticleData> => {
  return requestEntities({
    method: 'POST',
    path: `/articles/${articleId}/attachments`,
    data,
    schema: articleSchema,
    useFormData: true,
  });
};

export const deleteArticleAttachment = (articleId: number, id: number): RequestEntitiesAction<ArticleData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/articles/${articleId}/attachments/${id}`,
    schema: articleSchema,
    type: 'deleteAttachment',
    data: { articleId, id },
  });
};

const articleAttachmentsAdapter = createEntityAdapter<ArticleAttachmentEntity>();

export const articleAttachmentsSlice = createSlice({
  name: 'articleAttachments',
  initialState: articleAttachmentsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.articleAttachments) {
        articleAttachmentsAdapter.upsertMany(state, action.payload.entities.articleAttachments);
      }
      if (action.meta.arg.type === 'deleteAttachment') {
        articleAttachmentsAdapter.removeOne(state, action.meta.arg.data.id);
      }
    });
  },
});

export default articleAttachmentsSlice.reducer;
