import React from 'react';
import styled from 'styled-components';

interface StyledPageContentProps {
  paddingTop?: string;
  paddingBottom?: string;
}

const StyledPageContent = styled.div<StyledPageContentProps>(({ paddingTop, paddingBottom }) => {
  return {
    boxSizing: 'border-box',
    maxWidth: '1600px',
    padding: '0 7%',
    paddingTop,
    paddingBottom,
    margin: '0 auto',
  };
});

interface PageContentProps {
  paddingTop?: string;
  paddingBottom?: string;
}

const PageContent: React.FC<PageContentProps> = ({ paddingTop, paddingBottom, children }) => {
  return (
    <StyledPageContent paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {children}
    </StyledPageContent>
  );
};

export default PageContent;
