import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconPencil, IconDelete } from '@webfox-sc/icons';
import { useArticle } from './useArticle';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Panel from '../shared/atoms/Panel';
import { SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import TypeLabel from '../shared/atoms/TypeLabel';
import Text from '../shared/atoms/Text';
import StatusPanel from '../status/StatusPanel';

interface ArticleTeaserProps {
  articleId?: number;
}

const ArticleTeaser: React.FC<ArticleTeaserProps> = ({ articleId }) => {
  const navigate = useNavigate();
  const { article, channel } = useArticle(articleId);

  if (!article) {
    return null;
  }

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${articleId}`);
  };

  return (
    <>
      <Group nowrap spacing={SPACINGS.XXS}>
        <GroupItem grow>
          <Panel verticalStretch padding={SPACINGS.XL}>
            <TypeLabel primaryLabel="Beitrag" secondaryLabel={channel && channel.name} />
            <Block paddingTop={SPACINGS.M}>
              <Headline>
                <a href={`/article/${articleId}`} onClick={handleOnClickLink}>
                  {article.name}
                </a>
              </Headline>
              {article.abstract && (
                <Block paddingTop={SPACINGS.M}>
                  <Text nl2br>{article.abstract}</Text>
                </Block>
              )}
            </Block>
          </Panel>
        </GroupItem>
        <GroupItem>
          <StatusPanel
            showStatusLabel
            status={article.status}
            createdTime={article.createdTime}
            createdByUserId={article.createdByUserId}
            updatedTime={article.updatedTime}
            updatedByUserId={article.updatedByUserId}
            showPublicationItem
            publicationTime={article.publicationTime}
            showExpirationItem
            expirationTime={article.expirationTime}
            verticalStretch
          />
        </GroupItem>
        <GroupItem>
          <Group vertical spacing={SPACINGS.XXS}>
            <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickLink} />
            <ButtonPrimary ariaLabel="Entfernen" icon={IconDelete} disabled />
          </Group>
        </GroupItem>
      </Group>
    </>
  );
};

export default ArticleTeaser;
