import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SPACINGS, COLORS } from '../../styles/theme';

const StyledComponent = styled.div({
  position: 'fixed',
  zIndex: 200,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: SPACINGS.S,
});

interface StyledCanvasProps {
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
}

const StyledCanvas = styled.div<StyledCanvasProps>(({ width, maxWidth, height, maxHeight }) => {
  return {
    position: 'relative',
    width,
    maxWidth: maxWidth || '100%',
    height,
    maxHeight: maxHeight || '100%',
    overflow: 'auto',
    background: COLORS.WHITE,
  };
});

interface DialogProps {
  show?: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  onClickShader?: () => void;
  onPressEscape?: () => void;
}

const Dialog: React.FC<DialogProps> = ({
  show,
  width,
  maxWidth,
  height,
  maxHeight,
  onClickShader,
  onPressEscape,
  children,
}) => {
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.code === 'Escape') {
        if (onPressEscape) {
          onPressEscape();
        }
      }
    },
    [onPressEscape]
  );

  useEffect(() => {
    if (show) {
      window.addEventListener('keydown', handleOnKeyDown);
    } else {
      window.removeEventListener('keydown', handleOnKeyDown);
    }
    return () => {
      if (show) {
        window.removeEventListener('keydown', handleOnKeyDown);
      }
    };
  }, [show, handleOnKeyDown]);

  if (!show) {
    return null;
  }

  return (
    <StyledComponent onClick={onClickShader}>
      <StyledCanvas
        width={width}
        maxWidth={maxWidth}
        height={height}
        maxHeight={maxHeight}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </StyledCanvas>
    </StyledComponent>
  );
};

export default Dialog;
