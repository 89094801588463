import React, { useState, useCallback, useRef } from 'react';

export type APIError = {
  id: number;
  message: string;
};

export type APIErrorContextType = {
  errors: APIError[];
  addError: (error: string | undefined) => void;
  removeError: (id: number) => void;
};

export const APIErrorContext = React.createContext<APIErrorContextType>({
  errors: [],
  addError: () => {},
  removeError: () => {},
});

const APIErrorProvider: React.FC = ({ children }) => {
  const errorsRef = useRef<APIError[]>([]);
  const [errors, setErrors] = useState<APIError[]>([]);

  const removeError = (id: number) => {
    const errorIndex = errorsRef.current.findIndex((error) => error.id === id);
    if (errorIndex !== -1) {
      errorsRef.current.splice(errorIndex, 1);
      setErrors([...errorsRef.current]);
    }
  };
  const addError = (error: string | undefined) => {
    if (error) {
      const newErrorId = errorsRef.current.length ? errorsRef.current[errorsRef.current.length - 1].id + 1 : 1;
      errorsRef.current.push({
        id: newErrorId,
        message: error,
      });
      setErrors([...errorsRef.current]);
    }
  };

  const contextValue: APIErrorContextType = {
    errors: errors,
    addError: useCallback((error) => addError(error), []),
    removeError: useCallback((id: number) => removeError(id), []),
  };

  return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
};

export default APIErrorProvider;
