import React from 'react';
import OverlayLayout from '../overlay/OverlayLayout';

const AssetView: React.FC = () => {
  return (
    <OverlayLayout typeLabelPrimary="Anhang" title="Ansehen">
      TODO
    </OverlayLayout>
  );
};

export default AssetView;
