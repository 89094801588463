import { ReactNodeViewRenderer } from '@tiptap/react';
import { AdviceNode } from './AdviceNode';
import { Extension, Mark, Node } from '@tiptap/core';

export const AdviceExtension = Extension.create({
  name: 'adviceExtension',

  addStorage() {
    return {};
  },
});

export const Advice = Mark.create({
  name: 'advice',
  addAttributes() {
    return {
      type: {
        default: 'spell',
        renderHTML: (attributes) => {
          return {
            type: attributes.type,
          };
        },
      },
      key: {
        default: '',
        renderHTML: (attributes) => {
          return {
            key: attributes.key,
          };
        },
      },
    };
  },
  renderHTML(attributes) {
    const { HTMLAttributes } = attributes;
    return ['advice-view', HTMLAttributes, 0];
  },
});

export const AdviceView = Node.create({
  name: 'adviceView',
  group: 'inline',
  inline: true,
  content: 'text*',

  addAttributes() {
    return {
      type: {
        default: 'spell',
        renderHTML: (attributes) => {
          return {
            type: attributes.type,
          };
        },
      },
      key: {
        default: '',
        renderHTML: (attributes) => {
          return {
            key: attributes.key,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'advice-view',
      },
    ];
  },
  renderHTML(attributes) {
    const { HTMLAttributes } = attributes;
    return ['advice-view', HTMLAttributes, 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(AdviceNode, { as: 'span' });
  },
});
