import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { IconPencil } from '@webfox-sc/icons';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import Headline from '../shared/atoms/Headline';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useCurrentIssue } from './useCurrentIssue';
import { fetchIssue, selectIssuesState } from '../../app/slices/issuesSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useNewspaper } from '../newspaper/useNewspaper';
import Text from '../shared/atoms/Text';
import IssuePages from './IssuePages';
import { useIssue } from './useIssue';
import IssueElementBoxWrapper from './IssueElementBoxWrapper';
import { fetchArticlesByPublicationTime } from '../../app/slices/articlesSlice';
import { fetchPagesWithoutIssue } from '../../app/slices/pagesSlice';
// import IconDownload from '../../assets/icons/IconDownload';
// import { apiUrl } from '../../app/api';
import { fetchChannels } from '../../app/slices/channelsSlice';
// import { useChannels } from '../channels/useChannels';
import useAPIError from '../apiError/useAPIError';

const Issue: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { fetchIssueStatus } = useAppSelector(selectIssuesState);
  const { issueId } = useCurrentIssue();
  const { issue, publicationDateFormatted, publicationTimeISOStart, publicationTimeISOEnd } = useIssue(issueId);
  const { newspaper } = useNewspaper(issue?.newspaperId);
  // const { getChannelIdByName } = useChannels();

  useEffect(() => {
    (async () => {
      const responses = await Promise.all([dispatch(fetchPagesWithoutIssue()), dispatch(fetchChannels())]);
      responses.forEach((response) => {
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      });
    })();
  }, [addError, dispatch]);

  useEffect(() => {
    (async () => {
      if (issueId) {
        const response = await dispatch(fetchIssue(issueId));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [addError, dispatch, issueId]);

  useEffect(() => {
    (async () => {
      if (publicationTimeISOStart && publicationTimeISOEnd) {
        const response = await dispatch(fetchArticlesByPublicationTime(publicationTimeISOStart, publicationTimeISOEnd));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [dispatch, publicationTimeISOStart, publicationTimeISOEnd, addError]);

  const pathToNewsletter = `/newspaper/${newspaper?.id}`;

  const handleOnClickNewspaper = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(pathToNewsletter);
  };

  const handleOnClickEdit = () => {
    navigate(`/issue/${issueId}/edit`);
  };

  // const printChannelId = getChannelIdByName('Print');

  return (
    <DndProvider backend={HTML5Backend}>
      <LoadingWrapper
        requestStatus={fetchIssueStatus}
        errorCode="404"
        errorText={'Die Ausgabe konnte nicht geladen werden.'}
      >
        <Block justify="center" position="relative">
          <Group spacing={SPACINGS.XS}>
            <Headline variant="H1">
              <a href={pathToNewsletter} onClick={(e) => handleOnClickNewspaper(e)}>
                {newspaper?.name}
              </a>
            </Headline>
            <Headline variant="H1">{issue?.name}</Headline>
          </Group>

          {issue?.status !== 'published' && (
            <Block position="absolute" top="4px" right="0">
              <Group spacing={SPACINGS.XXS}>
                <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
                {/* not working, because authentication is needed, feature is not needed anyway at the moment */}
                {/* {printChannelId && (
                  <ButtonPrimary
                    ariaLabel="Download"
                    icon={IconDownload}
                    href={`${apiUrl}/issues/${issue?.id}/export?channel=${printChannelId}`}
                  />
                )} */}
              </Group>
            </Block>
          )}
        </Block>

        <Block justify="center" paddingTop={SPACINGS.S}>
          {issue?.status === 'published' ? (
            <Text color={COLORS.VIOLET}>Veröffentlicht am {publicationDateFormatted}</Text>
          ) : (
            <Text>Veröffentlichung am {publicationDateFormatted}</Text>
          )}
        </Block>

        <Group nowrap spacing={SPACINGS.S} paddingTop={SPACINGS.XL} justify="space-between">
          <GroupItem width="70%">
            <Block paddingBottom={SPACINGS.XXXL}>
              <IssuePages issueId={issue?.id} />
            </Block>
          </GroupItem>
          <GroupItem width="30%">
            <IssueElementBoxWrapper />
          </GroupItem>
        </Group>
      </LoadingWrapper>
    </DndProvider>
  );
};

export default Issue;
