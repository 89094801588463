import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export const useCurrentStory = (): {
  storyId?: number;
  story?: StoryEntity;
} => {
  const { sStoryId } = useParams();
  const storyId = parseInt(sStoryId || '', 10);
  const story = useAppSelector(({ entities }) => entities.stories.entities[storyId]);

  return {
    storyId,
    story,
  };
};
