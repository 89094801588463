import React from 'react';
import styled from 'styled-components';
import { Group, GroupItem } from '@webfox-sc/core';
import { IconClose } from '@webfox-sc/icons';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useOverlay } from './useOverlay';
import TypeLabel from '../shared/atoms/TypeLabel';
import Headline from '../shared/atoms/Headline';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';

const StyledLayout = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexFlow: 'column',
});

const StyledHeader = styled.div({
  flexGrow: 0,
  flexShrink: 0,
  height: '136px',
  background: COLORS.WHITE,
  display: 'flex',
  alignItems: 'center',
});

const StyledHeaderInner = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '1320px',
  padding: '0 7%',
});

const StyledContent = styled.div({
  position: 'relative',
  flexGrow: 1,
  overflow: 'hidden',
});

const StyledContentInner = styled.div({
  position: 'absolute',
  zIndex: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: COLORS.SILVER,
  overflowX: 'hidden',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
});

const StyledInnerOverlay = styled.div({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: COLORS.SILVER,
  overflowX: 'hidden',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
});

const StyledContentLayout = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '1320px',
  padding: `${SPACINGS.XL} 7% ${SPACINGS.XXXL} 7%`,
});

interface OverlayLayoutProps {
  typeLabelPrimary?: string;
  typeLabelSecondary?: string;
  title?: string;
  innerOverlayComponent?: React.ReactNode;
  innerContentRef?: React.RefObject<HTMLDivElement>;
  buttonPrimaryLabel?: string;
  buttonPrimaryIcon?: JSX.Element | null;
  buttonPrimaryClick?: () => void;
  buttonSecondaryLabel?: string;
  buttonSecondaryIcon?: JSX.Element | null;
  buttonSecondaryClick?: () => void;
  disableButtons?: boolean;
}

const OverlayLayout: React.FC<OverlayLayoutProps> = ({
  typeLabelPrimary,
  typeLabelSecondary,
  title,
  innerOverlayComponent,
  innerContentRef,
  buttonPrimaryLabel,
  buttonPrimaryIcon,
  buttonPrimaryClick,
  buttonSecondaryLabel,
  buttonSecondaryIcon,
  buttonSecondaryClick,
  children,
  disableButtons,
}) => {
  const { closeOverlay } = useOverlay();

  return (
    <StyledLayout>
      <StyledHeader>
        <StyledHeaderInner>
          <Group nowrap justify="space-between" alignItems="center" hSpacing={SPACINGS.S}>
            <GroupItem>
              <Group alignItems="center" hSpacing={SPACINGS.S} vSpacing={SPACINGS.XXS}>
                {(typeLabelPrimary || typeLabelSecondary) && (
                  <TypeLabel primaryLabel={typeLabelPrimary} secondaryLabel={typeLabelSecondary} />
                )}
                {title && <Headline>{title}</Headline>}
              </Group>
            </GroupItem>
            <GroupItem>
              <Group spacing={SPACINGS.XXS}>
                {buttonPrimaryLabel && (
                  <ButtonPrimary
                    label={buttonPrimaryLabel}
                    icon={buttonPrimaryIcon}
                    onClick={buttonPrimaryClick}
                    disabled={disableButtons}
                  />
                )}
                <ButtonSecondary
                  label={buttonSecondaryLabel || 'Schließen'}
                  icon={buttonSecondaryIcon || buttonSecondaryIcon === null ? undefined : IconClose}
                  onClick={buttonSecondaryClick || closeOverlay}
                  disabled={disableButtons}
                />
              </Group>
            </GroupItem>
          </Group>
        </StyledHeaderInner>
      </StyledHeader>
      <StyledContent>
        <StyledContentInner ref={innerContentRef}>
          <StyledContentLayout>{children}</StyledContentLayout>
        </StyledContentInner>
        {innerOverlayComponent && (
          <StyledInnerOverlay>
            <StyledContentLayout>{innerOverlayComponent}</StyledContentLayout>
          </StyledInnerOverlay>
        )}
      </StyledContent>
    </StyledLayout>
  );
};

export default OverlayLayout;
