import React from 'react';
import { Block, Group } from '@webfox-sc/core';
import { SPACINGS } from '../../styles/theme';
import Panel from '../shared/atoms/Panel';
import StatusLabel from '../shared/atoms/StatusLabel';
import Text from '../shared/atoms/Text';
import { useStatus } from './useStatus';
import StatusPanelItem from './StatusPanelItem';

interface StatusPanelProps {
  showStatusLabel?: boolean;
  showPublicationItem?: boolean;
  showExpirationItem?: boolean;
  showSnippetItems?: boolean;
  status?: EntityStatus;
  createdTime?: string;
  createdByUserId?: number;
  updatedTime?: string;
  updatedByUserId?: number;
  publicationTime?: string;
  expirationTime?: string;
  snippetCharacters?: number;
  snippetLines?: number;
  contentCountMediaAssets?: number;
  fileCountMediaAssets?: number;
  channelsFormatted?: string;
  padding?: string;
  background?: string;
  verticalStretch?: boolean;
}

const StatusPanel: React.FC<StatusPanelProps> = ({
  showStatusLabel,
  showPublicationItem,
  showExpirationItem,
  showSnippetItems,
  status,
  createdTime,
  createdByUserId,
  updatedTime,
  updatedByUserId,
  publicationTime,
  expirationTime,
  snippetCharacters,
  snippetLines,
  contentCountMediaAssets,
  fileCountMediaAssets,
  channelsFormatted,
  padding,
  background,
  verticalStretch,
}) => {
  const {
    statusLabelText,
    statusLabelBackground,
    createdTimeFormatted,
    createdByUserDisplayName,
    updatedTimeFormatted,
    updatedByUserDisplayName,
    publicationDateFormatted,
    expirationDateFormatted,
  } = useStatus({
    status,
    createdTime,
    createdByUserId,
    updatedTime,
    updatedByUserId,
    publicationTime,
    expirationTime,
  });

  return (
    <Panel
      variant="status-panel"
      verticalStretch={verticalStretch}
      padding={padding || SPACINGS.XL}
      background={background}
    >
      {showStatusLabel && (
        <Block paddingBottom={SPACINGS.S}>
          <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
          {status === 'published' && (
            <Block paddingTop={SPACINGS.XXS}>
              <Text variant="small" textAlign="center">
                {publicationDateFormatted}
              </Text>
            </Block>
          )}
        </Block>
      )}
      <Group vertical spacing={SPACINGS.XS}>
        {showPublicationItem && status !== 'published' && (
          <StatusPanelItem label="Veröffentlichung">{publicationDateFormatted || '–'}</StatusPanelItem>
        )}
        {showExpirationItem && <StatusPanelItem label="Verfällt">{expirationDateFormatted || '–'}</StatusPanelItem>}
        {(createdTimeFormatted || createdByUserDisplayName) && (
          <StatusPanelItem label="Erstellt">
            {createdTimeFormatted}
            {createdTimeFormatted && createdByUserDisplayName && <br />}
            {createdByUserDisplayName}
          </StatusPanelItem>
        )}
        {(updatedTimeFormatted || updatedByUserDisplayName) && updatedTimeFormatted !== createdTimeFormatted && (
          <StatusPanelItem label="Aktualisiert">
            {updatedTimeFormatted}
            {updatedTimeFormatted && updatedByUserDisplayName && <br />}
            {updatedByUserDisplayName}
          </StatusPanelItem>
        )}
        {showSnippetItems && (
          <StatusPanelItem label="Umfang">
            {snippetCharacters !== undefined ? (
              <>{snippetCharacters.toLocaleString('de-DE')} Zeichen</>
            ) : (
              <>0 Zeichen</>
            )}
            <br />
            {snippetLines !== undefined ? (
              <>
                {snippetLines.toLocaleString('de-DE')} {snippetLines === 1 ? 'Zeile' : 'Zeilen'}
              </>
            ) : (
              <>0 Zeilen</>
            )}
          </StatusPanelItem>
        )}

        {(fileCountMediaAssets !== undefined || contentCountMediaAssets !== undefined) && (
          <StatusPanelItem label="Media">
            {fileCountMediaAssets || 'Keine'} {fileCountMediaAssets === 1 ? 'Datei' : 'Dateien'}
            <br />
            {contentCountMediaAssets || 'Keine'} {contentCountMediaAssets === 1 ? 'Text' : 'Texte'}
          </StatusPanelItem>
        )}
        {channelsFormatted && <StatusPanelItem label="Channels">{channelsFormatted}</StatusPanelItem>}
      </Group>
    </Panel>
  );
};

export default StatusPanel;
