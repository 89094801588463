import { useCallback, useState } from 'react';

interface EditorContent {
  editorInput: string | undefined;
  editorContent: string | undefined;
  handleOnEditorChange: (newContent: string) => void;
}

const useEditorContent = (content: string | undefined): EditorContent => {
  const [editorInput, setEditorInput] = useState(content);
  const [editorContent, setEditorContent] = useState(content);

  const handleOnEditorChange = useCallback((newContent: string, noUpdate?: boolean) => {
    setEditorContent(newContent);
    if (!noUpdate) {
      setEditorInput(newContent);
    }
  }, []);

  return {
    editorInput,
    editorContent,
    handleOnEditorChange,
  };
};

export default useEditorContent;
