export const SPACINGS: {
  [key in AppSpacing]: string | undefined;
} = {
  none: undefined,
  XXXS: '4px',
  XXS: '8px',
  XS: '16px',
  S: '24px',
  M: '32px',
  L: '40px',
  XL: '48px',
  XXL: '64px',
  XXXL: '96px',
};

export const COLORS: {
  [key in AppColor]: string;
} = {
  WHITE: '#FFFFFF',
  DARK: '#333333',
  SILVER: '#F2F2F2',
  GREY: '#999999',
  MID_GREY: '#C4C4C4',
  BLUE: '#0199DD',
  RED: '#C50010',
  LIGHT_RED: '#F2E2E2',
  YELLOW: '#F6B938',
  GREEN: '#01BB3B',
  VIOLET: '#7C529E',
};

export const STYLES = {
  FONT_FAMILY: '"Fira Sans", sans-serif',
};
