import React from 'react';
import { Radio } from '@webfox-sc/core';
import FormSection from '../shared/forms/FormSection';
import { SPACINGS } from '../../styles/theme';
import { useSnippet } from './useSnippet';
import { snippetContentFormats } from './snippetContentFormats';
import CustomRadioGroup from '../shared/atoms/CustomRadioGroup';

interface FormSectionSnippetContentFormatProps {
  paddingTop?: string;
  snippetId?: number | undefined;
  onChange?: (contentFormat: SnippetContentFormat) => void;
}

const FormSectionSnippetContentFormat: React.FC<FormSectionSnippetContentFormatProps> = ({
  paddingTop,
  snippetId,
  onChange,
}) => {
  const { snippet } = useSnippet(snippetId);

  return (
    <FormSection title="Format" paddingTop={paddingTop}>
      <CustomRadioGroup
        spacing={SPACINGS.S}
        value={snippet?.contentFormat || 'text'}
        onChange={(contentFormat) => {
          if (onChange) {
            onChange(contentFormat as SnippetContentFormat);
          }
        }}
      >
        {Object.keys(snippetContentFormats).map((contentFormat) => {
          return (
            <Radio
              key={contentFormat}
              label={snippetContentFormats[contentFormat]}
              value={contentFormat}
              name="snippet-content-format"
            />
          );
        })}
      </CustomRadioGroup>
    </FormSection>
  );
};

export default FormSectionSnippetContentFormat;
