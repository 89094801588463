import React, { useContext } from 'react';
import Dialog from '../dialog/Dialog';
import { Block, Group } from '@webfox-sc/core';
import { ConfirmDialogContext } from './ConfirmDialogProvider';
import { SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Headline from '../shared/atoms/Headline';

const ConfirmDialog: React.FC = () => {
  const { isConfirmDialogVisible, confirmMessage, setConfirmAction } = useContext(ConfirmDialogContext);

  return (
    <Dialog show={isConfirmDialogVisible} width="100%" maxWidth="500px" onPressEscape={() => setConfirmAction(false)}>
      <Block justify="center">
        <Block background="white" padding={SPACINGS.L}>
          <Block paddingBottom={SPACINGS.S}>
            <Headline textAlign="center">{confirmMessage}</Headline>
          </Block>
          <Block justify="center">
            <Group spacing={SPACINGS.XXS}>
              <ButtonPrimary label="OK" onClick={() => setConfirmAction(true)} />
              <ButtonSecondary label="Abbrechen" onClick={() => setConfirmAction(false)} />
            </Group>
          </Block>
        </Block>
      </Block>
    </Dialog>
  );
};

export default ConfirmDialog;
