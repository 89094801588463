import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group, GroupItem, InlineSVG } from '@webfox-sc/core';
import { IconPencil, IconDelete, IconChevronUp, IconChevronDown } from '@webfox-sc/icons';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Panel from '../shared/atoms/Panel';
import { SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import TypeLabel from '../shared/atoms/TypeLabel';
import Text from '../shared/atoms/Text';
import StatusPanel from '../status/StatusPanel';
import { useAsset } from '../asset/useAsset';
import TagGroup from '../tags/TagGroup';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import { useAssetMedia } from '../asset/useAssetMedia';
import { useArticleSections } from './useArticleSections';
import IconMapMarker from '../../assets/icons/IconMapMarker';
import { ConfirmDialogContext } from '../dialog/ConfirmDialogProvider';
import useAPIError from '../apiError/useAPIError';

interface ArticleSectionAssetProps {
  sectionId: number;
  assetId?: number;
  articleId?: number;
  isFirst?: boolean;
  isLast?: boolean;
  onClickUp?: (assetId: number | undefined) => void;
  onClickDown?: (assetId: number | undefined) => void;
}

const ArticleSectionAsset: React.FC<ArticleSectionAssetProps> = ({
  sectionId,
  articleId,
  assetId,
  isFirst,
  isLast,
  onClickUp,
  onClickDown,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { confirmAction } = useContext(ConfirmDialogContext);

  const { asset } = useAsset(assetId);
  const { mediaAssets } = useAssetMedia(assetId);
  const { getSectionsMapReducedByAsset } = useArticleSections(articleId);

  if (!asset || !articleId) {
    return null;
  }

  const handleOnClickEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${articleId}/asset/${assetId}/edit`);
  };

  const handleOnClickRemove = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (await confirmAction('Anhang entfernen?')) {
      const response = await dispatch(
        updateArticle(articleId, {
          Section: getSectionsMapReducedByAsset(asset.id),
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  return (
    <>
      <Group nowrap spacing={SPACINGS.XXS}>
        <GroupItem grow>
          <Panel verticalStretch padding={SPACINGS.XL}>
            <TypeLabel primaryLabel="Anhang" />
            <Block paddingTop={SPACINGS.M}>
              <Headline>{asset.name}</Headline>
            </Block>
            {asset.description && (
              <Block paddingTop={SPACINGS.M}>
                <Text nl2br>{asset.description}</Text>
              </Block>
            )}
            {asset.locationName && (
              <Block paddingTop={SPACINGS.M}>
                <Text>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${asset.location?.lat},${asset.location?.lng}`}
                    target="_blank"
                  >
                    <InlineSVG width="24px" height="24px">
                      {IconMapMarker}
                    </InlineSVG>{' '}
                    {asset.locationName}
                  </a>
                </Text>
              </Block>
            )}
            {(asset.tagIds || []).length > 0 && (
              <Block paddingTop={SPACINGS.M}>
                <TagGroup tagIds={asset.tagIds} />
              </Block>
            )}
          </Panel>
        </GroupItem>
        <GroupItem>
          <StatusPanel
            createdTime={asset.createdTime}
            createdByUserId={asset.createdByUserId}
            updatedTime={asset.updatedTime}
            updatedByUserId={asset.updatedByUserId}
            contentCountMediaAssets={mediaAssets.filter(({ content }) => !!content).length || 0}
            fileCountMediaAssets={mediaAssets.filter(({ fileIds }) => !!fileIds?.length).length || 0}
            verticalStretch
          />
        </GroupItem>
        <GroupItem>
          <Group vertical spacing={SPACINGS.XXS}>
            <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
            <ButtonPrimary
              ariaLabel="Nach oben"
              icon={IconChevronUp}
              disabled={isFirst}
              onClick={() => {
                if (!isFirst && typeof onClickUp === 'function') {
                  onClickUp(sectionId);
                }
              }}
            />
            <ButtonPrimary
              ariaLabel="Nach unten"
              icon={IconChevronDown}
              disabled={isLast}
              onClick={() => {
                if (!isLast && typeof onClickDown === 'function') {
                  onClickDown(sectionId);
                }
              }}
            />
            <ButtonPrimary ariaLabel="Entfernen" icon={IconDelete} onClick={handleOnClickRemove} />
          </Group>
        </GroupItem>
      </Group>
    </>
  );
};

export default ArticleSectionAsset;
