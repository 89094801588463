import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useRessortsSelect = (
  ressortIds?: number[]
): {
  ressortsSorted: RessortEntity[];
  ressortsSortedFormatted: string;
} => {
  const ressorts = entitySelectors.selectByIds<RessortEntity>('ressorts', ressortIds);

  const ressortsSorted = useMemo(() => {
    return _orderBy(ressorts, [(ressort) => ressort.name], ['asc']);
  }, [ressorts]);

  const ressortsSortedFormatted = ressortsSorted.map((ressort) => ressort.name).join(', ');

  return {
    ressortsSorted,
    ressortsSortedFormatted,
  };
};
