import styled from 'styled-components';
import { COLORS } from '../../styles/theme';

export interface TextSectionMetaProps {
  $disabled?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
}

export const TextSectionMeta = styled.div<TextSectionMetaProps>(({ $disabled, borderTop, borderBottom }) => ({
  height: '60px',
  padding: '0 11px',
  display: 'flex',
  columnGap: '20px',
  alignItems: 'center',
  borderTop: borderTop && `2px solid ${COLORS.MID_GREY}`,
  borderBottom: borderBottom && `2px solid ${COLORS.MID_GREY}`,
  backgroundColor: $disabled ? COLORS.SILVER : COLORS.WHITE,
  width: '100%',
  boxSizing: 'border-box',
}));
