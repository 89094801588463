import React, { useEffect, useMemo } from 'react';
import { micromark } from 'micromark';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useCurrentSnippet } from './useCurrentSnippet';
import { useStatus } from '../status/useStatus';
import Headline from '../shared/atoms/Headline';
import StatusLabel from '../shared/atoms/StatusLabel';
import MetaTable from '../shared/molecules/MetaTable';
import MetaTableItem from '../shared/molecules/MetaTableItem';
import TagGroup from '../tags/TagGroup';
import Inline from '../shared/atoms/Inline';
import { fetchSnippet } from '../../app/slices/snippetsSlice';
import { useAppDispatch } from '../../app/hooks';
import SnippetVersionLinks from './SnippetVersionLinks';
import useAPIError from '../apiError/useAPIError';
import { useSnippets } from './useSnippets';

const SnippetView: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { snippetId, snippet, contentFormatLabel } = useCurrentSnippet();
  const { statusLabelText, statusLabelBackground, updatedTextLine } = useStatus({
    status: snippet?.status,
    updatedTime: snippet?.updatedTime,
    updatedByUserId: snippet?.updatedByUserId,
  });

  const snippetVersionIds = useMemo(
    () => [...(snippet?.parentId ? [snippet.parentId] : []), ...(snippet?.childIds?.length ? snippet.childIds : [])],
    [snippet]
  );
  const { snippets: snippetVersions } = useSnippets(snippetVersionIds);

  useEffect(() => {
    (async () => {
      if (snippetId) {
        const response = await dispatch(fetchSnippet(snippetId));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [addError, dispatch, snippetId]);

  useEffect(() => {
    (async () => {
      if (snippetVersionIds.length !== snippetVersions.length) {
        const missingSnippetIds = snippetVersionIds.filter((snippetVersionId) =>
          snippetVersions.every((snippetVersion) => snippetVersion.id !== snippetVersionId)
        );
        await Promise.all(missingSnippetIds.map((missingSnippetId) => dispatch(fetchSnippet(missingSnippetId))));
      }
    })();
  }, [dispatch, snippetVersionIds, snippetVersions]);

  const contentHTML = useMemo(() => {
    if (!snippet?.content) {
      return '';
    }
    return micromark(snippet.content);
  }, [snippet]);

  return (
    <OverlayLayout typeLabelPrimary="Textelement" typeLabelSecondary={contentFormatLabel} title="Ansehen">
      {snippet && (
        <>
          <Block minHeight="40px" alignItems="center">
            <Group width="100%" justify="space-between" alignItems="center">
              <GroupItem grow>
                <Text variant="small">{updatedTextLine}</Text>
              </GroupItem>
              <GroupItem>
                <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
              </GroupItem>
            </Group>
          </Block>

          <Block paddingTop={SPACINGS.XL}>
            <Headline>{snippet.name}</Headline>
          </Block>

          {snippet.comment && (
            <Block paddingTop={SPACINGS.S}>
              <Text>
                <Inline color={COLORS.GREY}>Versionskommentar:</Inline> {snippet.comment}
              </Text>
            </Block>
          )}

          <Block paddingTop={SPACINGS.XL}>
            <Block padding={`${SPACINGS.M} ${SPACINGS.XL}`} background={COLORS.WHITE}>
              <Text>
                {snippet?.content ? (
                  <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
                ) : (
                  <Inline color={COLORS.GREY}>
                    <em>Für dieses Textelement ist derzeit kein Text vorhanden.</em>
                  </Inline>
                )}
              </Text>
            </Block>
          </Block>

          <Block paddingTop={SPACINGS.S}>
            <Block padding={SPACINGS.XL} background={COLORS.WHITE}>
              <MetaTable>
                <MetaTableItem label="Umfang">
                  {(snippet.characters || 0).toLocaleString('de-DE')} Zeichen ( ={' '}
                  {(snippet.lines || 0).toLocaleString('de-DE')} Zeilen)
                </MetaTableItem>
                {(snippet.tagIds || []).length > 0 && (
                  <MetaTableItem label="Hashtags">
                    <TagGroup tagIds={snippet.tagIds} />
                  </MetaTableItem>
                )}
                {snippet.parentId && (
                  <MetaTableItem label="Vorgängerversion">
                    <SnippetVersionLinks snippetIds={[snippet.parentId]} />
                  </MetaTableItem>
                )}
                {!!snippet?.childIds?.length && (
                  <MetaTableItem label="Nachfolgeversion(en)">
                    <SnippetVersionLinks snippetIds={snippet.childIds} />
                  </MetaTableItem>
                )}
              </MetaTable>
            </Block>
          </Block>
        </>
      )}
    </OverlayLayout>
  );
};

export default SnippetView;
