import React from 'react';
import { COLORS, SPACINGS } from '../../../styles/theme';
import Text from '../atoms/Text';
import styled from 'styled-components';

const StyledLabelCell = styled.td({
  width: '1%',
  paddingRight: SPACINGS.S,
});

const StyledLabel = styled.span({
  whiteSpace: 'nowrap',
});

interface MetaTableItemProps {
  label: string;
}

const MetaTableItem: React.FC<MetaTableItemProps> = ({ label, children }) => {
  return (
    <tr>
      <StyledLabelCell>
        <Text color={COLORS.GREY}>
          <StyledLabel>{label}</StyledLabel>
        </Text>
      </StyledLabelCell>
      <td>{children}</td>
    </tr>
  );
};

export default MetaTableItem;
