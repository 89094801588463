import { Block, Collapse, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { cloneSnippet } from '../../app/slices/articlesSlice';
import { updateSnippet } from '../../app/slices/snippetsSlice';
import { createTag, fetchTagsByName } from '../../app/slices/tagsSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import { findSnippetIdByParent, stripMarkup } from '../../utils/article';
import useAPIError from '../apiError/useAPIError';
import { useCurrentArticle } from '../article/useCurrentArticle';
import FormSectionAudioTranscription from '../audio/FormSectionAudioTranscription';
import FormSectionAuthor from '../authors/FormSectionAuthor';
import { ConfirmDialogContext } from '../dialog/ConfirmDialogProvider';
import FormSectionLocation from '../location/FormSectionLocation';
import MarkdownEditor from '../markdownEditor/MarkdownEditor';
import OverlayLayout from '../overlay/OverlayLayout';
import { useOverlay } from '../overlay/useOverlay';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Input from '../shared/atoms/Input';
import StatusLabel from '../shared/atoms/StatusLabel';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import FormSectionStatus from '../status/FormSectionStatus';
import { useStatus } from '../status/useStatus';
import FormSectionTags from '../tags/FormSectionTags';
import FormSectionUsers from '../users/FormSectionUsers';
import FormSectionSnippetContentFormat from './FormSectionSnippetContentFormat';
import SnippetAITextTransform from './SnippetAITextTransform';
import SnippetClone from './SnippetClone';
import { useCurrentSnippet } from './useCurrentSnippet';
import useEditorContent from './useEditorContent';
import ChatGPTComponent from '../article/ChatGPTComponent';

interface SnippetEditProps {
  viewMode: 'create' | 'edit';
}

// adds an additional "#" character to hashtags (e.g. #ArTcklRocks becomes ##ArTcklRocks) in order to avoid further hashtag stripping on save
const escapeHashTags = (text: string): string => text.replace(/(?<!&)(#+[a-zA-Z_äöüÄÖÜß0-9]+)/gi, '#$1');

// strips one "#" character from hashtags (e.g. #ArTcklRocks becomes ArTcklRocks and ##yolo becomes #yolo)
const stripHashTags = (text?: string): string => (text || '').replace(/(?<!&)#(#*[a-zA-Z_äöüÄÖÜß0-9]+)/g, '$1');

const hasDataChanged = (snippet: SnippetEntity | undefined, updateData: SnippetUpdateData): boolean => {
  const propertyMap = {
    name: 'name',
    comment: 'comment',
    content: 'content',
    contentFormat: 'content_format',
    authorId: 'author',
    locationName: 'location_name',
    status: 'status',
  };

  if (
    Object.entries(propertyMap).some(
      ([snippetProp, updateDataProp]) => snippet?.[snippetProp] != updateData[updateDataProp]
    )
  ) {
    return true;
  }

  if (
    snippet?.location?.lat !== updateData.geo_json?.coordinates[1] ||
    snippet?.location?.lng !== updateData.geo_json?.coordinates[0]
  ) {
    return true;
  }

  const newTags = [...(updateData.tags || [])].sort();
  const oldTags = [...(snippet?.tagIds || [])].sort();
  return newTags.join(',') !== oldTags.join(',');
};

const SnippetEdit: React.FC<SnippetEditProps> = ({ viewMode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { articleId, article } = useCurrentArticle();
  const { snippetId, snippet, contentFormatLabel } = useCurrentSnippet();
  const { statusLabelText, statusLabelBackground, createdTextLine, updatedTextLine } = useStatus({
    status: snippet?.status,
    createdTime: snippet?.createdTime,
    createdByUserId: snippet?.createdByUserId,
    updatedTime: snippet?.updatedTime,
    updatedByUserId: snippet?.updatedByUserId,
  });

  const { confirmAction } = useContext(ConfirmDialogContext);

  const [name, setName] = useState(snippet?.name || '');
  const [isNameError, setIsNameError] = useState(false);
  const [comment, setComment] = useState(snippet?.name || '');
  const [contentFormat, setContentFormat] = useState(snippet?.contentFormat);
  const [authorId, setAuthorId] = useState(snippet?.authorId);
  const [tagIds, setTagIds] = useState(snippet?.tagIds);
  const [geoLocation, setGeoLocation] = useState(snippet?.location);
  const [locationName, setLocationName] = useState(snippet?.locationName);
  const [status, setStatus] = useState(snippet?.status);
  const [showCreateClone, setShowCreateClone] = useState(false);
  const [showAIEditor, setShowAIEditor] = useState(false);
  const [aiEditorText, setAIEditorText] = useState('');

  // const editorStateRef = useRef<EditorState>();

  const { editorInput, editorContent, handleOnEditorChange } = useEditorContent(escapeHashTags(snippet?.content || ''));

  useEffect(() => {
    setName(snippet?.name || '');
    setComment(snippet?.comment || '');
    handleOnEditorChange(escapeHashTags(snippet?.content || ''));
    setContentFormat(snippet?.contentFormat);
    setTagIds(snippet?.tagIds);
    setAuthorId(snippet?.authorId);
    setGeoLocation(snippet?.location);
    setLocationName(snippet?.locationName);
    setStatus(snippet?.status);
  }, [snippet, handleOnEditorChange]);

  useEffect(() => {
    setIsNameError(false);
  }, [name]);

  const getUpdateData = (newTextContent?: string, newTagIds?: number[]) => {
    const snippetData: SnippetUpdateData = {
      name: name.trim(),
      comment: comment.trim(),
      content: newTextContent || editorContent,
      content_format: contentFormat,
      author: authorId || null,
      tags: newTagIds || tagIds,
      location_name: locationName,
      status,
    };
    if (geoLocation) {
      snippetData.geo_json = { type: 'Point', coordinates: [geoLocation.lng, geoLocation.lat] };
    }
    return snippetData;
  };

  const createTagsFromText = async (text: string): Promise<number[]> => {
    let tags = (text.match(/(?<!&)#+[a-zA-Z_äöüÄÖÜß0-9]+/gi) || []).map((str) => str.replace(/^#+/, ''));

    if (!tags.length) {
      return [];
    }

    const fetchResponse = await dispatch(fetchTagsByName(tags));

    let existingTagIds: number[] = [];

    if (fetchResponse.payload?.data && fetchResponse.payload?.data.length > 0) {
      existingTagIds = fetchResponse.payload?.data.map(({ id }) => id);
      tags = tags.filter(
        (tag) => !(fetchResponse.payload?.data || []).find(({ attributes }) => attributes.name === tag)
      );
    }

    const createTagsResponse = await Promise.all(tags.map((tag) => dispatch(createTag(tag))));
    const newTagIds = (createTagsResponse || [])
      .filter((response) => response.meta.requestStatus === 'fulfilled' && response.payload?.data)
      .map((response) => response.payload?.data?.id || 0);

    return [...existingTagIds, ...newTagIds];
  };

  const handleOnClickUpdate = async () => {
    if (!name.trim()) {
      setIsNameError(true);
    } else if (snippetId) {
      // strip markup from spell check
      const markdownContent = stripMarkup(editorContent);
      const extractedTagIds = await createTagsFromText(markdownContent);
      const newTagIds = Array.from(new Set([...(tagIds || []), ...extractedTagIds]));
      const hashTagStrippedText = stripHashTags(markdownContent);
      const snippetData = getUpdateData(hashTagStrippedText, newTagIds);
      setTagIds(newTagIds);
      handleOnEditorChange(hashTagStrippedText);
      const response = await dispatch(updateSnippet(snippetId, snippetData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
      closeOverlay();
    }
  };

  const handleOnClickCancel = async () => {
    const hashTagStrippedText = stripHashTags(editorContent);
    const snippetUpdateData = getUpdateData(hashTagStrippedText);
    const dataHasChanged = hasDataChanged(snippet, snippetUpdateData);
    if (!dataHasChanged || (await confirmAction('Änderungen verwerfen?'))) {
      closeOverlay();
    }
  };

  const handleOnClickClone = async () => {
    if (!name.trim()) {
      setIsNameError(true);
    } else {
      setShowCreateClone(true);
    }
  };

  const handleOnSaveClone = async (newComment: string, text?: string) => {
    if (articleId && snippetId) {
      const snippetData = getUpdateData(text);
      snippetData.comment = newComment;
      const response = await dispatch(cloneSnippet(articleId, snippetId, snippetData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
      setShowCreateClone(false);
      if (response.payload?.data) {
        const newSnippetId = findSnippetIdByParent(snippetId, response.payload.data);
        if (newSnippetId) {
          navigate(`/article/${articleId}/snippet/${newSnippetId}/edit`, { replace: true });
        } else {
          closeOverlay();
        }
      } else {
        closeOverlay();
      }
    }
  };

  const handleOnTranscriptionComplete = (text: string) => {
    handleOnEditorChange(editorContent + '\n\n' + text);
  };

  return (
    <OverlayLayout
      typeLabelPrimary="Textelement"
      typeLabelSecondary={contentFormatLabel}
      title={viewMode === 'create' ? 'Erstellen' : 'Bearbeiten'}
      innerOverlayComponent={
        showCreateClone ? (
          <SnippetClone onClickSave={handleOnSaveClone} onClickCancel={() => setShowCreateClone(false)} />
        ) : (
          showAIEditor && (
            <SnippetAITextTransform
              textContent={aiEditorText}
              versionComment={comment}
              onClickCancel={() => setShowAIEditor(false)}
              onClickSave={(text) => {
                handleOnEditorChange(text);
                setShowAIEditor(false);
              }}
              onClickSaveAsClone={async (text, versionComment) => {
                handleOnEditorChange(text);
                await handleOnSaveClone(versionComment, text);
                setShowAIEditor(false);
              }}
              // TODO pass count
            />
          )
        )
      }
      buttonPrimaryLabel="Aktualisieren"
      buttonPrimaryClick={handleOnClickUpdate}
      buttonSecondaryIcon={null}
      buttonSecondaryLabel="Abbrechen"
      buttonSecondaryClick={handleOnClickCancel}
      disableButtons={showAIEditor}
    >
      <Block minHeight="40px" alignItems="center">
        <Group nowrap width="100%" spacing={SPACINGS.S} justify="space-between" alignItems="center">
          <Text variant="small">{viewMode === 'create' ? createdTextLine : updatedTextLine}</Text>
          <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
        </Group>
      </Block>

      <FormSection title="Titel *" paddingTop={SPACINGS.XL}>
        <Input
          name="name"
          autoComplete="ignore"
          value={name}
          error={isNameError}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setIsNameError(false)}
          onBlur={(e) => {
            setName(e.target.value.trim());
          }}
        />
        <Collapse show={isNameError}>
          <Block paddingTop={SPACINGS.XS}>
            <Text variant="small" color={COLORS.RED}>
              Dies ist ein Pflichtfeld.
            </Text>
          </Block>
        </Collapse>
      </FormSection>

      <FormSection title="Versionskommentar" paddingTop={SPACINGS.S}>
        <Input
          name="comment"
          autoComplete="ignore"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onBlur={(e) => {
            setComment(e.target.value.trim());
          }}
        />
      </FormSection>

      <FormSectionSnippetContentFormat paddingTop={SPACINGS.S} snippetId={snippetId} onChange={setContentFormat} />

      <FormSection title="Text" paddingTop={SPACINGS.S}>
        <MarkdownEditor markdownContent={editorInput} onChange={handleOnEditorChange} />
        <Group nowrap paddingTop="xxs" hSpacing="70px" justify="flex-end">
          <GroupItem>
            <Text variant="tiny">
              Info: Werden Hashtags im Text angegeben, z.B. "#yolo", so werden diese dem Snippets als Hashtags
              hinzugefügt und anschließend das "#"-Zeichen entfernt. Der Text enthält dann nur noch z.B. "yolo" statt
              "#yolo". Möchte man die Raute beibehalten, so kann das mit zwei aufeinanderfolgenden Rauten erreicht
              werden, z.B. "##yolo" wird dann zu "#yolo"
            </Text>
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              label="AI Editor"
              onClick={() => {
                setAIEditorText(stripMarkup(editorContent));
                setShowAIEditor(true);
              }}
            />
          </GroupItem>
        </Group>
      </FormSection>

      {articleId && article && article.storyId && (
        <FormSection title="AI Chat" paddingTop={SPACINGS.S}>
          <ChatGPTComponent articleId={articleId} sectionContent={editorInput} />
        </FormSection>
      )}

      <FormSectionAudioTranscription paddingTop={SPACINGS.S} onTranscriptionComplete={handleOnTranscriptionComplete} />

      <FormSectionAuthor paddingTop={SPACINGS.S} defaultAuthorId={snippet?.authorId} onChange={setAuthorId} />

      <FormSectionTags paddingTop={SPACINGS.S} defaultTagIds={snippet?.tagIds} onChange={setTagIds} />

      <FormSectionLocation
        paddingTop={SPACINGS.S}
        location={geoLocation}
        locationName={locationName}
        onLocationChange={setGeoLocation}
        onLocationNameChange={setLocationName}
      />

      <Block paddingTop={SPACINGS.S}>
        <Grid columns={4} spacing={SPACINGS.S}>
          <GridItem>
            <FormSectionStatus
              statusSelection={['editing', 'stuck', 'stopped', 'done', 'published']}
              defaultStatus={status}
              onChange={setStatus}
            />
          </GridItem>
        </Grid>
      </Block>

      <Block paddingTop={SPACINGS.S}>
        <FormSectionUsers userIds={snippet?.ownerIds} />
      </Block>

      <FormSection paddingTop={SPACINGS.XL}>
        <Collapse show={isNameError}>
          <Block paddingBottom={SPACINGS.XS}>
            <Text variant="small" color={COLORS.RED}>
              Es sind nicht alle Pflichtfelder ausgefüllt.
            </Text>
          </Block>
        </Collapse>
        <Group justify="space-between" spacing={SPACINGS.XXS}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
            <ButtonSecondary label="Abbrechen" onClick={handleOnClickCancel} />
          </Group>
          <ButtonSecondary icon={IconPlus} label="Neue Version anlegen" onClick={handleOnClickClone} />
        </Group>
      </FormSection>
    </OverlayLayout>
  );
};

export default SnippetEdit;
