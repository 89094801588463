import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconCheck, IconClose } from '@webfox-sc/icons';
import styled from 'styled-components';
import { COLORS, SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Text from '../shared/atoms/Text';
import Tooltip from '../tooltip/Tooltip';

interface AdviceOverlayProps {
  onSelect: (text: string) => void;
  onIgnore: () => void;
  onClose: () => void;
  wrapperRect: DOMRect;
  anchorRect: DOMRect;
  shortMessage?: string;
  errorMessage?: string;
  additionalInformation?: string;
  proposals?: string[];
}

const Proposal = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: COLORS.BLUE,
  display: 'block',
});

export const AdviceOverlay: React.FC<AdviceOverlayProps> = ({
  onSelect,
  onClose,
  onIgnore,
  wrapperRect,
  anchorRect,
  shortMessage,
  errorMessage,
  additionalInformation,
  proposals,
}) => {
  return (
    <Tooltip wrapperRect={wrapperRect} anchorRect={anchorRect}>
      <Block padding={SPACINGS.XS}>
        <Block>
          <Group nowrap alignItems="center" hSpacing="10px">
            <GroupItem grow>{shortMessage}</GroupItem>
            <GroupItem>
              <ButtonPrimary ariaLabel="Schliepen" icon={IconClose} onClick={onClose} />
            </GroupItem>
          </Group>
        </Block>
        {errorMessage && (
          <Block>
            <Text variant="small" asHTML>
              {errorMessage}
            </Text>
          </Block>
        )}
        {proposals && (
          <>
            <Block>Vorschläge:</Block>
            <Block paddingBottom={SPACINGS.XS}>
              {proposals?.map((proposal, index) => (
                <Proposal key={`k_${index}`} onClick={() => onSelect(proposal)}>
                  {proposal}
                </Proposal>
              ))}
            </Block>
          </>
        )}
        {additionalInformation && (
          <Block>
            <Text variant="small" asHTML>
              {additionalInformation}
            </Text>
          </Block>
        )}
        <Block justify="center">
          <ButtonPrimary label="Ignorieren" icon={IconCheck} onClick={onIgnore} />
        </Block>
      </Block>
    </Tooltip>
  );
};
