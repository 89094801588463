import { Block, InlineSVG } from '@webfox-sc/core';
import HtmlDiff from 'htmldiff-js';
import React from 'react';
import styled from 'styled-components';
import IconSpinner from '../../assets/icons/IconSpinner';
import { COLORS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import Text from '../shared/atoms/Text';
import { TextSection } from './TextSection';
import { TextSectionMeta } from './TextSectionMeta';
import { TextSectionWrapper } from './TextSectionWrapper';
import { micromark } from 'micromark';
import { useSnippetSize } from './useSnippetSize';

const SpinnerModal = styled.div({
  position: 'absolute',
  top: '2px',
  right: '2px',
  bottom: '2px',
  left: '2px',
  background: 'rgba(255,255,255,.75)',
  boxSizing: 'border-box',
  padding: '0 10px',
});

const SpinnerBox = styled.div({
  width: '440px',
  maxWidth: '100%',
  margin: 'auto',
  marginTop: '90px',
  border: `2px solid ${COLORS.MID_GREY}`,
  backgroundColor: COLORS.WHITE,
  textAlign: 'center',
  padding: '35px 15px',
  boxSizing: 'border-box',
});

interface SnippetAITextTransformTextSectionProps {
  title?: string;
  subtitle?: string;
  text?: string;
  oldText?: string;
  isInProgress?: boolean;
  disabled?: boolean;
}

const getDiff = (text1, text2) => {
  const diffText = HtmlDiff.execute(text2, text1);

  // remove diffs without change
  const cleanedText = diffText.replace(
    /<del class="diffmod">([^<]*)<\/del><ins class="diffmod">([^<]*)<\/ins>/g,
    (match, g1, g2) => {
      if (g1 === g2) {
        return g1;
      }
      return match;
    }
  );
  return cleanedText;
};

const SnippetAITextTransformTextSection: React.FC<SnippetAITextTransformTextSectionProps> = ({
  title,
  subtitle,
  text,
  oldText,
  isInProgress,
  disabled,
}) => {
  const displayText = oldText ? getDiff(text, oldText) : micromark(text || '');
  const { charCount, linesCount } = useSnippetSize(oldText || text);

  return (
    <TextSectionWrapper>
      <TextSectionMeta $disabled={disabled} borderBottom>
        {title && (
          <Text color={COLORS.GREY} fontWeight={600}>
            {title}
          </Text>
        )}
        {subtitle && <Text>{subtitle}</Text>}
      </TextSectionMeta>

      <TextSection $disabled={disabled} withPadding>
        <Text nl2br>{displayText}</Text>
      </TextSection>

      <TextSectionMeta $disabled={disabled} borderTop>
        <Text>
          {charCount} Zeichen (= {linesCount} {linesCount === 1 ? 'Zeile' : 'Zeilen'})
        </Text>
      </TextSectionMeta>

      {isInProgress && (
        <SpinnerModal>
          <SpinnerBox>
            <Headline variant="H2">Text wird konvertiert</Headline>
            <Block paddingTop="25px" paddingBottom="25px">
              <InlineSVG color={COLORS.BLUE} width="50px" height="50px">
                {IconSpinner}
              </InlineSVG>
            </Block>
            <Text>
              Einen kleinen Moment,
              <br />
              dies kann einige Sekunden dauern …
            </Text>
          </SpinnerBox>
        </SpinnerModal>
      )}
    </TextSectionWrapper>
  );
};

export default SnippetAITextTransformTextSection;
