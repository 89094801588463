import { Editor, EditorContent, PureEditorContent } from '@tiptap/react';
import { useContext, useEffect, useRef } from 'react';
import { AdviceOverlayContext } from './AdviceOverlayProvider';

interface EditorContentWrapperProps {
  editor: Editor;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export const EditorContentWrapper: React.FC<EditorContentWrapperProps> = ({ editor, onKeyDown, onClick }) => {
  const compRef = useRef<PureEditorContent | null>(null);
  const { setEditorDimensions } = useContext(AdviceOverlayContext);

  useEffect(() => {
    if (compRef.current?.editorContentRef?.current) {
      setEditorDimensions(compRef.current.editorContentRef.current.getBoundingClientRect());
    }
  }, [setEditorDimensions]);

  return <EditorContent onClick={onClick} onKeyDown={onKeyDown} editor={editor} ref={compRef} />;
};
