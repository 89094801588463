import React, { useEffect, useState } from 'react';
import { Block, Collapse, Grid, GridItem, Group } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import Input from '../shared/atoms/Input';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import { useOverlay } from '../overlay/useOverlay';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import FormSection from '../shared/forms/FormSection';
import { useStatus } from '../status/useStatus';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import { useCurrentIssue } from './useCurrentIssue';
import { selectIssuesState, updateIssue } from '../../app/slices/issuesSlice';
import CustomDatePicker from '../shared/atoms/CustomDatePicker';
import FormSectionStatus from '../status/FormSectionStatus';
import StatusLabel from '../shared/atoms/StatusLabel';
import { getDateFormattedEn } from '../../utils/date';
import useAPIError from '../apiError/useAPIError';

const IssueEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { fetchIssueStatus } = useAppSelector(selectIssuesState);

  const { issue } = useCurrentIssue();
  const { statusLabelText, statusLabelBackground, updatedTextLine } = useStatus({
    status: issue?.status,
    updatedTime: issue?.updatedTime,
    updatedByUserId: issue?.updatedByUserId,
  });

  const [name, setName] = useState(issue?.name || '');
  const [isNameError, setIsNameError] = useState(false);
  const [date, setDate] = useState<Date | undefined | null>(issue?.date ? new Date(issue?.date) : undefined);
  const [status, setStatus] = useState(issue?.status);

  useEffect(() => {
    setName(issue?.name || '');
    setDate(issue?.date ? new Date(issue?.date) : undefined);
    setStatus(issue?.status);
  }, [issue]);

  useEffect(() => {
    setIsNameError(false);
  }, [name]);

  const handleOnClickUpdate = async () => {
    if (!name) {
      setIsNameError(true);
    } else if (issue?.id) {
      const response = await dispatch(
        updateIssue(issue.id, {
          name,
          date: date ? getDateFormattedEn(date) : null,
          status,
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Ausgabe" title="Bearbeiten">
      <LoadingWrapper
        requestStatus={fetchIssueStatus}
        errorCode="404"
        errorText={'Die Ausgabe konnte nicht geladen werden.'}
      >
        <Block minHeight="40px" alignItems="center">
          <Group nowrap width="100%" spacing={SPACINGS.S} justify="space-between" alignItems="center">
            <Text variant="small">{updatedTextLine}</Text>
            <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
          </Group>
        </Block>

        <FormSection title="Titel *" paddingTop={SPACINGS.XL}>
          <Input
            name="name"
            autoComplete="ignore"
            value={name}
            error={isNameError}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameError(false)}
          />
          <Collapse show={isNameError}>
            <Block paddingTop={SPACINGS.XS}>
              <Text variant="small" color={COLORS.RED}>
                Dies ist ein Pflichtfeld.
              </Text>
            </Block>
          </Collapse>
        </FormSection>

        <Block paddingTop={SPACINGS.S}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSection title="Veröffentlichung *">
                <CustomDatePicker selected={date} dateFormat="dd.MM.yyyy" onChange={setDate} />
              </FormSection>
            </GridItem>
          </Grid>
        </Block>

        <Block paddingTop={SPACINGS.S}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSectionStatus
                statusSelection={['created', 'editing', 'published']}
                defaultStatus={status}
                onChange={setStatus}
              />
            </GridItem>
          </Grid>
        </Block>

        <FormSection paddingTop={SPACINGS.XL}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
            <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
          </Group>
        </FormSection>
      </LoadingWrapper>
    </OverlayLayout>
  );
};

export default IssueEdit;
