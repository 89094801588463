import { useParams } from 'react-router-dom';
import { entitySelectors } from '../../app/entitySelectors';

export const useCurrentArticle = (): {
  articleId?: number;
  article: ArticleEntity | undefined;
  channel: ChannelEntity | undefined;
  sections: SectionEntity[];
} => {
  const { sArticleId } = useParams();
  const articleId = parseInt(sArticleId || '', 10);
  const article = entitySelectors.selectById<ArticleEntity>('articles', articleId);
  const channel = entitySelectors.selectById<ChannelEntity>('channels', article?.channelId);
  const sections = entitySelectors.selectByIds<SectionEntity>('sections', article?.sectionIds);

  return {
    articleId,
    article,
    channel,
    sections,
  };
};
