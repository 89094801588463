import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { tagSchema } from '../schemas';

export const createTag = (name: string): RequestEntitiesAction<TagData> =>
  requestEntities({
    method: 'POST',
    path: '/tags',
    data: {
      name,
    },
    schema: tagSchema,
  });

export const fetchTagsByName = (tagNames: string | string[]): RequestEntitiesAction<TagData[]> => {
  if (typeof tagNames === 'string') {
    tagNames = [tagNames];
  }
  return requestEntities({
    method: 'GET',
    path: `/tags?${tagNames.map((tageName) => `filters[name]=${tageName}`).join('&')}`,
    schema: [tagSchema],
  });
};

const tagsAdapter = createEntityAdapter<TagEntity>();

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.tags) {
        tagsAdapter.upsertMany(state, action.payload.entities.tags);
      }
    });
  },
});

export default tagsSlice.reducer;
