import { entitySelectors } from '../../app/entitySelectors';

const mimeSecondariesWebImage = ['jpeg', 'jpg', 'png', 'gif', 'webp'];

export const useMediaAsset = (
  mediaAssetId: number | undefined
): {
  mediaAsset: MediaAssetEntity | undefined;
  files: FileEntity[];
  firstFile: FileEntity | undefined;
  firstFileSrc: string | undefined;
  firstFileTeaserImageSrc: string | undefined;
  firstFileMimePrimary: string;
  firstFileMimeSecondary: string;
  firstFileIsWebImage?: boolean;
} => {
  const mediaAsset = entitySelectors.selectById<MediaAssetEntity>('mediaAssets', mediaAssetId);
  const files = entitySelectors.selectByIds<FileEntity>('files', mediaAsset?.fileIds);

  const firstFile = files[0] as FileEntity | undefined;
  const firstFileSrc = firstFile?.url;
  const firstFileTeaserImageSrc = firstFile?.formats?.small?.url || firstFile?.formats?.thumbnail?.url;

  const firstFileMimePrimary = firstFile?.mime ? firstFile?.mime.split('/')[0] : '';
  const firstFileMimeSecondary = firstFile?.mime ? firstFile?.mime.split('/')[1] : '';
  const firstFileIsWebImage =
    !!firstFileTeaserImageSrc &&
    firstFileMimePrimary === 'image' &&
    mimeSecondariesWebImage.includes(firstFileMimeSecondary);

  return {
    mediaAsset,
    files,
    firstFile,
    firstFileSrc,
    firstFileTeaserImageSrc,
    firstFileMimePrimary,
    firstFileMimeSecondary,
    firstFileIsWebImage,
  };
};
