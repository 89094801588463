import React from 'react';
import { Block, Group } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import { useAssetMedia } from './useAssetMedia';
import { SPACINGS } from '../../styles/theme';
import FormSectionMediaAsset from './FormSectionMediaAsset';
import Text from '../shared/atoms/Text';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import FormSection from '../shared/forms/FormSection';
import { useAppDispatch } from '../../app/hooks';
import { fetchAsset, updateAsset } from '../../app/slices/assetsSlice';
import { deleteMediaAsset } from '../../app/slices/mediaAssetsSlice';
import { swapArrayElements } from '../../utils/array';
import useAPIError from '../apiError/useAPIError';

interface FormSectionMediaAssetsProps {
  assetId: number | undefined;
  paddingTop?: string;
  onBeforeEdit?: () => void;
  onClickAddMediaAsset?: React.MouseEventHandler;
  onClickEditMediaAsset?: (mediaAssetId: number | undefined) => void;
  isDisabled?: boolean;
}

const FormSectionMediaAssets: React.FC<FormSectionMediaAssetsProps> = ({
  assetId,
  paddingTop,
  onBeforeEdit,
  onClickAddMediaAsset,
  onClickEditMediaAsset,
  isDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { mediaAssets } = useAssetMedia(assetId);

  const handleOnClickDelete = async (mediaAssetId: number | undefined) => {
    if (!!assetId && !!mediaAssetId) {
      if (onBeforeEdit) {
        onBeforeEdit();
      }
      const deleteMediaAssetResponse = await dispatch(deleteMediaAsset(assetId, mediaAssetId));
      if (deleteMediaAssetResponse.payload?.error) {
        addError(deleteMediaAssetResponse.payload.error.message);
      }
      const fetchAssetResponse = await dispatch(fetchAsset(assetId));
      if (fetchAssetResponse.payload?.error) {
        addError(fetchAssetResponse.payload.error.message);
      }
    }
  };

  const handleOnClickMove = async (index: number, targetIndex: number) => {
    if (!!assetId) {
      if (onBeforeEdit) {
        onBeforeEdit();
      }
      const mediaAssetsNew = swapArrayElements(mediaAssets, index, targetIndex);
      const mediaAssetsData: MediaAssetUpdateData[] = mediaAssetsNew.map((mediaAsset) => {
        return {
          id: mediaAsset.id,
        };
      });
      const response = await dispatch(
        updateAsset(assetId, {
          media_assets: mediaAssetsData,
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  return (
    <FormSection title="Medien" paddingTop={paddingTop}>
      {mediaAssets.length !== 0 ? (
        <Group vertical vSpacing={SPACINGS.XXS}>
          {mediaAssets.map((mediaAsset, index) => {
            return (
              <FormSectionMediaAsset
                key={mediaAsset.id}
                mediaAssetId={mediaAsset.id}
                isFirst={index === 0}
                isLast={index === mediaAssets.length - 1}
                onClickEdit={onClickEditMediaAsset}
                onClickUp={() => handleOnClickMove(index, index - 1)}
                onClickDown={() => handleOnClickMove(index, index + 1)}
                onClickDelete={handleOnClickDelete}
              />
            );
          })}
        </Group>
      ) : (
        <Text>Für dieses Asset sind noch keine Medien vorhanden.</Text>
      )}
      <Block paddingTop={SPACINGS.XXS}>
        <ButtonSecondary
          label="Medium hinzufügen"
          icon={IconPlus}
          onClick={onClickAddMediaAsset}
          disabled={isDisabled}
        />
      </Block>
    </FormSection>
  );
};

export default FormSectionMediaAssets;
