import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';

interface StyledTextProps {
  color?: string;
}

const StyledInline = styled.span<StyledTextProps>(({ color }) => {
  return {
    color: color || COLORS.DARK,
  };
});

interface InlineProps {
  color?: string;
}

const Inline: React.FC<InlineProps> = ({ color, children }) => {
  return <StyledInline color={color}>{children}</StyledInline>;
};

export default Inline;
