import { Editor } from '@tiptap/react';
import { Group, GroupItem } from '@webfox-sc/core';
import React from 'react';
import {
  RiBold,
  RiCloseFill,
  RiCodeSSlashLine,
  RiItalic,
  RiListUnordered,
  RiTerminalWindowLine,
  RiPlayListAddFill,
} from 'react-icons/ri';
import { MdSpellcheck } from 'react-icons/md';
import styled, { CSSObject } from 'styled-components';

import { COLORS, SPACINGS } from '../../styles/theme';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import SingleSelect from '../shared/select/SingleSelect';
import RemoveNumberedListIcon from '../editor/RemoveNumberedListIcon';

interface StyledComponentProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

const ToolbarWrapper = styled.div<StyledComponentProps>((): CSSObject => {
  return {
    borderBottom: `2px solid ${COLORS.MID_GREY}`,
    padding: '11px',
  };
});

const paragraphFormatOptions = [
  {
    value: 'Normal',
    label: 'Normal',
    formatType: 'Node',
    formatArgs: 'paragraph',
  },
  {
    value: 'H1',
    label: 'H1',
    formatType: 'Heading',
    formatArgs: { level: 1 },
  },
  {
    value: 'H2',
    label: 'H2',
    formatType: 'Heading',
    formatArgs: { level: 2 },
  },
  {
    value: 'H3',
    label: 'H3',
    formatType: 'Heading',
    formatArgs: { level: 3 },
  },
  {
    value: 'H4',
    label: 'H4',
    formatType: 'Heading',
    formatArgs: { level: 4 },
  },
  {
    value: 'H5',
    label: 'H5',
    formatType: 'Heading',
    formatArgs: { level: 5 },
  },
  {
    value: 'H6',
    label: 'H6',
    formatType: 'Heading',
    formatArgs: { level: 6 },
  },
  {
    value: 'Blockquote',
    label: 'Blockquote',
    formatType: 'Blockquote',
  },
];

interface MarkdownEditorToolbarProps {
  editor: Editor | null;
  showEditor: boolean;
  setShowEditor: (boolean) => void;
  onCheckSpelling: () => void;
  suggestionsEnabled: boolean;
  canEnableSuggestions: boolean;
  setSuggestionsEnabled: (boolean) => void;
}

const MarkdownEditorToolbar: React.FC<MarkdownEditorToolbarProps> = ({
  editor,
  showEditor,
  setShowEditor,
  onCheckSpelling,
  suggestionsEnabled,
  setSuggestionsEnabled,
  canEnableSuggestions,
}) => {
  if (!editor) {
    return null;
  }

  let paragraphFormat;
  if (editor.isActive('blockquote')) {
    paragraphFormat = 'Blockquote';
  } else if (editor.isActive('heading')) {
    paragraphFormat = `H${editor.getAttributes('heading').level}`;
  } else {
    paragraphFormat = 'Normal';
  }

  const paragraphFormatOption = paragraphFormatOptions.find((option) => option.value === paragraphFormat);

  const handleChangeParagraphFormat = ({ formatType, formatArgs }) => {
    // set format
    const chainedCommand = editor.chain().focus();
    if (formatType) {
      const command = `set${formatType}`;
      if (formatArgs) {
        chainedCommand[command](formatArgs);
      } else {
        chainedCommand[command]();
      }
    }
    chainedCommand.focus().run();
  };

  return (
    <ToolbarWrapper>
      {showEditor ? (
        <Group spacing={SPACINGS.XXS}>
          <GroupItem>
            <SingleSelect
              options={paragraphFormatOptions}
              defaultValue={paragraphFormatOption}
              value={paragraphFormatOption}
              placeholder="Auswählen"
              onChange={handleChangeParagraphFormat}
              isClearable={false}
            />
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Fett"
                title="Fett"
                icon={<RiBold />}
                active={editor.isActive('bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
              />
              <ButtonSecondary
                ariaLabel="Kursiv"
                title="Kursiv"
                icon={<RiItalic />}
                active={editor.isActive('italic')}
                onClick={() => editor.chain().focus().toggleItalic().run()}
              />
              {/* <ButtonSecondary
                ariaLabel="Unterstrichen"
                title="Unterstrichen"
                icon={<RiUnderline />}
                active={editor.isActive('underline')}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
              /> */}
            </Group>
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Stichpunkte"
              title="Stichpunkte"
              icon={<RiListUnordered />}
              active={editor.isActive('bulletList')}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
            />
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Automatische Nummerierung unterdrücken"
              title="Automatische Nummerierung unterdrücken"
              icon={RemoveNumberedListIcon}
              onClick={() => editor.chain().focus().insertContent('&#8291;').run()}
            />
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Text bereinigen (Formatierungen entfernen, Zeilenumbrüche entfernen/in Absätze umwandeln)"
              title="Text bereinigen (Formatierungen entfernen, Zeilenumbrüche entfernen/in Absätze umwandeln)"
              icon={<RiCloseFill />}
              onClick={() => editor.chain().selectAll().unsetAllMarks().clearNodes().run()}
            />
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Rechtschreibprüfung"
              title="Rechtschreibprüfung"
              icon={<MdSpellcheck />}
              onClick={() => onCheckSpelling()}
            />
          </GroupItem>

          {/* TODO spell check */}

          <GroupItem>
            <ButtonSecondary
              ariaLabel="Quelltext anzeigen"
              title="Quelltext anzeigen"
              icon={showEditor ? <RiCodeSSlashLine /> : <RiTerminalWindowLine />}
              onClick={() => setShowEditor(false)}
            />
          </GroupItem>

          {canEnableSuggestions && (
            <GroupItem>
              <ButtonSecondary
                ariaLabel="Vorschläge einschalten"
                title="Vorschläge einschalten"
                icon={<RiPlayListAddFill />}
                active={canEnableSuggestions && suggestionsEnabled}
                onClick={() => setSuggestionsEnabled(!suggestionsEnabled && canEnableSuggestions)}
              />
            </GroupItem>
          )}
        </Group>
      ) : (
        <ButtonSecondary
          ariaLabel="Editor anzeigen"
          title="Editor anzeigen"
          icon={<RiTerminalWindowLine />}
          onClick={() => setShowEditor(true)}
        />
      )}
    </ToolbarWrapper>
  );
};

export default MarkdownEditorToolbar;
