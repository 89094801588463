import React from 'react';
import { COLORS, SPACINGS } from '../../styles/theme';
import { Block, Group } from '@webfox-sc/core';
import TextLink from '../shared/atoms/TextLink';
import Text from '../shared/atoms/Text';
import { useTags } from './useTags';

type TagGroupVariant = 'boxesWithoutLink' | undefined;
interface FormSectionTagsProps {
  tagIds?: number[];
  variant?: TagGroupVariant;
}

const TagGroup: React.FC<FormSectionTagsProps> = ({ tagIds, variant }) => {
  const { tagsSorted } = useTags(tagIds);

  return (
    <Group hSpacing={!variant ? SPACINGS.XS : SPACINGS.XXXS}>
      {tagsSorted.map((tag) => {
        return (
          (!variant && (
            <TextLink key={tag.id} to={`/tags/${tag.name}`}>
              #{tag.name}
            </TextLink>
          )) ||
          (variant === 'boxesWithoutLink' && (
            <Block background={COLORS.DARK} key={tag.id} padding="1px 4px" marginTop={SPACINGS.XXXS} borderRadius="3px">
              <Text color={COLORS.WHITE} variant="tiny">
                #{tag.name}
              </Text>
            </Block>
          ))
        );
      })}
    </Group>
  );
};

export default TagGroup;
