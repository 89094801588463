import React from 'react';
import { RadioGroup } from '@webfox-sc/core';
import { COLORS, STYLES } from '../../../styles/theme';

const theme = {
  item: {
    radio: {
      height: '32px',
      width: '32px',
      border: 'none',
      background: COLORS.WHITE,
      boxShadow: `inset 0 0 0 2px ${COLORS.MID_GREY}, inset 0 0 0 10px white`,
      transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
      '&[data-checked="true"]': {
        background: COLORS.BLUE,
        boxShadow: `inset 0 0 0 2px ${COLORS.MID_GREY}, inset 0 0 0 10px white`,
      },
      '&[data-focus="true"]': {
        boxShadow: `inset 0 0 0 2px ${COLORS.GREY}, inset 0 0 0 10px white`,
      },
    },
    label: {
      fontFamily: STYLES.FONT_FAMILY,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 'inherit',
      color: COLORS.DARK,
      paddingTop: '5px',
    },
  },
};

export interface CustomRadioGroupProps {
  value?: string;
  spacing?: string;
  vertical?: boolean;
  onChange?: (value: number | string) => void;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = (props) => {
  return <RadioGroup {...props} theme={theme} />;
};

export default CustomRadioGroup;
