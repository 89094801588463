import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Text from '../shared/atoms/Text';
import { useAppDispatch } from '../../app/hooks';
import { fetchArticle, fetchArticles, updateArticle } from '../../app/slices/articlesSlice';
import useAPIError from '../apiError/useAPIError';
import Headline from '../shared/atoms/Headline';
import OverlayLayout from '../overlay/OverlayLayout';
import { debounce } from 'lodash';
import FormSectionFilters from './FormSectionFilters';
import { filterDataToParams } from '../../utils/filterData';
import ArticleImportItem from './ArticleImportItem';
import { useCurrentArticle } from './useCurrentArticle';
import { useOverlay } from '../overlay/useOverlay';
import { useArticleSections } from './useArticleSections';
import { useArticle } from './useArticle';

const ITEMS_PER_PAGE = 10;

const getDefaultFilterData = (articleId: number): FilterData => ({
  start: 0,
  limit: ITEMS_PER_PAGE,
  dateRange: 'last_7_days',
  sortingKey: 'updatedAt',
  sortingOrder: 'desc',
  excludeIds: [articleId],
});

const ArticleImport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();

  const { article } = useCurrentArticle();
  const { sectionsMap } = useArticleSections(article?.id);

  const [filterData, setFilterData] = useState<FilterData>(getDefaultFilterData(article?.id || 0));

  const [articleIds, setArticleIds] = useState<number[]>([]);
  const [articleCount, setArticleCount] = useState<number>(0);

  const [disabled, setDisabled] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  const [filteredArticleId, setFilteredArticleId] = useState<number | undefined>(undefined);
  const { article: filteredArticleById } = useArticle(filteredArticleId);

  const fetchFilteredArticles = useCallback(
    async (newFilterData: FilterData) => {
      const res = await dispatch(fetchArticles(filterDataToParams(newFilterData, true)));
      if (res.payload?.error) {
        addError(res.payload.error.message);
      }

      const data = res?.payload?.data || [];
      const newArticleIds = data.map(({ id }) => id);
      setArticleIds((oldArticleIds) =>
        (newFilterData?.start || 0) > 0 ? [...oldArticleIds, ...newArticleIds] : newArticleIds
      );

      const count = res?.payload?.count || 0;
      setArticleCount(count);
      setShowLoadMoreButton((newFilterData.start || 0) + ITEMS_PER_PAGE < count);
    },
    [addError, dispatch]
  );

  const fetchFilteredArticlesDebounced = useMemo(() => debounce(fetchFilteredArticles, 300), [fetchFilteredArticles]);

  const setFilteredArticleIdFromSearchTextDebounced = useMemo(
    () =>
      debounce((searchText?: string) => {
        if (!searchText) {
          setFilteredArticleId(undefined);
          return;
        }
        // check if search text is a numeric value which is then assumed to be an article id
        if (!/^\d+$/.test(searchText)) {
          setFilteredArticleId(undefined);
          return;
        }

        const newFilteredArticleId = parseInt(searchText, 10);
        // we don't want to get the current article here
        if (newFilteredArticleId === article?.id) {
          setFilteredArticleId(undefined);
          return;
        }

        setFilteredArticleId(newFilteredArticleId);
      }, 300),
    [article?.id]
  );

  useEffect(() => {
    (async () => {
      if (article?.id) {
        await fetchFilteredArticles(getDefaultFilterData(article.id));
      }
    })();
  }, [article?.id, fetchFilteredArticles]);

  useEffect(() => {
    (async () => {
      if (filteredArticleId && !filteredArticleById) {
        const response = await dispatch(fetchArticle(filteredArticleId));
        if (!response?.payload?.data) {
          setFilteredArticleId(undefined);
        }
      }
    })();
  }, [dispatch, filteredArticleById, filteredArticleId]);

  const handleFilterChange = async (changedFilterData: FilterData) => {
    const { searchText } = changedFilterData;

    setFilteredArticleIdFromSearchTextDebounced(searchText);

    const newFilterData = {
      ...changedFilterData,
      start: 0,
      limit: ITEMS_PER_PAGE,
    };
    setFilterData(newFilterData);
    fetchFilteredArticlesDebounced(newFilterData);
  };

  const handleClickLoadMore = () => {
    const newFilterData = {
      ...filterData,
      start: (filterData?.start || 0) + ITEMS_PER_PAGE,
    };
    setFilterData(newFilterData);
    fetchFilteredArticlesDebounced(newFilterData);
  };

  const handleOnSelectArticle = async (importedSectionsMap: SectionsMapEntry[]) => {
    if (article && importedSectionsMap.length) {
      setDisabled(true);

      // remove all sectionMapEntries which are already present in the current article
      const importedSectionsMapFiltered = importedSectionsMap.filter((importedEntry) => {
        if (importedEntry.asset) {
          return !sectionsMap.some(({ asset }) => asset === importedEntry.asset);
        }
        if (importedEntry.snippet) {
          return !sectionsMap.some(({ snippet }) => snippet === importedEntry.snippet);
        }
        return false;
      });

      if (importedSectionsMapFiltered.length) {
        const newSectionsMap = [...sectionsMap, ...importedSectionsMapFiltered];

        /**
         * update article sections
         */
        const response = await dispatch(
          updateArticle(article.id, {
            Section: newSectionsMap,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }

      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Elemente" title="importieren">
      <Block>
        <Headline>Elemente aus bestehendem Artikel importieren</Headline>
      </Block>
      <Block paddingTop={SPACINGS.M}>
        <FormSectionFilters filterData={filterData} onChange={handleFilterChange} />
      </Block>

      {filteredArticleById && filteredArticleId && (
        <Group paddingTop={SPACINGS.M} paddingBottom={SPACINGS.M}>
          <GroupItem width="100%">
            <ArticleImportItem
              articleId={filteredArticleId}
              onSelect={handleOnSelectArticle}
              disabled={disabled}
              customText={`${filteredArticleById.name} (id: <b>${filteredArticleId}</b>)`}
            />
          </GroupItem>
        </Group>
      )}

      <Group paddingTop={SPACINGS.M} spacing={SPACINGS.XXS} vertical>
        {articleIds.map((articleId) => (
          <GroupItem key={`snippet-${articleId}`}>
            <ArticleImportItem articleId={articleId} onSelect={handleOnSelectArticle} disabled={disabled} />
          </GroupItem>
        ))}
      </Group>

      <Block display="inline-block" paddingTop={SPACINGS.XXS}>
        <Text variant="small" textAlign="center">
          {articleIds.length} von {articleCount}
        </Text>
        <Block background={COLORS.MID_GREY}>
          <Block
            width={`${(100 / articleCount) * articleIds.length}%`}
            height="4px"
            background={articleCount === 0 ? 'transparent' : COLORS.BLUE}
          />
        </Block>
        <Block paddingTop={SPACINGS.XXS}>
          <ButtonPrimary label="Weitere anzeigen" disabled={!showLoadMoreButton} onClick={handleClickLoadMore} />
        </Block>
      </Block>
    </OverlayLayout>
  );
};

export default ArticleImport;
