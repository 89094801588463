import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Block, Group } from '@webfox-sc/core';
import { IconPencil, IconDelete } from '@webfox-sc/icons';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useIssuePage } from './useIssuePage';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useRessortsSelect } from '../ressorts/useRessortsSelect';
import IssueArticle from './IssueArticle';
import Inline from '../shared/atoms/Inline';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import { refetchPage } from '../../app/slices/pagesSlice';
import useAPIError from '../apiError/useAPIError';

const StyledWrapper = styled.div({
  position: 'relative',
});

const StyledPageDragHandle = styled.div({
  cursor: 'grab',
});

const StyledPageDropTargetLeft = styled.div({
  position: 'absolute',
  top: 0,
  left: '-24px',
  width: '24px',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
});

const StyledPageDropTarget = styled.div({
  position: 'absolute',
  top: 0,
  right: '-24px',
  width: '24px',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
});

interface StyledPageDropTargetInnerProps {
  canDropPage?: boolean;
  isPageDragOver?: boolean;
}

const StyledPageDropTargetInner = styled.div<StyledPageDropTargetInnerProps>(({ canDropPage, isPageDragOver }) => {
  return {
    width: isPageDragOver ? '18px' : 0,
    border: `${isPageDragOver ? '2px' : '1px'} ${isPageDragOver ? 'dashed' : 'solid'} ${COLORS.BLUE}`,
    background: COLORS.WHITE,
    boxSizing: 'border-box',
    height: '100%',
    opacity: canDropPage ? 1 : 0,
    transition: 'opacity 0.3s ease',
  };
});

interface StyledContentProps {
  canDropArticle?: boolean;
}

const StyledContent = styled.div<StyledContentProps>(({ canDropArticle }) => {
  return {
    padding: `${SPACINGS.XS} ${SPACINGS.S}`,
    borderLeft: `2px solid ${COLORS.MID_GREY}`,
    borderRight: `2px solid ${COLORS.MID_GREY}`,
    borderBottom: `2px solid ${COLORS.MID_GREY}`,
    background: canDropArticle ? COLORS.SILVER : COLORS.WHITE,
    transition: 'background-color 0.3s ease',
  };
});

interface StyledDropBoxProps {
  isHighlight?: boolean;
}

const StyledArticleDropBox = styled.div<StyledDropBoxProps>(({ isHighlight }) => {
  return {
    height: '112px',
    background: COLORS.WHITE,
    border: `2px dashed ${isHighlight ? COLORS.BLUE : COLORS.MID_GREY}`,
  };
});

interface IssuePageProps {
  pageId: number | undefined;
  index: number;
  onClickDelete?: (pageId: number | undefined) => void;
  onPageMoveTo?: (pageId: number, targetIndex: number) => void;
}

const IssuePage: React.FC<IssuePageProps> = ({ pageId, index, onClickDelete, onPageMoveTo }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { page, pageSpanFormatted, articlesSorted, linesTotal, linesRemaining } = useIssuePage(pageId);
  const { ressortsSortedFormatted } = useRessortsSelect(page?.ressortIds);

  const handleOnClickEdit = () => {
    navigate(`/issue/${page?.issueId}/page/${page?.id}/edit`);
  };

  const handleOnClickDelete = () => {
    if (typeof onClickDelete === 'function') {
      onClickDelete(pageId);
    }
  };

  const pageDragItem: PageDragItem = { id: pageId || 0, index, issueId: page?.issueId || null };

  const [{ isPageDragging }, pageDragHandle, pageDragPreview] = useDrag(
    () => ({
      type: 'issuePage',
      item: pageDragItem,
      collect: (monitor) => ({
        isPageDragging: monitor.isDragging(),
      }),
    }),
    [pageDragItem, index, page]
  );

  const [{ isPageDragOverLeft, canDropPageLeft }, pageDropTargetLeft] = useDrop(
    () => ({
      accept: 'issuePage',
      collect: (monitor) => ({
        isPageDragOverLeft: monitor.isOver(),
        canDropPageLeft: monitor.canDrop(),
      }),
      canDrop: (item) => {
        return !item.issueId || (item.index !== index && item.index + 1 !== index);
      },
      drop: (item: PageDragItem) => {
        if (onPageMoveTo && item.id !== pageId) {
          onPageMoveTo(item.id, index);
        }
      },
    }),
    [index, page]
  );

  const [{ isPageDragOverRight, canDropPageRight }, pageDropTargetRight] = useDrop(
    () => ({
      accept: 'issuePage',
      collect: (monitor) => ({
        isPageDragOverRight: monitor.isOver(),
        canDropPageRight: monitor.canDrop(),
      }),
      canDrop: (item) => {
        return !item.issueId || (item.index !== index && item.index - 1 !== index);
      },
      drop: (item: PageDragItem) => {
        if (onPageMoveTo && item.id !== pageId) {
          onPageMoveTo(item.id, index + 1);
        }
      },
    }),
    [index, page]
  );

  const isDroppableArticleItem = (item: ArticleDragItem) => {
    if (!item.id || !item.ressortId) {
      return false;
    }
    return !(page?.articleIds || []).includes(item.id) && (page?.ressortIds || []).includes(item.ressortId);
  };

  const handleOnDropArticleItem = async (item: ArticleDragItem) => {
    if (isDroppableArticleItem(item)) {
      if (!!item.id && !!page) {
        const updateArticleResponse = await dispatch(
          updateArticle(item.id, {
            page: page.id,
          })
        );
        if (updateArticleResponse.payload?.error) {
          addError(updateArticleResponse.payload.error.message);
        }
        const refetchPageResponse = await dispatch(refetchPage(page.id));
        if (refetchPageResponse.payload?.error) {
          addError(refetchPageResponse.payload.error.message);
        }

        if (item.pageId) {
          const refetchItemResponse = await dispatch(refetchPage(item.pageId));
          if (refetchItemResponse.payload?.error) {
            addError(refetchItemResponse.payload.error.message);
          }
        }
      }
    }
  };

  const [{ canDropArticle, isArticleDragOver }, articleDropTarget] = useDrop(
    () => ({
      accept: 'issueArticle',
      drop: (item: ArticleDragItem) => handleOnDropArticleItem(item),
      canDrop: (item) => {
        return isDroppableArticleItem(item);
      },
      collect: (monitor) => ({
        canDropArticle: monitor.canDrop(),
        isArticleDragOver: monitor.isOver(),
      }),
    }),
    [page]
  );

  return (
    <StyledWrapper>
      <StyledPageDropTargetLeft ref={pageDropTargetLeft}>
        <StyledPageDropTargetInner canDropPage={canDropPageLeft} isPageDragOver={isPageDragOverLeft} />
      </StyledPageDropTargetLeft>
      <StyledPageDropTarget ref={pageDropTargetRight}>
        <StyledPageDropTargetInner canDropPage={canDropPageRight} isPageDragOver={isPageDragOverRight} />
      </StyledPageDropTarget>
      <div ref={pageDragPreview} style={{ opacity: isPageDragging ? 0 : 1 }}>
        <StyledPageDragHandle ref={pageDragHandle}>
          <Block
            height="32px"
            justify="center"
            alignItems="center"
            background={linesRemaining >= 0 ? COLORS.DARK : COLORS.RED}
          >
            <Text variant="small" color={COLORS.WHITE}>
              {pageSpanFormatted}
            </Text>
          </Block>
        </StyledPageDragHandle>
        <StyledContent ref={articleDropTarget} canDropArticle={canDropArticle}>
          <Text variant="label-small" textAlign="center">
            {ressortsSortedFormatted ? ressortsSortedFormatted : <em>Kein Ressort</em>}
          </Text>

          <Block paddingTop={SPACINGS.XXS}>
            <Text variant="small" textAlign="center">
              {page?.linesMax ? (
                <>
                  <b>{page?.linesMax}</b> Zeilen verfügbar
                </>
              ) : (
                <>Keine Zeilen verfügbar</>
              )}
            </Text>
          </Block>

          <Group vertical spacing={SPACINGS.XXS} paddingTop={SPACINGS.XS}>
            {articlesSorted.length > 0 &&
              articlesSorted.map((article) => {
                return <IssueArticle key={article.id} articleId={article.id} pageId={pageId} />;
              })}
            <StyledArticleDropBox isHighlight={canDropArticle && isArticleDragOver} />
          </Group>

          <Block paddingTop={SPACINGS.XS}>
            <Text variant="small" textAlign="center">
              {linesTotal} {linesTotal === 1 ? 'Zeile' : 'Zeilen'} gesamt
            </Text>
            <Text variant="small" textAlign="center">
              {linesRemaining === 0 && <>Keine zusätzlichen Zeilen verfügbar</>}
              {linesRemaining > 0 && (
                <>
                  noch{' '}
                  <Inline color={COLORS.GREEN}>
                    <b>{linesRemaining}</b>
                  </Inline>{' '}
                  {linesRemaining === 1 ? 'Zeile' : 'Zeilen'} verfügbar
                </>
              )}
              {linesRemaining < 0 && (
                <>
                  <Inline color={COLORS.RED}>
                    <b>{Math.abs(linesRemaining)}</b>
                  </Inline>{' '}
                  {Math.abs(linesRemaining) === 1 ? 'Zeile' : 'Zeilen'} zu viel
                </>
              )}
            </Text>
          </Block>

          <Group nowrap spacing={SPACINGS.XXS} justify="center" paddingTop={SPACINGS.XS}>
            <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
            <ButtonPrimary
              ariaLabel="Entfernen"
              icon={IconDelete}
              disabled={articlesSorted.length > 0}
              onClick={handleOnClickDelete}
            />
          </Group>
        </StyledContent>
      </div>
    </StyledWrapper>
  );
};

export default IssuePage;
