/* eslint-disable @typescript-eslint/no-explicit-any */
import _map from 'lodash/map';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import qs from 'qs';
import { requestEntities, RequestEntitiesAction, RequestEntitiesThunkArg } from '../requestEntities';
import { snippetSchema } from '../schemas';

export const createSnippet = (data: { name?: string }): RequestEntitiesAction<SnippetData> =>
  requestEntities({
    method: 'POST',
    path: '/snippets',
    data,
    schema: snippetSchema,
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchSnippets = (params?: any): RequestEntitiesAction<SnippetData[]> => {
  const requestData: RequestEntitiesThunkArg = {
    method: 'GET',
    path: '/snippets',
    schema: [snippetSchema],
    withCount: true,
  };

  if (params) {
    const paramStrings = _map(params, (args, key) => qs.stringify({ [key]: args }));
    requestData.path = `${requestData.path}?${paramStrings.join('&')}`;
  }

  return requestEntities(requestData);
};

export const fetchSnippet = (snippetId: number): RequestEntitiesAction<SnippetData> =>
  requestEntities({
    method: 'GET',
    path: `/snippets/${snippetId}`,
    schema: snippetSchema,
  });

export const updateSnippet = (snippetId: number, data: SnippetUpdateData): RequestEntitiesAction<ArticleData> =>
  requestEntities({
    method: 'PUT',
    path: `/snippets/${snippetId}`,
    data,
    schema: snippetSchema,
  });

const snippetsAdapter = createEntityAdapter<SnippetEntity>();

export const snippetsSlice = createSlice({
  name: 'snippets',
  initialState: snippetsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.snippets) {
        snippetsAdapter.upsertMany(state, action.payload.entities.snippets);
      }
    });
  },
});

export default snippetsSlice.reducer;
