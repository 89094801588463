import React from 'react';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import Inline from '../shared/atoms/Inline';
import { useArticle } from './useArticle';
import Headline from '../shared/atoms/Headline';
import { IconPencil } from '@webfox-sc/icons';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';

interface ArticleViewHeadlinesProps {
  articleId?: number;
  onClickEdit?: (e: React.MouseEvent) => void;
}

const ArticleViewHeadlines: React.FC<ArticleViewHeadlinesProps> = ({ articleId, onClickEdit }) => {
  const { headlines } = useArticle(articleId);

  return (
    <>
      <Group alignItems="flex-end">
        <GroupItem grow>
          <Text variant="small" color={COLORS.GREY}>
            Überschriften
          </Text>
        </GroupItem>
        <ButtonPrimary variant="small" ariaLabel="Bearbeiten" icon={IconPencil} onClick={onClickEdit} />
      </Group>
      <Block paddingTop={SPACINGS.XXS}>
        <Block padding={`${SPACINGS.M} ${SPACINGS.XL}`} background={COLORS.WHITE}>
          <Text>
            {headlines.length > 0 ? (
              <Group vertical spacing={SPACINGS.XS}>
                {headlines.map((headline, index) => {
                  return (
                    <Headline key={headline.id} color={index === 0 ? COLORS.DARK : COLORS.GREY}>
                      {headline.text}
                    </Headline>
                  );
                })}
              </Group>
            ) : (
              <Inline color={COLORS.GREY}>
                <em>Für diesen Artikel sind derzeit keine Überschriften vorhanden.</em>
              </Inline>
            )}
          </Text>
        </Block>
      </Block>
    </>
  );
};

export default ArticleViewHeadlines;
