import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const headlinesAdapter = createEntityAdapter<HeadlineEntity>();

export const headlinesSlice = createSlice({
  name: 'headlines',
  initialState: headlinesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.headlines) {
        headlinesAdapter.upsertMany(state, action.payload.entities.headlines);
      }
    });
  },
});

export default headlinesSlice.reducer;
