import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { channelSchema } from '../schemas';

export const fetchChannels = (): RequestEntitiesAction<ChannelData[]> =>
  requestEntities({
    method: 'GET',
    path: '/channels',
    schema: [channelSchema],
  });

const channelsAdapter = createEntityAdapter<ChannelEntity>();

export const channelsSlice = createSlice({
  name: 'users',
  initialState: channelsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.channels) {
        channelsAdapter.upsertMany(state, action.payload.entities.channels);
      }
    });
  },
});

export default channelsSlice.reducer;
