import React, { useEffect, useRef } from 'react';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import { useCurrentArticle } from './useCurrentArticle';
import Text from '../shared/atoms/Text';
import { SPACINGS } from '../../styles/theme';
import { useStatus } from '../status/useStatus';
import Headline from '../shared/atoms/Headline';
import StatusLabel from '../shared/atoms/StatusLabel';
import ArticleViewSnippet from './ArticleViewSnippet';
import ArticleViewAsset from './ArticleViewAsset';
import ArticleViewHeadlines from './ArticleViewHeadlines';
import { useLocation, useNavigate } from 'react-router-dom';

const ArticleView: React.FC = () => {
  const { article, channel, sections } = useCurrentArticle();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const scrollSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollSectionRef.current) {
      scrollSectionRef.current.scrollTop = hash ? parseInt(hash.substr(1)) : 0;
    }
  }, [hash]);

  const { statusLabelText, statusLabelBackground, updatedTextLine } = useStatus({
    status: article?.status,
    updatedTime: article?.updatedTime,
    updatedByUserId: article?.updatedByUserId,
  });

  const handleOnClickEdit = (type: 'headlines' | 'snippet' | 'asset', elementId?: number) => (e: React.MouseEvent) => {
    e.preventDefault();

    const navigateUrl =
      type === 'headlines' ? `/article/${article?.id}/edit` : `/article/${article?.id}/${type}/${elementId}/edit`;

    const scrollTopUrlHash = scrollSectionRef.current?.scrollTop ? `#${scrollSectionRef.current.scrollTop}` : '';
    navigate(scrollTopUrlHash, { replace: true });
    navigate(navigateUrl);
  };

  return (
    <OverlayLayout
      typeLabelPrimary="Beitrag"
      typeLabelSecondary={channel && channel.name}
      title="Ansehen"
      innerContentRef={scrollSectionRef}
    >
      {article && (
        <>
          <Block minHeight="40px" alignItems="center">
            <Group width="100%" justify="space-between" alignItems="center">
              <GroupItem grow>
                <Text variant="small">{updatedTextLine}</Text>
              </GroupItem>
              <GroupItem>
                <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
              </GroupItem>
            </Group>
          </Block>

          <Block paddingTop={SPACINGS.XL}>
            <Headline>{article.name}</Headline>
          </Block>

          <Group vertical spacing={SPACINGS.S} paddingTop={SPACINGS.XL}>
            <ArticleViewHeadlines articleId={article.id} onClickEdit={handleOnClickEdit('headlines')} />
            {sections.map((section) => {
              if (section.assetId) {
                return (
                  <ArticleViewAsset
                    key={section.id}
                    assetId={section.assetId}
                    onClickEdit={handleOnClickEdit('asset', section.assetId)}
                  />
                );
              } else if (section.snippetId) {
                return (
                  <ArticleViewSnippet
                    key={section.id}
                    onClickEdit={handleOnClickEdit('snippet', section.snippetId)}
                    snippetId={section.snippetId}
                  />
                );
              }
              return null;
            })}
          </Group>
        </>
      )}
    </OverlayLayout>
  );
};

export default ArticleView;
