import { Block, Collapse, Grid, GridItem, Group } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectArticlesState, updateArticle } from '../../app/slices/articlesSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import useAPIError from '../apiError/useAPIError';
import OverlayLayout from '../overlay/OverlayLayout';
import { useOverlay } from '../overlay/useOverlay';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import CustomDatePicker from '../shared/atoms/CustomDatePicker';
import Input from '../shared/atoms/Input';
import StatusLabel from '../shared/atoms/StatusLabel';
import Text from '../shared/atoms/Text';
import TextArea from '../shared/atoms/TextArea';
import FormSection from '../shared/forms/FormSection';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import { useStatus } from '../status/useStatus';
import FormSectionTags from '../tags/FormSectionTags';
import FormSectionUsers from '../users/FormSectionUsers';
import FormSectionHeadlines from './FormSectionHeadlines';
import { useArticle } from './useArticle';
import { useCurrentArticle } from './useCurrentArticle';

const ArticleEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { fetchArticleStatus } = useAppSelector(selectArticlesState);

  const { articleId, article } = useCurrentArticle();
  const { channel } = useArticle(articleId);

  const { statusLabelText, statusLabelBackground, updatedTextLine } = useStatus({
    status: article?.status,
    updatedTime: article?.updatedTime,
    updatedByUserId: article?.updatedByUserId,
  });

  const [name, setName] = useState(article?.name || '');
  const [isNameError, setIsNameError] = useState(false);
  const [abstract, setAbstract] = useState(article?.abstract || '');
  const [tagIds, setTagIds] = useState(article?.tagIds);
  const [publicationTime, setPublicationTime] = useState<Date | undefined | null>(
    article?.publicationTime ? new Date(article?.publicationTime) : null
  );
  const [expirationTime, setExpirationTime] = useState<Date | undefined | null>(
    article?.expirationTime ? new Date(article?.expirationTime) : null
  );
  const [rank, setRank] = useState<number | undefined>(article?.rank);
  const [headlines, setHeadlines] = useState<HeadlineUpdateData[] | undefined>(undefined);

  useEffect(() => {
    setName(article?.name || '');
    setAbstract(article?.abstract || '');
    setTagIds(article?.tagIds);
    setPublicationTime(article?.publicationTime ? new Date(article?.publicationTime) : null);
    setExpirationTime(article?.expirationTime ? new Date(article?.expirationTime) : null);
  }, [article]);

  useEffect(() => {
    setIsNameError(false);
  }, [name]);

  const handleOnClickUpdate = async () => {
    let hasError = false;
    let headlinesUpdateData: HeadlineUpdateData[] | undefined = undefined;
    if (!name) {
      setIsNameError(true);
      hasError = true;
    }
    if (headlines) {
      headlinesUpdateData = headlines
        .map((headline) => {
          return {
            id: headline?.id,
            text: headline?.text?.trim(),
          };
        })
        .filter((headline) => !!headline.text);
    }
    if (!hasError && articleId) {
      const articleUpdateData: ArticleUpdateData = {
        name,
        abstract,
        rank,
        tags: tagIds,
        publication_time: publicationTime,
        expiration_time: expirationTime,
      };
      if (headlinesUpdateData) {
        articleUpdateData.headlines = headlinesUpdateData;
      }
      const response = await dispatch(updateArticle(articleId, articleUpdateData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Beitrag" typeLabelSecondary={channel && channel.name} title="Metadaten bearbeiten">
      <LoadingWrapper
        requestStatus={fetchArticleStatus}
        errorCode="404"
        errorText={'Der Beitrag konnte nicht geladen werden.'}
      >
        <Block minHeight="40px" alignItems="center">
          <Group nowrap width="100%" spacing={SPACINGS.S} justify="space-between" alignItems="center">
            <Text variant="small">{updatedTextLine}</Text>
            <StatusLabel background={statusLabelBackground}>{statusLabelText}</StatusLabel>
          </Group>
        </Block>

        <FormSection title="Arbeitstitel *" paddingTop={SPACINGS.XL}>
          <Input
            name="name"
            autoComplete="ignore"
            value={name}
            error={isNameError}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameError(false)}
          />
          <Collapse show={isNameError}>
            <Block paddingTop={SPACINGS.XXS}>
              <Text variant="small" color={COLORS.RED}>
                Dies ist ein Pflichtfeld.
              </Text>
            </Block>
          </Collapse>
        </FormSection>

        <FormSectionHeadlines
          paddingTop={SPACINGS.S}
          defaultHeadlineIds={article?.headlineIds}
          onChange={setHeadlines}
        />

        <FormSection title="Abstract" paddingTop={SPACINGS.S}>
          <TextArea name="abstract" value={abstract} onChange={(e) => setAbstract(e.target.value)} />
        </FormSection>

        <FormSectionTags paddingTop={SPACINGS.S} defaultTagIds={article?.tagIds} onChange={setTagIds} />

        <Block paddingTop={SPACINGS.S}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSection title="Veröffentlichung">
                <CustomDatePicker
                  selected={publicationTime}
                  dateFormat="dd.MM.yyyy"
                  isClearable
                  onChange={setPublicationTime}
                />
              </FormSection>
            </GridItem>
            <GridItem>
              <FormSection title="Verfällt">
                <CustomDatePicker
                  selected={expirationTime}
                  dateFormat="dd.MM.yyyy"
                  isClearable
                  onChange={setExpirationTime}
                />
              </FormSection>
            </GridItem>
          </Grid>
        </Block>

        <Block paddingTop={SPACINGS.S}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSection title="Rang">
                <Input
                  type="number"
                  name="article-rank"
                  autoComplete="article-rank"
                  value={!rank && rank !== 0 ? '' : rank}
                  onChange={(e) => setRank(e.target.value !== '' ? parseInt(e.target.value, 10) : undefined)}
                />
              </FormSection>
            </GridItem>
          </Grid>
        </Block>

        <Block paddingTop={SPACINGS.S}>
          <FormSectionUsers userIds={article?.ownerIds} />
        </Block>

        <FormSection paddingTop={SPACINGS.XL}>
          <Collapse show={isNameError}>
            <Block paddingBottom={SPACINGS.XS}>
              <Text variant="small" color={COLORS.RED}>
                Es sind nicht alle Pflichtfelder ausgefüllt.
              </Text>
            </Block>
          </Collapse>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
            <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
          </Group>
        </FormSection>
      </LoadingWrapper>
    </OverlayLayout>
  );
};

export default ArticleEdit;
