import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import authReducer from './slices/authSlice';
import newspapersReducer from './slices/newspapersSlice';
import issuesReducer from './slices/issuesSlice';
import pagesReducer from './slices/pagesSlice';
import storiesReducer from './slices/storiesSlice';
import articlesReducer from './slices/articlesSlice';
import contentTypesReducer from './slices/contentTypesSlice';
import headlinesReducer from './slices/headlinesSlice';
import sectionsReducer from './slices/sectionsSlice';
import assetsReducer from './slices/assetsSlice';
import mediaAssetsReducer from './slices/mediaAssetsSlice';
import filesReducer from './slices/filesSlice';
import snippetsReducer from './slices/snippetsSlice';
import ressortsReducer from './slices/ressortsSlice';
import channelsReducer from './slices/channelsSlice';
import tagsReducer from './slices/tagsSlice';
import usersReducer from './slices/usersSlice';
import authorsReducer from './slices/authorsSlice';
import promptsReducer from './slices/promptsSlice';
import articleAttachmentsReducer from './slices/articleAttachmentSlice';

const entitiesReducer = combineReducers({
  newspapers: newspapersReducer,
  issues: issuesReducer,
  pages: pagesReducer,
  stories: storiesReducer,
  articles: articlesReducer,
  contentTypes: contentTypesReducer,
  headlines: headlinesReducer,
  sections: sectionsReducer,
  assets: assetsReducer,
  mediaAssets: mediaAssetsReducer,
  files: filesReducer,
  snippets: snippetsReducer,
  ressorts: ressortsReducer,
  channels: channelsReducer,
  tags: tagsReducer,
  users: usersReducer,
  authors: authorsReducer,
  prompts: promptsReducer,
  articleAttachments: articleAttachmentsReducer,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    entities: entitiesReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      const loggerMiddleware = createLogger({
        collapsed: true,
      });
      return getDefaultMiddleware().concat(loggerMiddleware);
    }
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type EntitiesState = ReturnType<typeof entitiesReducer>;
