import React from 'react';
import { Block } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import Text from '../shared/atoms/Text';

import { useCurrentStory } from './useCurrentStory';

const StoryCreateArticle: React.FC = () => {
  const { story } = useCurrentStory();

  return (
    <OverlayLayout typeLabelPrimary="Story" title="Beitrag hinzufügen">
      {story && (
        <>
          <Block minHeight="40px" alignItems="center">
            <Text>TODO</Text>
          </Block>
        </>
      )}
    </OverlayLayout>
  );
};

export default StoryCreateArticle;
