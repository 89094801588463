import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import Text from './Text';

type StatusLabelVariant = 'small' | undefined;

interface StyledStatusLabelProps {
  variant?: StatusLabelVariant;
  background?: string;
}

const StyledStatusLabel = styled.div<StyledStatusLabelProps>(({ variant, background }) => {
  return {
    display: 'block',
    boxSizing: 'border-box',
    minWidth: variant === 'small' ? undefined : '168px',
    padding: variant === 'small' ? '1px 8px 1px 8px' : '5px 16px 3px 16px',
    background: background || COLORS.DARK,
  };
});

interface StatusLabelProps {
  variant?: StatusLabelVariant;
  background?: string;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ variant, background, children }) => {
  if (!children) {
    return null;
  }

  return (
    <StyledStatusLabel variant={variant} background={background}>
      <Text variant={variant === 'small' ? 'label-tiny' : 'label-small'} color={COLORS.WHITE} textAlign="center">
        {children}
      </Text>
    </StyledStatusLabel>
  );
};

export default StatusLabel;
