import React, { useEffect } from 'react';
import { Block } from '@webfox-sc/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchArticle, selectArticlesState } from '../../app/slices/articlesSlice';
import TypeLabel from '../shared/atoms/TypeLabel';
import { useArticle } from './useArticle';
import { SPACINGS } from '../../styles/theme';
import ArticleMeta from './ArticleMeta';
import ArticleSections from './ArticleSections';
import { useCurrentArticle } from './useCurrentArticle';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import useAPIError from '../apiError/useAPIError';
import ArticleAttachments from './ArticleAttachments';

const Article: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { fetchArticleStatus } = useAppSelector(selectArticlesState);
  const { articleId } = useCurrentArticle();
  const { channel } = useArticle(articleId);

  useEffect(() => {
    (async () => {
      if (articleId) {
        const response = await dispatch(fetchArticle(articleId));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [dispatch, articleId, addError]);

  return (
    <LoadingWrapper
      requestStatus={fetchArticleStatus}
      errorCode="404"
      errorText={'Der Beitrag konnte nicht geladen werden.'}
    >
      <TypeLabel primaryLabel="Beitrag" secondaryLabel={channel && channel.name} />

      <Block paddingTop={SPACINGS.L}>
        <ArticleMeta articleId={articleId} />
      </Block>

      {articleId && (
        <Block paddingTop={SPACINGS.L}>
          <ArticleAttachments articleId={articleId}></ArticleAttachments>
        </Block>
      )}

      <Block paddingTop={SPACINGS.XL} paddingBottom={SPACINGS.XXXL}>
        <ArticleSections articleId={articleId} />
      </Block>
    </LoadingWrapper>
  );
};

export default Article;
