/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import qs from 'qs';
import { requestEntities, RequestEntitiesAction, RequestEntitiesThunkArg } from '../requestEntities';
import { articleSchema } from '../schemas';
import { RootState } from '../store';
import _map from 'lodash/map';

export const fetchArticlesByPublicationTime = (
  publicationTimeStart: string,
  publicationTimeEnd: string
): RequestEntitiesAction<ArticleData[]> =>
  requestEntities({
    method: 'GET',
    path: `/articles?${qs.stringify({
      filters: {
        $and: [
          {
            publication_time: { $lt: publicationTimeEnd },
          },
          {
            publication_time: { $gte: publicationTimeStart },
          },
        ],
      },
    })}`,
    schema: [articleSchema],
    type: 'fetchArticlesByPublicationTime',
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchArticles = (params?: any): RequestEntitiesAction<ArticleData[]> => {
  const requestData: RequestEntitiesThunkArg = {
    method: 'GET',
    path: '/articles',
    schema: [articleSchema],
    withCount: true,
  };

  if (params) {
    const paramStrings = _map(params, (args, key) => qs.stringify({ [key]: args }));
    requestData.path = `${requestData.path}?${paramStrings.join('&')}`;
  }

  return requestEntities(requestData);
};

export const fetchArticle = (articleId: number): RequestEntitiesAction<ArticleData> =>
  requestEntities({
    method: 'GET',
    path: `/articles/${articleId}`,
    schema: articleSchema,
    type: 'fetchArticle',
  });

export const updateArticle = (articleId: number, data: ArticleUpdateData): RequestEntitiesAction<ArticleData> =>
  requestEntities({
    method: 'PUT',
    path: `/articles/${articleId}`,
    data,
    schema: articleSchema,
  });

export const cloneSnippet = (
  articleId: number,
  snippetId: number,
  data: SnippetUpdateData
): RequestEntitiesAction<ArticleData> =>
  requestEntities({
    method: 'POST',
    path: `/articles/${articleId}/clone-snippet/${snippetId}`,
    data,
    schema: articleSchema,
  });

export interface ArticlesState extends EntityState<ArticleEntity> {
  fetchArticleStatus: RequestStatus;
}

const articlesAdapter = createEntityAdapter<ArticleEntity>();

const initialState: ArticlesState = articlesAdapter.getInitialState({
  fetchArticleStatus: 'idle',
});

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchArticle') {
          state.fetchArticleStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchArticle') {
          state.fetchArticleStatus = 'idle';
        }
        if (action.payload.entities?.articles) {
          articlesAdapter.upsertMany(state, action.payload.entities.articles);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchArticle') {
          state.fetchArticleStatus = 'failed';
        }
      });
  },
});

export const selectArticlesState = (state: RootState): ArticlesState => state.entities.articles;
export default articlesSlice.reducer;
