/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Group } from '@webfox-sc/core';
import { useAppDispatch } from '../../app/hooks';
import { updateMediaAsset } from '../../app/slices/mediaAssetsSlice';
import { SPACINGS } from '../../styles/theme';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Headline from '../shared/atoms/Headline';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Input from '../shared/atoms/Input';
import FormSectionChannels from '../channels/FormSectionChannels';
import { fetchAsset } from '../../app/slices/assetsSlice';
import FormSectionMediaAssetFile from './FormSectionMediaAssetFile';
import { useMediaAsset } from './useMediaAsset';
import { useAsset } from './useAsset';
import useAPIError from '../apiError/useAPIError';
import FormSectionMediaAssetType from './FormSectionMediaAssetType';
import FormHtmlEditor from '../htmlEditor/FormHtmlEditor';

interface AssetEditMediaEditProps {
  assetId: number;
  mediaAssetId: number | undefined;
  onBeforeEdit?: () => void;
  onMediaAssetUpdated?: () => void;
  onClickCancel?: React.MouseEventHandler;
}

const AssetEditMediaEdit: React.FC<AssetEditMediaEditProps> = ({
  assetId,
  mediaAssetId,
  onBeforeEdit,
  onMediaAssetUpdated,
  onClickCancel,
}) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { asset } = useAsset(assetId);
  const { mediaAsset, firstFile } = useMediaAsset(mediaAssetId);

  const [mediaAssetType, setMediaAssetType] = useState<MediaAssetType | undefined>(firstFile ? 'file' : 'text');
  const [file, setFile] = useState<File | undefined | null>(undefined);
  const [alternativeText, setAlternativeText] = useState(firstFile?.alternativeText || '');
  const [channelIds, setChannelIds] = useState<number[]>(mediaAsset?.channelIds || []);
  const [content, setContent] = useState<string>(mediaAsset?.content || '');

  const [hasChannelsError, setHasChannelsError] = useState(false);

  useEffect(() => {
    if (channelIds.length > 0) {
      setHasChannelsError(false);
    }
  }, [channelIds.length]);

  const handleOnClickUpdate = async (): Promise<boolean> => {
    if (!asset || !mediaAsset) {
      return false;
    }

    if (!channelIds.length) {
      setHasChannelsError(true);
      return false;
    }

    if (onBeforeEdit) {
      onBeforeEdit();
    }
    const isFile = mediaAssetType === 'file';

    /**
     * create mediaAssetRequestData
     */
    const mediaAssetRequestData = new FormData();
    const data: { [k: string]: any } = {
      channels: channelIds,
      fileInfo: isFile
        ? {
            alternativeText,
          }
        : null,
      content: isFile ? null : content,
    };
    if (isFile && file) {
      mediaAssetRequestData.append('files.files', file, file.name);
    }
    // TODO delete files if type changes?
    mediaAssetRequestData.append('data', JSON.stringify(data));

    /**
     * update media asset
     */
    if (mediaAssetId) {
      const response = await dispatch(updateMediaAsset(assetId, mediaAssetId, mediaAssetRequestData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }

    /**
     * re-fetch asset
     * TODO Dev temp
     */
    const response = await dispatch(fetchAsset(assetId));
    if (response.payload?.error) {
      addError(response.payload.error.message);
    }

    if (typeof onMediaAssetUpdated === 'function') {
      onMediaAssetUpdated();
    }
    return true;
  };

  return (
    <>
      <Headline>Medium bearbeiten</Headline>

      <FormSectionMediaAssetType paddingTop={SPACINGS.S} mediaAssetType={mediaAssetType} onChange={setMediaAssetType} />

      {mediaAssetType === 'file' ? (
        <>
          <FormSectionMediaAssetFile paddingTop={SPACINGS.S} onChangeFile={setFile} defaultFile={firstFile} />

          <FormSection title="Alternativer Text" paddingTop={SPACINGS.S}>
            <Input
              name="alternativeText"
              autoComplete="ignore"
              value={alternativeText}
              onChange={(e) => setAlternativeText(e.target.value)}
            />
          </FormSection>
        </>
      ) : (
        <FormSection title="Inhalt" paddingTop={SPACINGS.S}>
          <FormHtmlEditor htmlContent={content} onBlur={setContent} />
        </FormSection>
      )}

      <FormSectionChannels
        paddingTop={SPACINGS.S}
        defaultChannelIds={channelIds}
        onChange={setChannelIds}
        hasError={hasChannelsError}
      />

      <FormSection paddingTop={SPACINGS.XL}>
        <Group spacing={SPACINGS.XXS}>
          <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
          <ButtonSecondary label="Abbrechen" onClick={onClickCancel} />
        </Group>
      </FormSection>
    </>
  );
};

export default AssetEditMediaEdit;
