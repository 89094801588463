import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Collapse } from '@webfox-sc/core';
import { IconChevronRight } from '@webfox-sc/icons';
import { COLORS, SPACINGS } from '../../styles/theme';
import OverlayLayout from '../overlay/OverlayLayout';
import Headline from '../shared/atoms/Headline';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useCurrentArticle } from '../article/useCurrentArticle';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import { useArticleSections } from '../article/useArticleSections';
import { createAsset } from '../../app/slices/assetsSlice';
import FormSectionConnectAsset from './FormSectionConnectAsset';
import { useOverlay } from '../overlay/useOverlay';
import useAPIError from '../apiError/useAPIError';

const AssetAdd: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();

  const { article } = useCurrentArticle();
  const { getSectionsMapAddedByAsset } = useArticleSections(article?.id);

  const [name, setName] = useState(article?.name || '');
  const [isNameError, setIsNameError] = useState(false);

  const [assetConnectDisabled, setAssetConnectDisabled] = useState(false);

  const updateArticleSections = async (assetId: number | undefined) => {
    if (article && assetId) {
      const response = await dispatch(
        updateArticle(article.id, {
          Section: getSectionsMapAddedByAsset(assetId),
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  const handleOnClickCreate = async () => {
    if (!name) {
      setIsNameError(true);
    } else if (article) {
      /**
       * create asset
       */
      const response = await dispatch(
        createAsset({
          name,
        })
      );

      /**
       * update article sections
       */
      const assetId = response.payload?.data?.id;
      await updateArticleSections(assetId);

      navigate(`/article/${article.id}/asset/${assetId}/create`, { replace: true });
    }
  };

  const handleOnSelectAsset = async (assetId: number) => {
    if (article) {
      setAssetConnectDisabled(true);

      /**
       * update article sections
       */
      await updateArticleSections(assetId);

      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Anhang" title="Hinzufügen">
      <Headline>Anhang erstellen</Headline>

      <FormSection title="Titel *" paddingTop={SPACINGS.M}>
        <Input
          name="name"
          autoComplete="ignore"
          value={name}
          error={isNameError}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setIsNameError(false)}
        />
        <Collapse show={isNameError}>
          <Block paddingTop={SPACINGS.XS}>
            <Text variant="small" color={COLORS.RED}>
              Dies ist ein Pflichtfeld.
            </Text>
          </Block>
        </Collapse>
      </FormSection>

      <Block paddingTop={SPACINGS.S}>
        <ButtonPrimary iconRight={IconChevronRight} label="Weiter" onClick={handleOnClickCreate} />
      </Block>

      <FormSectionConnectAsset
        articleId={article?.id}
        paddingTop={SPACINGS.XXL}
        onSelect={handleOnSelectAsset}
        disabled={assetConnectDisabled}
      />
    </OverlayLayout>
  );
};

export default AssetAdd;
