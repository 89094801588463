import React from 'react';
import { useArticleSection } from './useArticleSection';
import ArticleSectionAsset from './ArticleSectionAsset';
import ArticleSectionSnippet from './ArticleSectionSnippet';

interface ArticleSectionProps {
  sectionId: number;
  isFirst?: boolean;
  isLast?: boolean;
  onClickUp?: (sectionEntityId: number | undefined) => void;
  onClickDown?: (sectionEntityId: number | undefined) => void;
}

const ArticleSection: React.FC<ArticleSectionProps> = ({ sectionId, isFirst, isLast, onClickUp, onClickDown }) => {
  const { section } = useArticleSection(sectionId);

  if (section?.assetId) {
    return (
      <ArticleSectionAsset
        sectionId={section.id}
        assetId={section?.assetId}
        articleId={section.articleId}
        isFirst={isFirst}
        isLast={isLast}
        onClickUp={onClickUp}
        onClickDown={onClickDown}
      />
    );
  }

  if (section?.snippetId) {
    return (
      <ArticleSectionSnippet
        sectionId={section.id}
        snippetId={section?.snippetId}
        articleId={section.articleId}
        isFirst={isFirst}
        isLast={isLast}
        onClickUp={onClickUp}
        onClickDown={onClickDown}
      />
    );
  }

  return null;
};

export default ArticleSection;
