import React from 'react';
import { Block, Image } from '@webfox-sc/core';
import { COLORS } from '../../styles/theme';
import { useMediaAsset } from '../asset/useMediaAsset';
import RatioBox from '../shared/atoms/RatioBox';
import Headline from '../shared/atoms/Headline';
import Text from '../shared/atoms/Text';

interface ArticleViewMediaAssetProps {
  mediaAssetId: number | undefined;
}

const ArticleViewMediaAsset: React.FC<ArticleViewMediaAssetProps> = ({ mediaAssetId }) => {
  const { mediaAsset, firstFile, firstFileTeaserImageSrc, firstFileIsWebImage, firstFileMimeSecondary } =
    useMediaAsset(mediaAssetId);

  return (
    <>
      {firstFileIsWebImage ? (
        <Image
          src={firstFileTeaserImageSrc}
          alt={firstFile?.alternativeText || 'Bild'}
          ratio="1:1"
          objectFit="contain"
          background={COLORS.DARK}
        />
      ) : (
        <>
          {mediaAsset?.content ? (
            <Text asHTML>{mediaAsset.content}</Text>
          ) : (
            <RatioBox ratio="1:1" background={COLORS.DARK}>
              <Block width="100%" height="100%" justify="center" alignItems="center">
                <Headline color={COLORS.GREY}>{firstFileMimeSecondary.toUpperCase()}</Headline>
              </Block>
            </RatioBox>
          )}
        </>
      )}
    </>
  );
};

export default ArticleViewMediaAsset;
