import React, { useEffect, useState } from 'react';
import { Block, Collapse, Grid, GridItem, Group } from '@webfox-sc/core';
import OverlayLayout from '../overlay/OverlayLayout';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import Input from '../shared/atoms/Input';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import { useOverlay } from '../overlay/useOverlay';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import FormSection from '../shared/forms/FormSection';
import { useStatus } from '../status/useStatus';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import { refetchIssue, selectIssuesState } from '../../app/slices/issuesSlice';
import { useCurrentIssuePage } from './useCurrentIssuePage';
import { useIssuePageNumbers } from './useIssuePageNumbers';
import { useIssuePage } from './useIssuePage';
import { updatePage } from '../../app/slices/pagesSlice';
import FormSectionRessorts from '../ressorts/FormSectionRessorts';
import useAPIError from '../apiError/useAPIError';

const linesMaxLimit = 100000;

const IssuePageEdit: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();
  const { fetchIssueStatus } = useAppSelector(selectIssuesState);

  const { page } = useCurrentIssuePage();
  const { pageCount, pageNumberStart, pageNumberEnd } = useIssuePage(page?.id);
  const { maxPageNumber, getUnavailablePageNumbersForUpdate } = useIssuePageNumbers(page?.issueId);
  const { updatedTextLine } = useStatus({
    updatedTime: page?.updatedTime,
    updatedByUserId: page?.updatedByUserId,
  });

  const [numberStart, setNumberStart] = useState<number | undefined>(pageNumberStart);
  const [numberStartErrorText, setNumberStartErrorText] = useState<string | undefined>(undefined);
  const [numberEnd, setNumberEnd] = useState<number | undefined>(pageNumberEnd);
  const [numberEndErrorText, setNumberEndErrorText] = useState<string | undefined>(undefined);
  const [linesMax, setLinesMax] = useState<number | undefined>(page?.linesMax);
  const [linesMaxErrorText, setLinesMaxErrorText] = useState<string | undefined>(undefined);
  const [ressortIds, setRessortIds] = useState<number[]>(page?.ressortIds || []);

  useEffect(() => {
    setNumberStart(pageNumberStart);
    setNumberEnd(pageNumberEnd);
    setLinesMax(page?.linesMax);
  }, [page, pageNumberStart, pageNumberEnd]);

  useEffect(() => {
    if ((numberStart || 0) < 0) {
      setNumberStart(0);
    }
    if ((numberEnd || 0) < 0) {
      setNumberEnd(0);
    }
  }, [numberStart, numberEnd]);

  const handleOnClickUpdate = async () => {
    if (!page) {
      return false;
    }

    if ((linesMax || 0) > linesMaxLimit) {
      setLinesMaxErrorText('Die Zeilenanzahl ist auf 100.000 begrenzt.');
      return false;
    }

    const count = (numberEnd || 0) - (numberStart || 0) + 1;
    let numberStartErrorTextDraft: string | undefined = undefined;
    let numberEndErrorTextDraft: string | undefined = undefined;

    if (!numberStart) {
      numberStartErrorTextDraft = 'Dies ist ein Pflichtfeld.';
    } else if (numberStart > maxPageNumber) {
      numberStartErrorTextDraft = `Die Seitennummer darf maximal ${maxPageNumber} sein.`;
    }

    if (!numberEnd) {
      numberEndErrorTextDraft = 'Dies ist ein Pflichtfeld.';
    } else if (numberEnd > maxPageNumber) {
      numberEndErrorTextDraft = `Die Seitennummer darf maximal ${maxPageNumber} sein.`;
    } else if (numberEnd < (numberStart || 0)) {
      numberEndErrorTextDraft = `Die Seitennummer darf nicht kleiner als ${numberStart || 0} sein.`;
    }

    if (!!numberStart && !!numberEnd) {
      const unavailablePageNumbers = getUnavailablePageNumbersForUpdate(numberStart, count, pageNumberStart, pageCount);
      if (unavailablePageNumbers.length === 1) {
        numberStartErrorTextDraft = `Die Seitennummer ${unavailablePageNumbers[0]} ist bereits vergeben`;
      } else if (unavailablePageNumbers.length > 1) {
        numberStartErrorTextDraft = `Einige Seitennummern zwischen ${numberStart} und ${numberEnd} sind bereits vergeben`;
      }
    }

    if (!!numberStartErrorTextDraft || !!numberEndErrorTextDraft) {
      setNumberStartErrorText(numberStartErrorTextDraft);
      setNumberEndErrorText(numberEndErrorTextDraft);
      return false;
    }

    const updatePageResponse = await dispatch(
      updatePage(page.id, {
        number: numberStart,
        count,
        lines_max: linesMax && Math.abs(linesMax),
        ressorts: ressortIds,
      })
    );
    if (updatePageResponse.payload?.error) {
      addError(updatePageResponse.payload.error.message);
    }

    /**
     * refetch issue
     */
    if (page.issueId) {
      const refetchIssueResponse = await dispatch(refetchIssue(page.issueId));
      if (refetchIssueResponse.payload?.error) {
        addError(refetchIssueResponse.payload.error.message);
      }
    }

    closeOverlay();
    return true;
  };

  return (
    <OverlayLayout typeLabelPrimary={page?.count === 1 ? 'Seite' : 'Seitenfolge'} title="Bearbeiten">
      <LoadingWrapper
        requestStatus={fetchIssueStatus}
        errorCode="404"
        errorText={'Die Ausgabe konnte nicht geladen werden.'}
      >
        <Block minHeight="40px" alignItems="center">
          <Text variant="small">{updatedTextLine}</Text>
        </Block>

        <Block paddingTop={SPACINGS.XL}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSection title="Ab Seitennummer *">
                <Input
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="multi-number-start"
                  autoComplete="page-multi-number-start"
                  value={numberStart || ''}
                  error={!!numberStartErrorText}
                  onChange={(e) => {
                    let numberStartNew: number | undefined = parseInt(e.target.value, 10);
                    if (!numberStartNew && numberStartNew !== 0) {
                      numberStartNew = undefined;
                    }
                    setNumberStart(numberStartNew);
                  }}
                  onFocus={() => {
                    setNumberStartErrorText(undefined);
                    setNumberEndErrorText(undefined);
                  }}
                />
                <Collapse show={!!numberStartErrorText}>
                  <Block paddingTop={SPACINGS.XS}>
                    <Text variant="small" color={COLORS.RED}>
                      {numberStartErrorText}
                    </Text>
                  </Block>
                </Collapse>
              </FormSection>
            </GridItem>
            <GridItem>
              <FormSection title="Bis Seitennummer *">
                <Input
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="multi-number-end"
                  autoComplete="page-multi-number-end"
                  value={numberEnd || ''}
                  error={!!numberEndErrorText}
                  onChange={(e) => {
                    let numberEndNew: number | undefined = parseInt(e.target.value, 10);
                    if (!numberEndNew && numberEndNew !== 0) {
                      numberEndNew = undefined;
                    }
                    setNumberEnd(numberEndNew);
                  }}
                  onFocus={() => {
                    setNumberStartErrorText(undefined);
                    setNumberEndErrorText(undefined);
                  }}
                />
                <Collapse show={!!numberEndErrorText}>
                  <Block paddingTop={SPACINGS.XS}>
                    <Text variant="small" color={COLORS.RED}>
                      {numberEndErrorText}
                    </Text>
                  </Block>
                </Collapse>
              </FormSection>
            </GridItem>
          </Grid>
        </Block>

        <Block paddingTop={SPACINGS.S}>
          <Grid columns={4} spacing={SPACINGS.S}>
            <GridItem>
              <FormSection title="Verfügbare Zeilen">
                <Input
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="lines-max"
                  autoComplete="page-lines-max"
                  value={linesMax || ''}
                  onChange={(e) => setLinesMax(parseInt(e.target.value, 10))}
                  onFocus={() => {
                    setLinesMaxErrorText(undefined);
                  }}
                />
              </FormSection>
              <Collapse show={!!linesMaxErrorText}>
                <Block paddingTop={SPACINGS.XS}>
                  <Text variant="small" color={COLORS.RED}>
                    {linesMaxErrorText}
                  </Text>
                </Block>
              </Collapse>
            </GridItem>
          </Grid>
        </Block>

        <FormSectionRessorts paddingTop={SPACINGS.S} defaultRessortIds={page?.ressortIds} onChange={setRessortIds} />

        <FormSection paddingTop={SPACINGS.XL}>
          <Group spacing={SPACINGS.XXS}>
            <ButtonPrimary label="Aktualisieren" onClick={handleOnClickUpdate} />
            <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
          </Group>
        </FormSection>
      </LoadingWrapper>
    </OverlayLayout>
  );
};

export default IssuePageEdit;
