import React from 'react';
import { COLORS } from '../../styles/theme';
import Inline from '../shared/atoms/Inline';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import { useUsers } from './useUsers';

interface FormSectionUsersProps {
  userIds?: number[];
}

const FormSectionUsers: React.FC<FormSectionUsersProps> = ({ userIds }) => {
  const { usersSortedFormatted } = useUsers(userIds);

  return (
    <FormSection title="Besitzer:innen">
      <Text>
        {usersSortedFormatted ? (
          usersSortedFormatted
        ) : (
          <Inline color={COLORS.GREY}>
            <em>Keine Besitzer:innen</em>
          </Inline>
        )}
      </Text>
    </FormSection>
  );
};

export default FormSectionUsers;
