import { statusItems, StatusOption } from './statusUtils';

export const useStatusSelect = (
  statusSelection: EntityStatus[]
): {
  statusOptions: StatusOption[];
} => {
  const statusOptions: StatusOption[] = [];
  statusSelection.forEach((status) => {
    if (status) {
      const statusConfigItem = statusItems[status];
      statusOptions.push({
        value: status,
        label: statusConfigItem.label,
      });
    }
  });

  return {
    statusOptions,
  };
};
