import React from 'react';
import styled from 'styled-components';

const StyledRatioBox = styled.div({
  position: 'relative',
});

const StyledRatioInnerBox = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

interface RatioBoxProps {
  ratio?: string;
  width?: string | 0;
  height?: string | 0;
  background?: string;
}

const RatioBox: React.FC<RatioBoxProps> = ({ ratio, width, height, background, children }) => {
  const validRatio = ratio || '1:1';
  const [w, h] = validRatio.split(':');
  const ratioWidth = parseFloat(w) || 1;
  const ratioHeight = parseFloat(h) || 1;
  const ratioFloat = ratioWidth / ratioHeight;

  const inlineStyle: React.CSSProperties = {
    width,
    height,
    paddingTop: ratio && !height ? `${(100 / ratioWidth) * ratioHeight}%` : undefined,
    background: background,
  };

  if (width && !height && ratio) {
    inlineStyle.paddingTop = `calc(${width} / ${ratioFloat})`;
  }

  if (!width && height && ratio) {
    inlineStyle.width = `calc(${height} * ${ratioFloat})`;
  }

  return (
    <StyledRatioBox style={inlineStyle}>
      <StyledRatioInnerBox>{children}</StyledRatioInnerBox>
    </StyledRatioBox>
  );
};

export default RatioBox;
