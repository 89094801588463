import React, { useEffect, useMemo, useState } from 'react';
import { Block, Collapse } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import CustomAsyncSelect from '../shared/select/CustomAsyncSelect';
import { useSelectedChannels } from './useSelectedChannels';
import { COLORS, SPACINGS } from '../../styles/theme';

type ChannelOption = {
  value: number;
  label?: string;
};

interface FormSectionChannelsProps {
  paddingTop?: string;
  defaultChannelIds?: number[];
  hasError?: boolean;
  onChange?: (channelIds: number[]) => void;
}

const FormSectionChannels: React.FC<FormSectionChannelsProps> = ({
  paddingTop,
  defaultChannelIds,
  hasError,
  onChange,
}) => {
  const { channelsSorted } = useSelectedChannels(defaultChannelIds);

  const defaultChannelOptionsSorted: ChannelOption[] = useMemo(() => {
    return channelsSorted.map((channel) => ({
      value: channel.id,
      label: `${channel.name}`,
    }));
  }, [channelsSorted]);

  const [channelOptions, setChannelOptions] = useState<ChannelOption[]>(defaultChannelOptionsSorted);

  useEffect(() => {
    setChannelOptions(defaultChannelOptionsSorted);
  }, [defaultChannelOptionsSorted]);

  const handleChangeChannelOptions = (channelOptionsNew: ChannelOption[]) => {
    setChannelOptions(channelOptionsNew);
    if (typeof onChange === 'function') {
      onChange(channelOptionsNew.map((channelOption) => channelOption.value));
    }
  };

  return (
    <FormSection title="Channels" paddingTop={paddingTop}>
      <CustomAsyncSelect
        asyncPath="/channels"
        asyncSort={['name:asc']}
        setAsyncParams={(inputValue, params) => {
          params.filters = {
            name: {
              $containsi: inputValue,
            },
          };
        }}
        value={channelOptions}
        placeholder="Suchen ..."
        formatOption={(entry) => ({
          value: entry.id,
          label: entry.name,
        })}
        isMulti
        isClearable={false}
        isDropdownIndicator={false}
        onChange={handleChangeChannelOptions}
      />
      <Collapse show={hasError}>
        <Block paddingTop={SPACINGS.XXS}>
          <Text variant="small" color={COLORS.RED}>
            Dies ist ein Pflichtfeld.
          </Text>
        </Block>
      </Collapse>
    </FormSection>
  );
};

export default FormSectionChannels;
