import React from 'react';
import { Group, GroupItem } from '@webfox-sc/core';
import { IconDelete, IconPencil, IconChevronUp, IconChevronDown } from '@webfox-sc/icons';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useMediaAsset } from './useMediaAsset';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import StatusPanel from '../status/StatusPanel';
import MediaAssetPanel from './MediaAssetPanel';
import { useSelectedChannels } from '../channels/useSelectedChannels';

interface FormSectionMediaAssetProps {
  mediaAssetId: number | undefined;
  isFirst?: boolean;
  isLast?: boolean;
  onClickEdit?: (mediaAssetId: number | undefined) => void;
  onClickUp?: (mediaAssetId: number | undefined) => void;
  onClickDown?: (mediaAssetId: number | undefined) => void;
  onClickDelete?: (mediaAssetId: number | undefined) => void;
}

const FormSectionMediaAsset: React.FC<FormSectionMediaAssetProps> = ({
  mediaAssetId,
  isFirst,
  isLast,
  onClickEdit,
  onClickUp,
  onClickDown,
  onClickDelete,
}) => {
  const { mediaAsset, firstFile, firstFileSrc, firstFileTeaserImageSrc } = useMediaAsset(mediaAssetId);
  const { channelsSortedFormatted } = useSelectedChannels(mediaAsset?.channelIds);

  return (
    <Group nowrap spacing={SPACINGS.XXS}>
      <GroupItem grow>
        <MediaAssetPanel
          variant={!!firstFile ? 'file' : 'content'}
          showTypeLabel
          fileImageSrc={firstFileTeaserImageSrc}
          fileImageAlt={firstFile?.alternativeText || '–'}
          fileName={firstFile?.name}
          fileMime={firstFile?.mime}
          fileWidth={firstFile?.width}
          fileHeight={firstFile?.height}
          fileSizeKB={firstFile?.size}
          fileUrl={firstFileSrc}
          content={mediaAsset?.content}
        />
      </GroupItem>
      <GroupItem>
        <StatusPanel
          padding={SPACINGS.S}
          background={COLORS.WHITE}
          verticalStretch
          createdTime={mediaAsset?.createdTime}
          createdByUserId={mediaAsset?.createdByUserId}
          updatedTime={mediaAsset?.updatedTime}
          updatedByUserId={mediaAsset?.updatedByUserId}
          channelsFormatted={channelsSortedFormatted}
        />
      </GroupItem>
      <GroupItem>
        <Group vertical spacing={SPACINGS.XXS}>
          <ButtonPrimary
            ariaLabel="Bearbeiten"
            icon={IconPencil}
            onClick={() => {
              if (typeof onClickEdit === 'function') {
                onClickEdit(mediaAssetId);
              }
            }}
          />
          <ButtonPrimary
            ariaLabel="Nach oben"
            icon={IconChevronUp}
            disabled={isFirst}
            onClick={() => {
              if (!isFirst && typeof onClickUp === 'function') {
                onClickUp(mediaAssetId);
              }
            }}
          />
          <ButtonPrimary
            ariaLabel="Nach unten"
            icon={IconChevronDown}
            disabled={isLast}
            onClick={() => {
              if (!isLast && typeof onClickDown === 'function') {
                onClickDown(mediaAssetId);
              }
            }}
          />
          <ButtonPrimary
            ariaLabel="Entfernen"
            icon={IconDelete}
            onClick={() => {
              if (typeof onClickDelete === 'function') {
                onClickDelete(mediaAssetId);
              }
            }}
          />
        </Group>
      </GroupItem>
    </Group>
  );
};

export default FormSectionMediaAsset;
