import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { promptSchema } from '../schemas';

export const fetchPrompts = (): RequestEntitiesAction<PromptData[]> =>
  requestEntities({
    method: 'GET',
    path: '/prompts',
    schema: [promptSchema],
  });

const promptsAdapter = createEntityAdapter<PromptEntity>();

export const promptsSlice = createSlice({
  name: 'prompts',
  initialState: promptsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.prompts) {
        promptsAdapter.upsertMany(state, action.payload.entities.prompts);
      }
    });
  },
});

export default promptsSlice.reducer;
