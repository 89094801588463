import React from 'react';
import { Block, Group, GroupItem, Image } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import RatioBox from '../shared/atoms/RatioBox';
import Headline from '../shared/atoms/Headline';
import Text from '../shared/atoms/Text';
import MetaTable from '../shared/molecules/MetaTable';
import MetaTableItem from '../shared/molecules/MetaTableItem';
import { formatFileSizeKB } from '../../utils/format';
import Panel from '../shared/atoms/Panel';
import TypeLabel from '../shared/atoms/TypeLabel';

const mimeSecondariesWebImage = ['jpeg', 'jpg', 'png', 'gif', 'webp'];

interface MediaAssetPanelProps {
  variant: 'file' | 'content';
  showTypeLabel?: boolean;
  fileImageSrc?: string;
  fileImageAlt?: string;
  fileName?: string;
  fileMime?: string;
  fileWidth?: number;
  fileHeight?: number;
  fileSizeKB?: number;
  fileUrl?: string;
  content?: string;
}

const MediaAssetPanel: React.FC<MediaAssetPanelProps> = ({
  variant,
  showTypeLabel,
  fileImageSrc,
  fileImageAlt,
  fileName,
  fileMime,
  fileWidth,
  fileHeight,
  fileSizeKB,
  fileUrl,
  content,
}) => {
  const fileMimePrimary = fileMime ? fileMime.split('/')[0] : undefined;
  const fileMimeSecondary = fileMime ? fileMime.split('/')[1] : '';
  const showImage = fileImageSrc && fileMimePrimary === 'image' && mimeSecondariesWebImage.includes(fileMimeSecondary);

  return (
    <Panel background={COLORS.WHITE} padding={SPACINGS.S} verticalStretch>
      {showTypeLabel && (
        <Block paddingBottom={SPACINGS.S}>
          <TypeLabel primaryLabel={variant === 'file' ? 'Datei' : 'Text'} secondaryLabel={fileMimeSecondary} />
        </Block>
      )}
      {variant === 'file' && (
        <Group nowrap spacing={SPACINGS.S}>
          <GroupItem width="33%" maxWidth="224px">
            {showImage ? (
              <Image src={fileImageSrc} alt={fileImageAlt} ratio="1:1" objectFit="contain" background={COLORS.DARK} />
            ) : (
              <RatioBox ratio="1:1" background={COLORS.DARK}>
                <Block width="100%" height="100%" justify="center" alignItems="center">
                  <Headline color={COLORS.GREY}>{fileMimeSecondary && fileMimeSecondary.toUpperCase()}</Headline>
                </Block>
              </RatioBox>
            )}
          </GroupItem>
          <GroupItem grow>
            <Block>
              <MetaTable variant="compact">
                {fileName && (
                  <MetaTableItem label="Dateiname">
                    <Text wordBreak>{fileName}</Text>
                  </MetaTableItem>
                )}
                {fileImageAlt && (
                  <MetaTableItem label="Alt. Text">
                    <Text>{fileImageAlt}</Text>
                  </MetaTableItem>
                )}
                {fileWidth && fileHeight && (
                  <MetaTableItem label="Maße">
                    <Text>
                      {fileWidth} x {fileHeight} px
                    </Text>
                  </MetaTableItem>
                )}
                {fileSizeKB && (
                  <MetaTableItem label="Größe">
                    <Text>{formatFileSizeKB(fileSizeKB)}</Text>
                  </MetaTableItem>
                )}
                {fileMime && (
                  <MetaTableItem label="Typ">
                    <Text>{fileMime}</Text>
                  </MetaTableItem>
                )}
                {fileUrl && (
                  <MetaTableItem label="Link">
                    <Text>
                      <a href={fileUrl} target="_blank">
                        Datei in neuem Tab öffnen
                      </a>
                    </Text>
                  </MetaTableItem>
                )}
              </MetaTable>
            </Block>
          </GroupItem>
        </Group>
      )}
      {variant === 'content' && <Text asHTML>{content}</Text>}
    </Panel>
  );
};

export default MediaAssetPanel;
