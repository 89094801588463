/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import DatePicker, { registerLocale, ReactDatePicker } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconCalendar, IconClose } from '@webfox-sc/icons';
import { COLORS } from '../../../styles/theme';
import FormButton from './FormButton';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from 'react';
import Text from './Text';

registerLocale('de', de);

interface StyledControlProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

const StyledControl = styled.div<StyledControlProps>(({ width, error, isFocus }): CSSObject => {
  return {
    boxSizing: 'border-box',
    width: width || '100%',
    height: '64px',
    border: `2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: '3px',
    background: COLORS.WHITE,
    transition: 'border-color 0.2s ease',
    cursor: 'text',
  };
});

interface CustomDatePickerProps {
  width?: string | 0;
  error?: boolean;
  selected?: Date | null;
  dateFormat?: string | string[] | undefined;
  showTimeSelect?: boolean;
  isClearable?: boolean;
  onChange?: (date: Date | undefined | null) => void;
  onCalendarOpen?: () => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  width,
  error,
  selected,
  dateFormat,
  showTimeSelect,
  isClearable,
  onChange,
  onCalendarOpen,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [date, setDate] = useState<Date | undefined | null>(selected);

  useEffect(() => {
    setDate(selected);
  }, [selected]);

  const CustomInput = forwardRef<ReactDatePicker, any>((props, ref) => {
    const { value, onClick } = props;
    return (
      <StyledControl width={width} error={error} isFocus={isFocus}>
        <Group>
          <GroupItem grow>
            <div ref={ref as React.RefObject<HTMLDivElement>} onClick={onClick}>
              <Block height="60px" padding="0 14px" alignItems="center">
                <Text>{value}</Text>
              </Block>
            </div>
          </GroupItem>
          {(!isClearable || !date) && (
            <GroupItem>
              <Block
                height="100%"
                borderLeft={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
              >
                <FormButton ariaLabel="check" icon={IconCalendar} onClick={onClick} />
              </Block>
            </GroupItem>
          )}
          {isClearable && !!date && (
            <GroupItem>
              <Block
                height="100%"
                borderLeft={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
              >
                <FormButton
                  ariaLabel="check"
                  icon={IconClose}
                  onClick={() => {
                    setDate(null);
                    if (typeof onChange === 'function') {
                      onChange(null);
                    }
                  }}
                />
              </Block>
            </GroupItem>
          )}
        </Group>
      </StyledControl>
    );
  });

  return (
    <DatePicker
      locale="de"
      selected={date}
      dateFormat={dateFormat}
      showTimeSelect={showTimeSelect}
      onChange={(dateNew) => {
        setDate(dateNew as Date);
        if (typeof onChange === 'function') {
          onChange(dateNew as Date);
        }
      }}
      customInput={<CustomInput />}
      onCalendarOpen={() => {
        setIsFocus(true);
        if (typeof onCalendarOpen === 'function') {
          onCalendarOpen();
        }
      }}
      onCalendarClose={() => setIsFocus(false)}
    />
  );
};
export default CustomDatePicker;
