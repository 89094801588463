import { useAppSelector } from '../../app/hooks';

export const useUser = (
  userId?: number
): {
  user?: UserEntity;
  displayName?: string;
} => {
  const user = useAppSelector(({ entities }) => entities.users.entities[userId || 0]);

  const fullName = user ? `${user.firstname} ${user.lastname}` : undefined;
  const displayName = user?.firstname && user?.lastname ? fullName : user?.username;

  return {
    user,
    displayName,
  };
};
