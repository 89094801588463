import { entitySelectors } from '../../app/entitySelectors';

export const useArticle = (
  articleId: number | undefined
): {
  article: ArticleEntity | undefined;
  headlines: HeadlineEntity[];
  sections: SectionEntity[];
  channel: ChannelEntity | undefined;
  story: StoryEntity | undefined;
  ressort: RessortEntity | undefined;
  contentType: ContentTypeEntity | undefined;
  attachments: ArticleAttachmentEntity[];
} => {
  const article = entitySelectors.selectById<ArticleEntity>('articles', articleId);
  const headlines = entitySelectors.selectByIds<HeadlineEntity>('headlines', article?.headlineIds);
  const sections = entitySelectors.selectByIds<SectionEntity>('sections', article?.sectionIds);
  const channel = entitySelectors.selectById<ChannelEntity>('channels', article?.channelId);
  const story = entitySelectors.selectById<StoryEntity>('stories', article?.storyId);
  const ressort = entitySelectors.selectById<RessortEntity>('ressorts', article?.ressortId);
  const contentType = entitySelectors.selectById<ContentTypeEntity>('contentTypes', article?.contentTypeId);
  const attachments = entitySelectors.selectByIds<ArticleAttachmentEntity>(
    'articleAttachments',
    article?.attachmentIds
  );

  return {
    article,
    headlines,
    sections,
    channel,
    story,
    ressort,
    contentType,
    attachments,
  };
};
