import { COLORS } from '../../styles/theme';

type StatusItem = {
  label: string;
  color: string;
};

export const statusItems: { [key in NonNullable<EntityStatus>]: StatusItem } = {
  created: {
    label: 'Erstellt',
    color: COLORS.MID_GREY,
  },
  editing: {
    label: 'In Bearbeitung',
    color: COLORS.YELLOW,
  },
  stuck: {
    label: 'Blockiert',
    color: COLORS.RED,
  },
  stopped: {
    label: 'Gestoppt',
    color: COLORS.RED,
  },
  done: {
    label: 'Fertig',
    color: COLORS.GREEN,
  },
  published: {
    label: 'Veröffentlicht',
    color: COLORS.VIOLET,
  },
};

export type StatusOption = {
  value: EntityStatus;
  label: string;
};

export const getStatusOption = (status: EntityStatus): StatusOption | null =>
  status && statusItems[status]
    ? {
        value: status,
        label: statusItems[status].label,
      }
    : null;
