import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export const useCurrentIssue = (): {
  issueId?: number;
  issue?: IssueEntity;
} => {
  const { sIssueId } = useParams();
  const issueId = parseInt(sIssueId || '', 10);
  const issue = useAppSelector(({ entities }) => entities.issues.entities[issueId]);

  return {
    issueId,
    issue,
  };
};
