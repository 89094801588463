import React from 'react';
import { Block, Group } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import { useNewspaperIssues } from './useNewspaperIssues';
import NewspaperIssue from './NewspaperIssue';
import { SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { deleteIssue } from '../../app/slices/issuesSlice';
import { refetchNewspaper } from '../../app/slices/newspapersSlice';
import Panel from '../shared/atoms/Panel';
import Text from '../shared/atoms/Text';
import useAPIError from '../apiError/useAPIError';

interface NewspaperIssuesProps {
  newspaperId?: number;
  viewMode: NewspaperViewMode;
}

const NewspaperIssues: React.FC<NewspaperIssuesProps> = ({ newspaperId, viewMode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { issuesSorted } = useNewspaperIssues(newspaperId, viewMode);

  const handleOnClickDeleteIssue = async (issueId: number | undefined) => {
    if (!!newspaperId && !!issueId) {
      /**
       * delete issue
       */
      const deleteIssueResponse = await dispatch(deleteIssue(issueId));
      if (deleteIssueResponse.payload?.error) {
        addError(deleteIssueResponse.payload.error.message);
      }

      /**
       * refetch newspaper
       */
      const refetchNewspaperResponse = await dispatch(refetchNewspaper(newspaperId));
      if (refetchNewspaperResponse.payload?.error) {
        addError(refetchNewspaperResponse.payload.error.message);
      }
    }
  };

  return (
    <>
      {issuesSorted.length > 0 ? (
        <>
          <Group vertical spacing={SPACINGS.XXS}>
            {issuesSorted.map((issue) => {
              return <NewspaperIssue key={issue.id} issueId={issue.id} onClickDelete={handleOnClickDeleteIssue} />;
            })}
          </Group>
          {viewMode === 'not-published' && (
            <Block paddingTop={SPACINGS.S}>
              <ButtonPrimary
                label="Ausgabe erstellen"
                icon={IconPlus}
                onClick={() => navigate(`/newspaper/${newspaperId}/create/issue`)}
              />
            </Block>
          )}
        </>
      ) : (
        <Block>
          <Panel padding={SPACINGS.XL}>
            {viewMode === 'not-published' ? (
              <>
                <Text>Für diese Zeitung sind keine zukünftigen Ausgaben vorhanden.</Text>
                <Block paddingTop={SPACINGS.M}>
                  <ButtonPrimary
                    label="Ausgabe erstellen"
                    icon={IconPlus}
                    onClick={() => navigate(`/newspaper/${newspaperId}/create/issue`)}
                  />
                </Block>
              </>
            ) : (
              <Text>Für diese Zeitung sind keine veröffentlichten Ausgaben vorhanden.</Text>
            )}
          </Panel>
        </Block>
      )}
    </>
  );
};

export default NewspaperIssues;
