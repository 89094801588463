import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useRessorts = (): {
  ressortsSorted: RessortEntity[];
} => {
  const ressorts = entitySelectors.selectAll<RessortEntity>('ressorts');

  const ressortsSorted = useMemo(() => {
    const activeRessorts = _filter(ressorts, ({ isDisabled }) => !isDisabled);
    return _orderBy(activeRessorts, [(ressort) => ressort.name], ['asc']);
  }, [ressorts]);

  return {
    ressortsSorted,
  };
};
