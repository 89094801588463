import React, { useState } from 'react';

interface AdviceOverlayProviderInterface {
  openOverlay?: string;
  setOpenOverlay: (key?: string) => void;
  editorDimensions?: DOMRect;
  setEditorDimensions: (rect: DOMRect | undefined) => void;
}

export const AdviceOverlayContext = React.createContext<AdviceOverlayProviderInterface>(
  {} as AdviceOverlayProviderInterface
);

const AdviceOverlayProvider: React.FC = ({ children }) => {
  const [openOverlay, setOpenOverlay] = useState<string | undefined>(undefined);
  const [editorDimensions, setEditorDimensions] = useState<DOMRect | undefined>(undefined);

  return (
    <AdviceOverlayContext.Provider
      value={{
        openOverlay,
        setOpenOverlay,
        setEditorDimensions,
        editorDimensions,
      }}
    >
      {children}
    </AdviceOverlayContext.Provider>
  );
};

export default AdviceOverlayProvider;
