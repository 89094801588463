import React from 'react';
import { Block } from '@webfox-sc/core';
import Headline from '../shared/atoms/Headline';
import { COLORS, SPACINGS } from '../../styles/theme';
import Panel from '../shared/atoms/Panel';
import Text from '../shared/atoms/Text';

const Tags: React.FC = () => {
  return (
    <>
      <Block justify="center" position="relative">
        <Headline variant="H1">Suche nach Hashtags</Headline>
      </Block>
      <Block paddingTop={SPACINGS.XL} maxWidth="640px" margin="0 auto">
        <Block border={`2px dashed ${COLORS.MID_GREY}`}>
          <Panel padding={SPACINGS.XL}>
            <Text textAlign="center">Diese Seite wird alsbald erstellt.</Text>
          </Panel>
        </Block>
      </Block>
    </>
  );
};

export default Tags;
