import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useIssuePage = (
  pageId: number | undefined
): {
  page?: PageEntity;
  pageCount: number;
  pageNumberStart: number;
  pageNumberEnd: number;
  pageSpanFormatted: string;
  articles: ArticleEntity[];
  articlesSorted: ArticleEntity[];
  linesTotal: number;
  linesRemaining: number;
} => {
  const page = useAppSelector(({ entities }) => entities.pages.entities[pageId || 0]);

  const pageCount = page?.count || 1;
  const pageNumberStart = page?.number || 0;
  const pageNumberEnd = pageNumberStart + pageCount - 1;
  const pageSpanFormatted = pageCount === 1 ? String(pageNumberStart) : `${pageNumberStart} – ${pageNumberEnd}`;

  const articles = entitySelectors.selectByIds<ArticleEntity>('articles', page?.articleIds);

  const articlesSorted = useMemo(() => {
    return _orderBy(articles, [(article) => article.rank], ['asc']);
  }, [articles]);

  let linesTotal = 0;
  articles.forEach((article) => {
    linesTotal += article?.lines || 0;
  });

  const linesRemaining = (page?.linesMax || 0) - linesTotal;

  return {
    page,
    pageCount,
    pageNumberStart,
    pageNumberEnd,
    pageSpanFormatted,
    articles,
    articlesSorted,
    linesTotal,
    linesRemaining,
  };
};
