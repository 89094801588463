import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';

export const useOverlay = (): {
  showOverlay: boolean;
  closeOverlay: () => void;
} => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { pathname } = useLocation();

  let showOverlay = false;
  if (
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/edit') ||
    pathname.includes('/view') ||
    pathname.includes('/import')
  ) {
    showOverlay = true;
  }

  const closeOverlay = () => {
    if (navigationType === 'POP') {
      /**
       * use deep link
       */
      let pathToPage = '/';
      const pathnameSegments = pathname.split('/');
      const views = ['newspaper', 'issue', 'story', 'article'];
      if (
        views.includes(pathnameSegments[1])
        // pathnameSegments[1] === 'newspaper' ||
        // pathnameSegments[1] === 'issue' ||
        // pathnameSegments[1] === 'story' ||
        // pathnameSegments[1] === 'article'
      ) {
        pathToPage = `/${pathnameSegments[1]}/${pathnameSegments[2]}`;
      }
      navigate(pathToPage);
    } else {
      /**
       * use browser history back
       */
      navigate(-1);
    }
  };

  return {
    showOverlay,
    closeOverlay,
  };
};
