import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import { SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import ArticleTeaser from '../article/ArticleTeaser';
import { useStoryArticles } from './useStoryArticles';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import SingleSelect from '../shared/select/SingleSelect';
import Text from '../shared/atoms/Text';
import Panel from '../shared/atoms/Panel';

interface StoryArticlesProps {
  storyId?: number;
}

const StoryArticles: React.FC<StoryArticlesProps> = ({ storyId }) => {
  const navigate = useNavigate();

  const {
    sortingKeyOptions,
    selectedSortingKeyOption,
    setSelectedSortingKeyOption,
    sortingOrderOptions,
    selectedSortingOrderOption,
    setSelectedSortingOrderOption,
    articlesSorted,
  } = useStoryArticles(storyId);

  let headlineArticles = 'Beiträge';
  if (articlesSorted && articlesSorted.length === 1) {
    headlineArticles = '1 Beitrag';
  } else if (articlesSorted && articlesSorted.length > 1) {
    headlineArticles = `${articlesSorted.length} Beiträge`;
  }

  return (
    <>
      <Block paddingBottom={SPACINGS.M}>
        <Headline>{headlineArticles}</Headline>
      </Block>

      {articlesSorted && articlesSorted.length > 0 ? (
        <>
          <Group
            paddingRight={SPACINGS.XL}
            justify={articlesSorted.length > 1 ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {articlesSorted.length > 1 && (
              <Group spacing={SPACINGS.XS} alignItems="center">
                <Text>Sortieren</Text>
                <Group spacing={SPACINGS.XXS}>
                  <SingleSelect
                    options={sortingKeyOptions}
                    defaultValue={selectedSortingKeyOption}
                    onChange={setSelectedSortingKeyOption}
                  />
                  <SingleSelect
                    options={sortingOrderOptions}
                    defaultValue={selectedSortingOrderOption}
                    onChange={setSelectedSortingOrderOption}
                  />
                </Group>
              </Group>
            )}

            <ButtonPrimary
              label="Beitrag hinzufügen"
              icon={IconPlus}
              onClick={() => navigate(`/story/${storyId}/create/article`)}
            />
          </Group>
          <Group vertical spacing={SPACINGS.S} paddingTop={SPACINGS.M}>
            {articlesSorted.map((article) => {
              return <ArticleTeaser key={article.id} articleId={article.id} />;
            })}
          </Group>
        </>
      ) : (
        <Block paddingRight={SPACINGS.XL}>
          <Panel padding={SPACINGS.XL}>
            <Text>Für diese Story sind derzeit keine Beiträge vorhanden.</Text>
            <Block paddingTop={SPACINGS.M}>
              <ButtonPrimary
                label="Beitrag hinzufügen"
                icon={IconPlus}
                onClick={() => navigate(`/story/${storyId}/create/article`)}
              />
            </Block>
          </Panel>
        </Block>
      )}
    </>
  );
};

export default StoryArticles;
