/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select from 'react-select';
import selectComponents from './SingleSelect.components';
import selectStyles from './SingleSelect.styles';

interface CustomAsyncSelectProps {
  options: any;
  defaultValue?: any;
  value?: any;
  placeholder?: string;
  onChange?: (newValue: any, actionMeta: any) => void;
  isClearable?: boolean;
}

const SingleSelect: React.FC<CustomAsyncSelectProps> = ({
  options,
  defaultValue,
  value,
  placeholder,
  onChange,
  isClearable,
}) => {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      components={selectComponents}
      styles={selectStyles}
      isClearable={!!isClearable}
    />
  );
};

export default SingleSelect;
