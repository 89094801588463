import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconPencil } from '@webfox-sc/icons';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import MetaTable from '../shared/molecules/MetaTable';
import MetaTableItem from '../shared/molecules/MetaTableItem';
import { useStory } from './useStory';
import Text from '../shared/atoms/Text';
import TagGroup from '../tags/TagGroup';
import StatusPanel from '../status/StatusPanel';

interface StoryMetaProps {
  storyId?: number;
}

const StoryMeta: React.FC<StoryMetaProps> = ({ storyId }) => {
  const navigate = useNavigate();
  const { story } = useStory(storyId);

  if (!story) {
    return null;
  }

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/story/${storyId}`);
  };

  const handleOnClickEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/story/${storyId}/edit`);
  };

  return (
    <Group nowrap spacing={SPACINGS.XXL}>
      <GroupItem grow>
        <Headline variant="H1">
          <a href={`/story/${storyId}`} onClick={handleOnClickLink}>
            {story.name}
          </a>
        </Headline>

        {story.description && (
          <Block paddingTop={SPACINGS.S}>
            <Text nl2br>{story.description}</Text>
          </Block>
        )}

        {story.tagIds.length > 0 && (
          <Block paddingTop={SPACINGS.L}>
            <MetaTable showDecoLine>
              <MetaTableItem label="Hashtags">
                <TagGroup tagIds={story.tagIds} />
              </MetaTableItem>
            </MetaTable>
          </Block>
        )}
      </GroupItem>

      <Group nowrap spacing={SPACINGS.XXS}>
        <GroupItem>
          <StatusPanel
            createdTime={story.createdTime}
            createdByUserId={story.createdByUserId}
            updatedTime={story.updatedTime}
            updatedByUserId={story.updatedByUserId}
          />
        </GroupItem>
        <GroupItem>
          <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
        </GroupItem>
      </Group>
    </Group>
  );
};

export default StoryMeta;
