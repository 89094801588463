import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/theme';
import { useOverlay } from './useOverlay';

const StyledShader = styled.div({
  position: 'fixed',
  top: 0,
  left: '99999px',
  bottom: 0,
  width: '100%',
  background: 'rgba(0,0,0,0.2)',
  opacity: 0,
  transition: 'opacity 0.3s ease, left 0s ease 0.3s',
  '&[data-visible="true"]': {
    left: '0',
    opacity: 1,
    transition: 'opacity 0.3s ease',
  },
});

const StyledOverlay = styled.div({
  position: 'fixed',
  top: 0,
  right: '-99999px',
  bottom: 0,
  overflow: 'hidden',
  opacity: 0,
  transition: 'opacity 0.2s ease, right 0.2s ease 0.2s',

  width: 'calc(100% - 7% - 48px)',
  '@media (min-width: 1600px)': {
    width: 'calc((100% - 1600px)/2 + 1600px - 7% - 48px)',
  },

  '&[data-visible="true"]': {
    right: 0,
    opacity: 1,
    transition: 'opacity 0.2s ease',
  },
});

const StyledCanvas = styled.div({
  position: 'absolute',
  top: 0,
  left: '30px',
  width: '100%',
  bottom: 0,
  background: COLORS.SILVER,
  transition: 'left 0.3s ease',
  '&[data-visible="true"]': {
    left: '0',
  },
});

const Overlay: React.FC = ({ children }) => {
  const { showOverlay } = useOverlay();

  /**
   * block page scrolling if overlay is shown
   */
  useEffect(() => {
    const body = document.querySelector('body');
    if (showOverlay) {
      body?.style.setProperty('overflow-y', 'hidden');
    } else {
      body?.style.setProperty('overflow-y', 'auto');
    }
  }, [showOverlay]);

  const dataAttributes = {
    'data-visible': showOverlay,
  };

  return (
    <>
      <StyledShader {...dataAttributes} />
      <StyledOverlay {...dataAttributes}>
        <StyledCanvas {...dataAttributes}>{children}</StyledCanvas>
      </StyledOverlay>
    </>
  );
};

export default Overlay;
