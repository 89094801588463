import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from '../shared/atoms/Input';
import { SortableHandle } from 'react-sortable-hoc';
import { Block, InlineSVG } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import IconDragHorizontal from '../../assets/icons/IconDragHorizontal';

interface FormSectionHeadlineInputProps {
  value: string;
  onChange?: (headlineText: string) => void;
  onFocus?: (e: React.FocusEvent) => void;
  deletable?: boolean;
  onDelete?: () => void;
}

const FormSectionHeadlineInput: React.FC<FormSectionHeadlineInputProps> = ({
  value,
  onChange,
  onFocus,
  deletable,
  onDelete,
}) => {
  const [valueDraft, setValueDraft] = useState(value);

  useEffect(() => {
    setValueDraft(value);
  }, [value]);

  const DragHandle = SortableHandle(() => {
    return (
      <Block
        height="60px"
        width="60px"
        justifyContent="center"
        alignItems="center"
        background={COLORS.WHITE}
        style={{ cursor: 'move' }}
      >
        <InlineSVG width="24px" height="24px">
          {IconDragHorizontal}
        </InlineSVG>
      </Block>
    );
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueDraft(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleOnClickRemoveButton = () => {
    if (deletable && onDelete) {
      onDelete();
    }
  };

  return (
    <Block paddingBottom={SPACINGS.XXXS}>
      <Input
        indentComponent={<DragHandle />}
        autoComplete="ignore"
        value={valueDraft}
        showRemoveButton={!!deletable}
        onClickRemoveButton={handleOnClickRemoveButton}
        onChange={handleOnChange}
        onFocus={onFocus}
      />
    </Block>
  );
};

export default FormSectionHeadlineInput;
