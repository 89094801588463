import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useUsers = (
  userIds?: number[]
): {
  usersSorted: UserEntity[];
  usersSortedFormatted: string;
} => {
  const users = entitySelectors.selectByIds<UserEntity>('users', userIds);

  const usersSorted = useMemo(() => {
    return _orderBy(users, [(user) => (user.lastname || user.firstname || user.username || '').toLowerCase()], ['asc']);
  }, [users]);

  const usersSortedFormatted = usersSorted
    .map((user) => (user.lastname && user.firstname ? `${user.firstname} ${user.lastname}` : user.username))
    .join(', ');

  return {
    usersSorted,
    usersSortedFormatted,
  };
};
