export const convertNewlinesToHTML = (text: string): string => {
  return text.replace(/(\r\n|\r|\n)/g, '<br>');
};

export const formatFileSizeKB = (fileSizeKB: number | undefined): string => {
  if (!fileSizeKB) {
    return '?';
  }
  if (fileSizeKB < 1024) {
    return `${String(Math.round(fileSizeKB))} KB`;
  }
  if (fileSizeKB < 1024 * 1024) {
    return `${String(Math.round((fileSizeKB / 1024) * 100) / 100).replace('.', ',')} MB`;
  }
  return `${String(Math.round((fileSizeKB / 1024 / 1024) * 100) / 100).replace('.', ',')} GB`;
};

export const formatFileSizeB = (fileSizeB: number | undefined): string => {
  if (fileSizeB === undefined) {
    return '?';
  }
  return formatFileSizeKB(Math.round(fileSizeB / 1024));
};
