import React from 'react';
import { Block, Grid, GridItem, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import Inline from '../shared/atoms/Inline';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useAssetMedia } from '../asset/useAssetMedia';
import ArticleViewMediaAsset from './ArticleViewMediaAsset';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { IconPencil } from '@webfox-sc/icons';

interface ArticleViewAssetProps {
  assetId?: number;
  onClickEdit?: (e: React.MouseEvent) => void;
}

const ArticleViewAsset: React.FC<ArticleViewAssetProps> = ({ assetId, onClickEdit }) => {
  const { asset, mediaAssets } = useAssetMedia(assetId);

  return (
    <>
      <Group alignItems="flex-end">
        <GroupItem grow>
          <Text variant="small">
            <Inline color={COLORS.GREY}>Anhang:</Inline> {asset?.name}
          </Text>
        </GroupItem>
        <ButtonPrimary variant="small" ariaLabel="Bearbeiten" icon={IconPencil} onClick={onClickEdit} />
      </Group>
      <Block paddingTop={SPACINGS.XXS}>
        <Block padding={SPACINGS.XL} background={COLORS.WHITE}>
          {mediaAssets?.length > 0 ? (
            <Grid columns={3} spacing={SPACINGS.XS}>
              {mediaAssets.map((mediaAsset) => {
                return (
                  <GridItem key={mediaAsset.id}>
                    <ArticleViewMediaAsset mediaAssetId={mediaAsset.id} />
                  </GridItem>
                );
              })}
            </Grid>
          ) : (
            <Inline color={COLORS.GREY}>
              <em>Für diesen Anhang ist derzeit keine Dateien vorhanden.</em>
            </Inline>
          )}
        </Block>
      </Block>
    </>
  );
};

export default ArticleViewAsset;
