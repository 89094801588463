import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';
import { useSnippets } from '../snippet/useSnippets';

type ArticleSize = {
  charCount: number;
  lineCount: number;
};

export const useArticleSize = (
  articleId: number | undefined
): {
  article?: ArticleEntity;
} & ArticleSize => {
  const article = useAppSelector(({ entities }) => entities.articles.entities[articleId || 0]);
  const sections = entitySelectors.selectByIds<SectionEntity>('sections', article?.sectionIds);

  const snippetIds = useMemo(() => {
    return sections
      .map((articleSection) => {
        return articleSection.snippetId || 0;
      })
      .filter(Boolean);
  }, [sections]);

  const { snippets } = useSnippets(snippetIds);

  const articleSize = useMemo(
    () =>
      snippets.reduce<ArticleSize>(
        (result, snippet) => {
          result.charCount += snippet.characters || 0;
          result.lineCount += snippet.lines || 0;
          return result;
        },
        { charCount: 0, lineCount: 0 }
      ),
    [snippets]
  );

  return {
    article,
    ...articleSize,
  };
};
