import React, { useEffect, useMemo, useState } from 'react';
import { Block, Collapse } from '@webfox-sc/core';
import FormSection from '../shared/forms/FormSection';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import { createTag, fetchTagsByName } from '../../app/slices/tagsSlice';
import { useAppDispatch } from '../../app/hooks';
import { useTags } from './useTags';
import SelectTags from './SelectTags';

type TagOption = {
  value: number;
  label?: string;
};

interface FormSectionTagsProps {
  paddingTop?: string;
  defaultTagIds?: number[];
  onChange?: (tagIds: number[]) => void;
}

const FormSectionTags: React.FC<FormSectionTagsProps> = ({ paddingTop, defaultTagIds, onChange }) => {
  const dispatch = useAppDispatch();
  const { tagsSorted } = useTags(defaultTagIds);

  const defaultTagOptionsSorted: TagOption[] = useMemo(() => {
    return tagsSorted.map((tag) => ({
      value: tag.id,
      label: `#${tag.name}`,
    }));
  }, [tagsSorted]);

  const [tagOptions, setTagOptions] = useState<TagOption[]>(defaultTagOptionsSorted);
  const [newTagName, setNewTagName] = useState('');
  const [newTagNameExistsError, setNewTagNameExistsError] = useState(false);

  useEffect(() => {
    setTagOptions(defaultTagOptionsSorted);
  }, [defaultTagOptionsSorted]);

  const handleChangeTagOptions = (tagOptionsNew: TagOption[]) => {
    setTagOptions(tagOptionsNew);
    if (typeof onChange === 'function') {
      onChange(tagOptionsNew.map((tagOption) => tagOption.value));
    }
  };

  const handleOnSubmitNewTag = async (): Promise<boolean> => {
    const tagName = newTagName.replaceAll(' ', '').trim();

    if (!tagName) {
      return false;
    }

    const fetchResponse = await dispatch(fetchTagsByName(tagName));

    if (fetchResponse.meta.requestStatus !== 'fulfilled') {
      return false;
    }

    if (fetchResponse.payload?.data && fetchResponse.payload?.data.length > 0) {
      setNewTagNameExistsError(true);
      return false;
    }

    const createResponse = await dispatch(createTag(tagName));

    if (createResponse.meta.requestStatus !== 'fulfilled') {
      return false;
    }

    if (!createResponse.payload?.data?.id) {
      return false;
    }

    handleChangeTagOptions([
      ...tagOptions,
      {
        value: createResponse.payload?.data?.id,
        label: createResponse.payload?.data?.attributes?.name,
      },
    ]);

    setNewTagName('');

    return true;
  };

  return (
    <FormSection title="Hashtags" paddingTop={paddingTop}>
      <SelectTags tagOptions={tagOptions} onChangeTagOptions={handleChangeTagOptions} />
      <Block paddingTop={SPACINGS.XXS}>
        <Input
          name="newtag"
          value={newTagName}
          indentLabel="Neues Hashtag"
          showSubmitButton
          error={newTagNameExistsError}
          onChange={(e) => setNewTagName(e.target.value)}
          onClickSubmitButton={handleOnSubmitNewTag}
          onFocus={() => setNewTagNameExistsError(false)}
        />
      </Block>
      <Collapse show={newTagNameExistsError}>
        <Block paddingTop={SPACINGS.XS}>
          <Text variant="small" color={COLORS.RED}>
            Dieses Tag gibt es schon.
          </Text>
        </Block>
      </Collapse>
    </FormSection>
  );
};

export default FormSectionTags;
