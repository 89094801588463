import { Block } from '@webfox-sc/core';
import React from 'react';
import { SPACINGS } from '../../../styles/theme';
import Text from '../atoms/Text';

interface FormSectionProps {
  title?: string;
  paddingTop?: string;
}

const FormSection: React.FC<FormSectionProps> = ({ title, paddingTop, children }) => {
  return (
    <Block paddingTop={paddingTop}>
      {title && (
        <Block paddingBottom={SPACINGS.XXS}>
          <Text variant="label-small">{title}</Text>
        </Block>
      )}
      {children}
    </Block>
  );
};

export default FormSection;
