import { useAppSelector } from '../../app/hooks';

export const useAsset = (
  assetId?: number
): {
  asset?: AssetEntity;
} => {
  const asset = useAppSelector(({ entities }) => entities.assets.entities[assetId || 0]);

  return {
    asset,
  };
};
