import _find from 'lodash/find';

export const findSnippetIdByParent = (parentId: number, articleData: ArticleData | undefined): number | null => {
  if (articleData?.attributes?.Section) {
    const section = _find(
      articleData?.attributes?.Section,
      (s) => s?.snippet?.data?.attributes?.parent?.data?.id === parentId
    );
    if (section) {
      return section.snippet.data.id;
    }
  }
  return null;
};

export const LINES_FACTOR = 29;

export const stripMarkup = (markup?: string): string => (markup || '').replace(/<[^>]+>/g, '');
