import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Block, Collapse, Group } from '@webfox-sc/core';
import Headline from '../shared/atoms/Headline';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Input from '../shared/atoms/Input';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { exchangeToken, getLoginLink, login, selectAuthState } from '../../app/slices/authSlice';
import Inline from '../shared/atoms/Inline';
import Text from '../shared/atoms/Text';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status: authStatus } = useAppSelector(selectAuthState);

  // TODO move out
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  if (hash) {
    const query = new URLSearchParams(hash.substring(1));
    if (query && query.has('access_token')) {
      const accessToken = query.get('access_token');
      if (accessToken) {
        dispatch(exchangeToken({ access_token: accessToken }));
        navigate(pathname, { replace: true });
      }
    }
  }

  const loginLink = getLoginLink();

  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (authStatus === 'failed') {
      setShowError(true);
    }
  }, [authStatus]);

  useEffect(() => {
    setShowError(false);
  }, [identifier, password]);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(login({ identifier, password }));
  };

  return (
    <Block justify="center" paddingTop={SPACINGS.M}>
      <Block background={COLORS.SILVER} padding={SPACINGS.XL} width="100%" maxWidth="480px">
        <Block justify="center">
          <Headline variant="H2">
            <Inline color={COLORS.BLUE}>Mundschenk</Inline> CMS BZ
          </Headline>
        </Block>
        <form onSubmit={(e) => handleOnSubmit(e)}>
          <Block justify="center" paddingTop={SPACINGS.M}>
            <Text variant="label-small">E-Mail</Text>
          </Block>
          <Block paddingTop={SPACINGS.XXS}>
            <Input
              type="text"
              name="identifier"
              autoComplete="current-identifier"
              value={identifier}
              width="100%"
              textAlign="center"
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </Block>
          <Block justify="center" paddingTop={SPACINGS.XS}>
            <Text variant="label-small">Passwort</Text>
          </Block>
          <Block paddingTop={SPACINGS.XXS}>
            <Input
              type="password"
              name="password"
              autoComplete="current-password"
              value={password}
              width="100%"
              textAlign="center"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Block>
          <Group paddingTop={SPACINGS.M} spacing={SPACINGS.XXS} justify="center">
            <ButtonPrimary type="submit" label="Strapi Login" />
            <ButtonSecondary href={loginLink} label="cidaas Login" />
          </Group>
        </form>
        <Collapse show={showError}>
          <Block paddingTop={SPACINGS.M} justify="center">
            <Text color={COLORS.RED}>Login fehlgeschlagen.</Text>
          </Block>
        </Collapse>
      </Block>
    </Block>
  );
};

export default LoginForm;
