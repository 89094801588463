/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Block, Group } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { SPACINGS } from '../../styles/theme';
import SingleSelect from '../shared/select/SingleSelect';
import Input from '../shared/atoms/Input';
import SelectTags from '../tags/SelectTags';

type DateRangeOption = {
  value: DateRange;
  label: string;
};

type SortingKeyOption = {
  value: string;
  label: string;
};

type SortingOrderOption = {
  value: 'asc' | 'desc';
  label: string;
};

const dateRangeOptions: DateRangeOption[] = [
  {
    value: 'last_7_days',
    label: 'Letzte 7 Tage',
  },
  {
    value: 'last_30_days',
    label: 'Letzte 30 Tage',
  },
  {
    value: 'entire_period',
    label: 'Gesamter Zeitraum',
  },
];

const sortingKeyOptions: SortingKeyOption[] = [
  {
    value: 'createdAt',
    label: 'Erstellt',
  },
  {
    value: 'updatedAt',
    label: 'Aktualisiert',
  },
  {
    value: 'name',
    label: 'Titel',
  },
];

const sortingOrderOptions: SortingOrderOption[] = [
  {
    value: 'asc',
    label: 'Aufsteigend',
  },
  {
    value: 'desc',
    label: 'Absteigend',
  },
];

const StyledTable = styled.table({
  width: '100%',
  borderSpacing: 0,
});

interface StyledTableCellProps {
  width?: string | 0;
  paddingTop?: string | 0;
  paddingRight?: string | 0;
}

const StyledCell = styled.td<StyledTableCellProps>(({ width, paddingTop, paddingRight }) => {
  return {
    width,
    padding: `${paddingTop !== undefined ? paddingTop : SPACINGS.XXS} ${
      paddingRight !== undefined ? paddingRight : 0
    } 0 0`,
    verticalAlign: 'middle',
  };
});

interface FormSectionFiltersProps {
  filterData?: FilterData;
  onChange?: (filterData: FilterData) => void;
}

const FormSectionFilters: React.FC<FormSectionFiltersProps> = ({ filterData, onChange }) => {
  const [dateRangeOption, setDateRangeOption] = useState<DateRangeOption | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [sortingKeyOption, setSortingKeyOption] = useState<SortingKeyOption | undefined>(undefined);
  const [sortingOrderOption, setSortingOrderOption] = useState<SortingOrderOption | undefined>(undefined);

  useEffect(() => {
    setDateRangeOption(dateRangeOptions.find((option) => option.value === filterData?.dateRange));
    setSearchText(filterData?.searchText);
    setSortingKeyOption(sortingKeyOptions.find((option) => option.value === filterData?.sortingKey));
    setSortingOrderOption(sortingOrderOptions.find((option) => option.value === filterData?.sortingOrder));
  }, [filterData]);

  const handleChange = (key: keyof FilterData, value: any) => {
    if (onChange) {
      const filterDataNew = { ...filterData };
      filterDataNew[key] = value;
      onChange(filterDataNew);
    }
  };

  return (
    <Block>
      <StyledTable>
        <tbody>
          <tr>
            <StyledCell width="1%" paddingTop={0} paddingRight={SPACINGS.S}>
              <Text>Hashtags</Text>
            </StyledCell>
            <StyledCell paddingTop={0}>
              <SelectTags onChangeTagIds={(tagIds) => handleChange('tagIds', tagIds)} />
            </StyledCell>
          </tr>
          <tr>
            <StyledCell width="1%" paddingRight={SPACINGS.S}>
              <Text>Filtern</Text>
            </StyledCell>
            <StyledCell>
              <Group nowrap spacing={SPACINGS.XXS}>
                <Block width="240px">
                  <SingleSelect
                    options={dateRangeOptions}
                    value={dateRangeOption}
                    onChange={(option) => handleChange('dateRange', option.value)}
                  />
                </Block>
                <Block width="240px">
                  <Input
                    variant="small-search"
                    name="asset-search"
                    autoComplete="asset-search"
                    value={searchText || ''}
                    placeholder="Volltextsuche ..."
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      handleChange('searchText', e.target.value);
                    }}
                  />
                </Block>
              </Group>
            </StyledCell>
          </tr>
          <tr>
            <StyledCell width="1%" paddingRight={SPACINGS.S}>
              <Text>Sortieren</Text>
            </StyledCell>
            <StyledCell>
              <Group nowrap spacing={SPACINGS.XXS}>
                <Block width="240px">
                  <SingleSelect
                    options={sortingKeyOptions}
                    value={sortingKeyOption}
                    onChange={(option) => handleChange('sortingKey', option.value)}
                  />
                </Block>
                <Block width="240px">
                  <SingleSelect
                    options={sortingOrderOptions}
                    value={sortingOrderOption}
                    onChange={(option) => handleChange('sortingOrder', option.value)}
                  />
                </Block>
              </Group>
            </StyledCell>
          </tr>
        </tbody>
      </StyledTable>
    </Block>
  );
};

export default FormSectionFilters;
