/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Block, Collapse, Grid, GridItem, Group, Radio } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import OverlayLayout from '../overlay/OverlayLayout';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useAppDispatch } from '../../app/hooks';
import { useCurrentIssue } from './useCurrentIssue';
import { createPage } from '../../app/slices/pagesSlice';
import { useOverlay } from '../overlay/useOverlay';
import { refetchIssue } from '../../app/slices/issuesSlice';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import { useIssuePageNumbers } from './useIssuePageNumbers';
import { useEffect } from 'react';
import CustomRadioGroup from '../shared/atoms/CustomRadioGroup';
import FormSectionRessorts from '../ressorts/FormSectionRessorts';
import useAPIError from '../apiError/useAPIError';

type CreateType = 'single-page' | 'single-pages' | 'page-sequence';

const linesMaxLimit = 100000;

const IssuePagesCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();

  const { issue } = useCurrentIssue();
  const {
    nextAvailablePageNumber,
    maxPageNumber,
    maxPageCount,
    getPageNumberRange,
    getUnavailablePageNumbersForCreate,
  } = useIssuePageNumbers(issue?.id);

  const [createType, setCreateType] = useState<CreateType>('single-page');
  const [numberStart, setNumberStart] = useState<number | undefined>(nextAvailablePageNumber);
  const [numberStartErrorText, setNumberStartErrorText] = useState<string | undefined>(undefined);
  const [numberEnd, setNumberEnd] = useState<number | undefined>(nextAvailablePageNumber);
  const [numberEndErrorText, setNumberEndErrorText] = useState<string | undefined>(undefined);
  const [linesMax, setLinesMax] = useState<number | undefined>(undefined);
  const [linesMaxErrorText, setLinesMaxErrorText] = useState<string | undefined>(undefined);
  const [ressortIds, setRessortIds] = useState<number[]>([]);

  useEffect(() => {
    setNumberStart(nextAvailablePageNumber);
    setNumberEnd(nextAvailablePageNumber);
  }, [nextAvailablePageNumber]);

  useEffect(() => {
    if ((numberStart || 0) < 0) {
      setNumberStart(0);
    }
    if ((numberEnd || 0) < 0) {
      setNumberEnd(0);
    }
    if (createType === 'single-page') {
      setNumberEnd(numberStart);
    }
  }, [createType, numberStart, numberEnd]);

  const clearPageNumberErrors = () => {
    setNumberStartErrorText(undefined);
    setNumberEndErrorText(undefined);
  };

  useEffect(() => {
    setNumberStart(nextAvailablePageNumber);
    setNumberEnd(nextAvailablePageNumber);
    clearPageNumberErrors();
  }, [createType, nextAvailablePageNumber]);

  const createSinglePages = async (numbers: number[]) => {
    const promises: Promise<any>[] = [];
    if (!!issue) {
      for (let n = 0; n < numbers.length; n += 1) {
        promises.push(
          dispatch(
            createPage({
              issue: issue?.id,
              number: numbers[n],
              count: 1,
              lines_max: linesMax && Math.abs(linesMax),
              ressorts: ressortIds,
            })
          )
        );
      }
    }
    return Promise.all(promises);
  };

  const handleOnClickCreate = async (): Promise<boolean> => {
    if (!issue) {
      return false;
    }

    if ((linesMax || 0) > linesMaxLimit) {
      setLinesMaxErrorText('Die Zeilenanzahl ist auf 100.000 begrenzt.');
      return false;
    }

    const count = (numberEnd || 0) - (numberStart || 0) + 1;
    let numberStartErrorTextDraft: string | undefined = undefined;
    let numberEndErrorTextDraft: string | undefined = undefined;

    if (!numberStart) {
      numberStartErrorTextDraft = 'Dies ist ein Pflichtfeld.';
    } else if (numberStart > maxPageNumber) {
      numberStartErrorTextDraft = `Die Seitennummer darf maximal ${maxPageNumber} sein.`;
    }

    if (!numberEnd) {
      numberEndErrorTextDraft = 'Dies ist ein Pflichtfeld.';
    } else if (numberEnd > maxPageNumber) {
      numberEndErrorTextDraft = `Die Seitennummer darf maximal ${maxPageNumber} sein.`;
    } else if (numberEnd < (numberStart || 0)) {
      numberEndErrorTextDraft = `Die Seitennummer darf nicht kleiner als ${numberStart || 0} sein.`;
    } else if (createType === 'single-pages' && numberEnd > (numberStart || 0) + maxPageCount) {
      numberEndErrorTextDraft = `Es können maximal ${maxPageCount} Seiten gemeinsam erstellt werden.`;
    }

    if (!!numberStart && !!numberEnd) {
      const unavailablePageNumbers = getUnavailablePageNumbersForCreate(numberStart, count);
      if (unavailablePageNumbers.length === 1) {
        numberStartErrorTextDraft = `Die Seitennummer ${unavailablePageNumbers[0]} ist bereits vergeben`;
      } else if (unavailablePageNumbers.length > 1) {
        numberStartErrorTextDraft = `Einige Seitennummern zwischen ${numberStart} und ${numberEnd} sind bereits vergeben`;
      }
    }

    if (!!numberStartErrorTextDraft || !!numberEndErrorTextDraft) {
      setNumberStartErrorText(numberStartErrorTextDraft);
      setNumberEndErrorText(numberEndErrorTextDraft);
      return false;
    }

    if (!!numberStart) {
      if (createType === 'single-page') {
        const response = await dispatch(
          createPage({
            issue: issue.id,
            number: numberStart,
            count: 1,
            lines_max: linesMax,
            ressorts: ressortIds,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      } else if (createType === 'single-pages') {
        const responses = await createSinglePages(getPageNumberRange(numberStart, count));
        responses.forEach((response) => {
          if (response.payload?.error) {
            addError(response.payload.error.message);
          }
        });
      } else if (createType === 'page-sequence') {
        const response = await dispatch(
          createPage({
            issue: issue.id,
            number: numberStart,
            count,
            lines_max: linesMax,
            ressorts: ressortIds,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }

      /**
       * refetch issue
       */
      const response = await dispatch(refetchIssue(issue.id));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }

      closeOverlay();
    }

    return true;
  };

  const handleChangeCreateType = (value: any) => {
    setCreateType(value);
  };

  return (
    <OverlayLayout typeLabelPrimary="Seiten" title="Erstellen">
      <FormSection title="Typ">
        <Block>
          <CustomRadioGroup spacing={SPACINGS.S} value={createType} onChange={handleChangeCreateType}>
            <Radio label="Einzelseite" value="single-page" name="create-type" />
            <Radio label="Mehrere Einzelseiten" value="single-pages" name="create-type" />
            <Radio label="Seitenfolge" value="page-sequence" name="create-type" />
          </CustomRadioGroup>
        </Block>
      </FormSection>

      <Block paddingTop={SPACINGS.S}>
        <Grid columns={4} spacing={SPACINGS.S}>
          <GridItem>
            <FormSection title={createType === 'single-page' ? 'Seitennummer *' : 'Ab Seitennummer *'}>
              <Input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                name="multi-number-start"
                autoComplete="page-multi-number-start"
                value={numberStart || ''}
                error={!!numberStartErrorText}
                onChange={(e) => {
                  let numberStartNew: number | undefined = parseInt(e.target.value, 10);
                  if (!numberStartNew && numberStartNew !== 0) {
                    numberStartNew = undefined;
                  }
                  setNumberStart(numberStartNew);
                }}
                onFocus={clearPageNumberErrors}
              />
              <Collapse show={!!numberStartErrorText}>
                <Block paddingTop={SPACINGS.XS}>
                  <Text variant="small" color={COLORS.RED}>
                    {numberStartErrorText}
                  </Text>
                </Block>
              </Collapse>
            </FormSection>
          </GridItem>
          {createType !== 'single-page' && (
            <GridItem>
              <FormSection title="Bis Seitennummer *">
                <Input
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  name="multi-number-end"
                  autoComplete="page-multi-number-end"
                  value={numberEnd || ''}
                  error={!!numberEndErrorText}
                  onChange={(e) => {
                    let numberEndNew: number | undefined = parseInt(e.target.value, 10);
                    if (!numberEndNew && numberEndNew !== 0) {
                      numberEndNew = undefined;
                    }
                    setNumberEnd(numberEndNew);
                  }}
                  onFocus={clearPageNumberErrors}
                />
                <Collapse show={!!numberEndErrorText}>
                  <Block paddingTop={SPACINGS.XS}>
                    <Text variant="small" color={COLORS.RED}>
                      {numberEndErrorText}
                    </Text>
                  </Block>
                </Collapse>
              </FormSection>
            </GridItem>
          )}
        </Grid>
      </Block>

      <Block paddingTop={SPACINGS.S}>
        <Grid columns={4} spacing={SPACINGS.S}>
          <GridItem>
            <FormSection title={createType === 'single-pages' ? 'Verfügbare Zeilen je Seite' : 'Verfügbare Zeilen'}>
              <Input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                name="lines-max"
                autoComplete="page-lines-max"
                value={linesMax || ''}
                onChange={(e) => setLinesMax(parseInt(e.target.value, 10))}
                onFocus={() => {
                  setLinesMaxErrorText(undefined);
                }}
              />
            </FormSection>
            <Collapse show={!!linesMaxErrorText}>
              <Block paddingTop={SPACINGS.XS}>
                <Text variant="small" color={COLORS.RED}>
                  {linesMaxErrorText}
                </Text>
              </Block>
            </Collapse>
          </GridItem>
        </Grid>
      </Block>

      <FormSectionRessorts paddingTop={SPACINGS.S} onChange={setRessortIds} />

      <Group nowrap spacing={SPACINGS.XXS} paddingTop={SPACINGS.XL}>
        <ButtonPrimary label="Erstellen" onClick={handleOnClickCreate} />
        <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
      </Group>
    </OverlayLayout>
  );
};

export default IssuePagesCreate;
