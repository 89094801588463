import { Editor } from '@tiptap/react';

export function replaceAdviceNodeWithText(
  editor: Editor,
  pos: number,
  textContent: string,
  storageKey: string,
  deleteFunction?: () => void
): void {
  delete editor.storage.adviceExtension[storageKey];
  if (deleteFunction) {
    deleteFunction();
    editor.commands.insertContentAt(pos, textContent);
  } else {
    editor.chain().deleteNode('adviceView').insertContentAt(pos, textContent).run();
  }
}

export function removeAdviceTags(markup: string): string {
  return markup.replace(/<advice-view\s*[^>]+>([^<]*)<\/advice-view>/g, '$1');
}
