import React, { useRef, useState } from 'react';
import Text from '../shared/atoms/Text';
import styled from 'styled-components';
import { SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import IconDownload from '../../assets/icons/IconDownload';
import { createArticleAttachment, deleteArticleAttachment } from '../../app/slices/articleAttachmentSlice';
import { useArticle } from './useArticle';
import { useAppDispatch } from '../../app/hooks';
import { IconDelete, IconPlus } from '@webfox-sc/icons';
import { getDateTimeFormatted } from '../../utils/date';
import useAPIError from '../apiError/useAPIError';

interface ArticleAttachmentsProps {
  articleId: number;
}

const StyledTable = styled.table({
  borderSpacing: 0,
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: '20px',
});

const StyledTableCell = styled.td({
  padding: '8px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
  '&:first-of-type': {
    paddingRight: SPACINGS.XXS,
  },
});

const StyledTableHeaderCell = styled.th({
  padding: '8px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
});

const StyledTableButton = styled(ButtonPrimary)`
  :not(:last-child) {
    margin-right: 15px;
  }
`;

const ArticleAttachments: React.FC<ArticleAttachmentsProps> = ({ articleId }) => {
  const { attachments } = useArticle(articleId);
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const uploadFile = async (file: File | undefined) => {
    if (!file) return;

    const requestData = new FormData();

    if (file) {
      requestData.append('files.file', file, file.name);
    }
    requestData.append('data', JSON.stringify({}));

    try {
      setLoading(true);

      const response = await dispatch(createArticleAttachment(articleId, requestData));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const deleteFile = async (id: number | undefined) => {
    if (!id) return;

    const response = await dispatch(deleteArticleAttachment(articleId, id));
    if (response.payload?.error) {
      addError(response.payload.error.message);
    }
  };

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <StyledTableHeaderCell>Name</StyledTableHeaderCell>
            <StyledTableHeaderCell>Datum</StyledTableHeaderCell>
            <StyledTableHeaderCell>Benutzer</StyledTableHeaderCell>
            <StyledTableHeaderCell>Aktionen</StyledTableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {attachments.map((attachment, index) => (
            <tr key={index}>
              <StyledTableCell>
                <Text variant="small">{attachment.name}</Text>
              </StyledTableCell>
              <StyledTableCell>
                <Text variant="small">{`${getDateTimeFormatted(attachment.createdTime)} Uhr`}</Text>
              </StyledTableCell>
              <StyledTableCell>
                <Text variant="small">{attachment.createdByUserName}</Text>
              </StyledTableCell>
              <StyledTableCell>
                <StyledTableButton
                  ariaLabel="Herunterladen"
                  icon={IconDownload}
                  href={attachment.fileUrl}
                  target="_blank"
                />
                <StyledTableButton ariaLabel="Löschen" icon={IconDelete} onClick={() => deleteFile(attachment.id)} />
              </StyledTableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <ButtonPrimary
        ariaLabel="Hochladen"
        icon={IconPlus}
        label="Hochladen"
        onClick={handleButtonClick}
        disabled={loading}
      />
    </>
  );
};

export default ArticleAttachments;
