import React, { useEffect, useRef, useState } from 'react';
import { COLORS, SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { useAppDispatch } from '../../app/hooks';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useArticle } from '../article/useArticle';
import FormSectionFilters from '../article/FormSectionFilters';
import { fetchSnippets } from '../../app/slices/snippetsSlice';
import FormSectionConnectSnippetItem from './FormSectionConnectSnippetItem';
import { filterDataToParams } from '../../utils/filterData';
import useAPIError from '../apiError/useAPIError';

const ITEMS_PER_PAGE = 10;

interface FormSectionConnectSnippetProps {
  articleId?: number | undefined;
  paddingTop?: string;
  onSelect: (snippetId: number) => void;
  disabled?: boolean;
}

const defaultFilterData: FilterData = {
  start: 0,
  limit: ITEMS_PER_PAGE,
  dateRange: 'last_7_days',
  sortingKey: 'updatedAt',
  sortingOrder: 'desc',
};

const FormSectionConnectSnippet: React.FC<FormSectionConnectSnippetProps> = ({
  articleId,
  paddingTop,
  onSelect,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { sections } = useArticle(articleId);

  const [filterData, setFilterData] = useState<FilterData | undefined>(undefined);

  const [snippetIds, setSnippetIds] = useState<number[]>([]);
  const snippetIdsRef = useRef<number[]>([]);

  const [snippetCount, setSnippetCount] = useState(0);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);

  useEffect(() => {
    const excludeSnippetIds = sections.map((section: SectionEntity) => Number(section.snippetId)).filter(Boolean);
    setFilterData({
      ...defaultFilterData,
      excludeIds: excludeSnippetIds,
    });
  }, [sections]);

  useEffect(() => {
    (async () => {
      if (filterData) {
        const res = await dispatch(fetchSnippets(filterDataToParams(filterData, true)));
        if (res.payload?.error) {
          addError(res.payload.error.message);
        }

        const data = (res?.payload?.data || []) as SnippetData[];
        const newSnippetIds = data.map((snippetData) => snippetData.id);
        if ((filterData?.start || 0) > 0) {
          snippetIdsRef.current.push(...newSnippetIds);
        } else {
          snippetIdsRef.current = newSnippetIds;
        }
        setSnippetIds([...snippetIdsRef.current]);
        const count = res?.payload?.count || 0;
        setSnippetCount(count);
        setShowLoadMoreButton(snippetIdsRef.current.length < count);
      }
    })();
  }, [addError, dispatch, filterData]);

  const handleFilterChange = (newFilterData: FilterData): void => {
    setFilterData({
      ...newFilterData,
      start: 0,
      limit: ITEMS_PER_PAGE,
    });
  };

  const handleClickLoadMore = () => {
    setFilterData({
      ...filterData,
      start: (filterData?.start || 0) + ITEMS_PER_PAGE,
    });
  };

  return (
    <Block paddingTop={paddingTop}>
      <Headline>Textelement verknüpfen</Headline>

      <Block paddingTop={SPACINGS.M}>
        <FormSectionFilters filterData={filterData} onChange={handleFilterChange} />
      </Block>

      <Group paddingTop={SPACINGS.M} spacing={SPACINGS.XXS} vertical>
        {snippetIds.map((snippetId) => (
          <GroupItem key={`snippet-${snippetId}`}>
            <FormSectionConnectSnippetItem snippetId={snippetId} onSelect={onSelect} disabled={disabled} />
          </GroupItem>
        ))}
      </Group>

      <Block display="inline-block" paddingTop={SPACINGS.XXS}>
        <Text variant="small" textAlign="center">
          {snippetIds.length} von {snippetCount}
        </Text>
        <Block background={COLORS.MID_GREY}>
          <Block
            width={`${(100 / snippetCount) * snippetIds.length}%`}
            height="4px"
            background={snippetCount === 0 ? 'transparent' : COLORS.BLUE}
          />
        </Block>
        <Block paddingTop={SPACINGS.XXS}>
          <ButtonPrimary label="Weitere anzeigen" disabled={!showLoadMoreButton} onClick={handleClickLoadMore} />
        </Block>
      </Block>
    </Block>
  );
};

export default FormSectionConnectSnippet;
