import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import PageHeader from './page/PageHeader';
import PageContent from './page/PageContent';
import Newspaper from './newspaper/Newspaper';
import NewspaperIssueCreate from './newspaper/NewspaperIssueCreate';
import Issue from './issue/Issue';
import IssueEdit from './issue/IssueEdit';
import IssuePagesCreate from './issue/IssuePagesCreate';
import IssuePageEdit from './issue/IssuePageEdit';
import Article from './article/Article';
import Story from './story/Story';
import LoginForm from './auth/LoginForm';
import { COLORS, SPACINGS } from '../styles/theme';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { autoLogin, logout, selectAuthState } from '../app/slices/authSlice';
import Overlay from './overlay/Overlay';
import StoryEdit from './story/StoryEdit';
import StoryCreateArticle from './story/StoryCreateArticle';
import ArticleEdit from './article/ArticleEdit';
import ArticleView from './article/ArticleView';
import AssetEdit from './asset/AssetEdit';
import AssetView from './asset/AssetView';
import AssetAdd from './asset/AssetAdd';
import SnippetEdit from './snippet/SnippetEdit';
import SnippetView from './snippet/SnippetView';
import SnippetAdd from './snippet/SnippetAdd';
import Newspapers from './newspaper/Newspapers';
import Tags from './tags/Tags';
import ConfirmDialogProvider from './dialog/ConfirmDialogProvider';
import ConfirmDialog from './dialog/ConfirmDialog';
import APIErrorNotifications from './apiError/APIErrorNotifications';
import APIErrorProvider from './apiError/APIErrorProvider';
import ArticleImport from './article/ArticleImport';

const GlobalStyle = createGlobalStyle({
  html: {
    fontSize: '100%',
    WebkitTapHighlightColor: 'transparent',
  },
  body: {
    padding: 0,
    margin: 0,
    fontFamily: '"Fira Sans", sans-serif',
    fontSize: '16px', // '1em',
    lineHeight: '24px', // '1.5em',
    color: COLORS.DARK,
    background: 'white',
  },
  a: {
    color: COLORS.BLUE,
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status: authStatus, username } = useAppSelector(selectAuthState);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      dispatch(autoLogin());
      setIsMounted(true);
    }
  }, [dispatch, isMounted]);

  if (!isMounted || authStatus === 'autoLogin') {
    return null;
  }

  return (
    <>
      {authStatus === 'loggedIn' ? (
        <APIErrorProvider>
          <ConfirmDialogProvider>
            <PageHeader loggedIn username={username} onClickLogout={() => dispatch(logout())} />
            <PageContent paddingTop={SPACINGS.XL}>
              <Routes>
                <Route path="/" element={<Newspapers />} />
                <Route path="/newspapers" element={<Newspapers />} />
                <Route path="/newspaper/:sNewspaperId/*" element={<Newspaper viewMode="not-published" />} />
                <Route path="/newspaper/:sNewspaperId/archive/*" element={<Newspaper viewMode="published" />} />
                <Route path="/issue/:sIssueId/*" element={<Issue />} />
                <Route path="/story/:sStoryId/*" element={<Story />} />
                <Route path="/article/:sArticleId/*" element={<Article />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/tags/:sTag" element={<Tags />} />
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </PageContent>
            <Overlay>
              <Routes>
                <Route path="/newspaper/:sNewspaperId/create/issue" element={<NewspaperIssueCreate />} />
                <Route path="/issue/:sIssueId/edit" element={<IssueEdit />} />
                <Route path="/issue/:sIssueId/create/pages" element={<IssuePagesCreate />} />
                <Route path="/issue/:sIssueId/page/:sPageId/edit" element={<IssuePageEdit />} />
                <Route path="/story/:sStoryId/edit" element={<StoryEdit />} />
                <Route path="/story/:sStoryId/create/article" element={<StoryCreateArticle />} />
                <Route path="/article/:sArticleId/edit" element={<ArticleEdit />} />
                <Route path="/article/:sArticleId/view" element={<ArticleView />} />
                <Route path="/article/:sArticleId/add/asset" element={<AssetAdd />} />
                <Route path="/article/:sArticleId/asset/:sAssetId/create" element={<AssetEdit viewMode="create" />} />
                <Route path="/article/:sArticleId/asset/:sAssetId/edit" element={<AssetEdit viewMode="edit" />} />
                <Route path="/article/:sArticleId/asset/:sAssetId/view" element={<AssetView />} />
                <Route path="/article/:sArticleId/add/snippet" element={<SnippetAdd />} />
                <Route path="/article/:sArticleId/import" element={<ArticleImport />} />
                <Route
                  path="/article/:sArticleId/snippet/:sSnippetId/create"
                  element={<SnippetEdit viewMode="create" />}
                />
                <Route path="/article/:sArticleId/snippet/:sSnippetId/edit" element={<SnippetEdit viewMode="edit" />} />
                <Route path="/article/:sArticleId/snippet/:sSnippetId/view" element={<SnippetView />} />
                <Route path="*" element={<div />} />
              </Routes>
            </Overlay>
            <ConfirmDialog />
          </ConfirmDialogProvider>
          <APIErrorNotifications />
        </APIErrorProvider>
      ) : (
        <PageContent paddingTop={SPACINGS.XL} paddingBottom={SPACINGS.XL}>
          <LoginForm />
        </PageContent>
      )}

      <GlobalStyle />
    </>
  );
};

export default App;
