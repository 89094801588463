import React, { useState } from 'react';
import { Group } from '@webfox-sc/core';
import FormSection from '../shared/forms/FormSection';
import { useAppDispatch } from '../../app/hooks';
import { useEffect } from 'react';
import { fetchRessorts } from '../../app/slices/ressortsSlice';
import { useRessorts } from './useRessorts';
import CustomCheckbox from '../shared/atoms/CustomCheckbox';
import { SPACINGS } from '../../styles/theme';
import useAPIError from '../apiError/useAPIError';

interface FormSectionTagsProps {
  paddingTop?: string;
  defaultRessortIds?: number[];
  onChange?: (ressortIds: number[]) => void;
}

const FormSectionRessorts: React.FC<FormSectionTagsProps> = ({ paddingTop, defaultRessortIds, onChange }) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { ressortsSorted } = useRessorts();
  const [selectedRessortIds, setSelectedRessortIds] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      const response = await dispatch(fetchRessorts());
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    })();
  }, [addError, dispatch]);

  useEffect(() => {
    if (Array.isArray(defaultRessortIds)) {
      setSelectedRessortIds(defaultRessortIds);
    }
  }, [defaultRessortIds]);

  if (ressortsSorted.length === 0) {
    return null;
  }

  const handleOnChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, ressortId: number) => {
    const { checked } = e.target;

    let selectedRessortIdsNew = [...selectedRessortIds];
    if (checked) {
      selectedRessortIdsNew.push(ressortId);
    } else {
      selectedRessortIdsNew = selectedRessortIdsNew.filter((id) => id !== ressortId);
    }

    setSelectedRessortIds(selectedRessortIdsNew);
    if (typeof onChange === 'function') {
      onChange(selectedRessortIdsNew);
    }
  };

  return (
    <FormSection title="Ressorts" paddingTop={paddingTop}>
      <Group hSpacing={SPACINGS.S} vSpacing={SPACINGS.XXS}>
        {ressortsSorted.map((ressort) => {
          return (
            <CustomCheckbox
              key={ressort.id}
              label={ressort.name}
              value={ressort.id}
              checked={selectedRessortIds.includes(ressort.id)}
              onChange={(e) => handleOnChangeCheckbox(e, ressort.id)}
            />
          );
        })}
      </Group>
    </FormSection>
  );
};

export default FormSectionRessorts;
