import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useIssue } from './useIssue';

export const useIssueArticles = (
  issueId: number | undefined
): {
  unassignedArticles?: ArticleEntity[];
} => {
  const { articleIsWithinIssueDate } = useIssue(issueId);
  const articles = entitySelectors.selectByFilter<ArticleEntity>('articles', (article) => !article.pageId);

  const unassignedArticles = useMemo(() => {
    return articles.filter((article) => articleIsWithinIssueDate(article));
  }, [articleIsWithinIssueDate, articles]);

  return {
    unassignedArticles,
  };
};
