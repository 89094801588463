import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../shared/forms/FormSection';
import CustomAsyncSelect from '../shared/select/CustomAsyncSelect';
import { useAuthor } from './useAuthor';

type AuthorOption = {
  value: number;
  label?: string;
};

interface FormSectionAuthorProps {
  paddingTop?: string;
  defaultAuthorId?: number;
  onChange?: (authorId: number) => void;
}

const FormSectionAuthor: React.FC<FormSectionAuthorProps> = ({ paddingTop, defaultAuthorId, onChange }) => {
  const { author } = useAuthor(defaultAuthorId);

  const defaultAuthorOption: AuthorOption | undefined = useMemo(() => {
    return author
      ? {
          value: author.id,
          label: author.displayName,
        }
      : undefined;
  }, [author]);

  const [authorOption, setAuthorOption] = useState(defaultAuthorOption);

  useEffect(() => {
    setAuthorOption(defaultAuthorOption);
  }, [defaultAuthorOption]);

  const handleChangeAuthorOption = (authorOptionNew: AuthorOption) => {
    setAuthorOption(authorOptionNew);
    if (typeof onChange === 'function') {
      onChange(authorOptionNew?.value);
    }
  };

  return (
    <FormSection title="Autor:in" paddingTop={paddingTop}>
      <CustomAsyncSelect
        asyncPath="/authors"
        asyncSort={['first_name:asc', 'last_name:asc']}
        setAsyncParams={(inputValue, params) => {
          params.filters = {
            $or: [
              {
                first_name: {
                  $containsi: inputValue,
                },
              },
              {
                first_name: {
                  $containsi: inputValue,
                },
              },
            ],
          };
        }}
        value={authorOption}
        placeholder="Suchen ..."
        formatOption={(entry) => ({
          value: entry.id,
          label: `${entry.first_name || ''} ${entry.last_name || ''}`.trim(),
        })}
        isMulti={false}
        isClearable={true}
        isDropdownIndicator={false}
        onChange={handleChangeAuthorOption}
      />
    </FormSection>
  );
};

export default FormSectionAuthor;
