import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';

interface StyledPanelProps {
  width?: string;
  height?: string;
  padding?: string;
  background?: string;
}

const StyledPanel = styled.div<StyledPanelProps>(({ width, height, padding, background }) => {
  return {
    boxSizing: 'border-box',
    width,
    height,
    padding,
    background: background || COLORS.SILVER,
  };
});

interface PanelProps {
  variant?: 'status-panel';
  padding?: string;
  background?: string;
  verticalStretch?: boolean;
}

const Panel: React.FC<PanelProps> = ({ variant, padding, background, verticalStretch, children }) => {
  return (
    <StyledPanel
      padding={padding}
      background={background}
      width={variant === 'status-panel' ? '264px' : undefined}
      height={verticalStretch ? '100%' : undefined}
    >
      {children}
    </StyledPanel>
  );
};

export default Panel;
