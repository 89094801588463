import React, { useEffect, useState } from 'react';
import FormSection from '../shared/forms/FormSection';
import { getStatusOption, StatusOption } from './statusUtils';
import SingleSelect from '../shared/select/SingleSelect';
import { useStatusSelect } from './useStatusSelect';

interface FormSectionStatusProps {
  paddingTop?: string;
  statusSelection: EntityStatus[];
  defaultStatus?: EntityStatus;
  onChange?: (status: EntityStatus) => void;
}

const FormSectionStatus: React.FC<FormSectionStatusProps> = ({
  paddingTop,
  statusSelection,
  defaultStatus,
  onChange,
}) => {
  const { statusOptions } = useStatusSelect(statusSelection);
  const [statusOption, setStatusOption] = useState(getStatusOption(defaultStatus));

  useEffect(() => {
    setStatusOption(getStatusOption(defaultStatus));
  }, [defaultStatus]);

  const handleChangeStatusOption = (statusOptionNew: StatusOption) => {
    setStatusOption(statusOptionNew);
    if (typeof onChange === 'function') {
      onChange(statusOptionNew?.value || null);
    }
  };

  return (
    <FormSection title="Status" paddingTop={paddingTop}>
      <SingleSelect
        options={statusOptions}
        defaultValue={statusOption}
        value={statusOption}
        placeholder="Auswählen"
        onChange={handleChangeStatusOption}
        isClearable={false}
      />
    </FormSection>
  );
};

export default FormSectionStatus;
