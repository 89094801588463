import React from 'react';
import styled from 'styled-components';
import { COLORS, STYLES } from '../../../styles/theme';

type HeadlineVariant = 'H1' | 'H2';

interface StyledHeadlineProps {
  variant?: HeadlineVariant;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const StyledHeadline = styled.div<StyledHeadlineProps>(({ variant, color, textAlign }) => {
  const baseStyle = {
    fontFamily: STYLES.FONT_FAMILY,
    fontWeight: 400,
    textAlign,
    color: color || COLORS.DARK,
    margin: 0,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    a: {
      color: COLORS.BLUE,
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.8,
      },
    },
  };

  switch (variant) {
    case 'H1':
      return {
        ...baseStyle,
        fontSize: '32px', // '2em'
        lineHeight: '48px', // '1.5em',
      };

    case 'H2':
      return {
        ...baseStyle,
        fontSize: '24px', // '1.5em',
        lineHeight: '32px', // '1.333333em',
      };

    /**
     * return H2 style as default
     */
    default:
      return {
        ...baseStyle,
        fontSize: '24px', // '1.5em',
        lineHeight: '32px', // '1.333333em',
      };
  }
});

interface HeadlineProps {
  variant?: HeadlineVariant;
  as?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const Headline: React.FC<HeadlineProps> = ({ variant, as, color, textAlign, children }) => {
  return (
    <StyledHeadline variant={variant} color={color} textAlign={textAlign} as={as}>
      {children}
    </StyledHeadline>
  );
};

export default Headline;
