import _orderBy from 'lodash/orderBy';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';

export const useTags = (
  tagIds?: number[]
): {
  tagsSorted: TagEntity[];
} => {
  const tagsSorted: TagEntity[] = useAppSelector(({ entities }) => {
    const tags: TagEntity[] = [];
    (tagIds || []).forEach((tagId) => {
      const tag = entities.tags.entities[tagId];
      if (tag) {
        tags.push(tag);
      }
    });
    return _orderBy(tags, [(tag) => tag.name?.toLowerCase()], ['asc']);
  }, shallowEqual);

  return {
    tagsSorted,
  };
};
