import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Block, Group, GroupItem, InlineSVG } from '@webfox-sc/core';
import { IconCheck, IconDelete, IconMagnify } from '@webfox-sc/icons';
import { COLORS, STYLES } from '../../../styles/theme';
import Text from './Text';
import FormButton from './FormButton';

type InputVariant = 'small-search' | undefined;

interface StyledControlProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

const StyledControl = styled.div<StyledControlProps>(({ width, error, isFocus }): CSSObject => {
  return {
    boxSizing: 'border-box',
    width,
    border: `2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: '3px',
    background: COLORS.WHITE,
    transition: 'border-color 0.2s ease',
  };
});

interface StyledInputProps {
  variant?: InputVariant;
  textAlign?: 'left' | 'center' | 'right';
}

const StyledInput = styled.input<StyledInputProps>(({ variant, textAlign }): CSSObject => {
  return {
    boxSizing: 'border-box',
    width: '100%',
    height: variant === 'small-search' ? '36px' : '60px',
    padding: variant === 'small-search' ? '0 10px 0 0' : '0 14px',
    border: 0,
    fontFamily: STYLES.FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '24px',
    color: COLORS.DARK,
    textAlign,
    background: COLORS.WHITE,
    filter: 'none', // disable autofill styles for Firefox
    '&::placeholder': {
      fontFamily: STYLES.FONT_FAMILY,
      fontStyle: 'italic',
      color: COLORS.GREY,
    },
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': COLORS.DARK,
      '-webkit-box-shadow': '0 0 0px 1000px #fff inset',
    },
    '&:focus': {
      outline: 0,
    },
  };
});

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariant;

  width?: string | 0;
  textAlign?: 'left' | 'center' | 'right';
  error?: boolean;
  indentLabel?: string;
  indentComponent?: React.ReactNode;
  showSubmitButton?: boolean;
  showRemoveButton?: boolean;
  onClickSubmitButton?: React.MouseEventHandler;
  onClickRemoveButton?: React.MouseEventHandler;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const Input: React.FC<InputProps> = ({
  variant,
  value,
  width,
  textAlign,
  error,
  indentLabel,
  indentComponent,
  showSubmitButton,
  showRemoveButton,
  onClickSubmitButton,
  onClickRemoveButton,
  onFocus,
  onBlur,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  return (
    <StyledControl width={width} error={error} isFocus={isFocus}>
      <Group nowrap>
        {indentLabel && (
          <GroupItem>
            <Block
              height="100%"
              padding="19px 14px 17px 14px"
              borderRight={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
            >
              <Text variant="label-small">{indentLabel}</Text>
            </Block>
          </GroupItem>
        )}
        {indentComponent && (
          <GroupItem>
            <Block
              height="100%"
              borderRight={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
            >
              {indentComponent}
            </Block>
          </GroupItem>
        )}
        {variant === 'small-search' && (
          <GroupItem>
            <Block width="36px" height="36px" justify="center" alignItems="center">
              <InlineSVG width="24px" height="24px" color={COLORS.GREY}>
                {IconMagnify}
              </InlineSVG>
            </Block>
          </GroupItem>
        )}
        <GroupItem grow>
          <StyledInput
            variant={variant}
            value={value}
            textAlign={textAlign}
            onFocus={(e) => handleOnFocus(e)}
            onBlur={(e) => handleOnBlur(e)}
            {...props}
          />
        </GroupItem>
        {showSubmitButton && (
          <GroupItem>
            <Block
              height="100%"
              borderLeft={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
            >
              <FormButton
                ariaLabel="check"
                icon={IconCheck}
                disabled={value === undefined || value === ''}
                onClick={onClickSubmitButton}
              />
            </Block>
          </GroupItem>
        )}
        {showRemoveButton && (
          <GroupItem>
            <Block
              height="100%"
              borderLeft={`2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`}
            >
              <FormButton ariaLabel="delete" icon={IconDelete} onClick={onClickRemoveButton} />
            </Block>
          </GroupItem>
        )}
      </Group>
    </StyledControl>
  );
};

export default Input;
