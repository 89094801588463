/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLORS, STYLES } from '../../../styles/theme';

const selectStyles = {
  control: (base: any, { isFocused }) => {
    return {
      ...base,
      appearance: 'none',
      background: COLORS.WHITE,
      boxSizing: 'border-box',
      width: '100%',
      minHeight: '64px',
      padding: 0,
      border: `2px solid ${isFocused ? COLORS.GREY : COLORS.MID_GREY}`,
      borderRadius: '3px',
      boxShadow: 'none',
      cursor: 'pointer',
      transition: 'border-color 0.2s ease',
      '&:hover': {
        border: `2px solid ${isFocused ? COLORS.GREY : COLORS.MID_GREY}`,
      },
    };
  },
  valueContainer: (base: any) => {
    return {
      ...base,
      padding: '10px',
    };
  },
  placeholder: (base: any) => {
    return {
      ...base,
      margin: '4px 6px',
      fontFamily: STYLES.FONT_FAMILY,
      fontStyle: 'italic',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '5px 0 3px 0',
      color: COLORS.GREY,
    };
  },
  input: (base: any) => {
    return {
      ...base,
      height: '32px',
      margin: '4px 6px',
      background: 'transparent',
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '5px 0 3px 0',
      color: COLORS.DARK,
    };
  },
  singleValue: (base: any) => {
    return {
      ...base,
      //   padding: 0,
      padding: '5px 0 3px 0',
      paddingLeft: 0,
      paddingRight: 0,
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '16px',
      lineHeight: '24px',

      color: COLORS.DARK,
    };
  },
  multiValue: (base: any) => {
    return {
      ...base,
      padding: 0,
      margin: '4px',
      borderRadius: '3px',
      background: COLORS.DARK,
      overflow: 'hidden',
    };
  },
  multiValueLabel: (base: any) => {
    return {
      ...base,
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: '.05em',
      color: COLORS.WHITE,
      padding: '5px 8px 3px 8px',
      paddingLeft: '8px',
      margin: 0,
      borderRadius: 0,
    };
  },
  multiValueRemove: (base: any) => {
    return {
      ...base,
      padding: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: '32px',
      height: '32px',
      borderRadius: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        width: '20px',
        height: '20px',
        verticalAlign: 'top',
        color: COLORS.WHITE,
      },
      '&:hover': {
        background: 'rgba(255,255,255,0.2)',
      },
    };
  },
  indicatorsContainer: (base: any) => {
    return {
      ...base,
      padding: 0,
      margin: 0,
      alignItems: 'stretch',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  loadingIndicator: () => {
    return {
      display: 'none',
    };
  },
  clearIndicator: (base: any) => {
    return {
      ...base,
      width: '60px',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      alignSelf: 'stretch',
      svg: {
        width: '24px',
        height: '24px',
        verticalAlign: 'top',
        color: COLORS.DARK,
      },
      '&:hover': {
        opacity: 0.8,
      },
    };
  },
  dropdownIndicator: (base: any, { isFocused }) => {
    return {
      ...base,
      boxSizing: 'content-box',
      width: '60px',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      borderLeft: `2px solid ${isFocused ? COLORS.GREY : COLORS.MID_GREY}`,
      svg: {
        width: '24px',
        height: '24px',
        verticalAlign: 'top',
        color: COLORS.DARK,
      },
      '&:hover': {
        svg: {
          opacity: 0.8,
        },
      },
    };
  },
  menu: (base: any) => {
    return {
      ...base,
      marginTop: '4px',
      marginBottom: '4px',
      padding: 0,
      borderRadius: '3px',
      border: `2px solid ${COLORS.MID_GREY}`,
      boxShadow: 'none',
    };
  },
  menuList: (base: any) => {
    return {
      ...base,
      padding: 0,
    };
  },
  option: (base: any, { isSelected, isFocused }: any) => {
    return {
      ...base,
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '5px 14px 3px 14px',
      color: isSelected ? COLORS.GREY : COLORS.DARK,
      backgroundColor: isFocused ? COLORS.SILVER : COLORS.WHITE,
      cursor: 'pointer',
    };
  },
};

export default selectStyles;
