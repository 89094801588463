import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const authorsAdapter = createEntityAdapter<AuthorEntity>();

export const authorsSlice = createSlice({
  name: 'authors',
  initialState: authorsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.authors) {
        authorsAdapter.upsertMany(state, action.payload.entities.authors);
      }
    });
  },
});

export default authorsSlice.reducer;
