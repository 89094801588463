import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { COLORS, SPACINGS, STYLES } from '../../../styles/theme';
import { convertNewlinesToHTML } from '../../../utils/format';

type TextVariant = 'small' | 'tiny' | 'label-small' | 'label-tiny';
type TextAlign = 'left' | 'center' | 'right';

interface StyledTextProps {
  variant?: TextVariant;
  color?: string;
  textAlign?: TextAlign;
  wordBreak?: boolean;
  nowrap?: boolean;
  fontWeight?: number;
}

const StyledText = styled.div<StyledTextProps>(({ variant, color, textAlign, wordBreak, nowrap, fontWeight }) => {
  const baseStyle: CSSObject = {
    fontFamily: STYLES.FONT_FAMILY,
    fontWeight: fontWeight || 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'inherit',
    color: color || COLORS.DARK,
    textAlign,
    overflowWrap: wordBreak ? 'break-word' : undefined,
    wordWrap: wordBreak ? 'break-word' : undefined,
    wordBreak: wordBreak ? 'break-all' : undefined,
    whiteSpace: nowrap ? 'nowrap' : undefined,
    a: {
      color: COLORS.BLUE,
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.8,
      },
    },
    table: {
      border: `1px solid ${COLORS.GREY}`,
      borderCollapse: 'collapse',
    },
    td: {
      border: `1px solid ${COLORS.GREY}`,
      padding: SPACINGS.XXXS,
    },
    th: {
      border: `1px solid ${COLORS.GREY}`,
      padding: SPACINGS.XXXS,
    },
    del: {
      color: COLORS.RED,
    },
    ins: {
      color: COLORS.GREEN,
    },
  };

  switch (variant) {
    case 'small':
      return {
        ...baseStyle,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '.01em',
      };

    case 'tiny':
      return {
        ...baseStyle,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '.01em',
      };

    case 'label-small':
      return {
        ...baseStyle,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '.06em',
        textTransform: 'uppercase',
        color: color || COLORS.GREY,
      };

    case 'label-tiny':
      return {
        ...baseStyle,
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '.06em',
        textTransform: 'uppercase',
        color: color || COLORS.GREY,
      };

    default:
      return baseStyle;
  }
});

interface TextProps {
  variant?: TextVariant;
  as?: 'div' | 'p' | 'span';
  color?: string;
  textAlign?: TextAlign;
  wordBreak?: boolean;
  nl2br?: boolean;
  asHTML?: boolean;
  nowrap?: boolean;
  fontWeight?: number;
}

const Text: React.FC<TextProps> = ({
  variant,
  as,
  color,
  textAlign,
  wordBreak,
  nl2br,
  asHTML,
  nowrap,
  fontWeight,
  children,
}) => {
  const styledTextProps = {
    variant,
    as,
    color,
    textAlign,
    wordBreak,
    nowrap,
    fontWeight,
  };

  if ((nl2br || asHTML) && typeof children === 'string') {
    return (
      <StyledText
        {...styledTextProps}
        dangerouslySetInnerHTML={{ __html: nl2br ? convertNewlinesToHTML(children) : children }}
      />
    );
  }

  return <StyledText {...styledTextProps}>{children}</StyledText>;
};

export default Text;
