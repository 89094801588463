import { COLORS, SPACINGS } from '../../styles/theme';
import styled from 'styled-components';

export interface TextSectionProps {
  $disabled?: boolean;
  withPadding?: boolean;
}

export const TextSection = styled.div<TextSectionProps>(({ $disabled, withPadding }) => ({
  backgroundColor: $disabled ? COLORS.SILVER : COLORS.WHITE,
  width: '100%',
  display: 'block',
  height: 'calc(100vh - 386px)',
  boxSizing: 'border-box',
  padding: withPadding ? `${SPACINGS.M} ${SPACINGS.S}` : 0,
  overflowY: 'auto',
}));
