import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconEye, IconPencil } from '@webfox-sc/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useAuthors } from '../authors/useAuthors';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Headline from '../shared/atoms/Headline';
import Inline from '../shared/atoms/Inline';
import Text from '../shared/atoms/Text';
import TextLink from '../shared/atoms/TextLink';
import MetaTable from '../shared/molecules/MetaTable';
import MetaTableItem from '../shared/molecules/MetaTableItem';
import StatusPanel from '../status/StatusPanel';
import TagGroup from '../tags/TagGroup';
import { useUsers } from '../users/useUsers';
import { useArticle } from './useArticle';
import { useArticleAuthors } from './useArticleAuthors';
import { useArticleSize } from './useArticleSize';

interface ArticleMetaProps {
  articleId?: number;
}

const ArticleMeta: React.FC<ArticleMetaProps> = ({ articleId }) => {
  const navigate = useNavigate();

  const { article, headlines, story, ressort, contentType } = useArticle(articleId);
  const { authorIds } = useArticleAuthors(articleId);
  const { authorsSortedFormatted } = useAuthors(authorIds);
  const { usersSortedFormatted } = useUsers(article?.ownerIds || []);
  const { charCount, lineCount } = useArticleSize(articleId);

  if (!article) {
    return null;
  }

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${articleId}`);
  };

  const handleOnClickView = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${articleId}/view`);
  };

  const handleOnClickEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${articleId}/edit`);
  };

  return (
    <Group nowrap spacing={SPACINGS.XXL}>
      <GroupItem grow>
        <Headline variant="H1">
          <a href={`/article/${articleId}`} onClick={handleOnClickLink}>
            {article.name}
          </a>
        </Headline>

        <Block paddingTop={SPACINGS.S}>
          <Block paddingBottom={SPACINGS.XXS}>
            <Text color={COLORS.GREY}>Überschriften</Text>
          </Block>
          {headlines.length > 0 ? (
            <>
              {headlines.map((headline) => {
                return <Text key={headline.id}>{headline.text}</Text>;
              })}
            </>
          ) : (
            <Text>
              <em>Keine Überschriften vorhanden</em>
            </Text>
          )}
        </Block>

        {article.abstract && (
          <Block paddingTop={SPACINGS.S}>
            <Text color={COLORS.GREY}>Abstract</Text>
            <Block paddingTop={SPACINGS.XXS}>
              <Text nl2br>{article.abstract}</Text>
            </Block>
          </Block>
        )}

        <Block paddingTop={SPACINGS.L}>
          <MetaTable showDecoLine>
            <MetaTableItem label="Autor:innen">
              <Text>
                {authorsSortedFormatted ? (
                  authorsSortedFormatted
                ) : (
                  <Inline color={COLORS.GREY}>
                    <em>Keine Autor:innen</em>
                  </Inline>
                )}
              </Text>
            </MetaTableItem>
            <MetaTableItem label="Ressort">
              <Text>
                {ressort?.name ? (
                  ressort.name
                ) : (
                  <Inline color={COLORS.GREY}>
                    <em>Kein Ressort</em>
                  </Inline>
                )}
              </Text>
            </MetaTableItem>
            <MetaTableItem label="Stiltyp">
              <Text>
                {contentType?.name ? (
                  contentType.name
                ) : (
                  <Inline color={COLORS.GREY}>
                    <em>Kein Stiltyp</em>
                  </Inline>
                )}
              </Text>
            </MetaTableItem>
            {article.tagIds.length > 0 && (
              <MetaTableItem label="Hashtags">
                <TagGroup tagIds={article.tagIds} />
              </MetaTableItem>
            )}
            {story && (
              <MetaTableItem label="Verknüpfte Story">
                <TextLink to={`/story/${story.id}`}>{story.name}</TextLink>
              </MetaTableItem>
            )}
            <MetaTableItem label="Rang">
              <Text>{article.rank}</Text>
            </MetaTableItem>
            <MetaTableItem label="Besitzer:innen">
              <Text>
                {usersSortedFormatted ? (
                  usersSortedFormatted
                ) : (
                  <Inline color={COLORS.GREY}>
                    <em>Keine Besitzer:innen</em>
                  </Inline>
                )}
              </Text>
            </MetaTableItem>
          </MetaTable>
        </Block>
      </GroupItem>

      <Group nowrap spacing={SPACINGS.XXS}>
        <GroupItem>
          <StatusPanel
            showStatusLabel
            status={article.status}
            createdTime={article.createdTime}
            createdByUserId={article.createdByUserId}
            updatedTime={article.updatedTime}
            updatedByUserId={article.updatedByUserId}
            showPublicationItem
            publicationTime={article.publicationTime}
            showExpirationItem
            expirationTime={article.expirationTime}
            showSnippetItems
            snippetLines={lineCount}
            snippetCharacters={charCount}
          />
        </GroupItem>

        <GroupItem>
          <Group vertical spacing={SPACINGS.XXS}>
            <ButtonPrimary ariaLabel="Ansehen" icon={IconEye} onClick={handleOnClickView} />
            <ButtonPrimary ariaLabel="Bearbeiten" icon={IconPencil} onClick={handleOnClickEdit} />
          </Group>
        </GroupItem>
      </Group>
    </Group>
  );
};

export default ArticleMeta;
