import React, { useEffect, useState } from 'react';
import useAPIError from './useAPIError';
import styled from 'styled-components';
import { COLORS } from '../../styles/theme';
import Text from '../shared/atoms/Text';
import { APIError } from './APIErrorProvider';

const TOAST_DURATION = 6; // seconds
const TRANSITION_DURATION = 0.5;

interface ToastProps {
  $opacity?: number;
}

const Toast = styled.div<ToastProps>(({ $opacity }) => ({
  borderRadius: '3px',
  background: COLORS.RED,
  padding: '5px 16px 3px',
  opacity: $opacity,
  marginBottom: '5px',
  transition: `${TRANSITION_DURATION}s opacity ease-out`,
}));

interface APIErrorNotificationProps {
  error: APIError;
}

const APIErrorNotification: React.FC<APIErrorNotificationProps> = ({ error }) => {
  const { removeError } = useAPIError();
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setOpacity(1);
    setTimeout(() => setOpacity(0), (TOAST_DURATION - TRANSITION_DURATION) * 1000);
    setTimeout(() => removeError(error.id), TOAST_DURATION * 1000);
  }, [error.id, removeError]);

  return (
    <Toast $opacity={opacity}>
      <Text variant="small" color={COLORS.WHITE}>
        {error.message}
      </Text>
    </Toast>
  );
};

export default APIErrorNotification;
