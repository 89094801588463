import { Block, Collapse, Group, GroupItem, Radio } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { fetchPrompts } from '../../app/slices/promptsSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import { stripMarkup } from '../../utils/article';
import MarkdownEditor from '../markdownEditor/MarkdownEditor';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import CustomRadioGroup from '../shared/atoms/CustomRadioGroup';
import Headline from '../shared/atoms/Headline';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';
import FormSection from '../shared/forms/FormSection';
import SingleSelect from '../shared/select/SingleSelect';
import SnippetAITextTransformTextSection from './SnippetAITextTransformTextSection';
import useEditorContent from './useEditorContent';
import { usePrompts } from './usePrompts';

interface SnippetAITextTransformProps {
  textContent?: string;
  versionComment?: string;
  onClickSave?: (text: string) => void;
  onClickSaveAsClone?: (text: string, comment: string) => void;
  onClickCancel?: () => void;
}

const SnippetAITextTransform: React.FC<SnippetAITextTransformProps> = ({
  textContent,
  versionComment,
  onClickSave,
  onClickSaveAsClone,
  onClickCancel,
}) => {
  const dispatch = useAppDispatch();
  const { prompts, promptOptions, performPrompt } = usePrompts();

  const [selectedPromptOption, setSelectedPromptOption] = useState<typeof promptOptions[number] | undefined>(undefined);
  const [transformedTextPromptName, setTransformedTextPromptName] = useState('');
  const [comparisonMode, setComparisonMode] = useState<string>('off');
  const [isInProgress, setIsInProgress] = useState(false);
  const [comment, setComment] = useState('');
  const [isCommentError, setIsCommentError] = useState(false);

  const { editorInput, editorContent, handleOnEditorChange } = useEditorContent(undefined);

  useEffect(() => {
    (async () => {
      if (!prompts.length) {
        await dispatch(fetchPrompts());
      }
    })();
  }, [dispatch, prompts]);

  const handleOnClickConvert = async () => {
    if (selectedPromptOption) {
      setIsInProgress(true);
      const result = await performPrompt(selectedPromptOption.value, textContent || '');
      if (result !== undefined) {
        setTransformedTextPromptName(selectedPromptOption.label);
        handleOnEditorChange(result);
      }
      setIsInProgress(false);
    }
  };

  return (
    <>
      <Group nowrap justify="space-between">
        <Headline>AI Editor</Headline>
        <ButtonSecondary label="Abbrechen" onClick={() => onClickCancel?.()} />
      </Group>

      <Group justify="space-between" paddingTop={SPACINGS.M} hSpacing="15px">
        <GroupItem width="50%">
          <FormSection title="Textkonvertierung">
            <SingleSelect
              options={promptOptions}
              value={selectedPromptOption}
              onChange={(value) => setSelectedPromptOption(value)}
              placeholder="Auswählen..."
            />
            <Block paddingTop="xxs">
              <ButtonPrimary
                label="Konvertieren"
                disabled={!selectedPromptOption || isInProgress}
                onClick={handleOnClickConvert}
              />
            </Block>
          </FormSection>
        </GroupItem>

        <GroupItem>
          <Group alignItems="center" hSpacing="20px" paddingTop="36px">
            <Text variant="label-small">Vergleich</Text>
            <CustomRadioGroup
              spacing={SPACINGS.S}
              value={comparisonMode}
              onChange={(value) => {
                setComparisonMode(`${value}`);
              }}
            >
              <Radio
                label="An"
                value="compare"
                name="snippet-text-transform-comparison"
                disabled={editorContent === undefined}
              />
              <Radio
                label="Unterschiede"
                value="diff"
                name="snippet-text-transform-comparison"
                disabled={editorContent === undefined}
              />
              <Radio label="Aus" value="off" name="snippet-text-transform-comparison" />
            </CustomRadioGroup>
          </Group>
        </GroupItem>
      </Group>

      <Group paddingTop={SPACINGS.S} nowrap hSpacing="10px">
        {editorContent !== undefined && (
          <GroupItem width={comparisonMode !== 'off' ? '50%' : '100%'}>
            <MarkdownEditor markdownContent={editorInput} onChange={handleOnEditorChange}>
              <>
                <Text color={COLORS.GREY} fontWeight={600}>
                  Preview
                </Text>
                {transformedTextPromptName && <Text>{transformedTextPromptName}</Text>}
              </>
            </MarkdownEditor>
          </GroupItem>
        )}

        {(editorContent === undefined || comparisonMode !== 'off') && (
          <GroupItem width={comparisonMode !== 'off' ? '50%' : '100%'}>
            <SnippetAITextTransformTextSection
              text={comparisonMode === 'diff' ? editorContent : textContent}
              oldText={comparisonMode === 'diff' ? textContent : undefined}
              isInProgress={comparisonMode !== 'off' && isInProgress}
              title={comparisonMode === 'diff' ? 'Unterschiede' : 'Original'}
              subtitle={versionComment}
              disabled={comparisonMode !== 'off'}
            />
          </GroupItem>
        )}
      </Group>

      {editorContent !== undefined && !isInProgress && (
        <>
          <Block paddingTop={SPACINGS.S}>
            <ButtonPrimary label="Preview übernehmen" onClick={() => onClickSave?.(stripMarkup(editorContent))} />
          </Block>

          <Block paddingTop={SPACINGS.XL}>
            <Headline>Preview als neue Textelementversion anlegen</Headline>
          </Block>

          <FormSection title="Versionskommentar" paddingTop={SPACINGS.XS}>
            <Input
              name="comment"
              autoComplete="ignore"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setIsCommentError(false);
              }}
            />
            <Collapse show={isCommentError}>
              <Block paddingTop={SPACINGS.XS}>
                <Text variant="small" color={COLORS.RED}>
                  Dies ist ein Pflichtfeld.
                </Text>
              </Block>
            </Collapse>
          </FormSection>

          <Block paddingTop={SPACINGS.S}>
            <ButtonPrimary
              label="Speichern"
              onClick={() => {
                if (!comment.trim()) {
                  setIsCommentError(true);
                } else {
                  onClickSaveAsClone?.(stripMarkup(editorContent), comment.trim());
                }
              }}
            />
          </Block>
        </>
      )}
    </>
  );
};

export default SnippetAITextTransform;
