/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button } from '@webfox-sc/core';
import { COLORS } from '../../../styles/theme';

type FormButtonVariant = 'smallWithBorder' | undefined;
interface ThemeProps {
  variant?: FormButtonVariant;
}

const theme = ({ variant }: ThemeProps) => {
  return {
    root: {
      color: COLORS.DARK,
      textTransform: 'uppercase',
      padding: '0',
      border: variant === 'smallWithBorder' ? `2px solid ${COLORS.MID_GREY}` : 0,
      borderRadius: variant === 'smallWithBorder' ? '3px' : 0,
      background: COLORS.WHITE,
      transition: 'opacity 0.2s ease',
      svg: {
        width: '24px',
        maxWidth: 'none',
        height: '24px',
      },
      '&[data-with-icon-only]': {
        width: variant === 'smallWithBorder' ? '40px' : '60px',
        height: variant === 'smallWithBorder' ? '40px' : '60px',
        minWidth: 0,
      },
      '&:hover': {
        opacity: 0.8,
      },
      '&:active': {
        opacity: 1,
      },
      '&[data-disabled]': {
        opacity: 0.3,
      },
    },
  };
};

export interface FormButtonProps {
  variant?: FormButtonVariant;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel: string;
  icon: any;
  disabled?: boolean;
  href?: string;
  target?: string;
  onClick?: React.MouseEventHandler;
}

const FormButton: React.FC<FormButtonProps> = ({ variant, ...props }) => {
  return <Button {...props} theme={theme({ variant })} />;
};

export default FormButton;
