import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const usersAdapter = createEntityAdapter<UserEntity>();

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.users) {
        usersAdapter.upsertMany(state, action.payload.entities.users);
      }
    });
  },
});

export default usersSlice.reducer;
