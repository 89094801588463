import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { snippetContentFormats } from './snippetContentFormats';

export const useCurrentSnippet = (): {
  snippetId?: number;
  snippet?: SnippetEntity;
  contentFormatLabel: string | undefined;
} => {
  const { sSnippetId } = useParams();
  const snippetId = parseInt(sSnippetId || '', 10);
  const snippet = useAppSelector(({ entities }) => entities.snippets.entities[snippetId]);
  const contentFormatLabel: string | undefined = snippetContentFormats[snippet?.contentFormat || ''] || undefined;

  return {
    snippetId,
    snippet,
    contentFormatLabel,
  };
};
