import React from 'react';
import { Link } from 'react-router-dom';
import Text from './Text';

interface TextLinkProps {
  to?: string;
  href?: string;
  target?: '_blank' | '_parent' | '_self' | '_top';
  replace?: boolean;
}

const TextLink: React.FC<TextLinkProps> = ({ to, href, target, children, replace }) => {
  if (to) {
    return (
      <Text>
        <Link to={to} target={target} replace={replace}>
          {children}
        </Link>
      </Text>
    );
  }

  if (href) {
    return (
      <Text>
        <a href={href} target={target}>
          {children}
        </a>
      </Text>
    );
  }

  return null;
};

export default TextLink;
