/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import Leaflet, { Control, LeafletMouseEvent } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-geocoder';

import styled, { CSSObject } from 'styled-components';
import { Block, Collapse, Group, GroupItem } from '@webfox-sc/core';
import { IconChevronUp } from '@webfox-sc/icons';
import FormSection from '../shared/forms/FormSection';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import Text from '../shared/atoms/Text';

import { IGeocoder } from 'leaflet-control-geocoder/dist/geocoders';
import { GeocodingResult } from 'leaflet-control-geocoder/src/geocoders/api';
import { COLORS } from '../../styles/theme';
import Inline from '../shared/atoms/Inline';
import FormButton from '../shared/atoms/FormButton';
import IconMapMarker from '../../assets/icons/IconMapMarker';

interface StyledControlProps {
  width?: string | 0;
  isFocus?: boolean;
}

const StyledControl = styled.div<StyledControlProps>(({ width, isFocus }): CSSObject => {
  return {
    boxSizing: 'border-box',
    width: width || '100%',
    height: '64px',
    border: `2px solid ${isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: isFocus ? '3px 3px 0 0' : '3px',
    background: COLORS.WHITE,
    transition: 'border-color 0.2s ease',
    cursor: 'pointer',
    overflow: 'hidden',
  };
});

interface FormSectionLocationProps {
  paddingTop?: string;
  location?: GeoLocation;
  locationName?: string;
  onLocationChange?: (location: GeoLocation) => void;
  onLocationNameChange?: (locationName: string) => void;
}

// Soltau Mundschenk Headquarter
const defaultLocation: GeoLocation = {
  lat: 52.99847906312073,
  lng: 9.836720291449279,
};

const defaultLocationName = 'Soltau, Heidekreis, Niedersachsen';

// the bigger this number is, the more details we get from our geocoder reverse search, like
// street names with house number, city districts etc. Very low numbers only lead to results like "Deutschland"
const geocoderSearchScale = 262144;

const FormSectionLocation: React.FC<FormSectionLocationProps> = ({
  paddingTop,
  location,
  locationName,
  onLocationChange,
  onLocationNameChange,
}) => {
  const [position, setPosition] = useState(location);
  const [locationNameState, setLocationNameState] = useState(locationName || '');
  const [map, setMap] = useState<Leaflet.Map | null>(null);
  const [showMap, setShowMap] = useState(false);

  // leaflet-control-geocoder extends Leaflet.Control which isn't recognized by typescript
  // thus we have to cast Control to "any" in order to access the Geocoder property
  // see https://github.com/perliedman/leaflet-control-geocoder/issues/333
  const geocoder = useRef<IGeocoder>((Control as any).Geocoder.nominatim());

  const getLocationNameByGeoLocation = useCallback(
    (geoLocation: GeoLocation): void => {
      if (geocoder.current.reverse) {
        geocoder.current.reverse(geoLocation, geocoderSearchScale, (results: GeocodingResult[]) => {
          const [result] = results;
          let newLocationName = result?.name || '';

          // the result of the reverse geocoder search is something like
          // - Dortmund, Nordrhein-Westfalen, Deutschland
          // - Berlin, 10967, Deutschland
          // - Kolkwitz, Spree-Neiße, Brandenburg, 03099, Deutschland
          // We want to get rid of the "Deutschland" (at least if we are in germany) and the zip code, if there's any
          newLocationName = newLocationName.replace(/, Deutschland$/, '');

          // HACK because of strange bug in Hamburg:
          // our search will only return the plz and "Deutschland", like
          // - 20457, Deutschland
          // - 22049, Deutschland
          // etc in all of Hamburg. If you search just a little outside of Hamburgs borders, then everything is ok again.
          // The (temporary?) solution is to look for a zip-code-only pattern (because "Deutschland" got already stripped above)
          // and only consider Hamburg specific zip codes starting with 2 and being followed by 0,1 or 2.
          // This bug was only found by chance and it might occur on other places as well.
          if (/^2[012]\d{3}$/.test(newLocationName)) {
            newLocationName = 'Hamburg';
          }

          // Same bug occurs in Leipzig ("Sachsen, 04347, Deutschland")
          // Consider Leipzig specific zip codes starting with 04 and being followed by 1,2 or 3.
          if (/^Sachsen, 04[123]\d\d$/.test(newLocationName)) {
            newLocationName = 'Leipzig, Sachsen';
          }

          // after handling the special cases, we are getting rid of the zip code
          newLocationName = newLocationName.replace(/, \d+$/, '');

          setLocationNameState(newLocationName);
          if (onLocationNameChange) {
            onLocationNameChange(newLocationName);
          }
        });
      }
    },
    [onLocationNameChange]
  );

  useEffect(() => {
    if (location) {
      setPosition(location);
      map?.setView(location);
      if (locationName) {
        setLocationNameState(locationName);
      } else {
        // There is a location but no location name -> this happens when assets with GPS Data where uploaded
        getLocationNameByGeoLocation(location);
      }
    } else {
      if (showMap) {
        // we only want to fill in the default location data when the map is extended. Otherwise it stays empty
        setPosition(defaultLocation);
        setLocationNameState(defaultLocationName);
        map?.setView(defaultLocation);
      }
    }
  }, [map, location, locationName, showMap, getLocationNameByGeoLocation]);

  const handleSetPosition = useCallback(
    (latLng: GeoLocation): void => {
      setPosition(latLng);
      if (onLocationChange) {
        onLocationChange(latLng);
      }
      getLocationNameByGeoLocation(latLng);
    },
    [getLocationNameByGeoLocation, onLocationChange]
  );

  const handleWhenMapCreated = (newMap: Leaflet.Map): void => {
    newMap.on('dblclick', (e: LeafletMouseEvent) => handleSetPosition(e.latlng));
    setMap(newMap);
  };

  const DraggableMarker = () => {
    const markerRef = useRef<Leaflet.Marker | null>(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            const latLng = marker.getLatLng();
            handleSetPosition(latLng);
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position || defaultLocation}
        ref={markerRef}
        icon={Leaflet.icon({
          iconSize: [25, 41],
          iconUrl: icon,
          shadowUrl: iconShadow,
          iconRetinaUrl: iconRetina,
          iconAnchor: [12.5, 41],
        })}
      />
    );
  };

  return (
    <FormSection title="Lokalisierung" paddingTop={paddingTop}>
      <StyledControl isFocus={showMap} onClick={() => setShowMap(!showMap)}>
        <Group>
          <GroupItem grow>
            <Block height="60px" padding="0 14px" alignItems="center">
              <Text>
                {locationNameState || '—'} <Inline color={COLORS.MID_GREY}>|</Inline>{' '}
                {position ? `LAT: ${position.lat.toFixed(7)}, LON: ${position.lng.toFixed(7)}` : '—'}
              </Text>
            </Block>
          </GroupItem>
          <GroupItem>
            <Block height="100%" borderLeft={`2px solid ${showMap ? COLORS.GREY : COLORS.MID_GREY}`}>
              <FormButton ariaLabel="check" icon={showMap ? IconChevronUp : IconMapMarker} />
            </Block>
          </GroupItem>
        </Group>
      </StyledControl>
      <Collapse show={showMap} duration={500}>
        <Block
          borderLeft={`2px solid ${COLORS.GREY}`}
          borderRight={`2px solid ${COLORS.GREY}`}
          borderBottom={`2px solid ${COLORS.GREY}`}
        >
          <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: '400px' }}
            whenCreated={handleWhenMapCreated}
            doubleClickZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker />
          </MapContainer>
        </Block>
      </Collapse>
    </FormSection>
  );
};

export default FormSectionLocation;
