import React, { useMemo } from 'react';
import { micromark } from 'micromark';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useSnippet } from '../snippet/useSnippet';
import Inline from '../shared/atoms/Inline';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { IconPencil } from '@webfox-sc/icons';

interface ArticleViewSnippetProps {
  snippetId?: number;
  onClickEdit?: (e: React.MouseEvent) => void;
}

const ArticleViewSnippet: React.FC<ArticleViewSnippetProps> = ({ snippetId, onClickEdit }) => {
  const { snippet, contentFormatLabel } = useSnippet(snippetId);

  const contentHTML = useMemo(() => {
    if (!snippet?.content) {
      return '';
    }
    return micromark(snippet.content);
  }, [snippet]);

  return (
    <>
      <Group alignItems="flex-end">
        <GroupItem grow>
          <Text variant="small">
            <Inline color={COLORS.GREY}>Textelement{contentFormatLabel ? `/${contentFormatLabel}` : ''}:</Inline>{' '}
            {snippet?.name} {snippet?.comment && <Inline color={COLORS.GREY}>{snippet?.comment}</Inline>}
          </Text>
        </GroupItem>
        <ButtonPrimary variant="small" ariaLabel="Bearbeiten" icon={IconPencil} onClick={onClickEdit} />
      </Group>
      <Block paddingTop={SPACINGS.XXS}>
        <Block padding={`${SPACINGS.M} ${SPACINGS.XL}`} background={COLORS.WHITE}>
          <Text>
            {snippet?.content ? (
              <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
            ) : (
              <Inline color={COLORS.GREY}>
                <em>Für dieses Textelement ist derzeit kein Text vorhanden.</em>
              </Inline>
            )}
          </Text>
        </Block>
      </Block>
    </>
  );
};

export default ArticleViewSnippet;
