/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { mediaAssetSchema } from '../schemas';

export type Arg = {
  data?: any;
};

export const createMediaAsset = (assetId: number, data: FormData): RequestEntitiesAction<MediaAssetData> => {
  return requestEntities({
    method: 'POST',
    path: `/assets/${assetId}/media-assets`,
    data,
    schema: mediaAssetSchema,
    useFormData: true,
  });
};

export const updateMediaAsset = (
  assetId: number,
  id: number,
  data: FormData
): RequestEntitiesAction<MediaAssetData> => {
  return requestEntities({
    method: 'PUT',
    path: `/assets/${assetId}/media-assets/${id}`,
    data,
    schema: mediaAssetSchema,
    useFormData: true,
  });
};

export const deleteMediaAsset = (assetId: number, id: number): RequestEntitiesAction<MediaAssetData> => {
  return requestEntities({
    method: 'DELETE',
    path: `/assets/${assetId}/media-assets/${id}`,
    schema: mediaAssetSchema,
  });
};

const mediaAssetsAdapter = createEntityAdapter<MediaAssetEntity>();

export const mediaAssetsSlice = createSlice({
  name: 'mediaAssets',
  initialState: mediaAssetsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.mediaAssets) {
        mediaAssetsAdapter.upsertMany(state, action.payload.entities.mediaAssets);
      }
    });
  },
});

export default mediaAssetsSlice.reducer;
