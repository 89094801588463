import React, { useEffect, useState } from 'react';
import { Block, Collapse, Group } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Headline from '../shared/atoms/Headline';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Input from '../shared/atoms/Input';
import Text from '../shared/atoms/Text';

interface SnippetCloneProps {
  onClickSave?: (comment: string) => void;
  onClickCancel?: React.MouseEventHandler;
}

const SnippetClone: React.FC<SnippetCloneProps> = ({ onClickSave, onClickCancel }) => {
  const [comment, setComment] = useState('');
  const [isCommentError, setIsCommentError] = useState(false);

  useEffect(() => {
    setIsCommentError(false);
  }, [comment]);

  const handleOnClickSave = (): void => {
    if (!comment.trim()) {
      setIsCommentError(true);
    } else if (onClickSave) {
      onClickSave(comment);
    }
  };

  return (
    <>
      <Headline>Neue Textelementversion anlegen</Headline>

      <FormSection title="Versionskommentar" paddingTop={SPACINGS.M}>
        <Input name="comment" autoComplete="ignore" value={comment} onChange={(e) => setComment(e.target.value)} />
        <Collapse show={isCommentError}>
          <Block paddingTop={SPACINGS.XS}>
            <Text variant="small" color={COLORS.RED}>
              Dies ist ein Pflichtfeld.
            </Text>
          </Block>
        </Collapse>
      </FormSection>

      <FormSection paddingTop={SPACINGS.S}>
        <Group spacing={SPACINGS.XXS}>
          <ButtonPrimary label="Speichern" onClick={handleOnClickSave} />
          <ButtonSecondary label="Abbrechen" onClick={onClickCancel} />
        </Group>
      </FormSection>
    </>
  );
};

export default SnippetClone;
