/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@webfox-sc/core';
import React from 'react';
import { COLORS, STYLES } from '../../../styles/theme';

type ButtonSecondaryVariant = 'small' | undefined;

interface ButtonSecondaryTheme {
  variant?: ButtonSecondaryVariant;
  width?: string;
  minWidth?: string;
}

const theme = ({ variant, width, minWidth }: ButtonSecondaryTheme) => {
  return {
    root: {
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '.1em',
      color: COLORS.BLUE,
      textTransform: 'uppercase',
      width,
      minWidth: minWidth || '168px',
      padding: variant === 'small' ? '0px 16px' : '0px 22px',
      border: `2px solid ${COLORS.MID_GREY}`,
      borderRadius: '3px',
      background: COLORS.WHITE,
      transition: 'background-color 0.2s ease, opacity 0.2s ease',
      span: {
        marginLeft: '8px',
      },
      svg: {
        width: variant === 'small' ? '20px' : '24px',
        maxWidth: 'none',
        height: variant === 'small' ? '20px' : '24px',
      },
      '& .ui-button__label': {
        padding: variant === 'small' ? '3px 0 1px 0' : '7px 0 5px 0',
      },
      '&[data-with-icon]': {
        paddingLeft: '16px',
      },
      '&[data-with-icon-right]': {
        paddingRight: '16px',
      },
      '&[data-with-icon-only]': {
        padding: variant === 'small' ? '4px' : '6px',
        minWidth: 0,
      },
      '&:hover': {
        opacity: 0.8,
      },
      '&:active': {
        opacity: 1,
      },
      '&[data-active]': {
        color: COLORS.WHITE,
        background: COLORS.MID_GREY,
      },
      '&[data-disabled]': {
        opacity: 0.3,
      },
    },
  };
};

export interface ButtonSecondaryProps {
  variant?: ButtonSecondaryVariant;
  width?: string;
  minWidth?: string;
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  ariaLabel?: string;
  title?: string;
  icon?: any;
  iconRight?: any;
  active?: boolean;
  disabled?: boolean;
  href?: string;
  target?: string;
  onClick?: React.MouseEventHandler;
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = (props) => {
  const { variant, width, minWidth } = props;
  return <Button {...props} theme={theme({ variant, width, minWidth })} />;
};

export default ButtonSecondary;
