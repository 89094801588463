import { COLORS } from '../../styles/theme';
import { getDateFormatted, getDateTimeFormatted } from '../../utils/date';
import { useUser } from '../user/useUser';
import { statusItems } from './statusUtils';

type StatusProps = {
  status?: EntityStatus;
  createdTime?: string;
  createdByUserId?: number;
  updatedTime?: string;
  updatedByUserId?: number;
  publicationTime?: string;
  expirationTime?: string;
};

export const useStatus = (
  statusProps: StatusProps
): {
  statusLabelText?: string;
  statusLabelBackground?: string;
  createdTimeFormatted?: string;
  createdByUserDisplayName?: string;
  createdTextLine?: string;
  updatedTimeFormatted?: string;
  updatedByUserDisplayName?: string;
  updatedTextLine?: string;
  publicationTimeFormatted?: string;
  publicationDateFormatted?: string;
  expirationTimeFormatted?: string;
  expirationDateFormatted?: string;
} => {
  const { status, createdTime, createdByUserId, updatedTime, updatedByUserId, publicationTime, expirationTime } =
    statusProps;

  const { displayName: createdByUserDisplayName } = useUser(createdByUserId);
  const { displayName: updatedByUserDisplayName } = useUser(updatedByUserId);

  let statusLabelText = 'Kein Status';
  let statusLabelBackground = COLORS.MID_GREY;
  if (status && statusItems[status]) {
    statusLabelText = statusItems[status].label;
    statusLabelBackground = statusItems[status].color;
  }

  const createdTimeFormatted = createdTime ? `${getDateTimeFormatted(createdTime)} Uhr` : undefined;
  const updatedTimeFormatted = updatedTime ? `${getDateTimeFormatted(updatedTime)} Uhr` : undefined;
  const publicationTimeFormatted = publicationTime ? `${getDateTimeFormatted(publicationTime)} Uhr` : undefined;
  const publicationDateFormatted = publicationTime ? getDateFormatted(publicationTime) : undefined;
  const expirationTimeFormatted = expirationTime ? `${getDateTimeFormatted(expirationTime)} Uhr` : undefined;
  const expirationDateFormatted = expirationTime ? getDateFormatted(expirationTime) : undefined;

  const createdTextLine = `Erstellt am ${createdTimeFormatted}${createdByUserDisplayName ? ' von ' : ''}${
    createdByUserDisplayName ? createdByUserDisplayName : ''
  }`;

  const updatedTextLine = `Aktualisiert am ${updatedTimeFormatted}${updatedByUserDisplayName ? ' von ' : ''}${
    updatedByUserDisplayName ? updatedByUserDisplayName : ''
  }`;

  return {
    statusLabelText,
    statusLabelBackground,
    createdTimeFormatted,
    createdByUserDisplayName,
    createdTextLine,
    updatedTimeFormatted,
    updatedByUserDisplayName,
    updatedTextLine,
    publicationTimeFormatted,
    publicationDateFormatted,
    expirationTimeFormatted,
    expirationDateFormatted,
  };
};
