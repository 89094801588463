import { Group } from '@webfox-sc/core';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import { refetchPage } from '../../app/slices/pagesSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import IssueArticle from './IssueArticle';
import { useCurrentIssue } from './useCurrentIssue';
import { useIssueArticles } from './useIssueArticles';
import useAPIError from '../apiError/useAPIError';

const StyledContent = styled.div({
  position: 'relative',
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
  transition: 'background-color 0.3s ease',
});

interface StyledContentDropOverlayProps {
  canDrop?: boolean;
  isDragOver?: boolean;
}

const StyledContentDropOverlay = styled.div<StyledContentDropOverlayProps>(({ canDrop, isDragOver }) => {
  return {
    display: canDrop ? 'block' : 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: `2px dashed ${isDragOver ? COLORS.BLUE : COLORS.MID_GREY}`,
  };
});

const IssueElementBoxArticles: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { issueId } = useCurrentIssue();
  const { unassignedArticles } = useIssueArticles(issueId);

  const handleOnDropArticleItem = async (item: ArticleDragItem) => {
    if (!!item.id && !!item.pageId) {
      const updateArticleResponse = await dispatch(
        updateArticle(item.id, {
          page: null,
        })
      );
      if (updateArticleResponse.payload?.error) {
        addError(updateArticleResponse.payload.error.message);
      }
      const refetchPageResponse = await dispatch(refetchPage(item.pageId));
      if (refetchPageResponse.payload?.error) {
        addError(refetchPageResponse.payload.error.message);
      }
    }
  };

  const [{ canDrop, isDragOver }, dropTarget] = useDrop(() => ({
    accept: 'issueArticle',
    drop: (item) => handleOnDropArticleItem(item),
    canDrop: (item: ArticleDragItem) => {
      return !!item.pageId;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isDragOver: monitor.isOver(),
    }),
  }));

  return (
    <StyledContent ref={dropTarget}>
      <Group vertical spacing={SPACINGS.XXS}>
        {unassignedArticles?.map((article) => {
          return <IssueArticle key={article.id} articleId={article.id} />;
        })}
      </Group>
      <StyledContentDropOverlay canDrop={canDrop} isDragOver={isDragOver} />
    </StyledContent>
  );
};

export default React.memo(IssueElementBoxArticles);
