import React, { useCallback, useState } from 'react';
import FormSection from '../shared/forms/FormSection';
import { COLORS, SPACINGS } from '../../styles/theme';
import styled from 'styled-components';
import Text from '../shared/atoms/Text';
import { useDropzone } from 'react-dropzone';
import { apiUrl } from '../../app/api';
import { useAppSelector } from '../../app/hooks';
import { selectAuthState } from '../../app/slices/authSlice';
import axios from 'axios';
import { formatFileSizeB } from '../../utils/format';
import Spinner from '../shared/atoms/Spinner';

interface FormSectionAudioTranscriptionProps {
  paddingTop?: string;
  onTranscriptionComplete?: (text: string) => void;
}

interface StyledDropzoneProps {
  isDragActive: boolean;
  isDisabled?: boolean;
}

const StyledDropzone = styled.div<StyledDropzoneProps>(({ isDragActive, isDisabled }) => {
  return {
    boxSizing: 'border-box',
    height: '240px',
    padding: SPACINGS.M,
    border: `2px dashed ${isDragActive ? COLORS.BLUE : COLORS.MID_GREY}`,
    background: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: isDisabled ? 'normal' : 'pointer',
  };
});

const FormSectionAudioTranscription: React.FC<FormSectionAudioTranscriptionProps> = ({
  paddingTop,
  onTranscriptionComplete,
}) => {
  const { jwt } = useAppSelector(selectAuthState);

  const [selectedFile, setSelectedFile] = useState<File | undefined | null>();
  const [amountUploaded, setAmountUploaded] = useState<string | undefined>();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const uploadFile = useCallback(
    async (file: File) => {
      setSuccess(false);
      setError(undefined);
      setUploadInProgress(true);

      const formData = new FormData();
      formData.append('files.files', file, file.name);
      formData.append('data', '{}');
      try {
        const response = await axios.post(`${apiUrl}/transcriptions`, formData, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          onUploadProgress: (progressEvent) => {
            setAmountUploaded(formatFileSizeB(progressEvent.loaded));

            if (progressEvent.loaded === progressEvent.total) {
              setTranscriptionInProgress(true);
            }
          },
        });
        onTranscriptionComplete?.(response.data.text || '');
        setSuccess(true);
      } catch (e) {
        setError(e.response?.data?.message || e.message);
      } finally {
        setUploadInProgress(false);
        setTranscriptionInProgress(false);
      }
    },
    [jwt, onTranscriptionComplete]
  );

  const onFilesDrop = useCallback(
    async (files: File[]) => {
      const firstFile = files?.[0];

      if (!firstFile) {
        return;
      }

      setSelectedFile(firstFile);

      await uploadFile(firstFile);
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    disabled: uploadInProgress,
    noClick: uploadInProgress,
    onDrop: onFilesDrop,
    multiple: false,
    preventDropOnDocument: true,
    accept: [
      'audio/mpeg',
      'video/mp4',
      'audio/mp4',
      'video/mpeg',
      'audio/m4a',
      'audio/x-m4a',
      'audio/wav',
      'audio/x-wav',
      'video/webm',
    ], // mp3/mpga, mp4, mpeg, m4a, wav, webm
  });

  return (
    <FormSection title="Audio-Transkription" paddingTop={paddingTop}>
      <StyledDropzone {...getRootProps()} isDragActive={isDragActive && isDragAccept} isDisabled={uploadInProgress}>
        <input {...getInputProps()} />
        <Text color={COLORS.GREY} textAlign="center">
          {selectedFile && (
            <>
              {selectedFile.name}
              <br />
              <em>
                {amountUploaded} von {formatFileSizeB(selectedFile.size)} hochgeladen
              </em>
              <br />
              <br />

              {transcriptionInProgress && (
                <>
                  Verarbeite <Spinner />
                </>
              )}

              {error && (
                <>
                  <Text color={COLORS.RED}>{error}</Text>
                  <br />
                </>
              )}

              {success && (
                <>
                  <Text color={COLORS.GREEN}>erfolgreich abgeschlossen</Text>
                  <br />
                </>
              )}
            </>
          )}
          {!uploadInProgress && (
            <>
              <em>Ziehe eine Datei in diesen Bereich oder klicke, um eine Datei auszuwählen.</em>
              <br />
              <em>Erlaubte Dateiendungen: .mp3, .mp4, .mpeg, .mpga, .m4a, .wav und .webm</em>
            </>
          )}
        </Text>
      </StyledDropzone>
    </FormSection>
  );
};

export default FormSectionAudioTranscription;
