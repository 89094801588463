import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { IconEye, IconPencil, IconDelete } from '@webfox-sc/icons';
import { useIssue } from '../issue/useIssue';
import Panel from '../shared/atoms/Panel';
import { COLORS, SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import StatusPanel from '../status/StatusPanel';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Text from '../shared/atoms/Text';
import Inline from '../shared/atoms/Inline';

interface NewspaperIssueProps {
  issueId: number | undefined;
  onClickDelete?: (issueId: number | undefined) => void;
}

const NewspaperIssue: React.FC<NewspaperIssueProps> = ({ issueId, onClickDelete }) => {
  const navigate = useNavigate();
  const { issue, publicationDateFormatted } = useIssue(issueId);

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/issue/${issue?.id}`);
  };

  const handleOnClickDelete = () => {
    if (typeof onClickDelete === 'function') {
      onClickDelete(issueId);
    }
  };

  return (
    <Group nowrap spacing={SPACINGS.XXS}>
      <GroupItem grow>
        <Panel verticalStretch padding={SPACINGS.XL}>
          <Group nowrap spacing={SPACINGS.S} justify="space-between" alignItems="center">
            <Headline>
              <a href={`/issue/${issue?.id}`} onClick={handleOnClickLink}>
                {issue?.name}
              </a>
            </Headline>
            <Text>
              <Inline color={COLORS.GREY}>
                {issue?.status === 'published' ? 'Veröffentlicht am' : 'Veröffentlichung am'}
              </Inline>{' '}
              {publicationDateFormatted}
            </Text>
          </Group>
          <Block paddingTop={SPACINGS.S}>
            <Text>
              {!issue?.pageCount ? (
                <em>Keine Seiten oder Seitenfolgen</em>
              ) : issue?.pageCount === 1 ? (
                '1 Seite oder Seitenfolge'
              ) : (
                `${issue?.pageCount} Seiten oder Seitenfolgen`
              )}
            </Text>
          </Block>
        </Panel>
      </GroupItem>
      <GroupItem>
        <StatusPanel showStatusLabel status={issue?.status} verticalStretch />
      </GroupItem>
      <GroupItem>
        <Group vertical spacing={SPACINGS.XXS}>
          <ButtonPrimary
            ariaLabel="Bearbeiten"
            icon={issue?.status === 'published' ? IconEye : IconPencil}
            onClick={handleOnClickLink}
          />
          {issue?.status !== 'published' && (
            <ButtonPrimary
              ariaLabel="Entfernen"
              icon={IconDelete}
              disabled={(issue?.pageCount || 0) > 0}
              onClick={handleOnClickDelete}
            />
          )}
        </Group>
      </GroupItem>
    </Group>
  );
};

export default NewspaperIssue;
