import { useAppSelector } from '../../app/hooks';

export const useNewspaper = (
  newspaperId: number | undefined
): {
  newspaper?: NewspaperEntity;
} => {
  const newspaper = useAppSelector(({ entities }) => entities.newspapers.entities[newspaperId || 0]);

  return {
    newspaper,
  };
};
