import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const sectionsAdapter = createEntityAdapter<SectionEntity>();

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: sectionsAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.sections) {
        sectionsAdapter.upsertMany(state, action.payload.entities.sections);
      }
    });
  },
});

export default sectionsSlice.reducer;
