import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../store';
import { apiUrl } from '../api';

export interface AuthState {
  status: 'idle' | 'loading' | 'autoLogin' | 'loggedIn' | 'failed';
  jwt?: string;
  username?: string;
  userId?: number;
  userEmail?: string;
}

const initialState: AuthState = {
  status: 'idle',
  jwt: undefined,
  username: undefined,
  userId: undefined,
  userEmail: undefined,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ identifier, password }: { identifier: string; password: string }) => {
    const { data } = await axios.post(`${apiUrl}/auth/local`, {
      identifier,
      password,
    });
    return data;
  }
);

export const autoLogin = createAsyncThunk('auth/autoLogin', async () => {
  const jwt = JSON.parse(localStorage.getItem('jwt') || '');

  if (jwt) {
    const { data } = await axios.get(`${apiUrl}/users/me`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return {
      jwt,
      user: data,
    };
  }

  return {
    jwt,
    user: undefined,
  };
});

export const getLoginLink = (): string => {
  // generate each time to prevent replay attacks
  const nonce = Math.random().toString(36).substring(2, 11);
  return `${process.env.REACT_APP_CIDAAS_ENDPOINT}/authz-srv/authz?response_type=token&scope=openid%20email%20phone%20profile&client_id=${process.env.REACT_APP_CIDAAS_CLIENT_ID}&nonce=${nonce}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`;
};

export const exchangeToken = createAsyncThunk(
  'auth/exchangeToken',
  async ({ access_token }: { access_token: string }) => {
    const res: AxiosResponse = await new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/auth/cidaas/callback?access_token=${access_token}`)
        .catch((reason) => {
          if (reason?.response?.status === 400) {
            // logout and redirect to login
            axios
              .post(`${process.env.REACT_APP_CIDAAS_ENDPOINT}/session/end_session?access_token_hint=${access_token}`)
              .finally(() => {
                window.location.href = getLoginLink();
              });
          }
          reject(reason.response);
        })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(null);
          }
        });
    });
    if (res) {
      return res.data;
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('jwt');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        const { jwt, user } = action.payload;
        localStorage.setItem('jwt', JSON.stringify(jwt));
        return {
          ...state,
          status: 'loggedIn',
          jwt,
          username: user?.username,
          userId: user?.id,
          userEmail: user?.email,
        };
      })
      .addCase(login.rejected, () => {
        return { ...initialState, status: 'failed' };
      })
      .addCase(exchangeToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(exchangeToken.fulfilled, (state, action) => {
        const { jwt, user } = action.payload;
        localStorage.setItem('jwt', JSON.stringify(jwt));
        return {
          ...state,
          status: 'loggedIn',
          jwt,
          username: user?.username,
          userId: user?.id,
          userEmail: user?.email,
        };
      })
      .addCase(exchangeToken.rejected, () => {
        return { ...initialState, status: 'failed' };
      })
      .addCase(autoLogin.pending, (state) => {
        state.status = 'autoLogin';
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        const { jwt, user } = action.payload;
        return {
          ...state,
          status: 'loggedIn',
          jwt,
          username: user?.username,
          userId: user?.id,
          userEmail: user?.email,
        };
      })
      .addCase(autoLogin.rejected, () => {
        localStorage.removeItem('jwt');
        return { ...initialState };
      });
  },
});

export const { logout } = authSlice.actions;
export const selectAuthState = (state: RootState): AuthState => state.auth;
export default authSlice.reducer;
