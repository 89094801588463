import React, { useRef, useState } from 'react';

interface ConfirmDialogContextInterface {
  isConfirmDialogVisible: boolean;
  confirmMessage: string;
  confirmAction: (message: string) => Promise<boolean>;
  setConfirmAction: (confirm: boolean) => void;
}

export const ConfirmDialogContext = React.createContext<ConfirmDialogContextInterface>(
  {} as ConfirmDialogContextInterface
);

const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const resolveRef = useRef<((value: boolean) => void) | undefined>(undefined);

  const confirmAction = async (message: string): Promise<boolean> => {
    setConfirmMessage(message);
    setIsConfirmDialogVisible(true);
    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const setConfirmAction = (confirm: boolean) => {
    setConfirmMessage('');
    setIsConfirmDialogVisible(false);
    if (resolveRef.current) {
      resolveRef.current(confirm);
    }
  };

  return (
    <ConfirmDialogContext.Provider
      value={{
        isConfirmDialogVisible,
        confirmMessage,
        confirmAction,
        setConfirmAction,
      }}
    >
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
