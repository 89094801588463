import { entitySelectors } from '../../app/entitySelectors';
import { useCallback, useMemo } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useAppSelector } from '../../app/hooks';
import { apiUrl } from '../../app/api';
import useAPIError from '../apiError/useAPIError';

type PromptOption = {
  value: number;
  label: string;
};

type PerformPromptData = {
  data: { id: number; text: string };
};
type PerformPromptReturned = {
  data: { index: number; text: string }[];
};

export const usePrompts = (): {
  prompts: PromptEntity[];
  promptOptions: PromptOption[];
  performPrompt: (promptId: number, text: string) => Promise<string | undefined>;
} => {
  const jwt = useAppSelector(({ auth }) => auth.jwt);
  const { addError } = useAPIError();

  const prompts = entitySelectors.selectAll<PromptEntity>('prompts');

  const promptOptions = useMemo(
    () =>
      prompts.map(({ id, name }) => ({
        value: id,
        label: name || '',
      })),
    [prompts]
  );

  const performPrompt = useCallback(
    async (promptId: number, text: string): Promise<string | undefined> => {
      try {
        const { data } = await axios.post<
          PerformPromptReturned,
          AxiosResponse<PerformPromptReturned>,
          PerformPromptData
        >(
          `${apiUrl}/prompts`,
          { data: { id: promptId, text } },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (data.data?.length) {
          return data.data[0].text;
        }
      } catch (e) {
        addError(e.response?.data?.message || e.message);
      }

      return undefined;
    },
    [addError, jwt]
  );

  return {
    prompts,
    promptOptions,
    performPrompt,
  };
};
