import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import { SPACINGS } from '../../styles/theme';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import Text from '../shared/atoms/Text';
import Panel from '../shared/atoms/Panel';
import ArticleSection from './ArticleSection';
import { useArticleSections } from './useArticleSections';
import { swapArrayElements } from '../../utils/array';
import { useAppDispatch } from '../../app/hooks';
import { updateArticle } from '../../app/slices/articlesSlice';
import useAPIError from '../apiError/useAPIError';

interface ArticleSectionsProps {
  articleId?: number;
}

const ArticleSections: React.FC<ArticleSectionsProps> = ({ articleId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { sections } = useArticleSections(articleId);

  const handleOnClickMove = async (index: number, targetIndex: number) => {
    if (!!articleId) {
      const sectionsNew = swapArrayElements(sections, index, targetIndex);
      const sectionsData: SectionsUpdateData[] = sectionsNew.map((section) => {
        return {
          id: section.id,
        };
      });
      const response = await dispatch(
        updateArticle(articleId, {
          Section: sectionsData,
        })
      );
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  return (
    <>
      {sections.length > 0 ? (
        <>
          <Group paddingRight={SPACINGS.XL} spacing={SPACINGS.XXS} justify="flex-end">
            <ButtonPrimary
              label="Elemente importieren"
              icon={IconPlus}
              onClick={() => navigate(`/article/${articleId}/import`)}
            />
            <ButtonPrimary
              label="Textelement hinzufügen"
              icon={IconPlus}
              onClick={() => navigate(`/article/${articleId}/add/snippet`)}
            />
            <ButtonPrimary
              label="Anhang hinzufügen"
              icon={IconPlus}
              onClick={() => navigate(`/article/${articleId}/add/asset`)}
            />
          </Group>
          <Group vertical spacing={SPACINGS.S} paddingTop={SPACINGS.M}>
            {sections.map((section, index) => {
              return (
                <ArticleSection
                  key={section.id}
                  sectionId={section.id}
                  isFirst={index === 0}
                  isLast={index === sections.length - 1}
                  onClickUp={() => handleOnClickMove(index, index - 1)}
                  onClickDown={() => handleOnClickMove(index, index + 1)}
                />
              );
            })}
          </Group>
        </>
      ) : (
        <Block paddingRight={SPACINGS.XL}>
          <Panel padding={SPACINGS.XL}>
            <Text>Für diesen Beitrag sind derzeit keine Textelemente oder Anhänge vorhanden.</Text>
            <Group paddingTop={SPACINGS.M} spacing={SPACINGS.XXS}>
              <ButtonPrimary
                label="Elemente importieren"
                icon={IconPlus}
                onClick={() => navigate(`/article/${articleId}/import`)}
              />
              <ButtonPrimary
                label="Textelement hinzufügen"
                icon={IconPlus}
                onClick={() => navigate(`/article/${articleId}/add/snippet`)}
              />
              <ButtonPrimary
                label="Anhang hinzufügen"
                icon={IconPlus}
                onClick={() => navigate(`/article/${articleId}/add/asset`)}
              />
            </Group>
          </Panel>
        </Block>
      )}
    </>
  );
};

export default ArticleSections;
