import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS, STYLES } from '../../../styles/theme';

interface StyledControlProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

const StyledControl = styled.div<StyledControlProps>(({ width, error, isFocus, noBorder }) => {
  return {
    boxSizing: 'border-box',
    width,
    border: noBorder ? 0 : `2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: '3px',
    background: COLORS.WHITE,
    transition: 'border-color 0.2s ease',
  };
});

interface StyledTextAreaProps {
  minHeight?: string | 0;
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>(({ minHeight }) => {
  return {
    display: 'block',
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: minHeight || '124px',
    padding: '14px',
    border: 0,
    fontFamily: STYLES.FONT_FAMILY,
    fontSize: '16px',
    lineHeight: '24px',
    color: COLORS.DARK,
    background: COLORS.WHITE,
    filter: 'none', // disable autofill styles for Firefox
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': COLORS.DARK,
      '-webkit-box-shadow': '0 0 0px 1000px #fff inset',
    },
    '&:focus': {
      outline: 0,
    },
  };
});

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  width?: string | 0;
  minHeight?: string | 0;
  error?: boolean;
  noBorder?: boolean;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

const TextArea: React.FC<TextAreaProps> = ({ width, minHeight, error, noBorder, onFocus, onBlur, ...props }) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleOnFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocus(true);
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocus(false);
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  return (
    <StyledControl width={width} error={error} isFocus={isFocus} noBorder={noBorder}>
      <StyledTextArea
        onFocus={(e) => handleOnFocus(e)}
        onBlur={(e) => handleOnBlur(e)}
        minHeight={minHeight}
        {...props}
      />
    </StyledControl>
  );
};

export default TextArea;
