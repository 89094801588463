import React from 'react';
import { Checkbox } from '@webfox-sc/core';
import { COLORS, STYLES } from '../../../styles/theme';

const theme = {
  checkbox: {
    height: '32px',
    width: '32px',
    background: COLORS.WHITE,
    border: `2px solid ${COLORS.MID_GREY}`,
    transition: 'border-color 0.2s ease',
    svg: {
      width: '24px',
      height: '24px',
      color: COLORS.BLUE,
    },
    '&[data-focus="true"]': {
      border: `2px solid ${COLORS.GREY}`,
    },
  },
  label: {
    fontFamily: STYLES.FONT_FAMILY,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'inherit',
    color: COLORS.DARK,
    paddingTop: '5px',
  },
};

export interface CustomCheckboxProps {
  value: string | number;
  label?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props) => {
  return <Checkbox {...props} theme={theme} />;
};

export default CustomCheckbox;
