import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { IconChevronDown } from '@webfox-sc/icons';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ children, ...props }) => {
  return <components.DropdownIndicator {...props}>{IconChevronDown}</components.DropdownIndicator>;
};

const selectComponents = {
  DropdownIndicator,
};

export default selectComponents;
