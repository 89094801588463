import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { requestEntities } from '../requestEntities';

const contentTypesAdapter = createEntityAdapter<ContentTypeEntity>();

export const contentTypesSlice = createSlice({
  name: 'contentTypes',
  initialState: contentTypesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestEntities.fulfilled, (state, action) => {
      if (action.payload.entities?.contentTypes) {
        contentTypesAdapter.upsertMany(state, action.payload.entities.contentTypes);
      }
    });
  },
});

export default contentTypesSlice.reducer;
