import _sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

type OptionalNumber = number | undefined;

const maxPageNumber = 1000;
const maxPageCount = 10;

export const useIssuePageNumbers = (
  issueId: OptionalNumber
): {
  pageNumbers: number[];
  nextAvailablePageNumber: number;
  maxPageNumber: number;
  maxPageCount: number;
  getPageNumberRange: (number: OptionalNumber, count: OptionalNumber) => number[];
  getUnavailablePageNumbersForCreate: (number: OptionalNumber, count: OptionalNumber) => number[];
  getUnavailablePageNumbersForUpdate: (
    number: OptionalNumber,
    count: OptionalNumber,
    protectedNumber: OptionalNumber,
    protectedCount: OptionalNumber
  ) => number[];
} => {
  const issue = useAppSelector(({ entities }) => entities.issues.entities[issueId || 0]);
  const pages = entitySelectors.selectByIds<PageEntity>('pages', issue?.pageIds);

  const getPageNumberRange = (number = 0, count = 0) => {
    return Array(count)
      .fill(undefined)
      .map((v, i) => i + number);
  };

  const pageNumbers = useMemo(() => {
    let numbers: number[] = [];
    pages.forEach((page) => {
      if (page.number && page.count) {
        numbers = [...numbers, ...getPageNumberRange(page.number, page.count)];
      }
    });
    return _sortBy(numbers);
  }, [pages]);

  const nextAvailablePageNumber = useMemo(() => {
    let result = 0;
    if (pageNumbers.length > 0) {
      pageNumbers.forEach((pageNumber) => {
        if (result === 0 && !pageNumbers.includes(pageNumber + 1)) {
          result = pageNumber + 1;
        }
      });
    } else {
      result = 1;
    }
    return result;
  }, [pageNumbers]);

  const getUnavailablePageNumbersForCreate = (number = 0, count = 0) => {
    if (count < 1 || count > 1000) {
      return [];
    }
    const numberRange = getPageNumberRange(number, count);
    return numberRange.filter((n) => pageNumbers.includes(n));
  };

  const getUnavailablePageNumbersForUpdate = (number = 0, count = 0, protectedNumber = 0, protectedCount = 0) => {
    if (count < 1 || count > 1000) {
      return [];
    }
    const numberRange = getPageNumberRange(number, count);
    const protectedNumberRange = getPageNumberRange(protectedNumber, protectedCount);
    const optNumberRange = numberRange.filter((n) => !protectedNumberRange.includes(n));
    return optNumberRange.filter((n) => pageNumbers.includes(n));
  };

  return {
    pageNumbers,
    nextAvailablePageNumber,
    maxPageNumber,
    maxPageCount,
    getPageNumberRange,
    getUnavailablePageNumbersForCreate,
    getUnavailablePageNumbersForUpdate,
  };
};
