import { Editor } from '@tiptap/react';
import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Group, GroupItem } from '@webfox-sc/core';
import {
  RiBold,
  RiClipboardLine,
  RiCodeSSlashLine,
  RiDeleteBin2Line,
  RiDeleteColumn,
  RiDeleteRow,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiItalic,
  RiLayoutColumnFill,
  RiLayoutFill,
  RiLayoutRowFill,
  RiListUnordered,
  RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
  RiTable2,
  RiTerminalWindowLine,
  RiUnderline,
} from 'react-icons/ri';

import { COLORS, SPACINGS } from '../../styles/theme';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import SingleSelect from '../shared/select/SingleSelect';

interface StyledComponentProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

const ToolbarWrapper = styled.div<StyledComponentProps>((): CSSObject => {
  return {
    borderBottom: `2px solid ${COLORS.MID_GREY}`,
    padding: '11px',
  };
});

const paragraphFormatOptions = [
  {
    value: 'Normal',
    label: 'Normal',
  },
  {
    value: 'H1',
    label: 'H1',
    formatType: 'Heading',
    formatArgs: { level: 1 },
  },
  {
    value: 'H2',
    label: 'H2',
    formatType: 'Heading',
    formatArgs: { level: 2 },
  },
  {
    value: 'H3',
    label: 'H3',
    formatType: 'Heading',
    formatArgs: { level: 3 },
  },
  {
    value: 'H4',
    label: 'H4',
    formatType: 'Heading',
    formatArgs: { level: 4 },
  },
  {
    value: 'H5',
    label: 'H5',
    formatType: 'Heading',
    formatArgs: { level: 5 },
  },
  {
    value: 'H6',
    label: 'H6',
    formatType: 'Heading',
    formatArgs: { level: 6 },
  },
  {
    value: 'Blockquote',
    label: 'Blockquote',
    formatType: 'Blockquote',
  },
];

interface FormHtmlEditorToolbarProps {
  editor: Editor | null;
  showEditor: boolean;
  setShowEditor: (boolean) => void;
}

const FormHtmlEditorToolbar: React.FC<FormHtmlEditorToolbarProps> = ({ editor, showEditor, setShowEditor }) => {
  if (!editor) {
    return null;
  }

  let paragraphFormat;
  if (editor.isActive('blockquote')) {
    paragraphFormat = 'Blockquote';
  } else if (editor.isActive('heading')) {
    paragraphFormat = `H${editor.getAttributes('heading').level}`;
  } else {
    paragraphFormat = 'Normal';
  }

  const paragraphFormatOption = paragraphFormatOptions.find((option) => option.value === paragraphFormat);

  const handleChangeParagraphFormat = ({ formatType, formatArgs }) => {
    // set format
    const chainedCommand = editor.chain().clearNodes();
    if (formatType) {
      const command = `set${formatType}`;
      if (formatArgs) {
        chainedCommand[command](formatArgs).run();
      } else {
        chainedCommand[command]().run();
      }
    } else {
      chainedCommand.run();
    }
  };

  return (
    <ToolbarWrapper>
      {showEditor ? (
        <Group spacing={SPACINGS.XXS}>
          <GroupItem>
            <SingleSelect
              options={paragraphFormatOptions}
              defaultValue={paragraphFormatOption}
              value={paragraphFormatOption}
              placeholder="Auswählen"
              onChange={handleChangeParagraphFormat}
              isClearable={false}
            />
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Fett"
                title="Fett"
                icon={<RiBold />}
                active={editor.isActive('bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
              />
              <ButtonSecondary
                ariaLabel="Kursiv"
                title="Kursiv"
                icon={<RiItalic />}
                active={editor.isActive('italic')}
                onClick={() => editor.chain().focus().toggleItalic().run()}
              />
              <ButtonSecondary
                ariaLabel="Unterstrichen"
                title="Unterstrichen"
                icon={<RiUnderline />}
                active={editor.isActive('underline')}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Stichpunkte"
              title="Stichpunkte"
              icon={<RiListUnordered />}
              active={editor.isActive('bulletList')}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
            />
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Tabelle einfügen"
                title="Tabelle einfügen"
                icon={<RiTable2 />}
                onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
              />
              <ButtonSecondary
                ariaLabel="Tabelle löschen"
                title="Tabelle löschen"
                icon={<RiDeleteBin2Line />}
                onClick={() => editor.chain().focus().deleteTable().run()}
                disabled={!editor.can().deleteTable()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Spalte links einfügen"
                title="Spalte links einfügen"
                icon={<RiInsertColumnLeft />}
                onClick={() => editor.chain().focus().addColumnBefore().run()}
                disabled={!editor.can().addColumnBefore()}
              />
              <ButtonSecondary
                ariaLabel="Spalte rechts einfügen"
                title="Spalte rechts einfügen"
                icon={<RiInsertColumnRight />}
                onClick={() => editor.chain().focus().addColumnAfter().run()}
                disabled={!editor.can().addColumnAfter()}
              />
              <ButtonSecondary
                ariaLabel="Spalte löschen"
                title="Spalte löschen"
                icon={<RiDeleteColumn />}
                onClick={() => editor.chain().focus().deleteColumn().run()}
                disabled={!editor.can().deleteColumn()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Zeile darüber einfügen"
                title="Zeile darüber einfügen"
                icon={<RiInsertRowTop />}
                onClick={() => editor.chain().focus().addRowBefore().run()}
                disabled={!editor.can().addRowBefore()}
              />
              <ButtonSecondary
                ariaLabel="Zeile darunter einfügen"
                title="Zeile darunter einfügen"
                icon={<RiInsertRowBottom />}
                onClick={() => editor.chain().focus().addRowAfter().run()}
                disabled={!editor.can().addRowAfter()}
              />
              <ButtonSecondary
                ariaLabel="Zeile löschen"
                title="Zeile löschen"
                icon={<RiDeleteRow />}
                onClick={() => editor.chain().focus().deleteRow().run()}
                disabled={!editor.can().deleteRow()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Zellen verbinden"
                title="Zellen verbinden"
                icon={<RiMergeCellsHorizontal />}
                onClick={() => editor.chain().focus().mergeCells().run()}
                disabled={!editor.can().mergeCells()}
              />
              <ButtonSecondary
                ariaLabel="Zellen aufspalten"
                title="Zellen aufspalten"
                icon={<RiSplitCellsHorizontal />}
                onClick={() => editor.chain().focus().splitCell().run()}
                disabled={!editor.can().splitCell()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <Group>
              <ButtonSecondary
                ariaLabel="Kopfzeile ein/aus"
                title="Kopfzeile ein/aus"
                icon={<RiLayoutRowFill />}
                onClick={() => editor.chain().focus().toggleHeaderRow().run()}
                disabled={!editor.can().toggleHeaderRow()}
              />
              <ButtonSecondary
                ariaLabel="Kopfspalte ein/aus"
                title="Kopfspalte ein/aus"
                icon={<RiLayoutColumnFill />}
                onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
                disabled={!editor.can().toggleHeaderColumn()}
              />
              <ButtonSecondary
                ariaLabel="Kopfzelle ein/aus"
                title="Kopfzelle ein/aus"
                icon={<RiLayoutFill />}
                onClick={() => editor.chain().focus().toggleHeaderCell().run()}
                disabled={!editor.can().toggleHeaderCell()}
              />
            </Group>
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Als HTML einfügen"
              title="Als HTML einfügen"
              icon={<RiClipboardLine />}
              onClick={async () => {
                const clipboardText = await navigator.clipboard.readText();
                editor.chain().focus().insertContent(clipboardText).run();
              }}
            />
          </GroupItem>
          <GroupItem>
            <ButtonSecondary
              ariaLabel="Quelltext anzeigen"
              title="Quelltext anzeigen"
              icon={showEditor ? <RiCodeSSlashLine /> : <RiTerminalWindowLine />}
              onClick={() => setShowEditor(false)}
            />
          </GroupItem>
        </Group>
      ) : (
        <ButtonSecondary
          ariaLabel="Editor anzeigen"
          title="Editor anzeigen"
          icon={<RiTerminalWindowLine />}
          onClick={() => setShowEditor(true)}
        />
      )}
    </ToolbarWrapper>
  );
};

export default FormHtmlEditorToolbar;
