import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useArticleSections = (
  articleId: number | undefined
): {
  article?: ArticleEntity;
  sections: SectionEntity[];
  sectionsMap: SectionsMapEntry[];
  getSectionsMapAddedByAsset: (assetId: number) => SectionsMapEntry[];
  getSectionsMapReducedByAsset: (assetId: number) => SectionsMapEntry[];
  getSectionsMapAddedBySnippet: (snippetId: number) => SectionsMapEntry[];
  getSectionsMapReducedBySnippet: (snippetId: number) => SectionsMapEntry[];
} => {
  const article = useAppSelector(({ entities }) => entities.articles.entities[articleId || 0]);
  const sections = entitySelectors.selectByIds<SectionEntity>('sections', article?.sectionIds);

  const sectionsMap: SectionsMapEntry[] = useMemo(() => {
    return sections.map((articleSection) => {
      if (articleSection.assetId) {
        return {
          asset: articleSection.assetId,
        };
      }
      return {
        snippet: articleSection.snippetId,
      };
    });
  }, [sections]);

  const getSectionsMapAddedByAsset = (assetId: number): SectionsMapEntry[] => {
    return [
      ...sectionsMap,
      {
        asset: assetId,
      },
    ];
  };

  const getSectionsMapReducedByAsset = (assetId: number): SectionsMapEntry[] => {
    return sectionsMap.filter((sectionsMapEntry) => sectionsMapEntry.asset !== assetId);
  };

  const getSectionsMapAddedBySnippet = (snippetId: number): SectionsMapEntry[] => {
    return [
      ...sectionsMap,
      {
        snippet: snippetId,
      },
    ];
  };

  const getSectionsMapReducedBySnippet = (snippetId: number): SectionsMapEntry[] => {
    return sectionsMap.filter((sectionsMapEntry) => sectionsMapEntry.snippet !== snippetId);
  };

  return {
    article,
    sections,
    sectionsMap,
    getSectionsMapAddedByAsset,
    getSectionsMapReducedByAsset,
    getSectionsMapAddedBySnippet,
    getSectionsMapReducedBySnippet,
  };
};
