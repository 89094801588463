import React from 'react';
import styled from 'styled-components';
import { COLORS, SPACINGS } from '../../styles/theme';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import Text from '../shared/atoms/Text';
import FormButton from '../shared/atoms/FormButton';
import { IconPlus } from '@webfox-sc/icons';
import Panel from '../shared/atoms/Panel';
import TagGroup from '../tags/TagGroup';
import { useSnippet } from './useSnippet';
import { useStatus } from '../status/useStatus';

const StyledTable = styled.table({
  borderSpacing: 0,
});

const StyledTableCell = styled.td({
  padding: 0,
  textAlign: 'left',
  '&:first-of-type': {
    paddingRight: SPACINGS.XXS,
  },
});

interface FormSectionConnectSnippetItemProps {
  snippetId: number;
  onSelect: (snippetId: number) => void;
  disabled?: boolean;
}

const FormSectionConnectSnippetItem: React.FC<FormSectionConnectSnippetItemProps> = ({
  snippetId,
  onSelect,
  disabled,
}) => {
  const { snippet, contentFormatLabel } = useSnippet(snippetId);

  const { statusLabelText, statusLabelBackground, createdTimeFormatted, updatedTimeFormatted } = useStatus({
    status: snippet?.status,
    createdTime: snippet?.createdTime,
    updatedTime: snippet?.updatedTime,
  });

  return (
    <Group nowrap spacing={SPACINGS.XXS}>
      <GroupItem grow>
        <Panel background={COLORS.WHITE} padding={SPACINGS.XS}>
          <Group nowrap spacing={SPACINGS.XS}>
            <GroupItem grow>
              <Text nl2br>{snippet?.name}</Text>
              <Text variant="small" color={COLORS.GREY}>
                {contentFormatLabel ? `${contentFormatLabel} / ` : ''}
                {snippet?.comment}
              </Text>
              <Block paddingTop={SPACINGS.XXS}>
                <TagGroup tagIds={snippet?.tagIds} variant="boxesWithoutLink" />
              </Block>
            </GroupItem>
            <GroupItem>
              <StyledTable>
                <tbody>
                  <tr>
                    <StyledTableCell>
                      <Text variant="label-small">Erstellt:</Text>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Text variant="small" nowrap>
                        {createdTimeFormatted}
                      </Text>
                    </StyledTableCell>
                  </tr>
                  <tr>
                    <StyledTableCell>
                      <Text variant="label-small">Aktualisiert:</Text>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Text variant="small" nowrap>
                        {updatedTimeFormatted}
                      </Text>
                    </StyledTableCell>
                  </tr>
                </tbody>
              </StyledTable>
            </GroupItem>
            <GroupItem>
              <Block padding="4px 0">
                <div title={statusLabelText}>
                  <Block width="16px" height="16px" background={statusLabelBackground} />
                </div>
              </Block>
            </GroupItem>
          </Group>
        </Panel>
      </GroupItem>
      <GroupItem>
        <FormButton
          variant="smallWithBorder"
          ariaLabel="auswählen"
          icon={IconPlus}
          disabled={disabled}
          onClick={() => !disabled && onSelect(snippetId)}
        />
      </GroupItem>
    </Group>
  );
};

export default FormSectionConnectSnippetItem;
