/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@webfox-sc/core';
import React from 'react';
import { COLORS, STYLES } from '../../../styles/theme';

type ButtonPrimaryVariant = 'small' | undefined;

interface ButtonPrimaryTheme {
  variant?: ButtonPrimaryVariant;
}

const theme = ({ variant }: ButtonPrimaryTheme) => ({
  root: {
    fontFamily: STYLES.FONT_FAMILY,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    letterSpacing: '.1em',
    color: 'white',
    textTransform: 'uppercase',
    minWidth: '168px',
    padding: variant === 'small' ? '0px 16px' : '0px 24px',
    borderRadius: '3px',
    background: COLORS.BLUE,
    transition: 'background-color 0.2s ease, opacity 0.2s ease',
    span: {
      marginLeft: '8px',
    },
    svg: {
      width: variant === 'small' ? '20px' : '24px',
      maxWidth: 'none',
      height: variant === 'small' ? '20px' : '24px',
    },
    '& .ui-button__label': {
      padding: variant === 'small' ? '3px 0 1px 0' : '9px 0 7px 0',
    },
    '&[data-with-icon]': {
      paddingLeft: '18px',
    },
    '&[data-with-icon-right]': {
      paddingRight: '18px',
    },
    '&[data-with-icon-only]': {
      padding: variant === 'small' ? '4px' : '8px',
      minWidth: 0,
    },
    '&:hover': {
      opacity: 0.8,
      background: COLORS.BLUE,
    },
    '&:active': {
      opacity: 1,
      background: COLORS.BLUE,
    },
    '&[data-active]': {
      background: COLORS.BLUE,
    },
    '&[data-disabled]': {
      opacity: 0.3,
    },
  },
});

export interface ButtonPrimaryProps {
  variant?: ButtonPrimaryVariant;
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  title?: string;
  ariaLabel?: string;
  icon?: any;
  iconRight?: any;
  active?: boolean;
  disabled?: boolean;
  href?: string;
  target?: string;
  onClick?: React.MouseEventHandler;
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({ variant, ...props }) => {
  return <Button {...props} theme={theme({ variant })} />;
};

export default ButtonPrimary;
