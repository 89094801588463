import React from 'react';
import {
  components,
  MultiValueRemoveProps,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
} from 'react-select';
import { IconChevronDown, IconClose } from '@webfox-sc/icons';

const MultiValueRemove: React.FC<MultiValueRemoveProps> = ({ children, ...props }) => {
  return <components.MultiValueRemove {...props}>{IconClose}</components.MultiValueRemove>;
};

const ClearIndicator: React.FC<ClearIndicatorProps> = ({ children, ...props }) => {
  return <components.ClearIndicator {...props}>{IconClose}</components.ClearIndicator>;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ children, ...props }) => {
  return <components.DropdownIndicator {...props}>{IconChevronDown}</components.DropdownIndicator>;
};

const IndicatorSeparator: React.FC<IndicatorSeparatorProps> = () => {
  return null;
};

export const selectComponentsWithoutDropdownIndicator = {
  MultiValueRemove,
  ClearIndicator,
  DropdownIndicator: null,
  IndicatorSeparator,
};

const selectComponents = {
  MultiValueRemove,
  ClearIndicator,
  DropdownIndicator,
  IndicatorSeparator,
};

export default selectComponents;
