import { Group } from '@webfox-sc/core';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { updatePage } from '../../app/slices/pagesSlice';
import { COLORS, SPACINGS } from '../../styles/theme';
import { useIssuePages } from './useIssuePages';
import IssuePage from './IssuePage';
import { refetchIssue } from '../../app/slices/issuesSlice';
import { useCurrentIssue } from './useCurrentIssue';
import useAPIError from '../apiError/useAPIError';

const StyledContent = styled.div({
  position: 'relative',
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
  transition: 'background-color 0.3s ease',
});

interface StyledContentDropOverlayProps {
  canDrop?: boolean;
  isDragOver?: boolean;
}

const StyledContentDropOverlay = styled.div<StyledContentDropOverlayProps>(({ canDrop, isDragOver }) => {
  return {
    display: canDrop ? 'block' : 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: `2px dashed ${isDragOver ? COLORS.BLUE : COLORS.MID_GREY}`,
  };
});

const IssueElementBoxPages: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { issueId } = useCurrentIssue();
  const { pagesSortedByCreatedDate: unassignedPages } = useIssuePages(undefined);

  const handleOnDropPageItem = async (item: PageDragItem) => {
    if (!!item.id && !!item.issueId) {
      const updatePageResponse = await dispatch(
        updatePage(item.id, {
          issue: null,
        })
      );
      if (updatePageResponse.payload?.error) {
        addError(updatePageResponse.payload.error.message);
      }
      if (issueId) {
        const refetchIssueResponse = await dispatch(refetchIssue(issueId));
        if (refetchIssueResponse.payload?.error) {
          addError(refetchIssueResponse.payload.error.message);
        }
      }
    }
  };

  const [{ canDrop, isDragOver }, dropTarget] = useDrop(() => ({
    accept: 'issuePage',
    drop: (item) => handleOnDropPageItem(item),
    canDrop: (item: PageDragItem) => {
      return !!item.issueId;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isDragOver: monitor.isOver(),
    }),
  }));

  return (
    <StyledContent ref={dropTarget}>
      <Group vertical spacing={SPACINGS.XXS}>
        {unassignedPages?.map((page) => {
          return <IssuePage key={page.id} pageId={page.id} index={0} />;
        })}
      </Group>
      <StyledContentDropOverlay canDrop={canDrop} isDragOver={isDragOver} />
    </StyledContent>
  );
};

export default React.memo(IssueElementBoxPages);
