import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { CSSObject } from 'styled-components';

import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { COLORS, SPACINGS } from '../../styles/theme';
import TextArea from '../shared/atoms/TextArea';
import FormHtmlEditorToolbar from './FormHtmlEditorToolbar';

const cellStyles = {
  minWidth: '1em',
  border: `2px solid ${COLORS.GREY}`,
  padding: SPACINGS.XXXS,
  verticalAlign: 'top',
  boxSizing: 'border-box',
  position: 'relative',
  '> *': {
    marginBottom: 0,
  },
};

interface StyledComponentProps {
  width?: string | 0;
  error?: boolean;
  isFocus?: boolean;
}

// const CustomTableCell = TableCell.extend({
//   addAttributes() {
//     return {
//       // extend the existing attributes …
//       ...this.parent?.(),

//       // and add a new one …
//       backgroundColor: {
//         default: null,
//         parseHTML: (element) => element.getAttribute('data-background-color'),
//         renderHTML: (attributes) => {
//           return {
//             'data-background-color': attributes.backgroundColor,
//             style: `background-color: ${attributes.backgroundColor}`,
//           };
//         },
//       },
//     };
//   },
// });

const EditorWrapper = styled.div<StyledComponentProps>(({ width, error, isFocus }): CSSObject => {
  return {
    position: 'relative',
    boxSizing: 'border-box',
    width: width || '100%',
    border: `2px solid ${error ? COLORS.RED : isFocus ? COLORS.GREY : COLORS.MID_GREY}`,
    borderRadius: '3px',
    background: COLORS.WHITE,
    transition: 'border-color 0.2s ease',
    '.ProseMirror': {
      boxSizing: 'border-box',
      height: 'calc(100vh - 450px)',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: `${SPACINGS.XXS} ${SPACINGS.S}`,
      '& div[data-contents]': {
        paddingBottom: SPACINGS.XXS,
      },

      table: {
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: '100%',
        margin: 0,
        overflow: 'hidden',
        td: cellStyles,
        th: {
          ...cellStyles,
          fontWeight: 'normal',
          textAlign: 'left',
          backgroundColor: COLORS.SILVER,
          '&.selectedCell': {
            background: 'rgba(200, 200, 255, 0.7)',
          },
        },

        '.column-resize-handle': {
          position: 'absolute',
          right: '-2px',
          top: 0,
          bottom: '-2px',
          width: '4px',
          backgroundColor: '#adf',
          pointerEvents: 'none',
        },
      },
    },
    '.selectedCell': {
      background: 'rgba(200, 200, 255, 0.4)',
      pointerEvents: 'none',
    },
    '.tableWrapper': {
      overflowX: 'auto',
    },
    '.resize-cursor': {
      cursor: 'col-resize',
    },
  };
});

interface FormHtmlEditorProps {
  htmlContent?: string;
  onBlur?: (htmlContent: string) => void;
}

const FormHtmlEditor: React.FC<FormHtmlEditorProps> = ({ htmlContent, onBlur }) => {
  const [showEditor, setShowEditor] = useState(true);

  const htmlEditor = useEditor({
    onBlur({ editor }) {
      if (onBlur) {
        const content = editor.getHTML();
        onBlur(content);
      }
    },
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Underline,
    ],
    content: htmlContent,
  });

  useEffect(() => {
    if (htmlEditor && showEditor) {
      htmlEditor
        .chain()
        .setContent(htmlContent || '')
        .run();
    }
  }, [htmlContent, htmlEditor, showEditor]);

  return (
    <EditorWrapper>
      <FormHtmlEditorToolbar editor={htmlEditor} showEditor={showEditor} setShowEditor={setShowEditor} />
      {showEditor ? (
        <EditorContent editor={htmlEditor} />
      ) : (
        <TextArea
          rows={30}
          name="content"
          value={htmlContent}
          onChange={(e) => onBlur && onBlur(e.target.value)}
          onBlur={(e) => onBlur && onBlur(e.target.value)}
        />
      )}
    </EditorWrapper>
  );
};

export default FormHtmlEditor;
