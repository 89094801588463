/* eslint-disable @typescript-eslint/no-explicit-any */
type AnyData = {
  [props: string]: any;
};

export const getEntityId = (dataOrId: AnyData | number | null | undefined): number | undefined => {
  if (!dataOrId) {
    return undefined;
  }

  if (typeof dataOrId === 'object') {
    if (dataOrId.data && typeof dataOrId.data === 'object') {
      return dataOrId.data.id;
    }
    return dataOrId.id;
  }

  if (typeof dataOrId === 'number') {
    return dataOrId;
  }

  return undefined;
};
