import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export const useCurrentIssuePage = (): {
  pageId?: number;
  page?: PageEntity;
} => {
  const { sPageId } = useParams();
  const pageId = parseInt(sPageId || '', 10);
  const page = useAppSelector(({ entities }) => entities.pages.entities[pageId]);

  return {
    pageId,
    page,
  };
};
