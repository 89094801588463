/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group } from '@webfox-sc/core';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { fetchAsset } from '../../app/slices/assetsSlice';
import { createMediaAsset } from '../../app/slices/mediaAssetsSlice';
import { SPACINGS } from '../../styles/theme';
import useAPIError from '../apiError/useAPIError';
import FormSectionChannels from '../channels/FormSectionChannels';
import FormHtmlEditor from '../htmlEditor/FormHtmlEditor';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import Headline from '../shared/atoms/Headline';
import Input from '../shared/atoms/Input';
import FormSection from '../shared/forms/FormSection';
import FormSectionMediaAssetFile from './FormSectionMediaAssetFile';
import FormSectionMediaAssetType from './FormSectionMediaAssetType';
import { useAsset } from './useAsset';

interface AssetEditMediaCreateProps {
  assetId: number;
  onBeforeEdit?: () => void;
  onMediaAssetCreated?: () => void;
  onClickCancel?: React.MouseEventHandler;
}

const AssetEditMediaCreate: React.FC<AssetEditMediaCreateProps> = ({
  assetId,
  onBeforeEdit,
  onMediaAssetCreated,
  onClickCancel,
}) => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { asset } = useAsset(assetId);

  const [mediaAssetType, setMediaAssetType] = useState<MediaAssetType | undefined>('file');
  const [file, setFile] = useState<File | undefined | null>(undefined);
  const [alternativeText, setAlternativeText] = useState('');
  const [channelIds, setChannelIds] = useState<number[]>([]);
  const [content, setContent] = useState<string>('');

  const [hasChannelsError, setHasChannelsError] = useState(false);

  useEffect(() => {
    if (channelIds.length > 0) {
      setHasChannelsError(false);
    }
  }, [channelIds.length]);

  const handleOnClickCreate = async (): Promise<boolean> => {
    if (!asset) {
      return false;
    }

    if (!channelIds.length) {
      setHasChannelsError(true);
      return false;
    }

    /**
     * create mediaAssetRequestData
     */
    const mediaAssetRequestData = new FormData();
    const data: { [k: string]: any } = {
      channels: channelIds,
      content: file ? null : content,
    };
    if (onBeforeEdit) {
      onBeforeEdit();
    }
    if (file) {
      mediaAssetRequestData.append('files.files', file, file.name);
      data.fileInfo = {
        alternativeText,
      };
    }
    mediaAssetRequestData.append('data', JSON.stringify(data));

    /**
     * create media asset
     */
    const createMediaAssetResponse = await dispatch(createMediaAsset(assetId, mediaAssetRequestData));
    if (createMediaAssetResponse.payload?.error) {
      addError(createMediaAssetResponse.payload.error.message);
    }
    if (!createMediaAssetResponse.payload?.data) {
      return false;
    }

    /**
     * re-fetch asset
     * TODO Dev temp
     */
    const fetchAssetResponse = await dispatch(fetchAsset(asset.id));
    if (fetchAssetResponse.payload?.error) {
      addError(fetchAssetResponse.payload.error.message);
    }

    if (typeof onMediaAssetCreated === 'function') {
      onMediaAssetCreated();
    }
    return true;
  };

  return (
    <>
      <Headline>Medium hinzufügen</Headline>

      <FormSectionMediaAssetType paddingTop={SPACINGS.S} mediaAssetType={mediaAssetType} onChange={setMediaAssetType} />

      {mediaAssetType === 'file' ? (
        <>
          <FormSectionMediaAssetFile paddingTop={SPACINGS.M} onChangeFile={setFile} />

          <FormSection title="Alternativer Text" paddingTop={SPACINGS.S}>
            <Input
              name="alternativeText"
              autoComplete="ignore"
              value={alternativeText}
              onChange={(e) => setAlternativeText(e.target.value)}
            />
          </FormSection>
        </>
      ) : (
        <FormSection title="Inhalt" paddingTop={SPACINGS.S}>
          <FormHtmlEditor htmlContent={content} onBlur={setContent} />
        </FormSection>
      )}

      <FormSectionChannels
        paddingTop={SPACINGS.S}
        defaultChannelIds={[]}
        onChange={setChannelIds}
        hasError={hasChannelsError}
      />

      <FormSection paddingTop={SPACINGS.XL}>
        <Group spacing={SPACINGS.XXS}>
          <ButtonPrimary label="Hinzufügen" disabled={!file && !content} onClick={handleOnClickCreate} />
          <ButtonSecondary label="Abbrechen" onClick={onClickCancel} />
        </Group>
      </FormSection>
    </>
  );
};

export default AssetEditMediaCreate;
