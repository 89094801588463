import styled, { CSSObject } from 'styled-components';
import { COLORS } from '../../styles/theme';

interface TooltipProps {
  wrapperRect: DOMRect;
  anchorRect: DOMRect;
}

const tooltipWidth = 300;
const tooltipHeight = 300;

const StyledTooltipWrapper = styled.div(({ orientation, alignment, styles }) => {
  const style: CSSObject = {
    position: 'absolute',
    zIndex: 1000,
    display: 'flex',
    minWidth: '180px',
  };
  if (orientation === 'top' || orientation === 'bottom') {
    if (orientation === 'top') {
      style.flexDirection = 'column-reverse';
      style.bottom = '100%';
    } else if (orientation === 'bottom') {
      style.flexDirection = 'column';
      style.top = '100%';
    }
    if (alignment === 'left') {
      style.right = '100%';
    } else if (alignment === 'right') {
      style.left = '100%';
    } else {
      // center
      style.transform = 'translateX(-50%)';
      style.left = '50%';
    }
  } else {
    if (orientation === 'left') {
      style.flexDirection = 'row-reverse';
      style.right = '100%';
    } else {
      // right
      style.left = '100%';
    }
    if (alignment === 'top') {
      style.bottom = '100%';
    } else if (alignment === 'bottom') {
      style.top = '100%';
    } else {
      // center
      style.transform = 'translateY(-50%)';
      style.top = '50%';
    }
  }
  return {
    ...style,
    ...styles,
  };
});

const StyledTooltip = styled.div(({ styles = {} }) => {
  return {
    border: `2px solid ${COLORS.MID_GREY}`,
    backgroundColor: COLORS.WHITE,
    color: COLORS.DARK,
    flex: '1 1 auto',
    width: tooltipWidth,
    ...styles,
  };
});

const getTooltipOrientation = ({ top, bottom, left, right }, { width: elementWidth, height: elementHeight }) => {
  let orientation;
  let alignment;
  if (bottom < elementHeight) {
    // no space below
    if (top < elementHeight) {
      // no space above
      // left or right
      if (right < elementWidth) {
        // no space right
        orientation = 'left';
      } else {
        orientation = 'right';
      }
      // determine alignment, default: center
      if (top < elementHeight / 2) {
        // no space above
        alignment = 'bottom';
      } else if (bottom < elementHeight / 2) {
        // no space below
        alignment = 'top';
      } else {
        alignment = 'center';
      }
    } else {
      orientation = 'top';
    }
  } else {
    orientation = 'bottom';
  }
  if (!alignment) {
    // alignment for orientation top or bottom, default: center
    if (left < elementWidth / 2) {
      // no space left
      alignment = 'right';
    } else if (right < elementWidth / 2) {
      // no space below
      alignment = 'left';
    } else {
      alignment = 'center';
    }
  }

  return { orientation, alignment };
};

const getRemainigSpace = (outer: DOMRect, inner: DOMRect) => {
  return {
    top: inner.y - outer.y,
    bottom: outer.y + outer.height - (inner.y + inner.height),
    left: inner.x - outer.x,
    right: outer.x + outer.width - (inner.x + inner.width),
  };
};

const Tooltip: React.FC<TooltipProps> = ({ children, wrapperRect, anchorRect }) => {
  const { top, bottom, left, right } = getRemainigSpace(wrapperRect, anchorRect);
  const { orientation, alignment } = getTooltipOrientation(
    { top, bottom, left, right },
    { width: tooltipWidth, height: tooltipHeight }
  );

  return (
    <StyledTooltipWrapper orientation={orientation} alignment={alignment}>
      <StyledTooltip styles={{ margin: 10 }}>{children}</StyledTooltip>
    </StyledTooltipWrapper>
  );
};

export default Tooltip;
