import { entitySelectors } from '../../app/entitySelectors';

export const useSnippets = (
  snippetIds?: number[]
): {
  snippets: SnippetEntity[];
} => {
  const snippets = entitySelectors.selectByIds<SnippetEntity>('snippets', snippetIds);

  return {
    snippets,
  };
};
