import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useAuthors = (
  authorIds?: number[]
): {
  authorsSorted: AuthorEntity[];
  authorsSortedFormatted: string;
} => {
  const authors = entitySelectors.selectByIds<AuthorEntity>('authors', authorIds);

  const authorsSorted = useMemo(() => {
    return _orderBy(authors, [(author) => author.displayName.toLowerCase()], ['asc']);
  }, [authors]);

  const authorsSortedFormatted = authorsSorted.map((author) => author.displayName).join(', ');

  return {
    authorsSorted,
    authorsSortedFormatted,
  };
};
