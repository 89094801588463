import React from 'react';
import CustomAsyncSelect from '../shared/select/CustomAsyncSelect';

type TagOption = {
  value: number;
  label?: string;
};

interface SelectTagsProps {
  tagOptions?: TagOption[];
  onChangeTagOptions?: (tagOptions: TagOption[]) => void;
  onChangeTagIds?: (tagIds: number[]) => void;
}

const SelectTags: React.FC<SelectTagsProps> = ({ tagOptions, onChangeTagOptions, onChangeTagIds }) => {
  return (
    <CustomAsyncSelect
      asyncPath="/tags"
      asyncSort={['name:asc']}
      setAsyncParams={(inputValue, params) => {
        params.filters = {
          name: {
            $containsi: inputValue,
          },
        };
      }}
      value={tagOptions}
      placeholder="Suchen ..."
      formatOption={(entry) => ({
        value: entry.id,
        label: `#${entry.name}`,
      })}
      isMulti
      isClearable={false}
      isDropdownIndicator={false}
      onChange={(options) => {
        if (onChangeTagOptions) {
          onChangeTagOptions(options);
        }
        if (onChangeTagIds) {
          onChangeTagIds(options.map((tagOption: TagOption) => tagOption.value));
        }
      }}
    />
  );
};

export default SelectTags;
