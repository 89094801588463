import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useArticleAuthors = (
  articleId: number | undefined
): {
  authors?: AuthorEntity[];
  authorIds?: number[];
} => {
  const article = useAppSelector(({ entities }) => entities.articles.entities[articleId || 0]);
  const sections = entitySelectors.selectByIds<SectionEntity>('sections', article?.sectionIds);

  const snippetIds = useMemo(() => {
    const ids: number[] = [];
    sections.forEach((section) => {
      if (section.snippetId && !ids.includes(section.snippetId)) {
        ids.push(section.snippetId);
      }
    });
    return ids;
  }, [sections]);

  const snippets = entitySelectors.selectByIds<SnippetEntity>('snippets', snippetIds);

  const authorIds = useMemo(() => {
    const ids: number[] = [];
    snippets.forEach((snippet) => {
      if (snippet.authorId && !ids.includes(snippet.authorId)) {
        ids.push(snippet.authorId);
      }
    });
    return ids;
  }, [snippets]);

  const authors = entitySelectors.selectByIds<AuthorEntity>('authors', authorIds);

  return {
    authors,
    authorIds,
  };
};
