import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// import Underline from '@tiptap/extension-underline';
import { Editor, Range } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { Markdown } from 'tiptap-markdown';
import { apiUrl } from '../../app/api';
import { useAppSelector } from '../../app/hooks';
import { selectAuthState } from '../../app/slices/authSlice';
import Text from '../shared/atoms/Text';
import TextArea from '../shared/atoms/TextArea';
import { TextSectionMeta } from '../snippet/TextSectionMeta';
import { useSnippetSize } from '../snippet/useSnippetSize';
import AdviceOverlayProvider from './AdviceOverlayProvider';
import { EditorContentWrapper } from './EditorContentWrapper';
import MarkdownEditorToolbar from './MarkdownEditorToolbar';
import { MarkdownEditorWrapper } from './MarkdownEditorWrapper';
import { removeAdviceTags, replaceAdviceNodeWithText } from './utils';
import { Advice, AdviceExtension, AdviceView } from './AdviceExtension';
import { SuggestionExtension } from './SuggestionExtension';
import { debounce } from 'lodash';
import { useCurrentArticle } from '../article/useCurrentArticle';

interface SuggestionResponse {
  suggestion: string;
}

interface ErrorInfo {
  offset: number;
  length: number;
  text?: string;
  originalError?: string;
}

interface MarkdownEditorProps {
  markdownContent?: string;
  onChange?: (markdownContent: string, noUpdate?: boolean) => void;
}

Markdown.configure({
  html: true, // Allow HTML input/output
  tightLists: true, // No <p> inside <li> in markdown output
  tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
  bulletListMarker: '-', // <li> prefix in markdown output
  linkify: false, // Create links from "https://..." text
  breaks: false, // New lines (\n) in markdown input are converted to <br>
  transformPastedText: false, // Allow to paste markdown text in the editor
  transformCopiedText: true, // Copied text is transformed to markdown,
});

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({ markdownContent, children, onChange }) => {
  const { jwt } = useAppSelector(selectAuthState);
  const [showEditor, setShowEditor] = useState(true);
  const [suggestionsLoading, setsuggestionsLoading] = useState(false);
  const [suggestionsEnabled, setsuggestionsEnabled] = useState(true);
  const [canEnableSuggestions, setCanEnableSuggestions] = useState(true);
  const [suggestedText, setSuggestedText] = useState('');
  const { articleId, article } = useCurrentArticle();
  const axiosSource = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    const canTurnOnSuggestions = !!articleId && !!article && !!article.storyId;

    setsuggestionsEnabled(canTurnOnSuggestions);
    setCanEnableSuggestions(canTurnOnSuggestions);
  }, [articleId, article]);

  const tryCancelSuggestionRequest = (reason?: string) => {
    if (axiosSource.current) {
      axiosSource.current.cancel(reason);
      setsuggestionsLoading(false);
    }
  };

  const fetchSuggestion = useCallback(
    async (text: string) => {
      tryCancelSuggestionRequest('Operation canceled due to new space press or key press.');
      axiosSource.current = axios.CancelToken.source();

      let response: AxiosResponse<SuggestionResponse, unknown>;
      try {
        setsuggestionsLoading(true);
        response = await axios.post<SuggestionResponse>(
          `${apiUrl}/articles/${articleId}/suggestion`,
          {
            sectionText: text,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
            cancelToken: axiosSource.current.token,
          }
        );
      } finally {
        setsuggestionsLoading(false);
      }
      if (response.data.suggestion) {
        setSuggestedText(response.data.suggestion);
      }
    },
    [articleId, jwt]
  );

  const debouncedFetchSuggestion = useRef(
    debounce((editor: Editor) => {
      const cursorPos = editor.state.selection.anchor;
      const text = editor.getText();
      fetchSuggestion(text.slice(0, cursorPos));
    }, 3000)
  ).current;

  const handleOnEditorUpdate = useCallback(
    (props) => {
      const { transaction, editor } = props;

      if (suggestionsEnabled) {
        const lastInput = transaction.steps.flatMap((x) => x.slice.content.content).map((x) => x.text)[0];
        const isLoadingIndicator =
          transaction.steps.flatMap((x) => x.slice.content.content).map((x) => x?.type?.name)[0] === 'loadingIndicator';
        if (!suggestedText && lastInput === ' ') {
          tryCancelSuggestionRequest('Operation canceled due to new space press.');
          debouncedFetchSuggestion(editor);
        } else if (!isLoadingIndicator) {
          tryCancelSuggestionRequest('Operation canceled due to key press.');
          debouncedFetchSuggestion.cancel();
        }
      }

      if (transaction?.meta?.paste && onChange) {
        const content = editor.storage.markdown.getMarkdown();
        onChange(content);
      } else if (transaction?.curSelection?.$anchor?.path) {
        // remove adviceView if something inside is changed
        const { path } = transaction.curSelection.$anchor;
        const adviceNodeIndex = path.findIndex((node) => node?.type?.name === 'adviceView');

        if (adviceNodeIndex >= 0) {
          const adviceNode = path[adviceNodeIndex];
          // TODO last numeric segment
          const parentIndex = path[adviceNodeIndex - 1];
          const textContent = adviceNode.textContent;
          replaceAdviceNodeWithText(editor, parentIndex, textContent, adviceNode.key);
        }
      }
    },
    [onChange, debouncedFetchSuggestion, suggestedText, suggestionsEnabled]
  );

  const markdownEditor = useEditor({
    onBlur({ editor }) {
      if (onChange) {
        tryCancelSuggestionRequest('Operation canceled due to mouse press.');
        const content = editor.storage.markdown.getMarkdown();
        onChange(content, true);
      }
    },
    extensions: [
      StarterKit,
      Markdown,
      Link,
      // CharacterCount,
      AdviceExtension,
      AdviceView,
      Advice,
      // Underline,
      SuggestionExtension,
    ],
    parseOptions: {
      preserveWhitespace: 'full',
    },
    content: markdownContent,
    onUpdate: handleOnEditorUpdate,
  });

  useEffect(() => {
    if (!markdownEditor) return;
    try {
      if (suggestedText) {
        markdownEditor.commands.insertSuggestionNode(suggestedText);
      } else {
        markdownEditor.commands.removeSuggestionsOrLoadingIndicatorNodes();
      }
    } catch (error) {
      // Sometimes TipTap throws exception
    }
  }, [suggestedText, markdownEditor]);

  useEffect(() => {
    if (markdownEditor && showEditor) {
      markdownEditor
        .chain()
        .setContent(markdownContent || '')
        .run();
    }
  }, [markdownContent, markdownEditor, showEditor]);

  useEffect(() => {
    markdownEditor?.commands.setLoadingNode(suggestionsLoading);
  }, [markdownEditor, suggestionsLoading]);

  const handleToggleEditor = (show) => {
    if (markdownEditor && onChange) {
      if (!show) {
        // exitor is about to be hidden, make sure data is copied to state
        const content = markdownEditor.storage.markdown.getMarkdown();
        onChange(content || '');
      }
      setShowEditor(show);
    }
  };

  const getErrorPosition = (error: ErrorInfo): Range => {
    const { offset, length, originalError } = error;
    let leadingSpaces = 0;
    let trailingSpaces = 0;
    if (originalError) {
      const match = originalError.match(/^([\s]*).*.*[^\s]([\s]*)$/);
      if (match) {
        leadingSpaces = match[1].length;
        trailingSpaces = match[2].length;
      }
    }

    // TODO custom element, maybe use regex to get exact position

    const from = offset + 1 + leadingSpaces;
    const to = offset + 1 - leadingSpaces - trailingSpaces + length;
    return { from, to };
  };

  const handleOnCheckSpelling = async () => {
    if (markdownEditor) {
      // remove advice from previous checks
      const markdown = markdownEditor.storage.markdown.getMarkdown();
      markdownEditor
        .chain()
        .setContent(removeAdviceTags(markdown) || '')
        .run();
      markdownEditor.storage.adviceExtension = {};
      const text = markdownEditor.getText();
      try {
        const response = await axios.post(
          `${apiUrl}/apis/spellcheck/execute`,
          { text },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        if (response?.data?.data) {
          const { spellAdvices, styleAdvices } = response?.data?.data;
          // setShowEditor(false);
          if (spellAdvices?.length || styleAdvices?.length) {
            (spellAdvices || []).map((spellAdvice) => {
              const { offset, length, originalError, shortMessage, errorMessage, additionalInformation, proposals } =
                spellAdvice;
              const errorPosition = getErrorPosition({ offset, length, originalError, text });
              const key = `sp_${offset}`;
              markdownEditor.storage.adviceExtension[key] = {
                shortMessage,
                errorMessage,
                additionalInformation,
                proposals,
              };

              markdownEditor
                .chain()
                .setTextSelection(errorPosition)
                .setMark('advice', {
                  type: 'spell',
                  key,
                })
                .run();
            });
            (styleAdvices || []).map((styleAdvice) => {
              const { offset, length, originalError, shortMessage, errorMessage, additionalInformation, proposals } =
                styleAdvice;
              const errorPosition = getErrorPosition({ offset, length, originalError, text });
              const key = `st_${offset}`;
              markdownEditor.storage.adviceExtension[key] = {
                shortMessage,
                errorMessage,
                additionalInformation,
                proposals,
              };
              markdownEditor
                .chain()
                .setTextSelection(errorPosition)
                .setMark('advice', {
                  type: 'style',
                  key,
                })
                .run();
            });
            // re-render to turn marks into nodes
            const newMarkdown = markdownEditor.storage.markdown.getMarkdown();
            markdownEditor
              .chain()
              .setContent(newMarkdown || '')
              .run();
          }
        }
      } catch (e) {}
    }
  };

  const handleKeyDown = (event) => {
    if (!markdownEditor || !suggestionsEnabled) return;

    const textToInsert = suggestedText || markdownEditor.storage.extension.suggestionNodeContent;
    if (!textToInsert) {
      return;
    }
    const isMac = navigator.userAgent.includes('Mac');
    const ctrlOrCmd = isMac ? event.metaKey : event.ctrlKey;

    const nextWordShortcut = !ctrlOrCmd && event.key === 'Tab';
    const nextSentenceShortcut = ctrlOrCmd && event.key === 'ArrowRight';
    const nextParagraphShortcut = ctrlOrCmd && event.key === 'Enter';
    const undoShortcut = ctrlOrCmd && event.key === 'z';

    if (event.key === 'Control' || event.key === 'Meta') return; // Ignore single cmd/ctrl

    while (
      undoShortcut &&
      (!!markdownEditor.storage.extension.suggestionNodeContent ||
        markdownEditor.storage.extension.isLoadingIndicatorVisible)
    ) {
      // If user undo we want to get rid of suggestions and loading indicator
      markdownEditor.commands.undo();
    }
    if (!nextWordShortcut && !nextSentenceShortcut && !nextParagraphShortcut && suggestedText) {
      setSuggestedText('');
      return;
    }

    event.preventDefault();

    const getFirstWordAndRest = (text) => {
      const words = text.trim().split(/\s+/);
      const firstWord = words[0];
      const restOfText = words.slice(1).join(' ');
      return [firstWord, restOfText];
    };
    const getFirstSentenceAndRest = (text) => {
      const sentences = text.trim().split(/(?<=[.!?])\s+/);
      const firstSentence = sentences[0];
      const restOfText = sentences.slice(1).join(' ');
      return [firstSentence, restOfText];
    };

    if (nextParagraphShortcut) {
      markdownEditor.commands.insertTextNode(textToInsert);
      setSuggestedText('');
      return;
    }

    let [toInsert, restOfSuggestion] = ['', ''];

    if (nextWordShortcut) {
      [toInsert, restOfSuggestion] = getFirstWordAndRest(textToInsert);
    } else if (nextSentenceShortcut) {
      [toInsert, restOfSuggestion] = getFirstSentenceAndRest(textToInsert);
    }

    if (toInsert || restOfSuggestion) {
      markdownEditor.commands.removeSuggestionsOrLoadingIndicatorNodes();
      markdownEditor.commands.insertTextNode(`${toInsert} `);
      setSuggestedText(restOfSuggestion);
    }
  };

  const handleClick = () => {
    if (!markdownEditor) return;

    tryCancelSuggestionRequest('Operation canceled due to mouse press.');
    markdownEditor.commands.removeSuggestionsOrLoadingIndicatorNodes();
  };

  const { charCount, linesCount } = useSnippetSize(markdownContent);

  return (
    <MarkdownEditorWrapper>
      {children && <TextSectionMeta borderBottom>{children}</TextSectionMeta>}
      <MarkdownEditorToolbar
        editor={markdownEditor}
        showEditor={showEditor}
        setShowEditor={handleToggleEditor}
        onCheckSpelling={handleOnCheckSpelling}
        suggestionsEnabled={suggestionsEnabled}
        canEnableSuggestions={canEnableSuggestions}
        setSuggestionsEnabled={setsuggestionsEnabled}
      />
      {showEditor && markdownEditor ? (
        <AdviceOverlayProvider>
          <EditorContentWrapper onClick={handleClick} onKeyDown={handleKeyDown} editor={markdownEditor} />
        </AdviceOverlayProvider>
      ) : (
        <TextArea
          rows={30}
          name="content"
          noBorder
          value={markdownContent}
          onChange={(e) => onChange && onChange(e.target.value)}
          onBlur={(e) => onChange && onChange(e.target.value)}
        />
      )}
      <TextSectionMeta borderTop>
        <Text variant="small">
          {charCount} Zeichen (= {linesCount} {linesCount === 1 ? 'Zeile' : 'Zeilen'})
        </Text>
      </TextSectionMeta>
    </MarkdownEditorWrapper>
  );
};

export default MarkdownEditor;
