import { useAppSelector } from '../../app/hooks';
import { snippetContentFormats } from './snippetContentFormats';

export const useSnippet = (
  snippetId: number | undefined
): {
  snippet?: SnippetEntity | undefined;
  contentFormatLabel: string | undefined;
} => {
  const snippet = useAppSelector(({ entities }) => entities.snippets.entities[snippetId || 0]);
  const contentFormatLabel: string | undefined = snippetContentFormats[snippet?.contentFormat || ''] || undefined;

  return {
    snippet,
    contentFormatLabel,
  };
};
