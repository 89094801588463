import React from 'react';
import { InlineSVG } from '@webfox-sc/core';
import IconSpinner from '../../../assets/icons/IconSpinner';
import { COLORS } from '../../../styles/theme';

const Spinner: React.FC = () => {
  return <InlineSVG color={COLORS.MID_GREY}>{IconSpinner}</InlineSVG>;
};

export default Spinner;
