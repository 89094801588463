import React, { useEffect, useState } from 'react';
import FormSection from '../shared/forms/FormSection';
import { entitySelectors } from '../../app/entitySelectors';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { moveArrayElement, removeArrayElement } from '../../utils/array';
import Text from '../shared/atoms/Text';
import { Group, GroupItem } from '@webfox-sc/core';
import { SPACINGS } from '../../styles/theme';
import FormButton from '../shared/atoms/FormButton';
import { IconPlus } from '@webfox-sc/icons';
import FormSectionHeadlineInput from './FormSectionHeadlineInput';

interface FormSectionHeadlinesProps {
  paddingTop?: string;
  defaultHeadlineIds?: number[] | undefined;
  onChange?: (headlines: HeadlineUpdateData[]) => void;
  onFocusAnyInput?: (e: React.FocusEvent) => void;
}

const FormSectionHeadlines: React.FC<FormSectionHeadlinesProps> = ({
  paddingTop,
  defaultHeadlineIds,
  onChange,
  onFocusAnyInput,
}) => {
  const articleHeadlines = entitySelectors.selectByIds<HeadlineUpdateData>('headlines', defaultHeadlineIds);

  const [headlines, setHeadlines] = useState<HeadlineUpdateData[]>([]);

  useEffect(() => {
    const newHeadlines = articleHeadlines.length > 0 ? [...articleHeadlines] : [{ text: '' }];
    setHeadlines(newHeadlines);
  }, [articleHeadlines]);

  const handleOnChangeHeadline = (index: number, text: string): void => {
    headlines[index] = {
      ...headlines[index],
      text,
    };

    if (onChange) {
      onChange(headlines);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newHeadlines = moveArrayElement(headlines, oldIndex, newIndex);
    setHeadlines(newHeadlines);

    if (onChange) {
      onChange(newHeadlines);
    }
  };

  const handleOnDeleteHeadline = (index: number) => {
    let newHeadlines: HeadlineUpdateData[];
    if (headlines.length > 1) {
      newHeadlines = removeArrayElement(headlines, index);
    } else {
      newHeadlines = [{ text: '' }];
    }
    setHeadlines(newHeadlines);

    if (onChange) {
      onChange(newHeadlines);
    }
  };

  const addHeadline = () => {
    const newHeadlines = [...headlines];
    newHeadlines.push({ text: '' });
    setHeadlines(newHeadlines);

    if (onChange) {
      onChange(newHeadlines);
    }
  };

  const SortableHeadline = ({ index, headline }: { index: number; headline: string | undefined }) => {
    const SortableHeadlineElement = SortableElement(({ value }) => {
      return (
        <FormSectionHeadlineInput
          value={value}
          onChange={(headlineText) => handleOnChangeHeadline(index, headlineText)}
          onFocus={onFocusAnyInput}
          deletable={true}
          onDelete={() => handleOnDeleteHeadline(index)}
        />
      );
    });

    return <SortableHeadlineElement key={`headline-${index}`} index={index} value={headline} />;
  };

  const SortableHeadlines = SortableContainer(({ items }: { items: HeadlineUpdateData[] }) => {
    return (
      <FormSection title="Überschriften" paddingTop={paddingTop}>
        {items.map((headline, index) => (
          <SortableHeadline key={String(index)} index={index} headline={headline.text} />
        ))}
      </FormSection>
    );
  });

  return (
    <>
      <SortableHeadlines items={headlines} onSortEnd={onSortEnd} useDragHandle />
      <Group alignItems="center" spacing={SPACINGS.XXS}>
        <GroupItem>
          <FormButton variant="smallWithBorder" ariaLabel="plus" icon={IconPlus} onClick={addHeadline} />
        </GroupItem>
        <GroupItem grow>
          <Text>Alternative hinzufügen</Text>
        </GroupItem>
      </Group>
    </>
  );
};

export default React.memo(FormSectionHeadlines);
