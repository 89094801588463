import _orderBy from 'lodash/orderBy';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../app/hooks';

export const useSelectedChannels = (
  channelIds?: number[]
): {
  channelsSorted: ChannelEntity[];
  channelsSortedFormatted: string;
} => {
  const channelsSorted: ChannelEntity[] = useAppSelector(({ entities }) => {
    const channels: ChannelEntity[] = [];
    (channelIds || []).forEach((channelId) => {
      const channel = entities.channels.entities[channelId];
      if (channel) {
        channels.push(channel);
      }
    });
    return _orderBy(channels, [(channel) => channel.name?.toLowerCase()], ['asc']);
  }, shallowEqual);

  const channelsSortedFormatted = channelsSorted.map((channel) => channel.name).join(', ');

  return {
    channelsSorted,
    channelsSortedFormatted,
  };
};
