import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useAssetMedia = (
  assetId: number | undefined
): {
  asset?: AssetEntity;
  mediaAssets: MediaAssetEntity[];
} => {
  const asset = useAppSelector(({ entities }) => entities.assets.entities[assetId || 0]);
  const mediaAssets = entitySelectors.selectByIds<MediaAssetEntity>('mediaAssets', asset?.mediaAssetIds);

  return {
    asset,
    mediaAssets,
  };
};
