import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Block } from '@webfox-sc/core';
import Panel from '../shared/atoms/Panel';
import { SPACINGS } from '../../styles/theme';
import Headline from '../shared/atoms/Headline';
import Text from '../shared/atoms/Text';
import { useNewspaper } from './useNewspaper';

interface NewspaperTeaserProps {
  newspaperId: number | undefined;
}

const NewspaperTeaser: React.FC<NewspaperTeaserProps> = ({ newspaperId }) => {
  const navigate = useNavigate();
  const { newspaper } = useNewspaper(newspaperId);

  if (!newspaper) {
    return null;
  }

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/newspaper/${newspaper?.id}`);
  };

  return (
    <Panel verticalStretch padding={SPACINGS.XL}>
      <Block justify="center">
        <Headline>
          <a href={`/newspaper/${newspaper.id}`} onClick={handleOnClickLink}>
            {newspaper?.name}
          </a>
        </Headline>
      </Block>
      <Block paddingTop={SPACINGS.S}>
        <Text textAlign="center">
          {newspaper.issueIds?.length === 0 ? (
            <em>Keine Ausgaben vorhanden</em>
          ) : newspaper.issueIds?.length === 1 ? (
            '1 Ausgabe'
          ) : (
            `${newspaper.issueIds?.length} Ausgaben`
          )}
        </Text>
      </Block>
    </Panel>
  );
};

export default NewspaperTeaser;
