/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { apiUrl } from '../../app/api';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useAppSelector } from '../../app/hooks';
import { selectAuthState } from '../../app/slices/authSlice';
import useAPIError from '../apiError/useAPIError';
import { COLORS } from '../../styles/theme';
import { MdContentCopy } from 'react-icons/md';

const Container = styled.div`
  margin: 0px auto;
  padding: 20px;
  border: 2px solid ${COLORS.MID_GREY};
  background: #fff;
`;

const ChatWindow = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #ccc;
  background: #f2f2f2;
  display: flex;
  flex-flow: column;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${({ isUser }: { isUser: boolean }) => (isUser ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin: 5px 0;
  &:hover button {
    visibility: visible;
  }
`;

const Message = styled.div<{ isUser: boolean }>`
  padding: 10px;
  margin: 5px 0;
  background: ${({ isUser }) => (isUser ? '#add8e6' : '#ffffff')};
  align-self: ${({ isUser }) => (isUser ? 'flex-end' : 'flex-start')};
  max-width: 80%;
  white-space: pre-wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  cursor: pointer;
  visibility: hidden;
  margin-left: 12px;
  color: ${COLORS.DARK};
`;

const TextInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

interface MessageType {
  text: string;
  isUser: boolean;
}

interface MessageContentResponse {
  value: string;
  annotations: AnnotationResponse[];
}

interface MessageResponse {
  messages: MessageContentResponse[];
  threadId: string;
}

interface AnnotationResponse {
  start_index: number;
  end_index: number;
  text: string;
  type: string;
  file_citation: AnnotationFileCitationResponse;
}

interface AnnotationFileCitationResponse {
  file_id: string;
}

interface ChatGPTComponentProps {
  articleId: number;
  sectionContent: string | undefined;
}

const ChatGPTComponent: FC<ChatGPTComponentProps> = ({ articleId, sectionContent }) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string | null>(null);
  const { addError } = useAPIError();

  const { jwt } = useAppSelector(selectAuthState);

  const handleSend = async () => {
    if (!input.trim()) return;

    const newMessage: MessageType = { text: input, isUser: true };
    setMessages([...messages, newMessage]);
    setInput('');

    setLoading(true);
    try {
      const response = await axios.post<MessageResponse>(
        `${apiUrl}/articles/${articleId}/chat`,
        {
          message: input,
          threadId,
          sectionContent: !!threadId ? null : sectionContent,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const botMessages = response.data.messages.map((msg) => ({
        text: msg.value,
        isUser: false,
      }));
      setMessages((prevMessages) => [...prevMessages, ...botMessages]);
      setThreadId(response.data.threadId);
    } catch (error) {
      addError((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSend();
  };

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <Container>
      <ChatWindow>
        {messages.map((message, index) => (
          <MessageContainer key={index} isUser={message.isUser}>
            <Message isUser={message.isUser}>{message.text}</Message>
            {!message.isUser && (
              <CopyButton onClick={() => handleCopy(message.text)} ariaLabel="Kopieren" title="Kopieren">
                <MdContentCopy />
              </CopyButton>
            )}
          </MessageContainer>
        ))}
      </ChatWindow>
      <InputContainer>
        <TextInput
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
        />
        <ButtonPrimary label="Send" onClick={handleSend} disabled={loading} />
      </InputContainer>
    </Container>
  );
};

export default ChatGPTComponent;
