import { Group } from '@webfox-sc/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import Text from './Text';

interface StyledLabelProps {
  background?: string;
}

const StyledLabel = styled.div<StyledLabelProps>(({ background }) => {
  return {
    display: 'inline-block',
    boxSizing: 'border-box',
    minWidth: '112px',
    padding: '5px 16px 3px 16px',
    background: background || COLORS.DARK,
  };
});

interface TypeLabelProps {
  primaryLabel?: string;
  secondaryLabel?: string;
}

const TypeLabel: React.FC<TypeLabelProps> = ({ primaryLabel, secondaryLabel }) => {
  if (!primaryLabel && !secondaryLabel) {
    return null;
  }

  return (
    <Group nowrap>
      {primaryLabel && (
        <StyledLabel background={COLORS.DARK}>
          <Text variant="label-small" color={COLORS.WHITE} textAlign="center">
            {primaryLabel}
          </Text>
        </StyledLabel>
      )}
      {secondaryLabel && (
        <StyledLabel background={COLORS.MID_GREY}>
          <Text variant="label-small" color={COLORS.WHITE} textAlign="center">
            {secondaryLabel}
          </Text>
        </StyledLabel>
      )}
    </Group>
  );
};

export default TypeLabel;
