import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Block } from '@webfox-sc/core';
import { IconPlus } from '@webfox-sc/icons';
import { useIssuePages } from './useIssuePages';
import { SPACINGS } from '../../styles/theme';
import IssuePage from './IssuePage';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useAppDispatch } from '../../app/hooks';
import { deletePage, updatePage } from '../../app/slices/pagesSlice';
import { refetchIssue } from '../../app/slices/issuesSlice';
import Panel from '../shared/atoms/Panel';
import Text from '../shared/atoms/Text';
import { useIssue } from './useIssue';
import useAPIError from '../apiError/useAPIError';

const StyledGrid = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: '24px',
});

const StyledGridItem = styled.div({
  flex: '0 0 calc((100% - 24px) / 2)',
});

interface IssuePagesProps {
  issueId: number | undefined;
}

const IssuePages: React.FC<IssuePagesProps> = ({ issueId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { issue } = useIssue(issueId);
  const { pagesSorted, pagesSortedIds } = useIssuePages(issueId);

  const handleOnClickDeletePage = async (pageId: number | undefined) => {
    if (!!issueId && !!pageId) {
      /**
       * delete page
       */
      const deletePageResponse = await dispatch(deletePage(pageId));
      if (deletePageResponse.payload?.error) {
        addError(deletePageResponse.payload.error.message);
      }

      /**
       * refetch issue
       */
      const fetchIssueResponse = await dispatch(refetchIssue(issueId));
      if (fetchIssueResponse.payload?.error) {
        addError(fetchIssueResponse.payload.error.message);
      }
    }
  };

  const handleOnPageMoveTo = async (pageId: number, targetIndex: number) => {
    if (!!issueId && !!pageId) {
      const pageIndex = pagesSortedIds.indexOf(pageId);
      const page: PageEntity | undefined = pagesSorted[pageIndex];
      const targetIndexPage: PageEntity | undefined = pagesSorted[targetIndex - 1];

      // console.log('#------');
      // console.log('#pageId', pageId);
      // console.log('#pageIndex', pageIndex);
      // console.log('#targetIndex', targetIndex);

      if (targetIndex === 0) {
        /**
         * move page to page number 1 (e.g. 10 to 1)
         */
        const response = await dispatch(
          updatePage(pageId, {
            number: 1,
            issue: issueId,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      } else if (targetIndex === pageIndex + 2 && pageIndex !== -1) {
        /**
         * swap pages next to each other (e.g. 1 to 2 / 2 to 1)
         */
        const response = await dispatch(
          updatePage(targetIndexPage.id, {
            number: page.number,
            issue: issueId,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      } else if (targetIndexPage && targetIndex > 0) {
        /**
         * insert page between 2 pages
         */
        const targetPageNumber = (targetIndexPage.number || 0) + (targetIndexPage.count || 1);
        const response = await dispatch(
          updatePage(pageId, {
            number: targetPageNumber,
            issue: issueId,
          })
        );
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }

      const response = await dispatch(refetchIssue(issueId));
      if (response.payload?.error) {
        addError(response.payload.error.message);
      }
    }
  };

  return (
    <>
      {pagesSorted.length > 0 ? (
        <>
          <StyledGrid>
            {pagesSortedIds.map((pageId, index) => {
              return (
                <StyledGridItem key={pageId}>
                  <IssuePage
                    pageId={pageId}
                    index={index}
                    onClickDelete={handleOnClickDeletePage}
                    onPageMoveTo={handleOnPageMoveTo}
                  />
                </StyledGridItem>
              );
            })}
          </StyledGrid>
          {issue?.status !== 'published' && (
            <Block paddingTop={SPACINGS.S}>
              <ButtonPrimary
                label="Seiten erstellen"
                icon={IconPlus}
                onClick={() => navigate(`/issue/${issueId}/create/pages`)}
              />
            </Block>
          )}
        </>
      ) : (
        <Block>
          <Panel padding={SPACINGS.XL}>
            <Text>Für diese Ausgabe sind keine Seiten oder Seitenfolgen vorhanden.</Text>
            {issue?.status !== 'published' && (
              <Block paddingTop={SPACINGS.M}>
                <ButtonPrimary
                  label="Seiten erstellen"
                  icon={IconPlus}
                  onClick={() => navigate(`/issue/${issueId}/create/pages`)}
                />
              </Block>
            )}
          </Panel>
        </Block>
      )}
    </>
  );
};

export default IssuePages;
