import React, { useState } from 'react';
import styled from 'styled-components';
import { SPACINGS } from '../../styles/theme';
import IssueElementBoxSwitch from './IssueElementBoxSwitch';
import IssueElementBoxArticles from './IssueElementBoxArticles';
import IssueElementBoxPages from './IssueElementBoxPages';

const StyledBox = styled.div({
  display: 'flex',
  flexFlow: 'column',
  gap: SPACINGS.XXS,
  maxHeight: '100%',
  height: '100%',
});

const IssueElementBox: React.FC = () => {
  const [selectedList, setSelectedList] = useState<'articles' | 'pages'>('articles');

  return (
    <StyledBox>
      <IssueElementBoxSwitch onChange={setSelectedList} />
      {selectedList === 'articles' && <IssueElementBoxArticles />}
      {selectedList === 'pages' && <IssueElementBoxPages />}
    </StyledBox>
  );
};

export default React.memo(IssueElementBox);
