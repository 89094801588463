import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import Inline from '../atoms/Inline';
import Text from '../atoms/Text';

const StyledContent = styled.div({
  transition: 'opacity 0.3s ease',
});

interface LoadingWrapperProps {
  requestStatus: RequestStatus;
  errorCode?: string;
  errorText?: string;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ requestStatus, errorCode, errorText, children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  if (!isMounted) {
    return null;
  }

  return (
    <>
      {requestStatus === 'failed' ? (
        <Text>
          {errorCode && (
            <>
              <Inline color={COLORS.RED}>{errorCode}</Inline>
              {' | '}
            </>
          )}
          {errorText || 'Es ist ein Fehler aufgetreten.'}
        </Text>
      ) : (
        <StyledContent style={{ opacity: requestStatus === 'idle' ? 1 : 0 }}>{children}</StyledContent>
      )}
    </>
  );
};

export default LoadingWrapper;
