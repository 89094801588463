import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, Group } from '@webfox-sc/core';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingWrapper from '../shared/molecules/LoadingWrapper';
import Headline from '../shared/atoms/Headline';
import { useCurrentNewspaper } from './useCurrentNewspaper';
import { fetchNewspaper, selectNewspapersState } from '../../app/slices/newspapersSlice';
import { SPACINGS } from '../../styles/theme';
import NewspaperIssues from './NewspaperIssues';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import useAPIError from '../apiError/useAPIError';

interface NewspaperProps {
  viewMode: NewspaperViewMode;
}

const Newspaper: React.FC<NewspaperProps> = ({ viewMode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { addError } = useAPIError();
  const { fetchNewspaperStatus } = useAppSelector(selectNewspapersState);
  const { newspaperId, newspaper } = useCurrentNewspaper();

  useEffect(() => {
    (async () => {
      if (newspaperId) {
        const response = await dispatch(fetchNewspaper(newspaperId));
        if (response.payload?.error) {
          addError(response.payload.error.message);
        }
      }
    })();
  }, [addError, dispatch, newspaperId]);

  return (
    <LoadingWrapper
      requestStatus={fetchNewspaperStatus}
      errorCode="404"
      errorText={'Die Ausgabe konnte nicht geladen werden.'}
    >
      <Block justify="center" position="relative">
        <Headline variant="H1">{newspaper?.name}</Headline>
      </Block>

      <Group paddingTop={SPACINGS.L} spacing={SPACINGS.XXS} justify="center">
        <ButtonSecondary
          label="Zukünftige Ausgaben"
          active={viewMode === 'not-published'}
          onClick={() => navigate(`/newspaper/${newspaper?.id}`)}
        />
        <ButtonSecondary
          label="Veröffentlichte Ausgaben"
          active={viewMode === 'published'}
          onClick={() => navigate(`/newspaper/${newspaper?.id}/archive`)}
        />
      </Group>

      <Block paddingTop={SPACINGS.L} paddingBottom={SPACINGS.XXXL}>
        <NewspaperIssues newspaperId={newspaper?.id} viewMode={viewMode} />
      </Block>
    </LoadingWrapper>
  );
};

export default Newspaper;
