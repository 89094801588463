/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dictionary } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useMemo } from 'react';
import { EntitiesState } from './store';
import { useAppSelector } from './hooks';

type EntitiesStateKey = string & keyof EntitiesState;

const useSelectEntities = <T = any>(entitiesStateKey: EntitiesStateKey): Dictionary<T> =>
  useAppSelector(({ entities }) => entities[entitiesStateKey]?.entities) as unknown as Dictionary<T>;

const useSelectAll = <T = any>(entitiesStateKey: EntitiesStateKey): T[] => {
  const entitiesLookupTable = useAppSelector(({ entities }) => entities[entitiesStateKey]?.entities);
  return useMemo(() => {
    return Object.values(entitiesLookupTable || {});
  }, [entitiesLookupTable]);
};

const useSelectById = <T = any>(entitiesStateKey: EntitiesStateKey, id: number | undefined): T | undefined => {
  return useAppSelector(({ entities }) => entities[entitiesStateKey]?.entities[id || 0]) as T | undefined;
};

const useSelectByIds = <T = any>(entitiesStateKey: EntitiesStateKey, ids: number[] | undefined): T[] => {
  const entitiesLookupTable = useAppSelector(({ entities }) => entities[entitiesStateKey]?.entities);
  return useMemo(() => {
    const result: T[] = [];
    (ids || []).forEach((id) => {
      const entity = entitiesLookupTable[id] as T | undefined;
      if (entity) {
        result.push(entity);
      }
    });
    return result;
  }, [entitiesLookupTable, ids]);
};

const useSelectByFilter = <T = any>(entitiesStateKey: EntitiesStateKey, predicate: any): T[] => {
  const entitiesLookupTable = useAppSelector(({ entities }) => entities[entitiesStateKey]?.entities);
  return useMemo(() => {
    return _.filter(entitiesLookupTable, predicate) as unknown as T[];
  }, [entitiesLookupTable, predicate]);
};

export const entitySelectors = {
  selectEntities: useSelectEntities,
  selectAll: useSelectAll,
  selectById: useSelectById,
  selectByIds: useSelectByIds,
  selectByFilter: useSelectByFilter,
};
