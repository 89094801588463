import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { useContext, useRef } from 'react';
import styled from 'styled-components';
import { AdviceOverlay } from './AdviceOverlay';
import { AdviceOverlayContext } from './AdviceOverlayProvider';
import { replaceAdviceNodeWithText } from './utils';

interface AdviceNodeProps {
  node: Node;
  editor: Editor;
  getPos: () => number;
  deleteNode: () => void;
}

const AdviceWrapper = styled.span(({ type }) => ({
  position: 'relative',
  textDecorationLine: 'underline',
  textDecorationStyle: 'wavy',
  textDecorationColor: type === 'spell' ? '#f00' : '#0f0',
}));

export const AdviceNode: React.FC<AdviceNodeProps> = ({ node, editor, getPos, deleteNode }) => {
  const compRef = useRef<HTMLSpanElement | null>(null);
  const { openOverlay, setOpenOverlay, editorDimensions } = useContext(AdviceOverlayContext);
  const { type, key } = node.attrs;
  const advice = editor.storage.adviceExtension[key];

  const onSelect = (text: string) => {
    const from = getPos();
    replaceAdviceNodeWithText(editor, from, text, key, deleteNode);
  };

  const onIgnore = () => {
    const from = getPos();
    replaceAdviceNodeWithText(editor, from, node.textContent, key, deleteNode);
  };

  return (
    <NodeViewWrapper as="span">
      <AdviceWrapper type={type} ref={compRef} onClick={() => setOpenOverlay(openOverlay === key ? undefined : key)}>
        <NodeViewContent as="span" />
        {compRef.current && openOverlay === key && (
          <AdviceOverlay
            onSelect={onSelect}
            onIgnore={onIgnore}
            onClose={() => setOpenOverlay(undefined)}
            {...advice}
            wrapperRect={editorDimensions}
            anchorRect={compRef.current.getBoundingClientRect()}
          />
        )}
      </AdviceWrapper>
    </NodeViewWrapper>
  );
};
