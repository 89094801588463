/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { COLORS, STYLES } from '../../../styles/theme';

const selectStyles = {
  control: (base: any, { isFocused }) => {
    return {
      ...base,
      appearance: 'none',
      background: COLORS.WHITE,
      boxSizing: 'border-box',
      width: '100%',
      minWidth: '200px',
      padding: 0,
      border: `2px solid ${isFocused ? COLORS.GREY : COLORS.MID_GREY}`,
      borderRadius: '3px',
      boxShadow: 'none',
      cursor: 'pointer',
      transition: 'border-color 0.2s ease',
      '&:hover': {
        border: `2px solid ${isFocused ? COLORS.GREY : COLORS.MID_GREY}`,
      },
    };
  },
  valueContainer: (base: any) => {
    return {
      ...base,
      padding: '0 10px',
    };
  },
  placeholder: (base: any) => {
    return {
      ...base,
      fontFamily: STYLES.FONT_FAMILY,
      fontStyle: 'italic',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '5px 0 3px 0',
      color: COLORS.GREY,
    };
  },
  input: (base: any) => {
    return {
      ...base,
      height: '32px',
      margin: '4px 6px',
      background: 'transparent',
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '5px 0 3px 0',
      color: COLORS.DARK,
    };
  },
  singleValue: (base: any) => {
    return {
      ...base,
      padding: '5px 0 3px 0',
      paddingLeft: 0,
      paddingRight: 0,
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '.08em',
      textTransform: 'uppercase',
      color: COLORS.DARK,
    };
  },
  indicatorsContainer: (base: any) => {
    return {
      ...base,
      padding: 0,
      margin: 0,
      alignItems: 'stretch',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  dropdownIndicator: (base: any) => {
    return {
      ...base,
      width: '36px',
      height: '36px',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      svg: {
        width: '24px',
        height: '24px',
        verticalAlign: 'top',
        color: COLORS.DARK,
      },
      '&:hover': {
        opacity: 0.8,
      },
    };
  },
  menu: (base: any) => {
    return {
      ...base,
      marginTop: '4px',
      marginBottom: '4px',
      padding: 0,
      borderRadius: '3px',
      border: `2px solid ${COLORS.MID_GREY}`,
      boxShadow: 'none',
    };
  },
  menuList: (base: any) => {
    return {
      ...base,
      padding: 0,
    };
  },
  option: (base: any, { isSelected, isFocused }: any) => {
    return {
      ...base,
      fontFamily: STYLES.FONT_FAMILY,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '.08em',
      textTransform: 'uppercase',
      padding: '5px 12px 3px 12px',
      color: isSelected ? COLORS.GREY : COLORS.DARK,
      backgroundColor: isFocused ? COLORS.SILVER : COLORS.WHITE,
      cursor: 'pointer',
    };
  },
};

export default selectStyles;
