import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { newspaperSchema } from '../schemas';
import { RootState } from '../store';

export const fetchNewspapers = (): RequestEntitiesAction<NewspaperData[]> =>
  requestEntities({
    method: 'GET',
    path: '/newspapers',
    schema: [newspaperSchema],
    type: 'fetchNewspapers',
  });

export const fetchNewspaper = (newspaperId: number): RequestEntitiesAction<NewspaperData> =>
  requestEntities({
    method: 'GET',
    path: `/newspapers/${newspaperId}`,
    schema: newspaperSchema,
    type: 'fetchNewspaper',
  });

export const refetchNewspaper = (newspaperId: number): RequestEntitiesAction<NewspaperData> =>
  requestEntities({
    method: 'GET',
    path: `/newspapers/${newspaperId}`,
    schema: newspaperSchema,
  });

export interface NewspapersState extends EntityState<NewspaperEntity> {
  fetchNewspapersStatus: RequestStatus;
  fetchNewspaperStatus: RequestStatus;
}

const newspapersAdapter = createEntityAdapter<NewspaperEntity>();

const initialState: NewspapersState = newspapersAdapter.getInitialState({
  fetchNewspapersStatus: 'idle',
  fetchNewspaperStatus: 'idle',
});

export const newspapersSlice = createSlice({
  name: 'newspapers',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchNewspapers') {
          state.fetchNewspapersStatus = 'loading';
        } else if (action.meta.arg.type === 'fetchNewspaper') {
          state.fetchNewspaperStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchNewspapers') {
          state.fetchNewspapersStatus = 'idle';
        } else if (action.meta.arg.type === 'fetchNewspaper') {
          state.fetchNewspaperStatus = 'idle';
        }
        if (action.payload.entities?.newspapers) {
          newspapersAdapter.upsertMany(state, action.payload.entities.newspapers);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchNewspapers') {
          state.fetchNewspapersStatus = 'failed';
        } else if (action.meta.arg.type === 'fetchNewspaper') {
          state.fetchNewspaperStatus = 'failed';
        }
      });
  },
});

export const selectNewspapersState = (state: RootState): NewspapersState => state.entities.newspapers;
export default newspapersSlice.reducer;
