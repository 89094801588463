import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';

export const useNewspapers = (): {
  newspapers: NewspaperEntity[];
  newspapersSorted: NewspaperEntity[];
} => {
  const newspapers = entitySelectors.selectAll<NewspaperEntity>('newspapers');

  const newspapersSorted = useMemo(() => {
    return _orderBy(newspapers, [(newspaper) => newspaper.name], ['asc']);
  }, [newspapers]);

  return {
    newspapers,
    newspapersSorted,
  };
};
