import { createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { requestEntities, RequestEntitiesAction } from '../requestEntities';
import { storySchema } from '../schemas';
import { RootState } from '../store';

export const fetchStory = (storyId: number): RequestEntitiesAction<StoryData> =>
  requestEntities({
    method: 'GET',
    path: `/stories/${storyId}`,
    schema: storySchema,
    type: 'fetchStory',
  });

export const updateStory = (
  storyId: number,
  data: { name?: string; description?: string; tags?: number[] }
): RequestEntitiesAction<StoryData> =>
  requestEntities({
    method: 'PUT',
    path: `/stories/${storyId}`,
    data,
    schema: storySchema,
  });

export interface StoriesState extends EntityState<StoryEntity> {
  fetchStoryStatus: RequestStatus;
}

const storiesAdapter = createEntityAdapter<StoryEntity>();

const initialState: StoriesState = storiesAdapter.getInitialState({
  fetchStoryStatus: 'idle',
});

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestEntities.pending, (state, action) => {
        if (action.meta.arg.type === 'fetchStory') {
          state.fetchStoryStatus = 'loading';
        }
      })
      .addCase(requestEntities.fulfilled, (state, action) => {
        if (action.meta.arg.type === 'fetchStory') {
          state.fetchStoryStatus = 'idle';
        }
        if (action.payload.entities?.stories) {
          storiesAdapter.upsertMany(state, action.payload.entities.stories);
        }
      })
      .addCase(requestEntities.rejected, (state, action) => {
        if (action.meta.arg.type === 'fetchStory') {
          state.fetchStoryStatus = 'failed';
        }
      });
  },
});

export const selectStoriesState = (state: RootState): StoriesState => state.entities.stories;
export default storiesSlice.reducer;
