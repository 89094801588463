import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import { Block, Group, GroupItem } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import Text from '../shared/atoms/Text';
import { useArticle } from '../article/useArticle';
import { useState } from 'react';
import { useStatus } from '../status/useStatus';
import StatusLabel from '../shared/atoms/StatusLabel';
import { useIssue } from './useIssue';
import { useCurrentIssue } from './useCurrentIssue';

const StyledDragSource = styled.div({
  cursor: 'grab',
});

interface IssueArticleProps {
  articleId: number | undefined;
  pageId?: number;
}

const IssueArticle: React.FC<IssueArticleProps> = ({ articleId, pageId }) => {
  const navigate = useNavigate();
  const { issueId } = useCurrentIssue();
  const { articleIsWithinIssueDate } = useIssue(issueId);
  const { article, ressort, contentType } = useArticle(articleId);
  const { statusLabelText, statusLabelBackground } = useStatus({
    status: article?.status,
  });
  const [didDrop, setDidDrop] = useState(false);

  const articleDragItem: ArticleDragItem = { id: articleId, ressortId: article?.ressortId, pageId };

  const [{ isDragging }, dragSource] = useDrag({
    type: 'issueArticle',
    item: articleDragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      setDidDrop(monitor.didDrop());
    },
  });

  // checks if article date (or expiration date) is within issue date
  const isSuitableForIssueDate = useMemo(() => {
    return articleIsWithinIssueDate(article);
  }, [article, articleIsWithinIssueDate]);

  const handleOnClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/article/${article?.id}`);
  };

  return (
    <StyledDragSource ref={dragSource} style={{ opacity: isDragging || didDrop ? 0 : 1 }}>
      <Block
        background={isSuitableForIssueDate ? COLORS.SILVER : COLORS.LIGHT_RED}
        border={isSuitableForIssueDate ? undefined : `2px dashed ${COLORS.RED}`}
        padding={SPACINGS.XS}
      >
        <Group hSpacing={SPACINGS.XS} justify="space-between" alignItems="center">
          <GroupItem>
            <Text variant="label-small">{ressort?.name ? ressort?.name : <em>Kein Ressort</em>}</Text>
          </GroupItem>
          <GroupItem>
            <StatusLabel variant="small" background={statusLabelBackground}>
              {statusLabelText}
            </StatusLabel>
          </GroupItem>
        </Group>
        <Block paddingTop={SPACINGS.XXXS}>
          <Text>
            <a href={`/article/${article?.id}`} onClick={handleOnClickLink}>
              {article?.name}
            </a>
          </Text>
        </Block>
        <Group paddingTop={SPACINGS.XXXS} justify="space-between">
          <Text variant="small">{contentType?.name ? contentType.name : <em>Kein Stiltyp</em>}</Text>
          <Text variant="small">
            {article?.lines || 0} {article?.lines === 1 ? 'Zeile' : 'Zeilen'} | Rang: {article?.rank}
          </Text>
        </Group>
      </Block>
    </StyledDragSource>
  );
};

export default IssueArticle;
