import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Group, GroupItem } from '@webfox-sc/core';
import { IconClose } from '@webfox-sc/icons';
import { COLORS, SPACINGS } from '../../styles/theme';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import MediaAssetPanel from './MediaAssetPanel';
import { useCallback } from 'react';
import Text from '../shared/atoms/Text';

interface StyledDropzoneProps {
  isDragActive: boolean;
}

const StyledDropzone = styled.div<StyledDropzoneProps>(({ isDragActive }) => {
  return {
    boxSizing: 'border-box',
    height: '272px',
    padding: SPACINGS.M,
    border: `2px dashed ${isDragActive ? COLORS.BLUE : COLORS.MID_GREY}`,
    background: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };
});

interface FormSectionMediaAssetFileProps {
  defaultFile?: FileEntity;
  paddingTop?: string;
  onChangeFile?: (file?: File) => void;
}

const FormSectionMediaAssetFile: React.FC<FormSectionMediaAssetFileProps> = ({
  defaultFile,
  paddingTop,
  onChangeFile,
}) => {
  const [file, setFile] = useState<File | FileEntity | undefined | null>(defaultFile);

  const [fileImgSrc, setFileImgSrc] = useState<string | ArrayBuffer | null | undefined>(defaultFile?.url || undefined);

  const onFilesDrop = useCallback(
    (files: File[]) => {
      setFileImgSrc(undefined);
      const firstFile = files && files[0];

      if (firstFile) {
        const mimePrimary = firstFile?.type ? firstFile?.type.split('/')[0] : undefined;

        if (mimePrimary === 'image') {
          const reader = new FileReader();
          reader.onload = (readerEvent) => {
            setFileImgSrc(readerEvent?.target?.result);
          };
          reader.readAsDataURL(firstFile);
        }

        setFile(firstFile);
        if (typeof onChangeFile === 'function') {
          onChangeFile(firstFile);
        }
      }
    },
    [onChangeFile]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    onDrop: onFilesDrop,
    multiple: false,
    preventDropOnDocument: true,
  });

  const handleOnRemoveFile = () => {
    setFile(undefined);
    if (typeof onChangeFile === 'function') {
      onChangeFile(undefined);
    }
  };

  return (
    <FormSection title="Datei *" paddingTop={paddingTop}>
      {file ? (
        <Group spacing={SPACINGS.XXS}>
          <GroupItem grow>
            <MediaAssetPanel
              variant="file"
              fileImageSrc={fileImgSrc as string}
              fileName={file.name}
              fileMime={file instanceof File ? (file as File).type : (file as FileEntity).mime}
              fileSizeKB={file instanceof File ? file.size / 1024 : file.size}
            />
          </GroupItem>
          <GroupItem>
            <ButtonPrimary ariaLabel="Entfernen" icon={IconClose} onClick={handleOnRemoveFile} />
          </GroupItem>
        </Group>
      ) : (
        <StyledDropzone {...getRootProps()} isDragActive={isDragActive && isDragAccept}>
          <input {...getInputProps()} />
          <Text color={COLORS.GREY} textAlign="center">
            <em>Ziehe eine Datei in diesen Bereich oder klicke, um eine Datei auszuwählen.</em>
          </Text>
        </StyledDropzone>
      )}
    </FormSection>
  );
};

export default FormSectionMediaAssetFile;
