import { useAppSelector } from '../../app/hooks';
import { getDateFormatted } from '../../utils/date';
import { useCallback } from 'react';

export const useIssue = (
  issueId: number | undefined
): {
  issue?: IssueEntity;
  publicationDateFormatted?: string;
  publicationDateIsPast?: boolean;
  publicationTimeISOStart?: string;
  publicationTimeISOEnd?: string;
  articleIsWithinIssueDate: (article: ArticleEntity | undefined) => boolean;
} => {
  const issue = useAppSelector(({ entities }) => entities.issues.entities[issueId || 0]);

  const publicationDateFormatted = getDateFormatted(issue?.date);

  let publicationTimeISOStart: string | undefined = undefined;
  let publicationTimeISOEnd: string | undefined = undefined;
  if (issue?.date) {
    const date = new Date(issue.date);
    publicationTimeISOStart = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000).toISOString();

    const dateEnd = new Date(issue.date);
    dateEnd.setDate(dateEnd.getDate() + 1);
    publicationTimeISOEnd = new Date(dateEnd.getTime() + dateEnd.getTimezoneOffset() * 60 * 1000).toISOString();
  }

  const now = new Date();
  const issueDate = new Date(issue?.date || 0);
  const publicationDateIsPast = now.getTime() > issueDate.getTime();

  const articleIsWithinIssueDate = useCallback(
    (article: ArticleEntity | undefined) => {
      if (!article?.publicationTime || !publicationTimeISOStart || !publicationTimeISOEnd) {
        return false;
      }

      const articleDate = new Date(article.publicationTime);
      const issueDateEnd = new Date(publicationTimeISOEnd);

      if (articleDate > issueDateEnd) {
        return false;
      }

      const issueDateStart = new Date(publicationTimeISOStart);
      if (article.expirationTime) {
        const expirationDate = new Date(article.expirationTime);
        return expirationDate >= issueDateStart;
      }

      return articleDate >= issueDateStart;
    },
    [publicationTimeISOEnd, publicationTimeISOStart]
  );

  return {
    issue,
    publicationDateFormatted,
    publicationDateIsPast,
    publicationTimeISOStart,
    publicationTimeISOEnd,
    articleIsWithinIssueDate,
  };
};
