import { Extension, Node, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands {
    suggestionParagraph: {
      removeSuggestionsOrLoadingIndicatorNodes: () => void;
      setLoadingNode: (isLoading: boolean) => void;
      insertTextNode: (text: string) => void;
      insertSuggestionNode: (text: string) => void;
      updateSuggestionNodeContentInStorage: () => void;
      updateIsLoadingVisibleInStorage: () => void;
    };
  }
}

const LoadingIndicatorNode = Node.create({
  name: 'loadingIndicator',
  inline: true,
  group: 'inline',
  content: 'text*',
  parseHTML() {
    return [{ tag: 'span.loading-indicator' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, {}), 0];
  },
  addAttributes() {
    return {
      color: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          return {
            style: `color:${attributes.color};`,
          };
        },
        parseHTML: (element) => ({
          color: element.style.color.replace(/['"]+/g, ''),
        }),
      },
    };
  },
});

const SuggestionNode = Node.create({
  name: 'suggestionParagraph',
  inline: true,
  group: 'inline',
  content: 'text*',
  parseHTML() {
    return [{ tag: 'span.suggestion-paragraph' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, {}), 0];
  },
  addAttributes() {
    return {
      color: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          return {
            style: `color:${attributes.color};`,
          };
        },
        parseHTML: (element) => ({
          color: element.style.color.replace(/['"]+/g, ''),
        }),
      },
    };
  },
});

export const SuggestionExtension = Extension.create({
  addExtensions() {
    return [SuggestionNode, LoadingIndicatorNode];
  },
  addStorage() {
    return {
      suggestionNodeContent: '',
      isLoadingIndicatorVisible: false,
    };
  },
  onBlur() {
    this.editor.commands.removeSuggestionsOrLoadingIndicatorNodes();
  },
  onUpdate() {
    this.editor.commands.updateSuggestionNodeContentInStorage();
    this.editor.commands.updateIsLoadingVisibleInStorage();
  },
  addCommands() {
    return {
      removeSuggestionsOrLoadingIndicatorNodes:
        () =>
        ({ chain }) => {
          const { view } = this.editor;
          view.state.doc.descendants((node, pos) => {
            if (node.type.name === 'suggestionParagraph' || node.type.name === 'loadingIndicator') {
              chain()
                .command(({ tr }) => {
                  tr.delete(pos, pos + node.nodeSize);
                })
                .run();
            }
          });
        },
      updateSuggestionNodeContentInStorage:
        () =>
        ({ editor }) => {
          const { view } = this.editor;
          const suggestionParagraphs: string[] = [];

          view.state.doc.descendants((node) => {
            if (node.type.name === 'suggestionParagraph') {
              suggestionParagraphs.push(node.textContent);
            }
          });
          editor.storage.extension.suggestionNodeContent = suggestionParagraphs[0];
        },
      updateIsLoadingVisibleInStorage:
        () =>
        ({ editor }) => {
          const { view } = this.editor;
          const loadingIndicators: string[] = [];

          view.state.doc.descendants((node) => {
            if (node.type.name === 'loadingIndicator') {
              loadingIndicators.push(node.textContent);
            }
          });
          if (loadingIndicators.length > 0) editor.storage.extension.isLoadingIndicatorVisible = true;
          else editor.storage.extension.isLoadingIndicatorVisible = false;
        },
      insertTextNode:
        (text: string) =>
        ({ chain }) => {
          const contentToInsert = {
            type: 'text',
            text,
          };

          chain().insertContent(contentToInsert).focus().run();
        },
      insertSuggestionNode:
        (text: string) =>
        ({ chain }) => {
          const coursorPos = this.editor.state.selection.anchor;

          const contentToInsert = {
            type: 'suggestionParagraph',
            content: [
              {
                type: 'text',
                text: text,
              },
            ],
            attrs: { color: 'gray' },
          };

          chain().insertContent(contentToInsert).focus().setTextSelection(coursorPos).run();
        },
      setLoadingNode:
        (isLoading: boolean) =>
        ({ chain }) => {
          if (isLoading) {
            const coursorPos = this.editor.state.selection.anchor;

            const contentToInsert = {
              type: 'loadingIndicator',
              content: [
                {
                  type: 'text',
                  text: 'Ladevorschlag...',
                },
              ],
              attrs: { color: 'gray' },
            };

            chain().insertContent(contentToInsert).focus().setTextSelection(coursorPos).run();
          } else {
            const { view } = this.editor;
            view.state.doc.descendants((node, pos) => {
              if (node.type.name === 'loadingIndicator') {
                chain()
                  .command(({ tr }) => {
                    tr.delete(pos, pos + node.nodeSize);
                  })
                  .run();
              }
            });
          }
        },
    };
  },
});
