import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export const useCurrentNewspaper = (): {
  newspaperId?: number;
  newspaper?: NewspaperEntity;
} => {
  const { sNewspaperId } = useParams();
  const newspaperId = parseInt(sNewspaperId || '', 10);
  const newspaper = useAppSelector(({ entities }) => entities.newspapers.entities[newspaperId]);

  return {
    newspaperId,
    newspaper,
  };
};
