import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export const useCurrentAsset = (): {
  assetId?: number;
  asset?: AssetEntity;
} => {
  const { sAssetId } = useParams();
  const assetId = parseInt(sAssetId || '', 10);
  const asset = useAppSelector(({ entities }) => entities.assets.entities[assetId]);

  return {
    assetId,
    asset,
  };
};
