import _orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { entitySelectors } from '../../app/entitySelectors';
import { useAppSelector } from '../../app/hooks';

export const useNewspaperIssues = (
  newspaperId: number | undefined,
  viewMode: NewspaperViewMode
): {
  newspaper?: NewspaperEntity;
  issuesSorted: IssueEntity[];
} => {
  const newspaper = useAppSelector(({ entities }) => entities.newspapers.entities[newspaperId || 0]);
  const issues = entitySelectors.selectByIds<IssueEntity>('issues', newspaper?.issueIds);

  const issuesSorted = useMemo(() => {
    const result: IssueEntity[] = [];
    issues.forEach((issue) => {
      if (issue.date) {
        if (
          (viewMode === 'not-published' && issue.status !== 'published') ||
          (viewMode === 'published' && issue.status === 'published')
        ) {
          result.push(issue);
        }
      }
    });
    if (viewMode === 'not-published') {
      return _orderBy(result, [(issue) => issue.date], ['asc']);
    }
    return _orderBy(result, [(issue) => issue.date], ['desc']);
  }, [issues, viewMode]);

  return {
    newspaper,
    issuesSorted,
  };
};
