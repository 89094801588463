import React, { useState } from 'react';
import { Block, Collapse, Grid, GridItem, Group } from '@webfox-sc/core';
import { COLORS, SPACINGS } from '../../styles/theme';
import OverlayLayout from '../overlay/OverlayLayout';
import Input from '../shared/atoms/Input';
import FormSection from '../shared/forms/FormSection';
import ButtonPrimary from '../shared/atoms/ButtonPrimary';
import { useAppDispatch } from '../../app/hooks';
import { useOverlay } from '../overlay/useOverlay';
import ButtonSecondary from '../shared/atoms/ButtonSecondary';
import { useCurrentNewspaper } from './useCurrentNewspaper';
import Text from '../shared/atoms/Text';
import CustomDatePicker from '../shared/atoms/CustomDatePicker';
import { createIssue } from '../../app/slices/issuesSlice';
import { refetchNewspaper } from '../../app/slices/newspapersSlice';
import FormSectionStatus from '../status/FormSectionStatus';
import { getDateFormattedEn } from '../../utils/date';
import useAPIError from '../apiError/useAPIError';

const NewspaperIssueCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addError } = useAPIError();
  const { closeOverlay } = useOverlay();

  const { newspaper } = useCurrentNewspaper();

  const [name, setName] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [date, setDate] = useState<Date | undefined | null>(null);
  const [isDateError, setIsDateError] = useState(false);
  const [dateErrorText, setDateErrorText] = useState('');
  const [status, setStatus] = useState<EntityStatus>('created');

  const handleOnClickCreate = async () => {
    if (!name) {
      setIsNameError(true);
    }

    if (!date) {
      setIsDateError(true);
    }

    const now = new Date();
    const dateIsPast = date && date.getTime() < now.getTime();
    if (dateIsPast) {
      setIsDateError(true);
      setDateErrorText('Das Datum darf nicht in der Vergangenheit liegen.');
    }

    if (newspaper?.id && !!name && !!date && !dateIsPast) {
      const createIssueResponse = await dispatch(
        createIssue({
          newspaper: newspaper.id,
          name,
          date: getDateFormattedEn(date),
          status,
        })
      );
      if (createIssueResponse.payload?.error) {
        addError(createIssueResponse.payload.error.message);
      }
      const refetchNewspaperResponse = await dispatch(refetchNewspaper(newspaper.id));
      if (refetchNewspaperResponse.payload?.error) {
        addError(refetchNewspaperResponse.payload.error.message);
      }
      closeOverlay();
    }
  };

  return (
    <OverlayLayout typeLabelPrimary="Ausgabe" title="Erstellen">
      <FormSection title="Titel *">
        <Input
          name="issue-name"
          autoComplete="issue-name"
          value={name}
          error={isNameError}
          onChange={(e) => setName(e.target.value)}
          onFocus={() => setIsNameError(false)}
        />
        <Collapse show={isNameError}>
          <Block paddingTop={SPACINGS.XS}>
            <Text variant="small" color={COLORS.RED}>
              Dies ist ein Pflichtfeld.
            </Text>
          </Block>
        </Collapse>
      </FormSection>

      <Block paddingTop={SPACINGS.S}>
        <Grid columns={4} spacing={SPACINGS.S}>
          <GridItem>
            <FormSection title="Veröffentlichung *">
              <CustomDatePicker
                selected={date}
                dateFormat="dd.MM.yyyy"
                error={isDateError}
                onChange={setDate}
                onCalendarOpen={() => {
                  setIsDateError(false);
                  setDateErrorText('');
                }}
              />
              <Collapse show={isDateError}>
                <Block paddingTop={SPACINGS.XS}>
                  <Text variant="small" color={COLORS.RED}>
                    {dateErrorText || 'Dies ist ein Pflichtfeld.'}
                  </Text>
                </Block>
              </Collapse>
            </FormSection>
          </GridItem>
        </Grid>
      </Block>

      <Block paddingTop={SPACINGS.S}>
        <Grid columns={4} spacing={SPACINGS.S}>
          <GridItem>
            <FormSectionStatus
              statusSelection={['created', 'editing', 'published']}
              defaultStatus={status}
              onChange={setStatus}
            />
          </GridItem>
        </Grid>
      </Block>

      <Group nowrap spacing={SPACINGS.XXS} paddingTop={SPACINGS.XL}>
        <ButtonPrimary label="Erstellen" onClick={handleOnClickCreate} />
        <ButtonSecondary label="Abbrechen" onClick={closeOverlay} />
      </Group>
    </OverlayLayout>
  );
};

export default NewspaperIssueCreate;
